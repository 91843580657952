<script setup>
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  role: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['close']);

const $services = inject('$services');
const $toast = inject('$toast');
const $t = inject('$t');
const common_store = useCommonStore();

const form$ = ref(null);
const form = ref({});

onMounted(() => {
  form.value = { name: `copy of ${props.role.name}` };
  form$.value.update(form.value);
});

async function save() {
  try {
    const { data } = await $services.roles.duplicate({
      id: props.role.uid,
      body: {
        name: `${form.value.name}`,
        asset: props.role.asset || null,
      },
    });

    if (data?.role) {
      await common_store.update_global_data({ roles: [data.role.uid] });
      $toast({
        title: $t('Success'),
        text: $t('Role duplicated successfully'),
        type: 'success',
      });
      emit('close');
    }
  }
  catch (error) {
    logger.log('error', error);
    $toast({
      title: error?.data?.title || $t('Something went wrong'),
      text: error?.data?.message || $t('Please try again'),
      type: 'error',
    });
  }
}
</script>

<template>
  <hawk-modal-container :width="400">
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :format-load="(data) => data"
      :endpoint="save"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t('Duplicate role') }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <TextElement
            :label="$t('Role name')"
            name="name"
            :placeholder="$t('Enter role name')"
            rules="required"
          />
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('Duplicate') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
