<script setup>
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';
import EmailPopupButton from '~/forms/components/form-workflows/sidebar/email-block/email-popup-button.vue';

const props = defineProps({
  form_data: {
    type: Object,
  },
  payload_data: {
    type: Object,
  },
  steps_with_sections: {
    type: Object,
  },
  access_controls_sections: {
    type: Object,
  },
  email_forms: {
    type: Object,
  },
  is_disabled: {
    type: Boolean,
    default: false,
  },
});
defineEmits('updateAccess');
const form_workflow_store = useFormWorkflowStore();

const $t = inject('$t');

const map_actions = computed(() => {
  return {
    view: $t('Can view'),
    edit: $t('Can edit'),
    hide: $t('Hide'),
  };
});
const step_sections = computed(() => {
  return Object.values(props.steps_with_sections).filter(step => step.index < props.payload_data.step && form_workflow_store.form_blocks.includes(step.index));
});

const get_member_fields = computed(() => {
  if (props.payload_data.step)
    return form_workflow_store.get_fields(props.payload_data.step, ['member']).map(field => ({
      label: field.name,
      field_uid: field.uid,
      type: 'exclusive',
    }));
  return [];
});
</script>

<template>
  <div>
    <template v-if="payload_data.step !== 1">
      <div class="flex items-start justify-between mb-4">
        <div>
          <div class="text-sm font-semibold text-gray-700">
            {{ $t('Rollback') }}
          </div>
          <div class="text-xs text-gray-700">
            {{ $t('Allow form assignees to rollback to any of the completed steps') }}
          </div>
        </div>
        <ToggleElement
          name="allow_rollback"
          class="ml-2"
        />
      </div>
      <hr class="mb-4">
      <ObjectElement v-show="payload_data.step" name="access_controls">
        <div class="col-span-12">
          <div class="flex items-start justify-between mb-2.5">
            <div>
              <div class="text-sm font-semibold text-gray-700">
                {{ $t('Access controls') }}
              </div>
              <div class="text-xs text-gray-700">
                {{ $t('Setup view/edit/hide access to specific sections') }}
              </div>
            </div>
            <ToggleElement
              true-value="custom"
              false-value="default"
              name="type"
              class="ml-2"
              @change="$emit('initSections')"
            />
          </div>
          <div v-show="form_data.access_controls.type === 'custom'" class="mb-3">
            <div v-for="(step, i) in step_sections" :key="i" class="mb-1">
              <div class="text-sm font-semibold text-gray-600 mb-2">
                {{ step.name }}
              </div>
              <div v-for="(section, index) in step.sections" :key="index">
                <div class="flex justify-between items-center mb-3">
                  <div class="text-sm text-gray-700">
                    {{ `${index + 1}. ${section.name}` }}
                  </div>
                  <HawkMenu
                    additional_trigger_classes="!ring-0"
                    :items="[
                      {
                        label: $t('Can view'),
                        on_click: () => $emit('updateAccess', { [section.uid]: 'view' }),
                      },
                      {
                        label: $t('Can edit'),
                        on_click: () => $emit('updateAccess', { [section.uid]: 'edit' }),
                      },
                      {
                        label: $t('Hide'),
                        on_click: () => $emit('updateAccess', { [section.uid]: 'hide' }),
                      },
                    ]"
                  >
                    <template #trigger>
                      <div class="flex items-center">
                        <div
                          class="cursor-pointer font-semibold text-sm text-gray-500"
                        >
                          {{ map_actions[access_controls_sections[section.uid]] || $t('Can view') }}
                        </div>
                        <div class="ml-1.5">
                          <IconHawkChevronDown class="text-gray-600" />
                        </div>
                      </div>
                    </template>
                  </HawkMenu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ObjectElement>
      <hr v-show="payload_data.step" class="mb-4">
    </template>
    <ObjectElement name="escalations">
      <div class="col-span-12">
        <div class="flex items-start justify-between mb-4">
          <div>
            <div class="text-sm font-semibold text-gray-700">
              {{ $t('Escalate') }}
            </div>
            <div class="text-xs text-gray-700">
              {{ $t('Escalate to other members if there is no outcome within in the defined time period') }}
            </div>
          </div>
          <ToggleElement
            name="is_enabled"
            class="mx-2"
            @change="$emit('reset', 'escalations')"
          />
        </div>
        <div v-show="form_data.escalations.is_enabled">
          <RadiogroupElement
            name="escalationType"
            :items="{
              add: $t('Add members'),
              replace: $t('Replace members'),
            }"
            :add-classes="{
              RadiogroupRadio: {
                container: 'mr-5 flex !items-start text-sm',
              },
            }"
            :remove-class="{
              wrapper: ['flex-col'],
            }"
          />

          <hawk-assignee-input
            :key="form_data.escalations.is_enabled"
            class="my-4"
            format_load
            :multi="true"
            :truncate_tag_length="35"
            :options="{
              name: 'members_data',
              has_teams: true,
              placeholder: $t('Select Assignees'),
              rules: [
                {
                  required: ['escalations.is_enabled', '==', true],
                },
              ],
              messages: {
                required: $t('At least one member should be assigned to the step'),
              },
              addClasses: {
                ElementLabel: {
                  container: '!hidden',
                },
              },
              columns: {
                lg: {
                  container: 12,
                  label: 0,
                  wrapper: 12,
                },
              },
            }"
          />

          <SelectElement
            v-show="get_member_fields.length"
            name="dynamic_assignees"
            class="mb-4"
            :label="$t('Field')"
            placeholder="Select field"
            :items="get_member_fields"
            :native="false"
            :object="true"
            label-prop="label"
            value-prop="field_uid"
          >
            <template #description>
              <HawkText :content="$t('The field value will override the above selected members if a value is available for the field.')" length="100" />
            </template>
          </SelectElement>

          <TextElement
            name="wait"
            input-type="number"
            class="mb-4"
            :default="1"
            :rules="[
              'required',
              'integer',
              'min:1',
            ]"
            autocomplete="off"
            :label="$t('Escalate after')"
            @input="$event => $emit('validateNumberInput', { name: 'wait', e: $event, key: 'escalations' })"
          >
            <template #addon-after>
              {{ $t('days') }}
            </template>
          </TextElement>
          <EmailPopupButton
            :form="email_forms.escalations"
            :step_number="payload_data.step"
            :is_disabled="is_disabled"
            @updateEmail="$emit('updateEmail', { key: 'escalations', value: $event })"
          />
        </div>
      </div>
    </ObjectElement>
    <hr class="mb-4">
    <ObjectElement name="reminders">
      <div class="col-span-12">
        <div class="flex items-start justify-between mb-4">
          <div>
            <div class="text-sm font-semibold text-gray-700">
              {{ $t('Reminders') }}
            </div>
            <div class="text-xs text-gray-700">
              {{ $t('Configure reminders to send email/push notifications for the assignees to submit the form.') }}
            </div>
          </div>
          <ToggleElement
            name="is_enabled"
            class="mx-2"
            @change="$emit('reset', 'reminders')"
          />
        </div>
        <div v-show="form_data.reminders.is_enabled" class="mb-4">
          <div class="text-sm mb-2">
            {{ $t('Remind every') }}
          </div>
          <div class="flex mb-5">
            <TextElement
              name="interval"
              input-type="number"
              class="mb-4"
              :default="1"
              :rules="[
                'required',
                'integer',
                'min:1',
              ]"
              autocomplete="off"
              @input="$event => $emit('validateNumberInput', { name: 'interval', e: $event, key: 'reminders' })"
            >
              <template #addon-after>
                {{ $t('days') }}
              </template>
            </TextElement>
            <div class="text-sm font-medium mx-4 mt-2">
              {{ $t('for') }}
            </div>
            <TextElement
              name="count"
              input-type="number"
              class="mb-3"
              :default="1"
              :rules="[
                'required',
                'integer',
                'min:1',
              ]"
              autocomplete="off"
              @input="$event => $emit('validateNumberInput', { name: 'count', e: $event, key: 'reminders' })"
            >
              <template #addon-after>
                {{ $t('times') }}
              </template>
            </TextElement>
            <div class="text-sm font-medium mx-4 mt-2">
              {{ $t('after') }}
            </div>

            <TextElement
              name="wait"
              input-type="number"
              class="mb-3"
              :default="1"
              :rules="[
                'required',
                'integer',
                'min:1',
              ]"
              autocomplete="off"
              @input="$event => $emit('validateNumberInput', { name: 'wait', e: $event, key: 'reminders' })"
            >
              <template #addon-after>
                {{ $t('days') }}
              </template>
            </TextElement>
          </div>
          <EmailPopupButton
            :form="email_forms.reminders"
            :step_number="payload_data.step"
            :is_disabled="is_disabled"
            @updateEmail="$emit('updateEmail', { key: 'reminders', value: $event })"
          />
        </div>
      </div>
    </ObjectElement>
  </div>
</template>
