<script setup>
import { useModal } from 'vue-final-modal';
import { capitalize, isEqual, lowerCase } from 'lodash-es';

import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';

import GraphComponent from '~/dashboard/components/widgets/graph-widgets/graph-component.vue';
import DashboardActivitiesListModal from '~/dashboard/components/dashboard-activities-list-modal.vue';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  is_mobile_view: {
    type: Boolean,
    default: false,
  },
});

const dashboard_schedule_store = useDashboardScheduleStore();

const $services = inject('$services');
const graph_data = ref(null);
const loading = ref(false);
const payload = ref(null);

const activities_list_modal = useModal({
  component: DashboardActivitiesListModal,
});

const chart_config = computed(() => {
  return props.data?.data?.chart_config;
});
const chart_type = computed(() => {
  let type;
  switch (props.data?.data?.type) {
    case 'project_progress':
      switch (props.data?.data?.chart_type) {
        case 'donut':
          type = 'doughnut2d';
          break;
        default:
          type = 'angulargauge';
          break;
      }
      break;
    case 'project_duration':
      type = 'angulargauge';
      break;
    case 'planned_vs_actual':
      type = props.data?.data.chart_type === 'vertical_graph' ? 'stackedcolumn2d' : 'stackedbar2d';
      break;
    case 'progress_history':
      type = 'stackedcolumn2d';
      break;
    case 's_curve':
      type = props.data.data?.dual_axis ? 'mscombidy2d' : 'mscombi2d';
      break;
    case 'vertical_graph':
      type = 'stackedcolumn2d';
      break;
    case 'horizontal_bar':
      type = 'stackedbar2d';
      break;
    case 'donut':
      type = 'doughnut2d';
      break;
    case 'activities_breakdown':
      switch (props.data?.data?.chart_type) {
        case 'vertical_graph':
          type = 'scrollstackedcolumn2d';
          break;
        case 'horizontal_bar':
          type = 'stackedbar2d';
          break;
        case 'donut':
          type = 'doughnut2d';
          break;
      }
      break;
  }
  if (chart_config.value?.chart_display_mode === 'Unstacked' && type === 'stackedcolumn2d')
    return 'mscolumn2d';
  if (chart_config.value?.chart_display_mode === 'Unstacked' && type === 'stackedbar2d')
    return 'msbar2d';
  return type;
});

function x_axis_name() {
  if (props.data?.data?.chart_config?.x_label) {
    return props.data?.data?.chart_config?.x_label;
  }

  else
    if (payload.value?.x?.label) {
      let text = payload.value.x.label;
      if (text.includes('activity_code_values_map.'))
        text = text.replace('activity_code_values_map.', 'Activity code: ');

      return capitalize((text));
    }
    else { return ''; }
}

const graph_config = computed(() => ({
  chart_name: props.data.data.name,
  dimensions: {
    x: props.data.x,
    y: props.data.y,
    h: props.data.h,
  },
  renderAt: `chart-${props.id}`,
  type: chart_type.value || '',
  dataSource: {
    chart: {
      xAxisName: x_axis_name(),
      yAxisName: props.data?.data?.chart_config?.y_label || capitalize(lowerCase(payload.value?.y?.label || '')),
      // plotToolText: toolTipText(),
      numbersuffix: '',
      theme: 'fusion',
      labelDisplay: 'Auto',
      useEllipsesWhenOverflow: '0',
      yAxisValuesStep: '2',
      gaugeOuterRadius: 150,
      connectNullData: 1,
      drawAnchors: 0,
      subCaption: props.data?.data?.chart_config?.description
      || props.data.data.subCaption || '',

    },
    dashboard_index: props.data.i,
    chart_type: props.data.data.type,
    ...graph_data.value,
  },
  events: {
    dataPlotClick: (e) => {
      window.getActivities = (query) => {
        const popup_query = JSON.parse(atob(query));
        activities_list_modal.patchOptions({
          attrs: {
            id: props.id,
            name: `${e?.data?.id} - ${props.data?.data?.name} `,
            payload: props.data,
            popup_query,
            onClose() {
              activities_list_modal.close();
            },
          },
        });
        activities_list_modal.open();
      };
    },
  },
}));

async function getReports() {
  graph_data.value = null;
  loading.value = true;
  payload.value = dashboard_schedule_store.parse_schedule_form_to_server_format(props.data.data);
  try {
    const { data } = await $services.project_management.get_graph({ body: payload.value });
    graph_data.value = data?.data || null;
    loading.value = false;
  }
  catch (err) {
    logger.log({ err });
    loading.value = false;
  }
}
if (props.id === 'preview')
  watch(() => props.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        getReports();
      }, 250);
  }, { immediate: true }, { deep: true });

if (props.id !== 'preview')
  watch(() => props.data.data, (new_val, old_val) => {
    if (new_val && !isEqual(new_val, old_val))
      setTimeout(() => {
        getReports();
      }, 250);
  }, { immediate: true }, { deep: true });
</script>

<template>
  <div class="w-full h-full">
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <hawk-loader v-if="loading" />

    <GraphComponent v-else-if="graph_config?.renderAt" :id="props.id" :configuration="graph_config" :chart_config="chart_config" :group_key="payload?.group?.label" />
  </div>
</template>
