<script setup>
import { useModal } from 'vue-final-modal';
import { useCommonStore } from '~/common/stores/common.store.js';
import AssetSelection from '~/account-settings/components/shared/asset-selection-modal.vue';

const props = defineProps({
  item_details: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['update']);
const route = useRoute();

const common_store = useCommonStore();

const is_adding_asset_disabled = computed(() => {
  return route?.params?.asset_id
    ? props.item_details?.assets?.includes(route?.params?.asset_id)
    : props.item_details?.assets?.length === common_store?.assets?.length;
});

const { open: openAssetModal, close: closeAssetModal, patchOptions } = useModal({
  component: AssetSelection,
});

function AssetSelectionHandler(asset_uid, details) {
  patchOptions(
    {
      attrs: {
        asset_uid,
        resources: asset_uid ? (details?.resources?.find(resource => resource?.asset === asset_uid)?.resources || []) : [],
        selected_assets: details.assets || [],
        onClose() {
          closeAssetModal();
        },
        on_submit: async (e) => {
          assetResourceActions(e);
          closeAssetModal();
        },
      },
    },
  );
  openAssetModal();
}

function assetResourceActions(e) {
  logger.log(e);

  let assets = [...(props.item_details?.assets || [])];
  let resources = [...(props.item_details?.resources || [])];
  if (e.action === 'remove') {
    // remove the asset from assets list
    assets = assets.filter(uid => uid !== e.asset_uid);
    // remove the asset from resources list
    resources = resources.filter(item => item.asset !== e.asset_uid);
  }
  else if (e.action === 'no_access') {
    // add the asset in assets list
    if (!assets?.find(uid => uid === e.asset_uid))
      assets = [...assets, e.asset_uid];

    // add the asset in resources list
    const index = resources?.findIndex(item => item.asset === e.asset_uid);
    if (index < 0)
      resources = [...resources, { asset: e.asset_uid, resources: [] }];
    else
      resources[index] = { asset: e.asset_uid, resources: [] };
  }
  else if (e.action === 'allow_access') {
    // add the asset in assets list
    if (!assets?.find(uid => uid === e.asset_uid))
      assets = [...assets, e.asset_uid];

    // add the asset in resources list
    const index = resources?.findIndex(item => item.asset === e.asset_uid);
    if (index < 0)
      resources = [...resources, { asset: e.asset_uid, resources: e.resources }];
    else
      resources[index] = { asset: e.asset_uid, resources: e.resources };
  }

  emit('update', { assets, resources });
}

function getItems(asset_uid) {
  return (props.item_details?.resources?.find(item => item?.asset === asset_uid)?.resources)?.map((i) => {
    return { uid: i.uid, name: i.name };
  });
}
</script>

<template>
  <div v-if="item_details">
    <div class="max-w-[700px]">
      <div class="my-5">
        <div class="text-lg font-semibold text-gray-900">
          {{ $t('Assets') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ $t('Assign assets maps to team and users') }}
        </div>
      </div>

      <div>
        <div class="mb-5">
          <div class="grid gap-5 w-[800px]">
            <div v-for="asset_uid in item_details.assets" :key="asset_uid">
              <Vueform size="sm">
                <div class="flex col-span-12">
                  <TagsElement
                    :key="`${item_details.uid}_${item_details?.resources?.length}${item_details?.resources?.find(item => item.asset === asset_uid)?.resources.length}`"
                    name="assets"
                    class="w-full mr-4"
                    :default="item_details?.resources?.find(item => item?.asset === asset_uid)?.resources.map(item => item.uid) || []"
                    :items="getItems(asset_uid)"
                    value-prop="uid"
                    :show-options="false"
                    :can-clear="false"
                    :can-remove="false"
                    :disabled="true"
                    :placeholder="$t('No access to map data')"
                  >
                    <template #label>
                      <HawkText :content="common_store.get_asset(asset_uid)?.name || ' '" :length="36" />
                    </template>
                    <template #tag="{ option }">
                      <div class="border-gray-300 flex items-center whitespace-nowrap text-sm rounded-lg border py-0.5 px-1.5 mr-1 mb-1">
                        <HawkText :content="option.name" :length="36" />
                      </div>
                    </template>
                  </TagsElement>
                  <HawkButton icon type="text" class="flex-shrink-0 mr-3" @click="AssetSelectionHandler(asset_uid, item_details)">
                    <IconHawkPencilOne />
                  </HawkButton>
                  <HawkButton icon type="text" class="flex-shrink-0" @click="assetResourceActions({ asset_uid, action: 'remove' })">
                    <IconHawkTrashThree />
                  </HawkButton>
                </div>
              </Vueform>
            </div>
          </div>
        </div>
        <HawkButton
          type="text"
          :disabled="is_adding_asset_disabled"
          @click="AssetSelectionHandler(null, item_details)"
        >
          <IconHawkPlus />
          {{ $t('Add Assets') }}
        </HawkButton>
      </div>
    </div>
  </div>
</template>
