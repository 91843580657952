/*
The utility functions exported from this file abstracts away date-time formatting. It's currently adapted for
Day.js but can be easily used with another date-time library.

Note: Not all formats and presets are covered by these functions. For a complete reference to formats supported
by Day.js, refer to https://day.js.org/docs/en/display/format
*/

/* -------- TIME FROM NOW -------- */
/* There are two options to use this utility: */

// $date_relative(date, TRUE)                                   4 days
// $date_relative(date, FALSE) OR $date_relative(date)          4 days ago

/* ---------- LOCALIZED ---------- */
/* These presets adapt to the user's locale and should be preferred over their non-localized counterparts */

// L_TIME_SHORT                               8:02 PM
// L_TIME_LONG                                8:02:18 PM
// L_DATE_SHORT                               10/20/1983
// L_DATE_MED                                 Oct 20, 1983
// L_DATE_LONG                                October 20, 1983
// L_DATETIME_MED                             Oct 20, 1983 8:02 PM
// L_DATETIME_LONG                            October 20, 1983 8:02 PM
// L_DATETIME_MED_WITH_WEEKDAY                Fri, Oct 20, 1983 8:02 PM
// L_DATETIME_LONG_WITH_WEEKDAY               Friday, October 20, 1983 8:02 PM

/* ---------- NOT LOCALIZED ---------- */
/* These presets are represented as-is and DO NOT adapt to the user's locale */

// DATE_SHORT                                 20/10/1983
// DATE_MED                                   Oct 20, 1983
// DATE_LONG                                  October 20, 1983
// DATE_MED_WITH_WEEKDAY                      Fri, Oct 20, 1983
// DATE_LONG_WITH_WEEKDAY                     Friday, October 20, 1983
// TIME_SHORT                                 1:30 PM
// TIME_LONG                                  1:30:23 PM
// TIME_SHORT_WITH_OFFSET                     1:30:23 PM EDT
// TIME_LONG_WITH_OFFSET                      1:30:23 PM Eastern Daylight Time
// TIME_24_SHORT                              13:30
// TIME_24_LONG                               13:30:23
// TIME_24_SHORT_WITH_OFFSET                  13:30:23 EDT
// TIME_24_LONG_WITH_OFFSET                   13:30:23 Eastern Daylight Time
// DATETIME_SHORT                             20/10/1983, 1:30 PM
// DATETIME_SHORT_WITH_WEEKDAY                Fri, Oct 20, 1983, 1:30 PM
// DATETIME_SHORT_WITH_SECONDS                20/10/1983, 1:30:23 PM
// DATETIME_MED                               Oct 20, 1983, 1:30 PM
// DATETIME_MED_WITH_SECONDS                  Oct 20, 1983, 1:30:23 PM
// DATETIME_LONG                              October 20, 1983, 1:30 PM
// DATETIME_LONG_WITH_OFFSET                  October 20, 1983, 1:30 PM EDT
// DATETIME_LONG_WITH_SECONDS                 October 20, 1983, 1:30:23 PM
// DATETIME_LONG_WITH_WEEKDAY                 Friday, October 20, 1983, 1:30 PM
// DATETIME_LONG_WITH_SECONDS_WEEKDAY         Friday, October 20, 1983, 1:30:23 PM
// DATETIME_LONG_WITH_SECONDS_OFFSET          October 20, 1983, 1:30:23 PM EDT
// DATETIME_LONG_WITH_WEEKDAY_OFFSET          Friday, October 20, 1983, 1:30 PM Eastern Daylight Time
// DATETIME_LONG_WITH_SECONDS_WEEKDAY_OFFSET  Friday, October 20, 1983, 1:30:23 PM Eastern Daylight Time

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export const $date_relative = (date, suffix = false) => dayjs(date).fromNow(suffix);

export function $date(date, preset = 'L_DATETIME_LONG_WITH_WEEKDAY') {
  let format = preset;

  switch (preset) {
    case 'L_TIME_SHORT':
      format = 'LT';
      break;
    case 'L_TIME_LONG':
      format = 'LTS';
      break;
    case 'L_DATE_SHORT':
      format = 'l';
      break;
    case 'L_DATE_MED':
      format = 'll';
      break;
    case 'L_DATE_LONG':
      format = 'LL';
      break;
    case 'L_DATETIME_MED':
      format = 'lll';
      break;
    case 'L_DATETIME_LONG':
      format = 'LLL';
      break;
    case 'L_DATETIME_MED_WITH_WEEKDAY':
      format = 'llll';
      break;
    case 'L_DATETIME_LONG_WITH_WEEKDAY':
      format = 'LLLL';
      break;
    case 'DATE_ORDINAL':
      format = 'Do MMMM YYYY'; // 20th October 1983
      break;
    case 'DATE_SHORT':
      format = 'D/M/YYYY'; // 20/10/1983
      break;
    case 'DATE_MED':
      format = 'MMM DD, YYYY'; // Oct 20, 1983
      break;
    case 'DATE_LONG':
      format = 'MMMM DD, YYYY'; // October 20, 1983
      break;
    case 'DATE_MED_WITH_WEEKDAY':
      format = 'ddd, MMM DD, YYYY'; // Fri, Oct 20, 1983
      break;
    case 'DATE_LONG_WITH_WEEKDAY':
      format = 'dddd, MMMM DD, YYYY'; // Friday, October 20, 1983
      break;
    case 'TIME_SHORT':
      format = 'h:mm A'; // 1:30 PM
      break;
    case 'TIME_LONG':
      format = 'h:mm:s A'; // 1:30:23 PM
      break;
    case 'TIME_SHORT_WITH_OFFSET':
      format = 'h:mm:s A z'; // 1:30:23 PM EDT
      break;
    case 'TIME_LONG_WITH_OFFSET':
      format = 'h:mm:s A zzz'; // 1:30:23 PM Eastern Daylight Time
      break;
    case 'TIME_24_SHORT':
      format = 'H:mm'; // 13:30
      break;
    case 'TIME_24_LONG':
      format = 'H:mm:s'; // 13:30:23
      break;
    case 'TIME_24_SHORT_WITH_OFFSET':
      format = 'H:mm:s z'; // 13:30:23 EDT
      break;
    case 'TIME_24_LONG_WITH_OFFSET':
      format = 'H:mm:s zzz'; // 13:30:23 Eastern Daylight Time
      break;
    case 'DATETIME_SHORT':
      format = 'DD/MM/YYYY, h:mm A'; // 20/10/1983, 1:30 PM
      break;
    case 'DATETIME_SHORT_WITH_WEEKDAY':
      format = 'ddd, MMM DD, YYYY, h:mm A'; // Fri, Oct 20, 1983, 1:30 PM
      break;
    case 'DATETIME_SHORT_WITH_SECONDS':
      format = 'DD/MM/YYYY, h:mm:s A'; // 20/10/1983, 1:30:23 PM
      break;
    case 'DATETIME_MED':
      format = 'MMM D, YYYY, h:mm A'; // Oct 20, 1983, 1:30 PM
      break;
    case 'DATETIME_MED_WITH_SECONDS':
      format = 'MMM D, YYYY, h:mm:s A'; // Oct 20, 1983, 1:30:23 PM
      break;
    case 'DATETIME_LONG':
      format = 'MMMM D, YYYY, h:mm A'; // October 20, 1983, 1:30 PM
      break;
    case 'DATETIME_LONG_WITH_SECONDS':
      format = 'MMMM D, YYYY, h:mm:s A'; // October 20, 1983, 1:30:23 PM
      break;
    case 'DATETIME_LONG_WITH_WEEKDAY':
      format = 'dddd, MMMM D, YYYY, h:mm A'; // Friday, October 20, 1983, 1:30 PM
      break;
    case 'DATETIME_LONG_WITH_SECONDS_WEEKDAY':
      format = 'dddd, MMMM D, YYYY, h:mm:s A'; // Friday, October 20, 1983, 1:30:23 PM
      break;
    case 'DATETIME_LONG_WITH_SECONDS_OFFSET':
      format = 'MMMM D, YYYY, h:mm:s A z'; // October 20, 1983, 1:30:23 PM EDT
      break;
    case 'DATETIME_LONG_WITH_WEEKDAY_OFFSET':
      format = 'dddd, MMMM D, YYYY, h:mm A zzz'; // Friday, October 20, 1983, 1:30 PM Eastern Daylight Time
      break;
    case 'DATETIME_LONG_WITH_OFFSET':
      format = 'MMMM D, YYYY, h:mm A z'; // October 20, 1983, 1:30 PM EDT
      break;
    case 'DATETIME_LONG_WITH_SECONDS_WEEKDAY_OFFSET':
      format = 'dddd, MMMM D, YYYY, h:mm:s A zzz'; // Friday, October 20, 1983, 1:30:23 PM Eastern Daylight Time
      break;
    case 'DATETIME_LONG_WITH_SHORT_DAY_AND_MONTH':
      format = 'ddd, MMM DD, YYYY hh:mm A';
      break;
    case 'DATETIME_LONG_WITH_LONG_DAY_AND_MONTH':
      format = 'dddd, MMMM DD, YYYY hh:mm A';
  }

  return dayjs(date).format(format);
}

export function setDefaultTimezone(timezone) {
  if (timezone)
    dayjs.tz.setDefault(timezone);
}

export function prepareTimeZones() {
  const timezones = Intl.supportedValuesOf('timeZone');
  const data = [];

  for (const timezone of timezones)
    data.push({
      label: `[GMT ${dayjs(new Date()).tz(timezone).format('Z')}] ${timezone}`,
      value: timezone,
      offset: dayjs().tz(timezone).utcOffset(),
    });

  return data;
}
