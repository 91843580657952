<script setup>
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const emit = defineEmits(['edit']);
const { $t, route, router } = useCommonImports();
const OPERATION_MAP = {
  move: 'Move',
  stock_in: 'Stock in',
  stock_out: 'Stock out',
  no_op: 'No operation',
};

const COLUMNS = [
  {
    accessorKey: 'name',
    header: $t('Name'),
    id: 'name',
  },
  {
    accessorFn: row => OPERATION_MAP[row.stock_operation] || '-',
    header: $t('Operation'),
    id: 'stock_operation',
    static: true,
    render_as: {
      field_type: 'text',
      options: {
        classes: 'text-gray-600',
      },
    },
  },
  {
    accessorKey: 'description',
    header: $t('Description'),
    id: 'description',
    size: 400,
    render_as: {
      field_type: 'text',
      options: {
        classes: 'text-gray-600',
      },
    },
  },
  {
    accessorKey: 'members',
    header: $t('Members'),
    id: 'members',
    static: true,
    size: 400,
    render_as: {
      field_type: 'member',
      options: {
        max_badges_to_display: 2,
      },
    },
  },
];

const { workflows_map } = useInventoryStore();

const freeze_table = ref('-1');

const data = computed(() => Object.values(workflows_map));

function editHandler(transaction_type) {
  router.push({ name: 'inventory-transaction-type', params: { ...route.params, transaction_type_uid: transaction_type.uid } });
}
</script>

<template>
  <div>
    <p class="text-lg font-bold">
      {{ $t('Transaction types') }}
    </p>
    <p class="text-xs text-gray-600">
      {{ $t('Below are the different transaction types available in the system. The workflows can be customized as per your business requirements. Get in touch with our team for help.') }}
    </p>
    <div v-if="!data?.length" class="text-sm text-gray-500 mt-3">
      {{ $t('No transaction types added') }}
    </div>
    <TableWrapperVue v-else :height="300" container_class="border mt-3">
      <HawkTable
        :data="data"
        :columns="COLUMNS"
        is_gapless
        cell_height="40px"
        :non_sortable_columns="['context_menu']"
        disable_resize
        :show_menu_header="false"
        @row-clicked="($event) => editHandler($event)"
      >
        <template #name="name">
          <HawkBadge :custom_color="name.data.row.original.color">
            {{ name.data.row.original.name }}
          </HawkBadge>
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
</template>
