<script setup>
import { saveAs } from 'file-saver';
import { useAnnotationsStore } from '../store/annotations.store';
import { extractAttrFromXML } from '../utils/helper';
import useEmitter from '~/common/composables/useEmitter';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const props = defineProps({
  payload: {
    type: Object,
    default: () => ({}),
  },
});

const annotations_store = useAnnotationsStore();
const emitter = useEmitter();

const notes_annotations = computed(() => annotations_store.get_annotations.map((annotation) => {
  const annot_id = extractAttrFromXML(annotation.annotation_data, 'name');
  return {
    text: /(?<=<contents>)(.*)(?=<\/contents>)/s.exec(annotation.annotation_data)?.[0],
    annotation_type: annotation.annotation_data?.toString()?.includes('FreeText') ? 'text' : 'comment',
    ...annotation,
    id: annot_id,
  };
}).filter(note => note.text));

const is_downloading = ref(false);

const {
  $date,
  $t,
  $toast,
  common_store,
} = useCommonImports();

async function download_annotations() {
  is_downloading.value = true;
  try {
    const response = await fetch(import.meta.env.VITE_APP_LAMBDA_API_HOST, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        url: 'https://sensehawk-stage-public.s3.ap-south-1.amazonaws.com/Templates/document_annotations_template.docx',
        placeholders: {
          ...(props.payload || {}),
          annotations: notes_annotations.value.map(note => (
            {
              user: common_store.get_user_or_team_name(note.owner),
              action: 'added a note',
              timestamp: $date(note.created_at, 'DATETIME_MED'),
              has_message: true,
              message: note.text,
            }
          )),
        },
      }),
    });
    const data = await response.json();
    if (data?.success) {
      saveAs(data.path);
      $toast({
        title: $t('Annotations downloaded'),
        type: 'success',
        position: 'bottom-right',
      });
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    }
  }
  catch (e) {
    console.error(e);
  }
  is_downloading.value = false;
}

function focus_annotation(id) {
  emitter.emit('focusAnnotation', { annotation_id: id });
}
</script>

<template>
  <hawk-accordion
    v-if="notes_annotations.length"
    :items="[
      { uid: 'annotation', title: $t('Annotations') },
    ]"
    type="plain"
  >
    <template #header="{ item, is_open }">
      <div class="flex justify-between">
        <div class="font-semibold gap-2 text-gray-900 text-sm flex items-center">
          <IconHawkChevronDown v-if="is_open" />
          <IconHawkChevronRight v-else />
          {{ item.title }}
        </div>
        <hawk-button
          type="plain"
          :disabled="is_downloading" @click.stop="download_annotations"
        >
          <IconHawkLoadingCircle v-if="is_downloading" class="animate-spin" />
          <IconHawkDownloadOne v-else />
        </hawk-button>
      </div>
    </template>
    <template #content>
      <div
        v-for="note in notes_annotations" :key="note.id" class="flex justify-between p-2"
        @mouseenter="focus_annotation(note.id)" @mouseleave="focus_annotation()"
      >
        <div class="flex items-center gap-2">
          <hawk-members :members="note.owner" class="mb-1" />
          <div>
            <div class="flex items-center">
              <hawk-members :members="note.owner" :has_avatar="false" type="only_name" class="font-semibold" />
              <div v-if="note.annotation_type === 'text'" class="text-xs ml-2">
                {{ $t('added a text') }}
              </div>
              <div v-else class="text-xs ml-2">
                {{ $t('added a comment') }}
              </div>
            </div>
            <div class="text-sm text-gray-700">
              {{ note.text }}
            </div>
          </div>
        </div>
        <div class="text-xs text-gray-600">
          {{ $date(note.created_at, 'DATETIME_MED') }}
        </div>
      </div>
    </template>
  </hawk-accordion>
</template>
