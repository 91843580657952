import { acceptHMRUpdate, defineStore } from 'pinia';
import { cloneDeep, groupBy, keyBy, map, orderBy } from 'lodash-es';
import { sortData } from '~/common/utils/common.utils';
import { useCommonStore } from '~/common/stores/common.store.js';

function tree_to_flat(data) {
  const _data = cloneDeep(data);
  function flatten(xs) {
    return xs.reduce((acc, x) => {
      acc = acc.concat(x);
      if (x.folders) {
        acc = acc.concat(flatten(x.folders));
        x.folders = [];
      }
      return acc;
    }, []);
  }

  return flatten(_data);
}

function modifyFolderTree(hierarchy, parent = null) {
  return hierarchy.map((item) => {
    const new_item = { ...item };
    new_item.children = item.folders ? sortData(item.folders, 'name', 'asc', true) : sortData(item.children, 'name', 'asc', true);
    new_item.hasChildren = new_item.children && new_item.children.length > 0;
    new_item.parent = parent;
    new_item.nodeKey = item.uid;
    new_item.name = JSON.stringify(item);
    if (new_item.hasChildren)
      new_item.children = modifyFolderTree(new_item.children, item.uid);
    return new_item;
  });
}

const crud_operation_map = {
  added: (object, value) => object[value.uid] = value,
  updated: (object, value) => object[value.uid] = value,
  removed: (object, value) => delete object[value],
  restored: (object, value) => object[value.uid] = value,
};

export function useDocumentStore(key) {
  return defineStore(key || 'documents', {
    state: () => ({
      document_details_map: {}, // Do not use this state for internal use only
      active_folder_meta: {}, // { uid:"<>",type:"folder|asset|organization"}
      active_item_meta: {}, // {uid:"<uid>",type:"file|folder"}
      folders_hierarchy: [], // just whatever coming from API
      folders_map: {},
      files_map: {},
      show_details: false,
      is_internal: false,
      search_query: null,
    }),
    getters: {
      active_item_details(state) {
        return state.active_item_meta.uid ? (state.active_item_meta.type === 'file' ? state.files_map[state.active_item_meta.uid] : state.folders_map[state.active_item_meta.uid]) : null;
      },
      active_folder_detail(state) {
        return this.folders_hierarchy_map[state.active_folder_meta?.uid] || state.active_folder_meta;
      },
      folders_hierarchy_map: (state) => {
        return { ...(keyBy(tree_to_flat(state.folders_hierarchy), 'uid') || {}), ...state.document_details_map };
      },
      folders: (state) => {
        return Object.values(state?.folders_map);
      },
      files: (state) => {
        return Object.values(state?.files_map);
      },
      documents(state) {
        const folders = sortData(state.folders, 'name', 'asc', true);
        const files = sortData(state.files, 'name', 'asc', true);
        return [...folders, ...files];
      },
      folder_tree: state => (asset_id) => {
        let final_tree = state.folders_hierarchy;
        if (final_tree?.length) {
          const common_store = useCommonStore();
          if (asset_id) { // for asset
            final_tree = final_tree.filter(item => item.asset === asset_id);
            final_tree = sortData(final_tree, 'name', 'asc', true);
            return modifyFolderTree(final_tree);
          }
          // org scope
          final_tree = map(groupBy(final_tree, 'asset'), (value, key) => (
            {
              name: key,
              uid: key,
              ancestors: [],
              type: key === 'null' ? 'organization' : 'asset',
              folders: sortData(value, 'name', 'asc', true),
            }
          ));
          final_tree = modifyFolderTree(final_tree);
          final_tree = orderBy(final_tree, [item => item.uid !== 'null' ? common_store.get_asset(item.uid)?.name.toLowerCase() : 'aaa'], 'asc');
          const orgIndex = final_tree.findIndex(i => i.uid === 'null');
          if (orgIndex !== -1) {
            const modified = final_tree.splice(orgIndex, 1)[0];
            final_tree.unshift(modified);
          }
          return final_tree;
        }
        else {
          return final_tree;
        }
      },
    },
    actions: {
      set_active_folder_meta(payload) {
        this.active_folder_meta = payload;
      },
      set_active_item_meta(payload) {
        this.active_item_meta = payload;
      },
      set_search_query(keyword) {
        this.search_query = keyword;
      },
      async set_hierarchy(asset) {
        const response = await this.$services.documents.getAll({
          query: {
            hierarchy: true,
            internal: this.is_internal,
            ...(asset ? { asset } : {}),
          },
        });
        if (response.data.folders)
          this.folders_hierarchy = response.data.folders;
      },
      async set_documents(options) {
        const response = await this.$services.documents.post({
          attribute: 'filters',
          query: {
            ...options.query,
            internal: this.is_internal,
          },
          ...(options?.body ? { body: options.body } : {}),
        });
        if (response.data.folders)
          this.folders_map = keyBy(response.data.folders, 'uid');
        if (response.data.files)
          this.files_map = keyBy(response.data.files, 'uid');
        if (response.data.details)
          this.document_details_map = keyBy(response.data.details, 'uid');
      },
      async fetch_document(uid) {
        const { data } = await this.$services.documents.getAll(
          {
            query: {
              uid,
            },
          },
        );
        return data;
      },
      async fetch_autonum_configuration(config_uid) {
        try {
          const { data } = await this.$services.dms.get({
            attribute: 'autonumber',
            query: {
              uid: config_uid,
            },
          });

          return data?.autonumber[0];
        }
        catch (err) {
          logger.error(err);
        }
      },
      async add_or_update_autonum_configuration(options) {
        const { request_type, autonumber, config_uid } = options;
        const attribute = config_uid ? `autonumber/${config_uid}` : 'autonumber';

        try {
          const { data } = await this.$services.dms[request_type]({
            attribute,
            body: {
              autonumber,
            },
          });

          if (data?.autonumber?.length) {
            this.$toast({
              title: `Configuration has been ${request_type === 'post' ? 'created' : 'updated'} successfully`,
              type: 'success',
            });

            return data?.autonumber[0];
          }
        }
        catch (err) {
          logger.error(err);
          this.$toast({
            title: 'Something went wrong',
            text: err?.data?.description || '',
            type: 'error',
          });
        }
      },
      async crud_documents(options) {
        try {
          if (this.is_internal)
            options.request.query = { internal: this.is_internal };
          if (options.request.body?.files?.add?.length)
            options.request.body.files.add = options.request.body?.files?.add.map(file => ({ ...file, merge: true }));

          const response = await this.$services.documents.post(options.request);
          response.data.documents.folders && Object.entries(response.data.documents.folders).forEach(([key, folders_list]) => {
            if (crud_operation_map[key])
              folders_list.forEach((folder) => {
                crud_operation_map[key](this.folders_map, folder);
              });
          });
          response.data.documents.files && Object.entries(response.data.documents.files).forEach(([key, files_list]) => {
            if (crud_operation_map[key])
              files_list.forEach((file) => {
                crud_operation_map[key](this.files_map, file);
              });
          });
          return response;
        }
        catch (err) {
          if (['DMS_9784', 'DMS_9783', 'DMS_5461'].includes(err.data.errorCode)) {
            this.$toast({
              title: err?.data?.description || 'Something went wrong',
              text: 'Please try again later',
              type: 'error',
              position: 'bottom-right',
            });
            return;
          }

          logger.error(err);
          throw err;
        }
      },
      async remove_integration(options) {
        const response = await this.$services.documents.delete({
          ...options,
        });
        if (this.folders_map[options.document_uid]?.integrations)
          this.folders_map[options.document_uid].integrations = this.folders_map[options.document_uid].integrations.filter(integration => integration.id !== options.integration_id);
        return response;
      },
      async create_folder_structure(options) {
        try {
          const response = await this.$services.documents.post({
            body: options,
            url: 'dms/documents/create-recursive',
          });
          return response;
        }
        catch (err) {
          logger.error(err);
          this.$toast({
            title: ['DMS_5461'].includes(err?.data?.errorCode) ? err?.data?.description : 'Something went wrong',
            text: 'Please try again later',
            type: 'error',
            position: 'bottom-right',
          });
          return undefined;
        }
      },
      async get_download_url(options) {
        const response = await this.$services.documents.post({
          ...options,
          attribute: 'url',
        });
        return response;
      },
      async get_activities_feed(req, type) {
        try {
          const response = await this.$services.common.get_activities_feed({
            feed_id: `${type}:${req.id}`,
            query: { numActivities: 10 },
          });
          return await response.data;
        }
        catch (e) {
          logger.error(e);
          this.$toast({
            title: 'Something went wrong',
            text: 'Please try again later',
            type: 'error',
            position: 'bottom-right',
          });
        }
      },
      set_show_details(should_show) {
        this.show_details = should_show;
      },
      toggle_is_internal() {
        this.is_internal = !this.is_internal;
      },
      reset_files_folders() {
        this.files_map = {};
        this.folders_map = {};
        this.document_details_map = {};
      },
    },

  })();
}
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDocumentStore, import.meta.hot));
