import { acceptHMRUpdate, defineStore } from 'pinia';
import { keyBy } from 'lodash-es';

export const useComponentMasterStore = defineStore('component-master', {
  state: () => ({
    component_master_map: {},
    active_component_master: null,
  }),
  getters: {
    component_master: state => Object.values(state.component_master_map),
  },
  actions: {
    async set_component_master() {
      try {
        const { data } = await this.$services.sm_components.getAll({
          query: {
            'include[]': ['category'], // 'slaves.*'
            'filter{is_master}': true,
            'sideloading': false,
          },
        });
        this.component_master_map = keyBy(data?.components, 'uid');
      }
      catch (err) {
        logger.error(err);
      }
    },
    async create_component_master(payload) {
      try {
        const { data } = await this.$services.sm_components.post({
          body: payload,
        });

        if (data?.component)
          this.component_master_map[data.component.uid] = data?.component;
      }
      catch (err) {
        logger.error(err);
      }
    },
    async set_component_master_detail(payload) {
      try {
        const query = {
          'include[]': ['fieldvalues.field.*', 'fields.*', 'parents.*', 'category.*', 'element.*'],
          'sideloading': false,
        };
        const { data } = await this.$services.sm_components.get({
          query,
          id: payload?.component_uid,
        });
        if (data?.component)
          this.active_component_master = data.component;
        return data?.component;
      }
      catch (err) {
        logger.error(err);
      }
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useComponentMasterStore, import.meta.hot));
