<script setup>
import RichTextIcon from '~icons/hawk/basic-section';
import CheckboxIcon from '~icons/hawk/checklist-section';
import TableIcon from '~icons/hawk/table-section';

const props = defineProps({
  section: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

const emits = defineEmits(['select_section']);

const $t = inject('$t');

const form_template_detail_store = inject('form_template_detail_store');
const section = props.section;

async function createSection({ section, index, type }) {
  try {
    const obj = {
      name: '',
      form: form_template_detail_store.form_template_detail.uid,
      type,
      status: 'active',
      previous_section: section?.uid,
      step_index: form_template_detail_store.step_number !== -1 ? form_template_detail_store.step_number : 1,
    };

    form_template_detail_store.form_template_detail.sections.splice(
      index + 1,
      0,
      obj,
    );
    emits('select_section', { section: obj, index });
  }
  catch (e) {
    logger.log(e);
    form_template_detail_store.reset_form_template();
  }
}

const section_menu_items = [
  { label: $t('Basic'), icon: RichTextIcon, on_click: () => createSection({ type: 'default', section: section.element, index: section.index }) },
  { label: $t('Checklist'), icon: CheckboxIcon, on_click: () => createSection({ type: 'checklistv2', section: section.element, index: section.index }) },
  { label: $t('Table'), icon: TableIcon, on_click: () => createSection({ type: 'tablev2', section: section.element, index: section.index }) },
];
</script>

<template>
  <hawk-menu class="my-4" additional_trigger_classes="!ring-0 !border-0" additional_dropdown_classes="!z-[17]" :items="section_menu_items" @click.stop>
    <template #trigger>
      <hawk-button type="plain">
        {{ $t('New section') }}
        <icon-hawk-chevron-down class="text-blue-600" />
      </hawk-button>
    </template>
    <template #item="{ item, active }">
      <div class="mr-2">
        <component :is="item.icon" :class="{ 'text-gray-500': !active }" />
      </div>
      {{ item.label }}
    </template>
  </hawk-menu>
</template>

<style></style>
