<script setup>
import { computed, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { cloneDeep, pick } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { v4 as uuidv4 } from 'uuid';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useTerraStore } from '~/terra/store/terra.store.js';
import WorkflowForm from '~/terra/components/workflow/workflow-form.vue';
import WorkflowAssociation from '~/terra/components/workflow/workflow-association.vue';
import DuplicateWorkflow from '~/terra/components/workflow/duplicate-workflow.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import ExportWorkflow from '~/terra/components/workflow/export-workflow.vue';
import { useCommonStore } from '~/common/stores/common.store';

const $t = inject('$t');
const $toast = inject('$toast');
const route = useRoute();
const $services = inject('$services');
const auth_store = useAuthStore();
const common_store = useCommonStore();
const terra_store = useTerraStore();
const export_options = [
  {
    label: $t('Progress data'),
    value: 'Progress data',
  },
  {
    label: $t('Progress history'),
    value: 'Progress history',
  },
];
const state = reactive({
  has_access: false,
  is_loading: false,
});

const workflows = computed(() => {
  return Object.values(terra_store.terra_workflows);
});

const associate_workflow_popup = useModal({
  component: WorkflowAssociation,
  attrs: {
    onClose() {
      associate_workflow_popup.close();
    },
  },
});
const create_workflow_popup = useModal({
  component: WorkflowForm,
  attrs: {
    onClose() {
      create_workflow_popup.close();
      create_workflow_popup.patchOptions({
        attrs: {
          is_edit: false,
          workflow: null,
        },
      });
    },
  },
});
const delete_popup = useModal({
  component: HawkDeletePopup,
  attrs: {
    onClose() {
      delete_popup.close();
    },
  },
});
const duplicate_workflow_popup = useModal({
  component: DuplicateWorkflow,
  attrs: {
    onClose() {
      duplicate_workflow_popup.close();
    },
  },
});
const export_workflow_popup = useModal({
  component: ExportWorkflow,
  attrs: {
    onClose() {
      export_workflow_popup.close();
    },
  },
});

function openWorkflowForm() {
  create_workflow_popup.patchOptions({
    attrs: {
      clickToClose: false,
      escToClose: false,
      is_edit: false,
      workflow: null,
    },
  });
  create_workflow_popup.open();
}
function editWorkflow(workflow) {
  create_workflow_popup.patchOptions({
    attrs: {
      is_edit: true,
      workflow,
    },
  });
  create_workflow_popup.open();
}
function deleteWorkflow(workflow) {
  delete_popup.patchOptions({
    attrs: {
      header: $t('Delete workflow'),
      content: $t('Deleting the workflow will remove all associated fields and all data collected for the features/fields. This action is irreversible. Are you sure you want to delete the workflow?'),
      match_text: workflow.name,
      match_text_input_placeholder: 'Enter the name of the workflow to delete',
      confirm: async () => {
        try {
          await terra_store.delete_workflow({
            id: workflow.uid,
          });
          terra_store.terra_track_events('Workflow deleted');
        }
        catch (err) {
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
          });
        }
        delete_popup.close();
      },
    },
  });
  delete_popup.open();
}
function duplicateWorkflow(workflow) {
  const workflow_payload = cloneDeep(workflow);
  const old_field_hash = terra_store.workflow_fields_hash({ workflow: workflow.uid })?.fields;
  const new_fields_hash = {};
  Object.keys(old_field_hash).forEach((key) => {
    new_fields_hash[key] = uuidv4();
  });
  Object.keys(workflow.data).forEach((key) => {
    workflow_payload.data[key].fields = workflow.data[key].fields.map((item) => {
      if (workflow_payload.data[key].progress_type === 'cumulative') {
        if (workflow_payload.data[key].weights[item.uid]) {
          workflow_payload.data[key].weights[new_fields_hash[item.uid]] = workflow_payload.data[key].weights[item.uid];
          delete workflow_payload.data[key].weights[item.uid];
        }
      }
      else {
        if (workflow_payload.data[key].required[item.uid]) {
          workflow_payload.data[key].required[new_fields_hash[item.uid]] = workflow_payload.data[key].required[item.uid];
          delete workflow_payload.data[key].required[item.uid];
        }
      }

      return { ...item, uid: new_fields_hash[item.uid] };
    });
  });
  duplicate_workflow_popup.patchOptions({
    attrs: {
      workflow,
      on_save: async (e, form) => {
        await terra_store.create_workflow({
          body: {
            ...pick(workflow_payload, ['data', 'name']),
            asset: form.data.asset,
          },
          asset_id: route.params.asset_id,
        });
        terra_store.terra_track_events('Workflow duplicated');
        duplicate_workflow_popup.close();
      },
    },
  });
  duplicate_workflow_popup.open();
}
async function clearProgressData() {
  const selected_feature_uids = terra_store.selected_features.map(item => item.properties.uid);
  delete_popup.patchOptions({
    attrs: {
      header: $t('Clear progress data'),
      content: selected_feature_uids.length ? $t('You are about to clear the progress history for the selected features. This action is irreversible. If you want to continue, type confirm in the box.') : $t('You are about to clear the progress history for all the features within the selected projects. This action is irreversible. If you want to continue, type confirm in the box.'),
      match_text: 'confirm',
      show_match_text: false,
      match_text_input_placeholder: 'If you want to continue, type confirm in the box',
      confirm: async () => {
        try {
          await $services.features.reset_workflow_progress(
            {
              container_id: route.params.id,
              body: {
                ...(selected_feature_uids.length
                  ? { features: terra_store.selected_features.map(item => item.properties.uid) }
                  : {
                      projects: terra_store.active_projects.map(val => val.uid),
                    }),

              },
              toast: {
                type: 'success',
                text: 'Progress data cleared successfully',
              },
            },

          );
          terra_store.terra_track_events('Cleared progress history');

          await terra_store.set_terra_workflows({
            asset_id: route.params.asset_id,
          });
          const container = { ...terra_store.container };
          const projects = [];
          Object.values(container.groups).forEach((group) => {
            Object.values(group.projects).forEach((project) => {
              if (terra_store.active_projects_map[project.uid].ortho_enabled || terra_store.active_projects_map[project.uid].features_enabled)
                projects.push(project);
            });
          });
          await terra_store.set_projects_features({
            projects,
            forceUpdate: true,
          });

          terra_store.update_map_features_and_polygon();
          terra_store.selected_features = [];
        }
        catch (err) {
          logger.error(err);
        }
        delete_popup.close();
      },
    },
  });
  delete_popup.open();
}
function exportProgressData(item) {
  export_workflow_popup.patchOptions({
    attrs: {

      on_save: async (e, form) => {
        let response;
        if (item.value === 'Progress history')
          response = await $services.terra_view_service.export_progress_data({
            body: {
              export_csv: true,
              project: form.data.projects,
            },
          });

        else response = await $services.terra.post({
          attribute: `container/${route.params.id}/workflow-progress`,
          body: {
            export_csv: true,
            projects: form.data.projects,
          },
        });

        const csvData = new Blob([response.data], {
          type: 'text/csv;charset=utf-8;',
        });
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(csvData);
        elem.download = `${common_store?.active_asset?.name}-${terra_store.container.name}-progress-${item.value === 'Progress history' ? 'history' : 'data'}-${new Date()}.csv`;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
        terra_store.terra_track_events(`Exported progress ${item.value === 'Progress history' ? 'history' : 'data'}`);
        export_workflow_popup.close();
      },
      title: item.value === 'Progress history' ? $t('Export progress history') : $t('Export progress data'),

    },
  });
  export_workflow_popup.open();
}
async function getWorkflows() {
  state.is_loading = true;
  if (Object.values(terra_store.terra_workflows).length === 0)
    await terra_store.set_terra_workflows({
      asset_id: route.params.asset_id,
    });
  state.is_loading = false;
}

getWorkflows();
</script>

<template>
  <div class="h-full overflow-auto scrollbar p-4">
    <div class="flex items-center justify-between mb-6">
      <div class="text-base font-semibold">
        {{ $t('Workflows') }}
      </div>
      <div class="flex items-center">
        <HawkButton v-if="terra_store.check_permission('modify_workflows')" on type="light" color="gray" @click="openWorkflowForm()">
          <IconHawkPlus class="w-5 h-5 text-gray-700" />
        </HawkButton>
        <HawkMenu
          v-if="terra_store.check_permission('modify_workflows')"
          position="fixed"
          additional_trigger_classes="!ring-0 p-0 pt-1.5"
          additional_dropdown_classes="-ml-[180px] w-52"
        >
          <template #trigger>
            <div class="cursor-pointer p-2">
              <IconHawkDotsVertical class="w-5 h-5 text-gray-700" />
            </div>
          </template>
          <template #content>
            <div class="rounded p-0.5">
              <div v-if="terra_store.check_permission('modify_workflow_associations')" class="flex items-center cursor-pointer p-2.5 hover:bg-gray-50" @click="associate_workflow_popup.open()">
                <div
                  class="rounded-md mr-2"
                >
                  <IconHawkDataflowThree class="w-4.5 h-4.5 text-gray-500" />
                </div>
                <div class="text-sm font-medium text-gray-700">
                  {{ $t('Associate workflows') }}
                </div>
              </div>
              <div v-if="terra_store.check_permission('clear_progress') && (auth_store.is_hacker_user || terra_store.selected_features?.length)" class="flex items-center cursor-pointer p-2.5 hover:bg-gray-50" @click="clearProgressData">
                <div
                  class="rounded-md mr-2"
                >
                  <IconHawkTrashThree class="w-4.5 h-4.5 text-gray-500" />
                </div>
                <div class="text-sm font-medium text-gray-700">
                  {{ $t('Clear Progress data') }}
                </div>
              </div>
              <HawkMenu
                :items="export_options"
                additional_trigger_classes="!ring-0 p-0 pt-1.5 w-full"
                position="right"
                class="w-full"
                @select="exportProgressData"
              >
                <template #trigger>
                  <div class="flex items-center justify-between cursor-pointer p-2.5 hover:bg-gray-50">
                    <div class="text-sm font-medium text-gray-700">
                      {{ $t('Export') }}
                    </div>
                    <div
                      class="rounded-md mr-2"
                    >
                      <IconHawkChevronRight class="w-4.5 h-4.5 text-gray-500" />
                    </div>
                  </div>
                </template>
              </HawkMenu>
            </div>
          </template>
        </HawkMenu>
      </div>
    </div>
    <HawkLoader v-if="state.is_loading" />
    <HawkIllustrations v-else-if="!workflows.length" type="no-data" for="no-workflows" variant="mini_vertical">
      <template #content>
        <HawkButton class="mt-6" @click="openWorkflowForm()">
          {{ $t('Add Workflow') }}
        </HawkButton>
      </template>
    </HawkIllustrations>
    <template v-else>
      <div v-for="workflow in workflows" :key="workflow.uid" class="flex items-center justify-between group cursor-pointer hover:bg-gray-50 mb-1.5 px-3.5 py-2">
        <div class="text-[14px] text-gray-900 hover:text-blue-600" @click="editWorkflow(workflow)">
          {{ workflow.name }}
        </div>
        <HawkMenu
          v-if="terra_store.check_permission('modify_workflows')"
          class="invisible group-hover:visible"
          position="fixed"
          additional_trigger_classes="!ring-0 p-0 !w-5 !h-5"
          additional_dropdown_classes="!h-[120px] !w-[160px] -ml-[156px]"
        >
          <template #trigger>
            <IconHawkDotsVertical />
          </template>
          <template #content>
            <div class="rounded p-0.5">
              <div class="flex items-center cursor-pointer p-2.5 pt-[8px] hover:bg-gray-50" @click="duplicateWorkflow(workflow)">
                <div
                  class="rounded-md mr-2"
                >
                  <IconHawkCopyOne class="w-4.5 h-4.5 text-gray-500" />
                </div>
                <div class="text-sm font-medium text-gray-700">
                  {{ $t('Duplicate') }}
                </div>
              </div>
              <div class="flex items-center cursor-pointer p-2.5 hover:bg-gray-50" @click="editWorkflow(workflow)">
                <div
                  class="rounded-md mr-2"
                >
                  <IconHawkPencilOne class="w-4.5 h-4.5 text-gray-500" />
                </div>
                <div class="text-sm font-medium text-gray-700">
                  {{ $t('Edit') }}
                </div>
              </div>
              <div class="flex items-center cursor-pointer rounded-lg p-2.5 pb-[8px] hover:bg-gray-50" @click="deleteWorkflow(workflow)">
                <div
                  class="rounded-md mr-2"
                >
                  <IconHawkTrashThree class="w-4.5 h-4.5 text-gray-500" />
                </div>
                <div class="text-sm font-medium text-gray-700">
                  {{ $t('Delete') }}
                </div>
              </div>
            </div>
          </template>
        </HawkMenu>
      </div>
    </template>
  </div>
</template>
