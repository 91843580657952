<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useModal } from 'vue-final-modal';
import createCategory from '~/account-settings/components/categories/category-form.vue';
import { useCommonStore } from '~/common/stores/common.store';
import { useAuthStore } from '~/auth/stores/auth.store';

const props = defineProps({
  asset_id: {
    type: String,
    default: null,
  },
  multi: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Object,
    required: true,
  },
  truncate_width: {
    type: Number,
    default: 20,
  },
  setter_function: {
    type: Function,
    default: () => {},
  },
  create_classes: {
    type: String,
    default: '',
  },
});

const route = useRoute();
const common_store = useCommonStore();
const auth_store = useAuthStore();
const category_data = computed(() => {
  const asset_id = props?.asset_id ? props.asset_id : route.params.asset_id;
  return common_store.scope_categories(asset_id)?.sort((category1, category2) => category1?.name?.localeCompare(category2.name));
});

const { open: openCategoryModal, close: closeNormalModal } = useModal({
  component: createCategory,
  attrs: {
    action_type: 'add',
    ...(props.options.create && { from: props.options.from }),
    onClose() {
      closeNormalModal();
    },
    async onCreated(e) {
      await props.setter_function(e);
    },
  },
});
</script>

<template>
  <v-tags-input
    v-if="multi"
    :options="{
      'autocomplete': 'off',
      'search': true,
      'track-by': 'name',
      'label-prop': 'name',
      'value-prop': 'uid',
      'close-on-select': false,
      'items': category_data,
      ...options,
    }"
  >
    <template #after>
      <slot name="after" />
    </template>
  </v-tags-input>
  <SelectElement
    v-else
    v-bind="{
      'autocomplete': 'off',
      'track-by': 'name',
      'label-prop': 'name',
      'value-prop': 'uid',
      'search': true,
      'hide-selected': false,
      'items': category_data,
      ...options,
    }"
  >
    <template #before-list>
      <p
        v-if="options.create && auth_store.check_permission('modify_categories', route.params.asset_id)"
        class="text-primary hover:bg-gray-100 font-medium rounded-md m-1 mb-0 py-2 px-1.5"
        :class="create_classes"
        @click="openCategoryModal"
      >
        + {{ $t('Create new') }}
      </p>
    </template>
    <template #single-label="{ value }">
      <slot name="single-label" :value="value">
        <div class="flex items-center h-full absolute left-0 top-0 bg-transparent leading-snug pl-3">
          <HawkText :length="truncate_width" :content="value.name" />
        </div>
      </slot>
    </template>
    <template #after>
      <slot name="after" />
    </template>
  </SelectElement>
</template>
