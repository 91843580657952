<script setup>
import { computed, reactive, ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';

import { useTasksStore } from '~/tasks/store/tasks.store';
import { useFormsStore } from '~/forms/store/forms.store';
import { useTerraStore } from '~/terra/store/terra.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import TasksBulkActionsMenu from '~/tasks/components/organisms/tasks-bulk-actions-menu.vue';
import FormActions from '~/forms/components/form-actions-reusable.vue';
import FeatureTypes from '~/terra/components/feature-type-groups/feature-types.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import FeaturesMovePopup from '~/terra/components/feature-details/features-move-popup.vue';

const props = defineProps({
  hawk_menu_items: {
    type: Array,
  },
  show_action_button: {
    type: Boolean,
    default: false,
  },
  used_for: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['openCreationPopup', 'updateFeatureType']);
const auth_store = useAuthStore();
const task_store = useTasksStore('terra_task_store');
const forms_store = useFormsStore('terra_form_store');
const terra_store = useTerraStore();
const route = useRoute();

const $t = inject('$t');
const $form_create_permission = inject('$form_create_permission');

const bulk_task_actions = ref({ show: false, tasks: [] });
const bulk_form_actions = ref({ show: false, forms: [] });
const $task_btn = ref(null);
const $form_btn = ref(null);

const state = reactive({
  dropdown_open: false,
});

const delete_popup = useModal({
  component: HawkDeletePopup,
  attrs: {
    onClose() {
      delete_popup.close();
    },
    confirm: async () => {
      await terra_store.delete_features();
      const id = terra_store.selected_features[0]?.properties?.uid;
      if (props.used_for)
        terra_store.terra_track_events('Table select row action delete', { count: terra_store.selected_features.length, id });
      else
        terra_store.terra_track_events('Feature deleted', { count: terra_store.selected_features.length, id });
      delete_popup.close();
    },
  },
});

const workflow_exists = computed(() => {
  logger.log(terra_store.selected_features.some(item => Boolean(item.properties.workflow)));
  return terra_store.selected_features.some(item => Boolean(item.properties.workflow));
});

const default_menu_items = computed(() => {
  const is_bulk_select = terra_store.selected_features.length > 1;
  const id = terra_store.selected_features[0]?.properties?.uid;
  const default_menu = [
    {
      label: is_bulk_select ? $t('Create tasks') : $t('Create task'),
      uid: 'create-task',
      id: 4,
      disabled: !auth_store.check_permission('create_tasks', route.params.asset_id),
      on_click: () => {
        if (props.used_for)
          terra_store.terra_track_events('Table select row create task');
        emit('openCreationPopup', 'task');
      },
    },
    ...($form_create_permission?.value?.state === 'hidden'
      ? []
      : [{
          label: is_bulk_select ? $t('Create forms') : $t('Create form'),
          uid: 'create-form',
          id: 5,
          disabled: $form_create_permission?.value?.state === 'disabled',
          tooltip: $form_create_permission?.value?.tooltip,
          on_click: () => {
            if (props.used_for)
              terra_store.terra_track_events('Table select row create form');
            emit('openCreationPopup', 'form');
          },
        }]),
  ];
  if (is_bulk_select || props.show_action_button)
    default_menu.push({
      label: $t('Change feature type'),
      uid: 'change-feature-type',
      id: 3,
      on_click: () => {
        if (props.used_for)
          terra_store.terra_track_events('Table select row action change feature type', { id });
      },
    });
  const is_move_feature_disabled = new Set(terra_store.selected_features.map(f => f.properties.project || f.properties.projectUid)).size !== 1;
  default_menu.push({
    label: $t('Move'),
    uid: 'move',
    id: 9,
    disabled: is_move_feature_disabled,
    tooltip: is_move_feature_disabled && $t('Can not move the features associated with a different project'),
    on_click: () => {
      const move_popup = useModal({
        component: FeaturesMovePopup,
        attrs: {
          onClose() {
            move_popup.close();
          },
          onMove() {
            if (props.used_for)
              terra_store.terra_track_events('Table select row action move', { id });
            else
              terra_store.terra_track_events('Moved', { id });
          },
        },
      });
      move_popup.open();
    },
  });
  default_menu.push({
    label: $t('Delete'),
    uid: 'delete',
    id: 10,
    on_click: () => {
      delete_popup.patchOptions({
        attrs: {
          header: is_bulk_select ? $t('Delete features') : $t('Delete feature'),
          content: is_bulk_select ? $t('Are you sure you want to delete the features?') : $t('Are you sure you want to delete the feature?'),
          show_toast: false,
        },
      });
      delete_popup.open();
    },
  });

  return default_menu;
});

const menu_items = computed(() => {
  const menu = [...props.hawk_menu_items, ...default_menu_items.value].sort((a, b) => a.id - b.id);
  return menu.map((item) => {
    if (item.uid === 'update-task') {
      bulk_task_actions.value.tasks = item.tasks;
      item.on_click = () => {
        bulk_task_actions.value = { show: true, tasks: item.tasks, handleDelete: item.handleDelete };
        // TODO: Alex needs an option for default opening of content without trigger
        setTimeout(() => {
          $task_btn.value.click();
        }, 100);
      };
    }
    if (item.uid === 'update-form') {
      bulk_form_actions.value = item.forms;
      item.on_click = () => {
        bulk_form_actions.value = { show: true, forms: item.forms, handleDelete: item.handleDelete };
        // TODO: Alex needs an option for default opening of content without trigger
        setTimeout(() => {
          $form_btn.value.click();
        }, 100);
      };
    }
    if (['edit', 'change-feature-type'].includes(item.uid))
      return {
        ...item,
        ...(!terra_store.check_permission('modify_feature_properties')
          ? { disabled: true, tooltip: $t('You don\'t have permissions to perform this action.'), hide: true }
          : {}),
      };
    if (['edit-geometry', 'delete', 'move'].includes(item.uid))
      return {
        ...item,
        ...(!terra_store.check_permission('modify_features')
          ? { disabled: true, tooltip: $t('You don\'t have permissions to perform this action.'), hide: true }
          : {}),
      };
    return item;
  }).filter(item => !item.hide);
});
</script>

<template>
  <div class="relative flex">
    <FormActions
      v-if="bulk_form_actions.forms?.length"
      :forms_store="forms_store"
      :selected_forms="bulk_form_actions.forms"
      :options="{
        additional_trigger_classes: '!w-0 !h-0 !ring-0',
        additional_dropdown_classes: 'w-44 -left-[320px]',
        position: 'top-left',
      }"
      @deleteForms="bulk_form_actions.handleDelete"
      @close="bulk_form_actions.show = false"
    >
      <template #trigger>
        <div ref="$form_btn" class="w-0 h-0 hidden" />
      </template>
    </FormActions>
    <TasksBulkActionsMenu
      v-if="bulk_task_actions.tasks?.length"
      :task_store="task_store"
      :selected_tasks="bulk_task_actions.tasks"
      :options="{
        additional_trigger_classes: '!w-0 !h-0 !ring-0',
        additional_dropdown_classes: 'w-44 -left-[320px]',
        position: 'top-left',
      }"
      @deleteTasks="bulk_task_actions.handleDelete"
      @close="bulk_task_actions.show = false"
    >
      <template #trigger>
        <div ref="$task_btn" class="w-0 h-0 hidden" />
      </template>
    </TasksBulkActionsMenu>
    <HawkMenu
      :key="menu_items.length"
      :items="menu_items"
      :has_bordered_trigger="false"
      additional_trigger_classes="!ring-0 p-0 -mt-1"
      additional_dropdown_classes="max-h-60 scrollbar"
      position="fixed"
      @open="state.dropdown_open = true; emit('updateDropdownOpen', state.dropdown_open)"
      @close="state.dropdown_open = false; emit('updateDropdownOpen', state.dropdown_open)"
    >
      <template v-if="show_action_button" #trigger>
        <hawk-button type="outlined">
          {{ $t('Actions') }}
          <IconHawkChevronDown
            class="text-white transition-transform"
            :class="{ 'rotate-180': state.dropdown_open }"
          />
        </hawk-button>
      </template>

      <template #content>
        <div class="w-[180px] p-1 text-sm font-medium">
          <div v-for="(menu, i) in menu_items" :key="i" v-tippy="{ content: (menu.tooltip || '') }" class="flex items-center  hover:bg-gray-50 rounded-lg p-3 cursor-pointer" :class="{ 'pointer-events-auto opacity-60': menu.disabled }" @click="!menu.disabled && menu.on_click()">
            <div v-if="menu.uid === 'edit-geometry'" class="flex items-center justify-between">
              <span> {{ menu.label }}</span>
              <HawkButton v-tippy="'Press Shift + e to edit'" icon size="xs" type="outlined" class="ml-4 w-[37px] h-[28px] text-gray-500">
                <div class="flex items-center px-[6px] py-[4px]">
                  <IconHawkArrowBlockUp class=" w-4 h-4" /> <span>e</span>
                </div>
              </HawkButton>
            </div>
            <div v-else-if="menu.uid === 'change-feature-type'" v-tippy="workflow_exists && !menu.disabled ? $t('Can not change type for the features associated with a workflow') : ''">
              <HawkMenu :disabled="workflow_exists" position="fixed" additional_trigger_classes="!ring-0 !border-0" additional_dropdown_classes="!top-auto">
                <template #trigger>
                  <div class="w-40 text-left">
                    {{ menu.label }}
                  </div>
                </template>
                <template #content="{ close }">
                  <FeatureTypes class="overflow-auto max-w-[400px] max-h-[240px] min-w-[240px]" type="dropdown" @select="emit('updateFeatureType', $event), close()" />
                </template>
              </HawkMenu>
            </div>
            <span v-else>{{ menu.label }}</span>
          </div>
        </div>
      </template>
    </HawkMenu>
  </div>
</template>
