import ComponentMaster from '~/system-model/component-master/pages/cm/cm.vue';
import ComponentMasterDetail from '~/system-model/component-master/pages/cm-component-detail/cm-component-detail.vue';

const routes = [
  {
    path: '/component-master',
    name: 'component-master',
    component: ComponentMaster,
    meta: {
      title: 'Component master',
    },
  },
  {
    path: '/component-master/:component_uid',
    component: ComponentMasterDetail,
    name: 'component-master-detail',
    meta: {
      title: 'Component master detail',
    },
  },
];
export default routes;
