<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { searchData } from '~/common/utils/common.utils';
import UserInviteForm from '~/account-settings/components/users/account-settings-user-invite-form.vue';

const props = defineProps({
  invited_users: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['loading', 'update']);
const auth_store = useAuthStore();
const common_store = useCommonStore();
const $services = inject('$services');
const $toast = inject('$toast');
const $t = inject('$t');
const route = useRoute();
const router = useRouter();

const search = ref('');
const is_loading = ref(false);

const { open: openUserInvitePopup, close: closeUserInvitePopup, patchOptions } = useModal({
  component: UserInviteForm,
});

function invitePopupHandler() {
  patchOptions(
    {
      attrs: {
        onClose() {
          closeUserInvitePopup();
        },
        onSave() {
          emit('update');
          closeUserInvitePopup();
        },
      },
    },
  );
  openUserInvitePopup();
}

const columns = [
  {
    header: $t('Email'),
    accessorKey: 'email',
    id: 'email',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    header: $t('Invited By'),
    accessorKey: 'requested_by',
    id: 'requested_by',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    header: $t('Invited On'),
    accessorKey: 'created_at',
    id: 'created_at',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '5',
    show_on_hover: 'true',
  },
];

const hawk_menu_items = [
  {
    label: $t('Resend'),
    type: 'resend',
    on_click: () => {
    },
  },
  {
    label: $t('Revoke'),
    type: 'revoke',
    on_click: () => {
    },
  },
];

const filtered_data = computed(() => {
  return props.invited_users ? searchData(props.invited_users, search.value, 'email') : [];
});

async function resendInvite(user) {
  try {
    is_loading.value = true;
    const { data } = await $services.users.resend_invite({
      organization_id: auth_store.current_organization?.uid,
      body: {
        invitations: [user.uid],
        asset: route?.params?.asset_id,
      },
    });
    if (data) {
      $toast({
        text: 'Email sent successfully',
        type: 'success',
      });
      is_loading.value = false;
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
      is_loading.value = false;
    }
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
    is_loading.value = false;
    logger.log(`Error: ${error}`);
  }
}

async function revokeUser(user) {
  try {
    is_loading.value = true;
    const { data } = await $services.users.revoke_invite({
      organization_id: auth_store.current_organization.uid,
      body: {
        invitations: [user.uid],
        asset: route.params.asset_id,
      },
    });

    if (data.status === 'success') {
      $toast({
        text: 'User revoked successfully',
        type: 'success',
      });
      emit('update');
      is_loading.value = false;
    }

    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
      is_loading.value = false;
    }
  }
  catch (error) {
    is_loading.value = false;
    logger.log(`Error: ${error}`);
  }
}

async function onActionClicked(type, user) {
  try {
    switch (type) {
      case 'resend':{
        await resendInvite(user);
        break;
      }
      case 'revoke':{
        await revokeUser(user);
        break;
      }
    }
  }
  catch (error) {
    logger.error(error);
  }
}

function handleRowClick(e) {
  router.push({
    name: route.params?.asset_id
      ? 'asset-settings-invites-details'
      : 'account-settings-invites-details',
    params: {
      asset_id: route.params?.asset_id,
      user_id: e?.user,
    },
  });
}
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="my-4">
      <template #left>
        <HawkSearchInput v-model="search" :placeholder="$t('Search email')" />
      </template>
      <template #right>
        <HawkButton v-if="auth_store.check_permission('invite_users', route.params.asset_id)" color="primary" @click="invitePopupHandler()">
          <IconHawkPlus class="text-white" />
          <span>{{ $t('Invite Members') }}</span>
        </HawkButton>
      </template>
    </HawkPageSecondaryHeader>
    <HawkLoader v-if="props.loading" />
    <HawkIllustrations v-else-if="!auth_store.check_permission('invite_users', route.params.asset_id)" type="no-permission" />
    <div v-else-if="!filtered_data?.length">
      <HawkIllustrations v-if="search" type="no-results" for="invitations" />
      <HawkIllustrations v-else type="no-data" for="invitations" />
    </div>
    <div v-else-if="filtered_data.length">
      <HawkTable
        :pagination_config="{ totalRows: filtered_data?.length, pageSize: 25 }"
        :data="filtered_data"
        :columns="columns"
        :is_loading="is_loading"
        :default_height="500"
        :is_gapless="true"
        @rowClicked="handleRowClick"
      >
        <template #email="email">
          <div class="font-medium">
            {{ email.data.getValue() }}
          </div>
        </template>
        <template #requested_by="requested_by">
          <HawkMembers :members="requested_by.data.getValue()" size="xs" type="label" />
        </template>
        <template #created_at="created_at">
          {{ $date(created_at.data.getValue(), 'DATETIME_MED') }}
        </template>
        <template #context_menu="user">
          <HawkMenu
            :items="hawk_menu_items"
            @click.stop
          >
            <template #trigger>
              <IconHawkDotsVertical />
            </template>
            <template #content>
              <div class="p-1 w-48 flex flex-col">
                <HawkButton
                  v-for="item in hawk_menu_items" :key="item.label"
                  type="text"
                  @click="onActionClicked(item.type, user.data.row.original)"
                >
                  {{ item.label }}
                </HawkButton>
              </div>
            </template>
          </HawkMenu>
        </template>
      </HawkTable>
    </div>
  </div>
</template>
