<script setup>
import { computed } from 'vue';
import DOMPurify from 'dompurify';

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
    default: 'info',
    validator: val => ['success', 'warning', 'error', 'info'].includes(val),
  },
  title: {
    type: String,
    required: false,
    default: '',
  },
  html_text: {
    default: null,
  },
  action_button_text: {
    type: String,
    required: false,
    default: '',
  },
  has_dismiss_button: {
    type: Boolean,
    required: false,
    default: false,
  },
  dismiss_button_text: {
    type: String,
    required: false,
    default: '',
  },
});

const emit = defineEmits(['click', 'close-toast']);

const classes = computed(() => {
  const wrapper_classes = ['rounded-xl', 'border', 'p-4', 'text-gray-900'];
  const icon_classes = [];

  switch (props.type) {
    case 'info':
      wrapper_classes.push('border-primary-300', 'bg-primary-25');
      icon_classes.push('bg-primary-100', 'ring-primary-50', 'text-primary-600');
      break;
    case 'success':
      wrapper_classes.push('border-success-300', 'bg-success-25');
      icon_classes.push('bg-success-100', 'ring-success-50', 'text-success-600');
      break;
    case 'warning':
      wrapper_classes.push('border-warning-300', 'bg-warning-25');
      icon_classes.push('bg-warning-100', 'ring-warning-50', 'text-warning-600');
      break;
    case 'error':
      wrapper_classes.push('border-error-300', 'bg-error-25');
      icon_classes.push('bg-error-100', 'ring-error-50', 'text-error-600');
      break;
  }

  return {
    wrapper_classes,
    icon_classes,
  };
});

const has_action_button = computed(() => props.action_button_text !== '');
const has_buttons = computed(() => (has_action_button.value || props.has_dismiss_button));
</script>

<template>
  <div :class="classes.wrapper_classes">
    <div class="flex flex-wrap justify-between">
      <div class="flex w-0 flex-1 items-center">
        <div class="w-8 h-8 rounded-full flex justify-center items-center ring-8 mr-4" :class="classes.icon_classes">
          <IconHawkCheck v-if="props.type === 'success'" class="w-5 h-5" />
          <IconHawkAlertTriangle v-else-if="props.type === 'warning'" class="w-5 h-5 " />
          <IconHawkAlertCircle v-else class="w-5 h-5" />
        </div>
        <div class="text-sm">
          <h4 v-if="props.title" class="mb-1 font-semibold font-sans text-gray-900">
            {{ props.title }}
          </h4>
          <p class="text-gray-600 font-sans" :class="[has_buttons && 'mb-4']">
            {{ props.text }}
          </p>
          <div v-if="props.html_text" v-html="DOMPurify.sanitize(props.html_text)" />

          <div v-if="has_buttons" class="flex gap-6">
            <div v-if="props.has_dismiss_button" class="font-semibold text-gray-600 font-sans cursor-pointer" @click.stop="emit('close-toast')">
              {{ props.dismiss_button_text }}
            </div>
            <div v-if="has_action_button" class="font-semibold text-primary-700 hover:text-primary-600 font-sans cursor-pointer" @click.stop="emit('click')">
              {{ props.action_button_text }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <HawkButton icon type="plain">
          <IconHawkX class="h-6 w-6 !text-gray-500" />
        </HawkButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.Vue-Toastification__container.hawk-toast-container {
  .Vue-Toastification__toast.hawk-toast {
    border-radius: 0.75rem;
    background-color: transparent;
    padding: 0;
    min-width: 30rem;
  }
}
</style>
