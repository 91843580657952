<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import { useComponentMasterStore } from '~/system-model/store/component-master.store';
import SmCategoryForm from '~/system-model/components/forms/sm-category-form.vue';

const emit = defineEmits(['close']);

const system_model_store = useSystemModelStore();
const component_master_store = useComponentMasterStore();
const { create_component_master } = component_master_store;
const { categories_map } = storeToRefs(system_model_store);

const form$ = ref(null);
const state = reactive({
  is_loading: false,
});

const { open: openCategoryModal, close: closeCategoryModal } = useModal({
  component: SmCategoryForm,
  attrs: {
    onClose() {
      closeCategoryModal();
    },
    onSetCategory(category) {
      form$.value.el$('category').load(category);
    },
  },
});

async function saveHandler(form$) {
  try {
    state.is_loading = true;
    await create_component_master({
      ...form$.data,
      is_master: true,
    });
    emit('close');
  }
  catch (err) {
    logger.error(err);
  }
  finally {
    state.is_loading = false;
  }
}
</script>

<template>
  <hawk-modal-container content_class="w-64 rounded-lg">
    <Vueform
      ref="form$"
      :display-errors="false"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @submit="saveHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ $t('New component') }}
          </template>
        </hawk-modal-header>

        <hawk-modal-content :is_scroll="false">
          <TextElement
            v-bind="{
              label: `${$t('Component name')}`,
              name: 'name',
              placeholder: 'Enter component name',
              rules: ['required'],
              class: 'mb-6',
            }"
          />
          <TextareaElement
            v-bind="{
              name: 'description',
              label: $t('Description'),
              placeholder: $t('Add description'),
              autocomplete: 'off',
              class: 'mb-6',
            }"
          />
          <SelectElement
            v-bind="{
              name: 'category',
              items: Object.values(categories_map),
              valueProp: 'uid',
              labelProp: 'name',
              search: true,
              native: false,
              label: $t('Category'),
              placeholder: 'Select Category',
              autocomplete: 'off',
              inputType: 'search',
              rules: ['required'],
            }"
          >
            <template #before-list>
              <div
                class="text-gray-700 text-sm font-semibold p-2 border-b border-gray-300"
                @click="openCategoryModal"
              >
                {{ $t('Create category') }}
              </div>
            </template>
          </SelectElement>
        </hawk-modal-content>

        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Save')"
                :loading="state.is_loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
