<script setup>
const header_ref = ref(null);
const is_sticky = ref(false);
function addStickyHeader() {
  const sibling = header_ref.value;

  const observer = new IntersectionObserver(
    ([e]) => { is_sticky.value = e.intersectionRatio < 1; },
    { threshold: 0.1, rootMargin: '-40px' },
  );
  observer.observe(sibling);
}

onMounted(() => {
  addStickyHeader();
});
</script>

<template>
  <div ref="header_ref" />
  <div class="sticky-header bg-white -mx-4 !z-[11]" :class="{ 'pb-4 !z-20': is_sticky }">
    <div class="px-4" :class="is_sticky ? 'shadow-md py-2' : 'pb-5'">
      <slot />
    </div>
  </div>
</template>
