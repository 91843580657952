<script setup>
import { saveAs } from 'file-saver';
import { useCommonStore } from '~/common/stores/common.store.js';

const props = defineProps({
  field_value: {
    type: Object,
    default: () => {},
  },
  field_selector: {
    type: String,
    default: 'uid',
  },
  value_separator: {
    type: String,
    default: ' | ',
  },
});

const common_store = useCommonStore();

const state = reactive({
  file_downloading: null,
});

const field = computed(() => {
  return common_store.assets_custom_fields.find(item => item.uid === props?.field_value?.[props.field_selector]);
});

const get_prefix_suffix = computed(() => {
  const prefix = field.value?.properties?.prefix?.length ? `${field.value?.properties?.prefix} ` : '';
  const suffix = field.value?.properties?.suffix?.length ? ` ${field.value?.properties?.suffix}` : '';
  const currency = field.value?.properties?.currency?.length ? `${field.value?.properties?.currency} ` : '';
  return {
    prefix, suffix, currency,
  };
});

function formatNumber(value) {
  return Number.isInteger(value) ? value : Number.parseFloat(value).toFixed(2);
}

function getFieldValue() {
  if (!props.field_value?.value)
    return '-';

  switch (field.value.type) {
    case 'percentage':
      return props.field_value?.value ? `${formatNumber(Number.parseInt(props.field_value?.value * 100)) || 0}%` : '-';
    case 'planned_actual':{
      const actualValue = props.field_value?.value?.actual === null
        ? '-'
        : `${get_prefix_suffix.value?.prefix}${formatNumber(props.field_value?.value?.actual)}${get_prefix_suffix.value?.suffix}`;
      const plannedValue = props.field_value?.value?.planned === null
        ? '-'
        : `${get_prefix_suffix.value?.prefix}${formatNumber(props.field_value?.value?.planned)}${get_prefix_suffix.value?.suffix}`;
      return `${actualValue} / ${plannedValue}`;
    }
    case 'dropdown':
    case 'radio':
      return field?.value?.config?.find(item => item.uid === props?.field_value?.value)?.name;
    case 'number':
      return props.field_value?.value
        ? `${get_prefix_suffix.value?.prefix}${formatNumber(props.field_value?.value) || 0}${get_prefix_suffix.value?.suffix}`
        : '-';
    case 'money':
      return props.field_value?.value
        ? `${get_prefix_suffix.value?.currency}${formatNumber(props.field_value?.value) || 0}`
        : '-';
    case 'labels':
    case 'checkbox':{
      const values = props.field_value?.value.map((val) => {
        return field?.value?.config.find(item => item.uid === val)?.name;
      });
      return values.join(props.value_separator) || '-';
    }
    default:
      return props?.field_value?.value || '-';
  }
}

async function downloadAttachment(file) {
  state.file_downloading = file.id;
  const response = await fetch(file?.url);
  const response_blob = await response.blob();
  saveAs(response_blob, file?.file_name);
  state.file_downloading = null;
}
</script>

<template>
  <div class="group">
    <div v-if="field?.type === 'files'" class="min-w-48">
      <hawk-attachments-list
        :items="field_value?.value"
        :can_delete="false"
        variant="mini"
        :is_loading_uid="state.file_downloading"
        :filename_truncate_length="14"
        @download="downloadAttachment"
      />
    </div>
    <div v-else-if="field?.type === 'datetime'">
      <div v-if="field_value.value">
        {{ $date(field_value.value, 'DD MMM YYYY, HH:MM') }}
      </div>
      <div v-else>
        -
      </div>
    </div>
    <div v-else-if="field?.type === 'date'">
      <div v-if="field_value.value">
        {{ $date(field_value.value, 'DD MMM YYYY') }}
      </div>
      <div v-else>
        -
      </div>
    </div>
    <div v-else-if="field?.type === 'members'">
      <HawkMembers :members="field_value.value" type="badge" />
    </div>
    <div v-else-if="field?.type === 'date_range'">
      <div v-if="field_value?.value?.start && field_value?.value?.end" class="flex items-center flex-wrap">
        <span>{{ $date(field_value?.value?.start, 'DD MMMM YYYY') }}</span>
        <IconHawkArrowNarrowRight class="mx-2" />
        <span>{{ $date(field_value?.value?.end, 'DD MMMM YYYY') }}</span>
        ({{ field_value?.value?.duration }} {{ $t('days') }})
      </div>
      <div v-else>
        -
      </div>
    </div>
    <div v-else class="break-all">
      {{ getFieldValue() }}
    </div>
  </div>
</template>
