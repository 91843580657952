<script setup>
import { onMounted, onUnmounted } from 'vue';
import { load_js_css_file } from '~/common/utils/load-script.util';

const props = defineProps({
  hot_settings: {
    type: Object,
    default: () => ({}),
  },
  data: {
    type: Array,
    required: true,
  },
  columns: {
    type: Array,
    default: () => [],
  },
  hot_table_id: {
    type: String,
    default: 'hawk-handsontable',
  },
  cells: {
    type: Function,
    default: null,
  },
  height: {
    type: String,
    default: '600px',
  },
  width: {
    type: String,
    default: '100%',
  },
  nested_headers: {
    type: Array,
    default: () => [],
  },
  col_headers: Function,
  after_change: Function,
  before_change: Function,
  after_on_cell_mouse_down: Function,
  after_validate: Function,
  before_validate: Function,
  before_remove_row: Function,
  after_remove_row: Function,
  after_selection: Function,
  after_selection_end: Function,
  before_on_cell_mouse_down: Function,
  after_get_col_header: Function,
});

const emit = defineEmits(['ready']);
const state = reactive({
  hotInstance: null,
});
onMounted(async () => {
  // Figure out version and performance metrics
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@13.1.0/dist/handsontable.full.min.js',
    'handsontable-js',
    'js',
  );
  await load_js_css_file(
    'https://cdn.jsdelivr.net/npm/handsontable@13.1.0/dist/handsontable.full.min.css',
    'handsontable-css',
    'css',
  );
  const hot_settings = {
    data: props.data,
    columns: props.columns,
    height: props.height,
    width: props.width,
    afterChange: props.after_change,
    beforeChange: props.before_change,
    afterOnCellMouseDown: props.after_on_cell_mouse_down,
    afterValidate: props.after_validate,
    beforeValidate: props.before_validate,
    beforeRemoveRow: props.before_remove_row,
    afterRemoveRow: props.after_remove_row,
    afterSelection: props.after_selection,
    afterSelectionEnd: props.after_selection_end,
    beforeOnCellMouseDown: props.before_on_cell_mouse_down,
    afterGetColHeader: props.after_get_col_header,
    licenseKey: import.meta.env.VITE_APP_HOT_LICENSE_KEY,
    colHeaders: props.col_headers,
    rowHeights: '36px',
    stretchH: 'all',
    ...(props.cells && { cells: props.cells }),
    ...(props.nested_headers?.length && { nestedHeaders: props.nested_headers }),
    ...props.hot_settings,
  };
  const container = document.getElementById(props.hot_table_id);
  state.hotInstance = new Handsontable(container, hot_settings);
  emit('ready', state.hotInstance);
});
watch(
  () => props.data,
  (newData) => {
    if (state.hotInstance)
      state.hotInstance.loadData(newData);
  },
);

watch(
  () => props.columns,
  (newColumns) => {
    if (state.hotInstance)
      state.hotInstance.updateSettings({ columns: newColumns });
  },
);
onUnmounted(() => {
  if (state.hotInstance)
    state.hotInstance.destroy();
});
</script>

<template>
  <div :id="hot_table_id" />
</template>

<style lang="scss">
.htCore {
  th{
    font-weight: 500 !important;
    padding: 4px;
    border-right:  1px solid rgba(234, 236, 240, 1) !important;
    background-color: rgba(249, 250, 251, 1) !important;
    border-bottom: 1px solid rgba(234, 236, 240, 1) !important;
    color: rgba(71, 84, 103, 1) !important;

  }
  thead th:last-child {
    border-right: 1px solid rgba(234, 236, 240, 1) !important;
  }
  thead th {
    height: 34px !important;
    &:nth-child(1) {
      text-align: center;
      border-right:  1px solid rgba(234, 236, 240, 1) !important;
    }
    vertical-align: middle;
    text-align: left;
  }
  td {
    padding: 4px;
    max-width: 30rem;
    border-right:  1px solid rgba(234, 236, 240, 1) !important;
    border-left: none !important;
    border-bottom: 1px solid rgba(234, 236, 240, 1) !important;
    color: rgb(71, 84, 103) !important;
  }
  td:last-child{
    border-right: 1px solid rgba(234, 236, 240, 1) !important
  }

}
.handsontable tr:first-child td, .handsontable tr:first-child th{
    border-top: 1px solid rgba(234, 236, 240, 1) !important;
  }
.ht_master:not(.innerBorderInlineStart):not(.emptyColumns)~.handsontable:not(.ht_clone_top) thead tr th:first-child, .ht_master:not(.innerBorderInlineStart):not(.emptyColumns)~.handsontable tbody tr th{
    border-left: 1px solid rgba(234, 236, 240, 1) !important;
  }
  .handsontable.htRowHeaders thead tr th:nth-child(2){
    border-left: none !important;
  }
</style>
