<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'light', 'outlined', 'text', 'link', 'plain', 'dark'].includes(value);
      },
    },
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'success', 'error', 'gray', 'active'].includes(value);
      },
    },
    size: {
      type: String,
      default: 'sm',
      validator(value) {
        return ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl'].includes(value);
      },
    },
    icon: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {};
  },
  computed: {
    button_classes() {
      const btn = [];

      const defaultBtn = [
        'inline-flex',
        'items-center',
        'border',
        'border-transparent',
        'font-medium',
        'shadow-sm',
        'relative',
        'hawk-button',
        'rounded-lg',
        'focus:outline-none',
      ];

      switch (this.type) {
        case 'default':
          btn.push(...defaultBtn);
          switch (this.color) {
            case 'primary':
              this.disabled
                ? btn.push('text-white bg-primary-200')
                : btn.push(
                  'text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-100',
                );
              break;
            case 'success':
              this.disabled
                ? btn.push('text-white bg-green-200')
                : btn.push(
                  'text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-100',
                );
              break;
            case 'error':
              this.disabled
                ? btn.push('text-white bg-red-200')
                : btn.push(
                  'text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-100',
                );
              break;
            case 'gray':
              this.disabled
                ? btn.push('text-gray-700 bg-gray-100')
                : btn.push(
                  'text-gray-700 bg-gray-300 hover:bg-gray-400 focus:ring-4 focus:ring-gray-100',
                );
              break;

            default:
              break;
          }
          break;

        case 'light':
          btn.push(...defaultBtn);
          switch (this.color) {
            case 'primary':
              btn.push(this.disabled
                ? 'text-primary-300 bg-primary-50 !shadow-none'
                : 'text-primary-700 bg-primary-50 hover:bg-primary-100 hover:text-primary-800 focus:ring-4 focus:ring-primary-100 !shadow-none');
              break;

            case 'error':
              btn.push(this.disabled
                ? 'text-red-300 bg-red-50 !shadow-none'
                : 'text-red-700 bg-red-50 hover:bg-red-100 hover:text-red-800 focus:ring-4 focus:ring-red-100 !shadow-none');
              break;

            case 'gray':
              btn.push(this.disabled
                ? 'text-gray-300 bg-gray-50'
                : 'text-gray-700 bg-gray-50 hover:bg-gray-100 hover:text-gray-800 focus:ring-4 focus:ring-gray-100');
              break;

            default:
              btn.push(this.disabled
                ? 'text-gray-300 bg-gray-50'
                : 'text-gray-700 bg-gray-50 hover:bg-gray-100 hover:text-gray-800 focus:ring-4 focus:ring-gray-100');
              break;
          }
          break;

        case 'outlined':
          switch (this.color) {
            case 'active':
              btn.push(
                ...defaultBtn,
                this.disabled
                  ? '!border-primary-200 text-primary-300 bg-white'
                  : '!border-primary-600 text-primary-700 !bg-primary-50 hover:text-primary-800 focus:ring-4 focus:ring-primary-100',
              );
              break;

            default:
              btn.push(
                ...defaultBtn,
                this.disabled
                  ? '!border-gray-200 text-gray-300 bg-white'
                  : '!border-gray-300 text-gray-700 bg-white hover:bg-gray-50 hover:text-gray-800 focus:ring-4 focus:ring-gray-100',
              );
              break;
          }
          btn.push(
            ...defaultBtn,
            this.disabled
              ? '!border-gray-200 text-gray-300 bg-white'
              : '!border-gray-300 text-gray-700 bg-white hover:bg-gray-50 hover:text-gray-800 focus:ring-4 focus:ring-gray-100',
          );
          break;

        case 'text':
          btn.push(
            ...defaultBtn,
            this.disabled
              ? 'text-gray-300 !shadow-none'
              : 'text-gray-600 hover:text-gray-700 !shadow-none hover:bg-gray-50',
          );
          break;

        case 'plain':
          btn.push(...defaultBtn, '!shadow-none');
          switch (this.color) {
            case 'error':
              btn.push(this.disabled ? 'text-red-300' : 'text-red-700 hover:bg-red-50');
              break;

            default:
              btn.push(this.disabled ? 'text-gray-300' : 'text-gray-700');
              break;
          }
          break;

        case 'dark':
          btn.push(
            ...defaultBtn,
            '!shadow-none',
            'text-white bg-gray-700 hover:bg-gray-800 hover:text-white focus:ring-2 focus:ring-gray-300',
          );
          break;

        case 'link':
          btn.push(...defaultBtn);
          switch (this.color) {
            case 'error':
              btn.push(
                ...defaultBtn,
                this.disabled
                  ? '!p-0 !shadow-none text-red-200'
                  : '!p-0 !shadow-none text-red-700 hover:text-red-800',
              );
              break;

            default:
              btn.push(
                ...defaultBtn,
                this.disabled
                  ? '!p-0 !shadow-none text-primary-200'
                  : '!p-0 !shadow-none text-primary-700 hover:text-primary-800',
              );
              break;
          }
          break;

        default:
          btn.push(...defaultBtn, '!shadow-none');
          break;
      }

      switch (this.size) {
        case 'xxs':
          btn.push(
            ...(this.icon ? ['justify-center', 'w-[24px] h-[24px]'] : ['px-2', 'py-2', 'text-sm']),
            'h-[24px]',
          );
          break;
        case 'xs':
          btn.push(
            ...(this.icon ? ['justify-center', 'w-[28px] h-[28px]'] : ['px-3', 'py-2', 'text-sm']),
            'h-[28px]',
          );
          break;
        case 'sm':
          btn.push(
            ...(this.icon ? ['justify-center', 'w-9'] : ['px-3', 'py-2', 'text-sm']),
            'h-9',
          );
          break;

        case 'md':
          btn.push(
            ...(this.icon ? ['justify-center', 'w-10'] : ['px-4', 'py-2', 'text-sm']),
            'h-10',
          );
          break;

        case 'lg':
          btn.push(
            ...(this.icon ? ['justify-center', 'w-11'] : ['px-4', 'py-2', 'text-base']),
            'h-11',
          );
          break;

        case 'xl':
          btn.push(
            ...(this.icon ? ['justify-center', 'w-12'] : ['px-6', 'py-3', 'text-base']),
            'h-12',
          );
          break;

        case '2xl':
          btn.push(
            ...(this.icon ? ['justify-center', 'w-14'] : ['px-6', 'py-3', 'text-lg']),
            'h-14',
          );
          break;
      }

      if (this.rounded)
        btn.push('!rounded-full');

      if (this.block)
        btn.push('!block w-full');

      if (this.icon)
        btn.push('icon');

      // if (this.disabled)
      //   btn.push('bg-primary-200');

      return btn;
    },
  },
};
</script>

<template>
  <!-- add check loading header -->
  <button
    type="button"
    :class="button_classes"
    :disabled="disabled || loading"
    @click="$emit('click', $event)"
  >
    <div
      v-if="loading"
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
          opacity=".25"
        />
        <path
          fill="currentColor"
          d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
        >
          <animateTransform
            attributeName="transform"
            dur="0.75s"
            repeatCount="indefinite"
            type="rotate"
            values="0 12 12;360 12 12"
          />
        </path>
      </svg>
    </div>
    <div class="flex items-center justify-center gap-2" :class="{ invisible: loading }">
      <slot />
    </div>
  </button>
</template>

<style lang="scss">
.hawk-button {
  svg {
    color: inherit;
  }
}
</style>
