<script setup>
import { useRoute } from 'vue-router';

const $t = inject('$t');
const route = useRoute();

const header_tabs = [
  {
    uid: 'asset-settings-general',
    label: $t('General'),
    to: { name: 'asset-settings-general' },
  },
  {
    uid: 'asset-settings-users',
    label: $t('Users'),
    to: { name: 'asset-settings-users' },
  },
  {
    uid: 'asset-settings-teams',
    label: $t('Teams'),
    to: { name: 'asset-settings-teams' },
  },
  {
    uid: 'asset-settings-roles',
    label: $t('Roles'),
    to: { name: 'asset-settings-roles' },
  },
  {
    uid: 'asset-settings-categories',
    label: $t('Categories'),
    to: { name: 'asset-settings-categories' },
  },
];

const active_item = computed(() => {
  return route?.meta?.module ? route.meta.module : route.name;
});
</script>

<template>
  <div>
    <HawkPageHeader title="Asset Settings">
      <template #left>
        <div class="flex">
          <HawkPageHeaderTabs :tabs="header_tabs" :active_item="active_item" />
        </div>
      </template>
    </HawkPageHeader>
    <div class="mx-4">
      <router-view />
    </div>
  </div>
</template>
