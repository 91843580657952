import Therm from '~/therm/pages/therm.vue';
import ThermMap from '~/therm/pages/therm-map.vue';
import ThermDefects from '~/therm/pages/therm-defects.vue';
import ThermReports from '~/therm/pages/therm-reports.vue';
import ThermCompare from '~/therm/pages/therm-compare.vue';

const routes = [{
  path: '/:asset_id?/therm/:id?',
  component: Therm,
  meta: {
    title: 'Therm',

  },
  children: [
    {
      path: '',
      name: 'therm',
      component: ThermMap,
      meta: {
        title: 'Therm',

      },
    },
    {
      path: 'defects',
      name: 'therm-defects',
      component: ThermDefects,
      meta: {
        title: 'Therm',

      },
    },
    {
      path: 'reports',
      name: 'therm-reports',
      component: ThermReports,
      meta: {
        title: 'Therm',

      },
    },
    {
      path: 'compare',
      name: 'therm-compare',
      component: ThermCompare,
      meta: {
        title: 'Therm compare',

      },
    },
  ],
}];

export default routes;
