<script setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { includes } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { sortRowsByColumn } from '~/common/utils/common.utils.js';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import UserInviteForm from '~/account-settings/components/users/account-settings-user-invite-form.vue';

const common_store = useCommonStore();
const router = useRouter();
const route = useRoute();
const auth_store = useAuthStore();
const $toast = inject('$toast');
const $t = inject('$t');
const $services = inject('$services');
const search = ref('');
const loading = ref(false);
const data_loading = ref(false);

const columns = [
  {
    header: $t('Name'),
    accessorKey: 'uid',
    id: 'uid',
    cell: info => info.getValue(),
    columns: [],
    sortingFn: sortRowsByColumn,
  },
  {
    header: $t('Type'),
    accessorKey: 'user_type',
    id: 'user_type',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    header: $t('Organization'),
    accessorKey: 'organization',
    id: 'organization',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    header: $t('Subscription'),
    accessorKey: 'paid',
    id: 'paid',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    header: $t('Status'),
    accessorKey: 'active',
    id: 'active',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '5',
    show_on_hover: 'true',
  },
];

const { open: openUserInvitePopup, close: closeUserInvitePopup, patchOptions } = useModal({
  component: UserInviteForm,
});

onBeforeMount(() => {
  common_store.set_organization_list();
});

function invitePopupHandler() {
  patchOptions({
    attrs: {
      onClose() {
        closeUserInvitePopup();
      },
      async onSave() {
        data_loading.value = true;
        await common_store.update_global_data({ assets: true, users: true });
        closeUserInvitePopup();
        data_loading.value = false;
      },
    },
  });
  openUserInvitePopup();
}

function handleClick(e) {
  logger.log(e.row.original.uid);
  router.push({ name: 'account-settings-users-details', params: { user_id: e.row.original.uid } });
}

function checkUser(user) {
  return (((user?.first_name && user?.last_name)
    ? (`${user?.first_name} ${user?.last_name}`)
    : user?.email) || user?.username);
}

const filtered_users = computed(() => {
  return common_store
    ?.members_scope_users(route?.params?.asset_id)
    .filter(
      (user) => {
        return user?.status !== 'invited'
        && (includes(checkUser(user)?.toLowerCase(),
          search?.value?.toLowerCase(),
        ) || includes(user.email?.toLowerCase(),
          search?.value?.toLowerCase()?.replace(/ +/g, ''),
        ));
      },
    );
});

function itemsIfOwnerOrManager(user) {
  return [
    user.is_guest
      ? {
          label: $t('Remove account'),
          uid: 'remove-user-from-organization',
        }
      : {
          label: user.active ? $t('Deactivate') : $t('Activate'),
          uid: user.active ? 'toggle-member-deactivate' : 'toggle-member-active',
        },
    ...(!user.is_owner
      ? [{ label: $t('Convert to member'), uid: 'convert-to-member' }]
      : []),
    { label: $t('Edit'), uid: 'edit' },
    ...(route.params?.asset_id
      ? [{ label: $t('Remove'), uid: 'remove-user-from-asset' }]
      : []),
  ];
}

function itemsIfNotOwnerOrManager(user, user_active_option) {
  return [
    ...(user.is_guest
      ? [
          {
            label: $t('Remove account'),
            uid: 'remove-user-from-organization',
          },
        ]
      : user_active_option),

    { label: $t('Edit'), uid: 'edit' },

    user.user_type === 'guest'
      ? { label: $t('Convert to member'), uid: 'convert-to-member' }
      : { label: $t('Convert to guest'), uid: 'convert-to-guest' },
    user.paid
      ? { label: $t('Convert to free'), uid: 'convert-to-free' }
      : { label: $t('Convert to paid'), uid: 'convert-to-paid' },
    ...(route.params.asset_id
      ? [{ label: $t('Remove'), uid: 'remove-user-from-asset' }]
      : []),
  ];
}

function hawk_menu_items(user) {
  const user_active_option = user.active
    ? [
        {
          label: $t('Deactivate'),
          uid: 'toggle-member-deactivate',
        },
        ...((user.user_type !== 'guest' && user.paid)
          ? [{ label: $t('Mark as admin'), uid: 'mark-as-admin' }]
          : []),
      ]
    : [
        {
          label: $t('Activate'),
          uid: 'toggle-member-active',
        },
      ];

  return (user.is_owner || user.is_manager)
    ? itemsIfOwnerOrManager(user)
    : itemsIfNotOwnerOrManager(user, user_active_option);
}

async function updateMemberAndStore(type, params, message = '') {
  data_loading.value = true;
  const { data } = await common_store.update_data({
    type,
    id: params.id,
    data: params.body,
    attribute: params.attribute || '',
    service: 'users',
    append_data: true,
    state_prop: 'users_map',
    update_state: true,
  });

  if (data) {
    await common_store.update_global_data({
      users: true,
      internal_users: true,
      internal_users_uids: true,
    });
    $toast({
      text: message,
      type: 'success',
    });
    data_loading.value = false;
  }
  else {
    data_loading.value = false;
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
  }
}

function toggleMemberActivation(member, val) {
  if (Array.isArray(member))
    updateMemberAndStore(
      'update',
      {
        body: { users: member },
        attribute: val === true ? 'activate' : 'deactivate',
      },
      val === true ? 'Users deactivated' : 'Users activated',
    );
  else if (member.is_guest)
    updateMemberAndStore(
      'toggle_guest_user_activation',
      {
        id: member.uid,
        organization_id: auth_store.current_organization?.uid,
        body: { active: !member.active },
      },
      member.active ? 'User deactivated' : 'User activated',
    );
  else
    updateMemberAndStore(
      'update',
      {
        id: member.uid,
        attribute: `lifecycle/${member.active ? 'deactivate' : 'activate'}`,
        body: { sendEmail: false },
      },
      member.active ? 'User deactivated' : 'User activated',
    );
}

async function removeUserFromOrganization(uid) {
  try {
    data_loading.value = true;
    const response = await $services.organizations.post({
      id: auth_store.current_organization?.uid,
      attribute: 'remove-guestusers',
      body: {
        users: [uid],
      },
    });

    if (response) {
      await common_store.update_global_data({ users: true });
      $toast({
        text: 'User successfully removed from the organization',
        type: 'success',
      });
      data_loading.value = false;
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
      data_loading.value = false;
    }
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
    data_loading.value = false;
    logger.log(error);
  }
}

async function convertToAdmin(member) {
  if (Array.isArray(member))
    updateMemberAndStore(
      'update',
      {
        body: { users: member, sendEmail: true, user_type: 'admin' },
        attribute: 'convert-to-admin',
      },
      'Successfully converted to admin',
      // "User converted to admin"
    );

  else
    await updateMemberAndStore(
      'update',
      {
        id: member.uid,
        body: { user_type: 'admin' },
        attribute: 'convert-to-admin',
      },
      'Successfully converted to admin',
      // "User converted to admin"
    );
}

async function convertToGuest(member) {
  if (Array.isArray(member))
    updateMemberAndStore(
      'update',
      {
        body: { users: member, sendEmail: true },
        attribute: 'convert-to-guest',
      },
      'Successfully converted to guest',
      // "User converted to guest"
    );

  else
    await updateMemberAndStore(
      'update',
      {
        id: member.uid,
        attribute: 'convert-to-guest',
      },
      'Successfully converted to guest',
      // "User converted to guest"
    );
}

async function convertToMember(member) {
  if (Array.isArray(member))
    updateMemberAndStore(
      'update',
      {
        body: { users: member, sendEmail: true, user_type: 'member' },
        attribute: 'convert-to-member',
      },
      'Successfully converted to member',
      // "User converted to member"
    );

  else
    await updateMemberAndStore(
      'update',
      {
        id: member.uid,
        body: { user_type: 'member' },
        attribute: 'convert-to-member',
      },
      'Successfully converted to member',
      // "User converted to member"
    );
}

async function convertToFree(member) {
  if (Array.isArray(member))
    updateMemberAndStore(
      'update',
      {
        body: { users: member, sendEmail: true, paid: false },
        attribute: 'convert-to-free',
      },
      'Successfully converted to free',
      // "User converted to free"
    );

  else
    await updateMemberAndStore(
      'update',
      {
        id: member.uid,
        body: { paid: false },
        attribute: 'convert-to-free',
      },
      'Successfully converted to free',
      // "User converted to free"
    );
}

async function convertToPaid(member) {
  if (Array.isArray(member))
    updateMemberAndStore(
      'update',
      {
        body: { users: member, sendEmail: true, paid: true },
        attribute: 'convert-to-paid',
      },
      'Successfully converted to paid',
      // "User converted to paid"
    );

  else
    await updateMemberAndStore(
      'update',
      {
        id: member.uid,
        body: { paid: false },
        attribute: 'convert-to-paid',
      },
      'Successfully converted to paid',
      // "User converted to paid"
    );
}

async function remove_user_from_asset(user_id) {
  try {
    data_loading.value = true;
    const { data } = await common_store.update_data({
      type: 'patch_update',
      id: route.params.asset_id,
      data: {
        asset: {
          members: {
            remove: [user_id],
          },
        },
      },
      service: 'assets',
      append_data: false,
      state_prop: 'assets_map',
      update_state: false,
    });
    if (data?.asset) {
      await common_store.update_global_data({ assets: true, users: true });
      $toast({
        text: 'User removed successfully from the asset',
        type: 'success',
      });
      data_loading.value = false;
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
      data_loading.value = false;
    }
  }
  catch (error) {
    logger.log(error);
  }
}

async function onActionClicked(type, user) {
  switch (type) {
    case 'toggle-member-active':
      toggleMemberActivation(user, true);
      break;
    case 'toggle-member-deactivate':
      toggleMemberActivation(user, false);
      break;
    case 'remove-user-from-organization':
      removeUserFromOrganization(user.uid);
      break;
    case 'mark-as-admin':
      convertToAdmin(user);
      break;
    case 'convert-to-member':
      convertToMember(user);
      break;
    case 'convert-to-guest':
      convertToGuest(user);
      break;
    case 'convert-to-free':
      convertToFree(user);
      break;
    case 'convert-to-paid':
      convertToPaid(user);
      break;
    case 'remove-user-from-asset':
      remove_user_from_asset(user.uid);
      break;
    case 'edit':
      router.push({
        name: route?.params?.asset_id
          ? 'asset-settings-users-details'
          : 'account-settings-users-details',
        params: {
          asset_id: route?.params?.asset_id,
          user_id: user.uid,
        },
      });
      break;
  }
}
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="my-4">
      <template #left>
        <HawkSearchInput v-model="search" :placeholder="$t('Search member')" />
      </template>
      <template #right>
        <div class="flex gap-3">
          <HawkButton v-if="auth_store.check_permission('invite_users', $route.params.asset_id)" color="primary" @click="invitePopupHandler()">
            <IconHawkPlus class="text-white" />
            <span>{{ $t('Invite Members') }}</span>
          </HawkButton>
        </div>
      </template>
    </HawkPageSecondaryHeader>

    <div v-if="!auth_store.check_permission('view_users', $route.params.asset_id)">
      <HawkIllustrations type="no-permission" for="users" />
    </div>
    <hawk-loader v-else-if="loading" />
    <div v-else-if="!filtered_users?.length">
      <HawkIllustrations v-if="search" type="no-results" for="users" />
      <HawkIllustrations v-else type="no-data" for="users" />
    </div>
    <div v-else-if="filtered_users.length">
      <TableWrapperVue>
        <HawkTable
          :pagination_config="{ totalRows: filtered_users?.length, pageSize: 25 }"
          :data="filtered_users"
          :columns="columns"
          :is_loading="data_loading"
          :default_height="500"
          is_gapless
        >
          <template #uid="uid">
            <HawkMembers
              :members="uid.data.getValue()"
              size="md"
              type="label"
              has_email
              @click="
                router.push({
                  name: $route.params.asset_id
                    ? 'asset-settings-users-details'
                    : 'account-settings-users-details',
                  params: {
                    asset_id: $route.params.asset_id,
                    user_id: uid.data.getValue(),
                  },
                })
              "
            />
          </template>
          <template #organization="organization">
            {{ common_store.get_organization(organization.data.getValue())?.name }}
          </template>
          <template #paid="paid">
            {{ paid.data.getValue() ? "Paid" : "Free" }}
          </template>
          <template #active="active">
            <HawkBadge :color="active.data.getValue() ? 'green' : 'gray'">
              {{ active.data.getValue() ? "Active" : "Deactivated" }}
            </HawkBadge>
          </template>
          <template #user_type="user_type">
            <div>
              <span v-if="user_type.data.row.original.is_owner">
                {{ $t("Owner") }}
              </span>
              <span v-else-if="user_type.data.row.original.is_manager">
                {{ $t("Admin") }}
              </span>
              <span v-else-if="user_type.data.row.original.user_type === 'guest'">
                {{ $t("Guest") }}
              </span>
              <span v-else>
                {{ $t("User") }}
              </span>
            </div>
          </template>
          <template #context_menu="user">
            <HawkMenu
              v-if="auth_store.check_permission('modify_users', $route.params.asset_id)"
              :items="hawk_menu_items(user.data.row.original)"
              position="bottom-left"
              class="z-[999]"
            >
              <template #trigger>
                <IconHawkDotsVertical />
              </template>
              <template #content>
                <div class="p-1 w-48 flex flex-col">
                  <HawkButton
                    v-for="item in hawk_menu_items(user.data.row.original)"
                    :key="item.label"
                    type="text"
                    @click="onActionClicked(item.uid, user.data.row.original)"
                  >
                    {{ item.label }}
                  </HawkButton>
                </div>
              </template>
            </HawkMenu>
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
