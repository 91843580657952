import { acceptHMRUpdate, defineStore } from 'pinia';
import { keyBy } from 'lodash-es';
import { document_status_keys } from '~/dms/constants';

const document_operation = {
  'add_markups': {
    method: 'post',
    url: '',
    success_msg: '',
    error_msg: '',
  },
  'delegate': {
    method: 'patch',
    url: 'documents/delegate',
    success_msg: 'Delegate added successfully.',
    error_msg: 'Delegate has not been added',
  },
  'add_reviewers': {
    method: 'post',
    url: 'add-reviewers',
    success_msg: 'Reviewers added successfully.',
    error_msg: 'Reviewers has not been added.',
  },
  'mark_as_submitted': {
    method: 'patch',
    url: 'documents/mark-as-submitted',
    success_msg: 'Document marked as submitted',
    error_msg: 'Document marking as submitted failed',
  },
  'cancel': {
    method: 'patch',
    url: 'cancel-documents',
    success_msg: 'Documents cancelled successfully',
    error_msg: 'Documents cancellation failed',
  },
  'uncancel': {
    method: 'patch',
    url: 'uncancel-documents',
    success_msg: 'Documents uncancelled successfully',
    error_msg: 'Documents un-cancellation failed',
  },
  'sync': {
    method: 'patch',
    url: 'sync-documents',
    success_msg: 'Documents synced successfully',
    error_msg: 'Document syncing failed ',
  },
  'reopen_for_review': {
    method: 'post',
    url: 'reopen-for-review',
    success_msg: 'Document reopened successfully',
    error_msg: 'Document reopening failed',
  },
  'initiate_transmittal': {
    method: 'patch',
    success_msg: '',
    error_msg: '',
  },
  'unlock': {
    method: 'patch',
    url: 'documents/unlock-for-review',
    success_msg: 'Document unlocked successfully',
    error_msg: 'Document unlocking failed',
  },
  'reassign-members': {
    method: 'post',
    url: 'reassign-members',
    success_msg: 'Document reassigned successfully',
    error_msg: 'Document reassigning failed',
  },
};

const transmittal_statuses_map = {
  'pending_upload': {
    label: 'Submission pending',
    color: 'yellow',
  },
  'waiting_for_upload': {
    label: 'Waiting for documents',
    color: 'gray',
  },
  'pending_review': {
    label: 'Pending review',
    color: 'yellow',
  },
  'waiting_for_review': {
    label: 'Waiting for review',
    color: 'gray',
  },
  'completed': {
    label: 'Completed',
    color: 'green',
  },
  'cancelled': {
    label: 'Cancelled',
    color: 'gray',
  },
  'Review Completed': {
    label: 'Review Completed',
    color: 'green',
  },
};

export function useTransmittalsStore(key) {
  return defineStore(key || 'transmittals', {
    state: () => ({
      transmittals_map: {},
      transmittal: {},
      total_count: 0,
      filters: {},
      active_document: null,
      sub_workflow_documents: null,
      priority_values_map: {
        critical: {
          name: 'critical',
          label: 'Critical',
          value: 1,
        },
        high: {
          name: 'high',
          label: 'High',
          value: 2,
        },
        medium: {
          name: 'medium',
          label: 'Medium',
          value: 3,
        },
        low: {
          name: 'low',
          label: 'Low',
          value: 4,
        },
        not_set: {
          name: 'not_set',
          label: 'Not set',
          value: 5,
        },
      },
      transmittal_statuses_map,
    }),
    getters: {
      transmittals: (state) => {
        return Object.values(state?.transmittals_map || {});
      },
      document_details: () => {
        return {};
      },
      priority_values: state => Object.values(state?.priority_values_map || {}),
      priority_value: state => (priority) => {
        return state.priority_values_map[priority]?.value;
      },
      transmittal_status: state => (transmittal) => {
        if (transmittal.status === 'pending_review' && transmittal.actions?.can_close)
          return { color: 'green', label: 'Ready to close' };
        return state.transmittal_statuses_map[transmittal.status] || null;
      },
    },
    actions: {
      set_filters({ payload }) {
        this.filters = payload;
      },
      async set_transmittals(options, append = false) {
        try {
          const { data, headers } = await this.$services.dms_transmittals[options?.method || 'getAll']({
            query: {
              ...this.filters,
              asset: this.$router.currentRoute.value.params.asset_id,
              ...(options?.query || {}),
            },
            body: options.body,
            attribute: options.attribute,
          });
          this.total_count = headers['x-total-count'];
          if (append)
            this.transmittals_map = { ...this.transmittals_map, ...keyBy(data?.transmittals || [], 'uid') };
          else if (data?.transmittals)
            this.transmittals_map = keyBy(data.transmittals, 'uid');
          return data;
        }
        catch (err) {
          logger.error(err);
        }
      },
      async save_as_draft_transmittal(options, is_update = true) {
        try {
          const { data } = await await this.$services.dms_transmittals.post({
            attribute: `${options.transmittal_uid}/save-draft`,
            body: { transmittals: options.transmittals },
          });
          if (!data?.errors?.length) {
            if (is_update)
              this.transmittal = data.transmittals[0];

            this.$toast({ text: 'Transmittal saved as draft', type: 'success' });
          }
          else {
            this.$toast({ text: 'Transmittal saving as draft failed', type: 'error' });
          }
          return data?.transmittals[0];
        }
        catch (err) {
          logger.error(err);
          this.$toast({ text: 'Something went wrong!', type: 'error' });
          return err;
        }
      },
      async set_transmittal(options) {
        try {
          const { data } = await this.$services.dms_transmittals.get({
            id: options?.transmittal_uid,
            query: options.query,
          });
          if (data?.transmittals) {
            this.transmittal = data.transmittals[0];
            const review_status_set = document_status_keys.reduce((sets, field) => {
              if (this.transmittal?.review_status_set?.[field])
                sets[field] = this.transmittal?.review_status_set?.[field];
              return sets;
            }, {});
            this.transmittal = {
              ...this.transmittal,
              review_status_set: {
                ...(review_status_set || {}),
                pending: 'Pending submission',
                submitted: 'Submitted',
              },
            };
          }

          return data?.transmittals[0];
        }
        catch (err) {
          logger.error(err);
          return err;
        }
      },
      async create_transmittal(options) {
        try {
          if (options?.transmittals)
            options.transmittals.asset = this.$router.currentRoute.value.params.asset_id;
          const { data } = await this.$services.dms_transmittals.post({
            body: {
              transmittals: options?.transmittals,
            },
          });
          if (data?.transmittals) {
            this.transmittals_map = {
              ...this.transmittals_map,
              ...keyBy(data.transmittals[0], 'uid'),
            };
            this.$toast({ text: 'Transmittal Created Successfully', type: 'success' });
            return data.transmittals[0];
          }
          return null;
        }
        catch (err) {
          logger.error(err);
          return err;
        }
      },
      update_transmittal_field(options) {
        this.transmittal = {
          ...this.transmittal,
          [options.field_name]: options.field_value,
        };
      },
      async update_transmittal(options) {
        try {
          const { data } = await this.$services.dms_transmittals.patch({
            id: options?.transmittal_uid,
            body: {
              transmittals: options?.transmittals,
            },
          });
          if (data?.transmittals) {
            this.transmittal = data.transmittals[0];
            this.$toast({ text: 'Transmittal updated Successfully', type: 'success' });
          }
          return data?.transmittals[0];
        }
        catch (err) {
          logger.error(err);
          this.$toast({
            text: err?.data?.description || '',
            title: 'Something went wrong!',
            type: 'error',
          });
        }
      },
      async archive_transmittal(options) {
        try {
          const { data } = await this.$services.dms_transmittals.post({
            id: `${options?.transmittal_uid}/archive`,
          });
          if (data?.transmittal) {
            this.transmittal = { ...data.transmittal };
            this.$toast({ text: 'Transmittal archived Successfully', type: 'success' });
          }
          return data?.transmittal;
        }
        catch (err) {
          this.$toast({
            text: err?.data?.description || '',
            title: 'Something went wrong!',
            type: 'error',
          });
        }
      },
      async unarchive_transmittal(options) {
        try {
          const { data } = await this.$services.dms_transmittals.post({
            id: `${options?.transmittal_uid}/unarchive`,
          });
          if (data?.transmittal) {
            this.transmittal = { ...data.transmittal };
            this.$toast({ text: 'Transmittal unarchived Successfully', type: 'success' });
          }
          return data?.transmittal;
        }
        catch (err) {
          this.$toast({
            text: err?.data?.description || '',
            title: 'Something went wrong!',
            type: 'error',
          });
        }
      },
      async cancel_transmittal(options) {
        try {
          const { data } = await this.$services.dms_transmittals.post({
            attribute: `${options?.transmittal_uid}/cancel`,
            body: options?.payload,
          });
          if (data?.transmittal) {
            this.transmittal = data.transmittal;
            this.$toast({ text: 'Transmittal cancelled Successfully', type: 'success' });
          }
          return data;
        }
        catch (err) {
          logger.error(err);
          this.$toast({ text: 'Something went wrong!', type: 'error' });
          return err;
        }
      },
      update_transmittal_document({ uid = '', data = {} }) {
        Object.assign(this.transmittal.documents[uid], data);
      },
      async transmit_transmittal(options) {
        try {
          const { data } = await this.$services.dms_transmittals.post({
            attribute: `${options.transmittal_uid}/transmit`,
            body: { transmittals: options.transmittals },
          });
          if (data?.errors?.length) {
            this.$toast({ text: 'Something went wrong!', type: 'error' });
          }
          else {
            this.transmittal = data.transmittal;
            this.$toast({ text: 'Transmitted successfully', type: 'success' });
          }
          return data.transmittal;
        }
        catch (err) {
          logger.error(err);
          this.$toast({ text: 'Something went wrong!', type: 'error' });
          return err;
        }
      },
      async initiate_sub_workflow(documents, is_review) {
        this.sub_workflow_documents = Array.isArray(documents) ? documents : [documents];
        this.$router.push({ name: 'files-create-transmittals', query: { is_review } });
      },
      async transmittal_mark_as_completed(options) {
        try {
          const { data } = await this.$services.dms_transmittals.post({
            attribute: `${options.transmittal_uid}/close`,
            body: { transmittals: { comment: options.message } },
          });
          if (!data?.errors?.length)
            this.$toast({ text: 'Transmittal is completed', type: 'success' });
        }
        catch (err) {
          logger.error(err);
        }
      },
      async transmittal_send_message(options) {
        try {
          const { data } = await this.$services.dms_transmittals.post({
            attribute: `${options.transmittal_uid}/message`,
            body: { transmittals: options.payload },
          });
          if (data?.errors?.length) {
            this.$toast({ text: 'Something went wrong!', type: 'error' });
          }
          else {
            this.transmittal = data.transmittal;
            this.$toast({ text: 'Message sent successfully', type: 'success' });
          }
          return data.transmittal;
        }
        catch (err) {
          logger.error(err);
        }
      },
      /* --------------------------------Documents------------------------------------------ */
      async document_operations(options, operation) {
        try {
          const { method, url, success_msg, error_msg } = document_operation[operation];
          const { data } = await this.$services.dms_transmittals[method]({
            attribute: `${options.transmittal_uid}/${url}`,
            body: { transmittals: options.transmittals },
          });
          if (!data?.errors?.length) {
            this.transmittal = data?.transmittal || data.transmittals[0];
            this.$toast({ text: success_msg, type: 'success' });
          }
          else {
            this.$toast({ text: error_msg, type: 'error' });
          }
        }
        catch (err) {
          logger.error(err);
        }
      },
      async transmittal_feedback(options) {
        try {
          const { data } = await this.$services.dms_transmittals.get({
            attribute: `${options.transmittal_uid}/documents/${options?.document_uid}/feedback`,
          });
          return data;
        }
        catch (err) {
          logger.error(err);
        }
      },
    },
  })();
}

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTransmittalsStore, import.meta.hot));
