<script setup>
import { capitalize, cloneDeep, isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

import { watch } from 'vue';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});

const route = useRoute();

const { parseRulesDateData } = useFamConstants();

const $services = inject('$services');
const $t = inject('$t');

const dashboard_store = useDashboardStore();
const dashboard_forms_store = useDashboardFormsStore();

const {
  forms_configuration,
  forms_v2_filters,
} = storeToRefs(dashboard_forms_store);

const loading = ref(false);
const activities = ref([]);
const columns = ref([]);
const payload = ref(null);
const forceUpdate = ref(0);
const no_data = ref(false);
const prevent_watcher = ref(false);
const form_error = ref(false);

async function getReports() {
  loading.value = true;
  payload.value = dashboard_forms_store.parse_forms_form_to_server_format(props.data.data);

  const forms_payload = cloneDeep(payload.value);

  const filters = forms_payload.filters.advanced_filter && Object.keys(forms_payload.filters.advanced_filter).length ? [forms_payload.filters.advanced_filter, ...cloneDeep(forms_v2_filters.value) || []] : cloneDeep(forms_v2_filters.value);

  forms_payload.filters.asset_uid = route.params.asset_id || null;

  forms_payload.filters = { ...forms_payload.filters, advanced_filter: filters };

  forms_payload.filters.advanced_filter = forms_payload.filters.advanced_filter.map((filter) => {
    filter.rules = parseRulesDateData(filter.rules);
    return filter;
  });
  try {
    const { data } = await $services.forms.get_graph({
      body: forms_payload,
    });
    if (data?.data?.length) {
      if (props.data.data.transpose)
        if (props.data.data.breakdown.key !== 'none')
          generateGroupedTransposeActivities(data.data);

        else
          generateUngroupedTransposeActivities(data.data);

      else generateActivities(data.data);

      no_data.value = false;
      forceUpdate.value++;
    }
    else {
      no_data.value = true;
      activities.value = [];
      columns.value = [];
      loading.value = false;
    }

    form_error.value = null;
    loading.value = false;
  }
  catch (err) {
    form_error.value = err?.response?.status === 400 ? $t('Template is not published') : $t('Template not found');
    loading.value = false;
  }
}

const columns_widths_map = computed(() => props.data.data.columns_widths || {});

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function generateUngroupedTransposeActivities(data) {
  const activity_array = [];
  const column_array = [];
  const field_name = props.data.data.field.label;
  const columnData = data.reduce((acc, item) => {
    // building column data
    const col_id = `column-${item[field_name]}`;
    column_array.push({
      accessorFn: data => data[col_id],
      header: capitalize(item[field_name]),
      id: col_id,
      size: columns_widths_map.value[col_id] || 150,
    });

    // building data required to build activities data
    Object.keys(item).forEach((key) => {
      if (!acc[key]) {
        acc[key] = [];
        data.forEach((newItem) => {
          acc[key].push(newItem[key]);
        });
      }
      else {
        data.forEach((newItem) => {
          acc[key].push(newItem[key]);
        });
      }
    });
    return acc;
  }, {});

  const values = Object.keys(columnData).find(key => key !== field_name);
  const result = columnData[field_name].reduce((acc, field, index) => {
    acc[`column-${field}`] = columnData[values][index];
    return acc;
  }, {});
  activity_array.push(result);
  activities.value = activity_array;
  columns.value = column_array;
}

function generateGroupedTransposeActivities(data) {
  const activity_array = [];
  const field_name = props.data.data.field.label;
  const column_array = [{
    accessorKey: 'name',
    id: 'name',
    header: props.data.data.breakdown.label,
    size: columns_widths_map.value.name || 400,
  }];

  const used_column_keys = [];

  Object.values(data).forEach((value) => {
    for (const [nestedKey, nestedValue] of Object.entries(value))
      if (!used_column_keys.includes(value[field_name])) {
        column_array.push({
          accessorKey: value[field_name].toLowerCase().replace(' ', '_'),
          header: value[field_name],
          id: value[field_name].toLowerCase().replace(' ', '_'),
          size: columns_widths_map.value[field_name] || 150,
        });
        used_column_keys.push(value[field_name]);
      }
  });

  const names = new Set();
  for (const entry of data)
    Object.keys(entry).forEach((key) => {
      if (key !== field_name)
        names.add(key);
    });
  // Initialize output data
  const outputData = [];
  names.forEach((name) => {
    const dataItem = { name };
    data.forEach((entry) => {
      const textField = entry[field_name];
      const value = entry[name];
      dataItem[textField.toLowerCase().replace(' ', '_')] = value;
    });
    outputData.push(dataItem);
  });

  activities.value = outputData;
  columns.value = column_array;
}

function generateActivities(data) {
  const activity_array = [];
  const column_array = [];

  const used_column_keys = [];

  Object.values(data).forEach((value) => {
    const current_item = {};
    for (const [nestedKey, nestedValue] of Object.entries(value)) {
      current_item[nestedKey] = nestedValue || '';
      // this check prevents multiple columns being created with same id
      if (!used_column_keys.includes(nestedKey)) {
        column_array.push({
          accessorFn: data => data[nestedKey],
          header: nestedKey,
          id: nestedKey,
          size: columns_widths_map.value[nestedKey] || 150,
        });
        used_column_keys.push(nestedKey);
      }
    }
    activity_array.push(current_item);
  });

  activities.value = activity_array;
  columns.value = column_array;
}

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: columns.value,
      activities: activities.value,
      dataset: activities.value,
      is_transpose: props.data.data.transpose === true,
      dashboard_index: props.data.i,
      is_new_pivot_chart: props.data.data.chart === 'workflow_pivot_table',
    },
  });
}

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getReports();
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true }, { deep: true });
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <div v-if="no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <a v-else-if="activities?.length">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkTable
          :key="forceUpdate"
          :data="activities"
          :columns="columns"
          additional_table_classes=""
          additional_row_classes="even:bg-gray-50"
          :show_menu_header="false"
          :show_column_borders="true"
          :disable_resize="!dashboard_store.is_editing_dashboard"
          is_gapless
          @column-resized="columnResized"
        />
      </div>
    </a>
    <div
      v-else-if="!loading && form_error"
      class="h-[calc(100%-80px)] pt-10 w-full flex items-center justify-center"
    >
      <div class="text-sm">
        {{ form_error }}
      </div>
    </div>
  </div>
</template>

<style>

</style>
