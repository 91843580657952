import AssetSettings from '~/asset-settings/pages/asset-settings.vue';
import General from '~/asset-settings/pages/asset-settings-general.vue';
import Users from '~/account-settings/pages/account-settings-users.vue';
import UserList from '~/account-settings/components/users/account-settings-users-list.vue';
import Invitation from '~/account-settings/components/users/account-settings-invitations.vue';
import UsersDetails from '~/account-settings/components/users/account-settings-user-details.vue';
import Teams from '~/account-settings/pages/account-settings-teams.vue';
import TeamForm from '~/account-settings/components/teams/account-settings-team-form.vue';
import Roles from '~/account-settings/pages/account-settings-roles.vue';
import RoleForm from '~/account-settings/components/roles/account-settings-roles-form.vue';
import Categories from '~/account-settings/pages/account-settings-categories.vue';

const routes = [
  {
    path: '/:asset_id/asset-settings',
    component: AssetSettings,
    children: [
      {
        path: '',
        name: 'asset-settings-general',
        component: General,
        meta: {
          title: 'General',
        },
      },
      {
        path: '',
        component: Users,
        children: [
          {
            path: 'users',
            name: 'asset-settings-users',
            component: UserList,
            meta: {
              title: 'Members',
            },
          },
          {
            path: 'invites',
            name: 'asset-settings-users-invitation',
            component: Invitation,
            meta: {
              module: 'asset-settings-users',
              title: 'Members Invitation',
            },
          },
        ],
      },
      {
        path: 'users/:user_id/',
        name: 'asset-settings-users-details',
        component: UsersDetails,
        meta: {
          module: 'asset-settings-users',
          title: 'Member details',
        },
      },
      {
        path: 'invites/:user_id/',
        name: 'asset-settings-invites-details',
        component: UsersDetails,
        meta: {
          module: 'asset-settings-users',
          title: 'Member details',
        },
      },
      {
        path: 'teams',
        name: 'asset-settings-teams',
        component: Teams,
        meta: {
          title: 'Teams',
        },
      },
      {
        path: 'create-team',
        name: 'asset-settings-create-team',
        component: TeamForm,
        meta: {
          module: 'asset-settings-teams',
          title: 'Create Team',
        },
      },
      {
        path: 'team/:id/',
        name: 'asset-settings-edit-team',
        component: TeamForm,
        meta: {
          module: 'asset-settings-teams',
          title: 'Edit Team',
        },
      },
      {
        path: 'roles',
        name: 'asset-settings-roles',
        component: Roles,
        meta: {
          title: 'Roles',
        },
      },
      {
        path: 'create-role',
        name: 'asset-settings-create-role',
        component: RoleForm,
        meta: {
          module: 'asset-settings-roles',
          title: 'Create Role',
        },
      },
      {
        path: 'role/:id/',
        name: 'asset-settings-edit-role',
        component: RoleForm,
        meta: {
          module: 'asset-settings-roles',
          title: 'Edit Role',
        },
      },
      {
        path: 'categories',
        name: 'asset-settings-categories',
        component: Categories,
        meta: {
          title: 'Categories',
        },
      },
    ],
  },

];
export default routes;
