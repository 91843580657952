<script setup>
import { ref } from 'vue';
import { useTerraStore } from '~/terra/store/terra.store';
import LineElevation from '~/terra/components/map-tools/line-elevation.vue';

const terra_store = useTerraStore();
const $services = inject('$services');
const $toast = inject('$toast');
const $t = inject('$t');

const method_items = ref([
  {
    label: $t('Line'),
    value: 'line',
  },
  {
    label: $t('Point'),
    value: 'point',
  },
]);
const form = ref({
  method: 'line',
  interval: 1,
  layer: '',
});
const select_bound = ref(false);
const is_loading_elevation = ref(false);
const line_elevation = ref(null);
const point_elevation = ref(null);

// TODO: Mixing property
const reports = computed(() => {
  const arr = [];
  if (terra_store.last_selected_project)
    for (const key in terra_store.last_selected_project.reports)
      if (
        (key === 'dsm' || key === 'dtm')
              && Object.keys(terra_store.last_selected_project.reports || {}).includes(key)
      )
        arr.push({ value: key, label: terra_store.last_selected_project.reports[key].name });
  return arr;
});

watch(reports, () => {
  if (reports.value && reports.value.length)
    form.value.layer = reports.value[0].key;
}, { immediate: true });

function showToast(message) {
  $toast({
    text: message,
    type: 'warning',
  });
}

function calculate_elevation() {
  try {
    const active_project = terra_store.last_selected_project;
    const reports = terra_store.projects_details_reports_map[active_project.uid];

    if (!active_project) {
      showToast($t('Select a project'));
      return;
    }
    if (!terra_store.selected_features.length) {
      showToast($t('Select a feature'));
      return;
    }

    if (
      form.value.method === 'line'
            && terra_store.selected_features[0].geometry.type !== 'LineString'
    ) {
      showToast($t('Select line'));
      return;
    }
    if (
      form.value.method === 'point'
            && terra_store.selected_features[0].geometry.type !== 'Point'
    ) {
      showToast($t('Select point'));
      return;
    }
    if (
      !reports[form.value.layer]
            || !reports[form.value.layer].url
    ) {
      showToast($t('Layer not present'));
      return;
    }
    if (form.value.method === 'point') {
      point_elevation.value = null;
      const body = {
        url: reports[form.value.layer].url,
        uid: reports[form.value.layer].uid,
        feature: terra_store.selected_features[0],
      };
      is_loading_elevation.value = true;
      $services.gis_service.elevation_point({ body })
        .then((res) => {
          is_loading_elevation.value = false;
          point_elevation.value = res.data;
        })
        .catch((err) => {
          logger.log(err);
          is_loading_elevation.value = false;
        });
    }
    else if (form.value.method === 'line') {
      if (form.value.interval < 0.1) {
        showToast($t('Sampling should be more than 0.1'));

        return;
      }

      line_elevation.value = null;
      const body = {
        url: reports[form.value.layer].url,
        uid: reports[form.value.layer].uid,
        feature: terra_store.selected_features[0],
        options: {
          interval: Number.parseFloat(form.value.interval),
        },
      };

      is_loading_elevation.value = true;
      $services.gis_service.elevation_line({ body })
        .then((res) => {
          is_loading_elevation.value = false;
          line_elevation.value = res.data.elevations;
        })
        .catch((err) => {
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
          });
          is_loading_elevation.value = false;
          logger.log(err);
        });
    }
    terra_store.terra_track_events('Elevation Profile');
  }
  catch (err) {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
    logger.log('Err :: while calculating elevation ');
  }
}
</script>

<template>
  <div class="p-3 w-80 bg-white rounded-lg">
    <div class="text-[16px] font-semibold mb-2">
      {{ $t('Elevation Profile') }}
    </div>
    <div v-show="!point_elevation">
      <Vueform v-model="form" :sync="true" size="sm">
        <SelectElement
          name="method"
          :items="method_items"
          :native="false"
          :can-clear="false"
          autocomplete="off"
          :label="$t('Method')"
          :add-classes="{
            ElementLayout: {
              outerWrapper: '!block',
            },
            ElementLabel: {
              container: '!mb-2.5',
            },
          }"
        />
        <SelectElement
          name="layer"
          :items="reports"
          :native="false"
          :can-clear="false"
          autocomplete="off"
          :label="$t('Layer')"
          :add-classes="{
            ElementLayout: {
              outerWrapper: '!block',
            },
            ElementLabel: {
              container: '!mb-2.5',
            },
          }"
        />
        <TextElement
          v-show="form.method === 'line'"
          name="interval"
          input-type="number"
          :rules="[
            'nullable',
            'numeric',
          ]"
          autocomplete="off"
          :label="$t('Sampling(M)')"
          :add-classes="{
            ElementLayout: {
              outerWrapper: '!block',
            },
            ElementLabel: {
              container: '!mb-2.5',
            },
          }"
        />
        <StaticElement>
          <HawkButton class="mt-4" :class="{ 'pointer-events-none opacity-50': false }" :loading="is_loading_elevation" color="primary" block @click="calculate_elevation">
            {{ $t('Calculate elevation') }}
          </HawkButton>
        </StaticElement>
      </Vueform>
    </div>
    <transition name="slide-fade">
      <div v-show="point_elevation && form.method !== 'line'">
        <div
          v-if="point_elevation" class="columns-2 text-[14px] font-normal py-1"
        >
          <div class="text-gray-600 font-normal text-[14px]">
            {{ $t('Elevation') }}
          </div>
          <div class="text-gray-900 font-normal text-[14px]">
            {{ point_elevation.elevations[2].toFixed(3) }} {{ $t('meters') }}
          </div>
        </div>
        <HawkButton class="mt-4" color="primary" block @click="point_elevation = null">
          {{ $t('Back') }}
        </HawkButton>
      </div>
    </transition>
    <LineElevation
      v-show="line_elevation"
      :line_elevation="line_elevation"
      :draw="terra_store.draw"
      @close="line_elevation = null"
    />
  </div>
</template>
