<script setup>
import axios from 'axios';
import LocationIcon from '~icons/ic/baseline-location-on';

const props = defineProps({
  options: {
    type: Object,
    required: false,
    default() {
      return {
        name: 'location',
      };
    },
  },
});

const emit = defineEmits(['updateLocationMarker']);

const map_div = ref(null);
const marker = ref(null);
const map = ref(null);
const autocomplete = ref(null);

const raw_location_icon = markRaw(LocationIcon);

async function getAddress(coords) {
  try {
    const {
      data: { results },
    } = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords[0]},${coords[1]}&key=${import.meta.env.VITE_APP_GOOGLE_API_KEY}`);
    return results[0];
  }
  catch (err) {
    return { address: {} };
  }
}

const moveToPosition = function (position) {
  map.value.setCenter(position);
  marker.value.setPosition(position);
  marker.value.setVisible(true);
};

async function markerChanged() {
  try {
    autocomplete.value.off('change');
    const position = {
      lat: marker.value.getPosition().lat(),
      lng: marker.value.getPosition().lng(),
    };
    const address = (position.lat && position.lng) ? await getAddress([position.lat, position.lng]) : null;
    autocomplete.value.load({ ...position, formatted_address: address?.formatted_address });
    moveToPosition(position);
    autocomplete.value.on('change', moveToPosition);
    emit('updateLocationMarker', address);
  }
  catch (e) {
    autocomplete.value.on('change', moveToPosition);
    emit('updateLocationMarker', address);
  }
}

function autoCompleteChanged(place) {
  map.value.setZoom(14);
  moveToPosition(place);
  emit('updateLocationMarker', {
    formatted_address: place.formatted_address,
    geometry: {
      location: {
        lng: place.lng,
        lat: place.lat,
      },
    },
  });
}

onMounted(() => {
  map.value = new google.maps.Map(
    map_div.value,
    {
      disableDefaultUI: true,
      center: { lat: 41.0082376, lng: 28.97835889999999 },
      zoom: 15,
    },
  );

  marker.value = new google.maps.Marker(
    Object.assign(
      {
        map: map.value,
        position: map.value.getCenter(),
        draggable: true,
      },
      {},
    ),
  );

  marker.value.addListener('dragend', markerChanged);
});
</script>

<template>
  <div class="map_container relative">
    <LocationElement
      ref="autocomplete" v-bind="props.options" class="absolute z-1 w-full p-2"
      :addons="{ after: raw_location_icon }" @change="autoCompleteChanged"
    />
    <div id="map" ref="map_div" />
  </div>
</template>

<style scoped lang="scss">
.map_container,
#map {
  min-height: 200px;
  height: 100%;
  width: 100%;
}
</style>
