<script setup>
import { storeToRefs } from 'pinia';
import { useCommonStore } from '~/common/stores/common.store.js';

const $t = inject('$t');
const common_store = useCommonStore();
const { assets_map } = storeToRefs(common_store);

const table_columns = [
  {
    accessorKey: 'name',
    header: $t('Item'),
    id: 'name',
  },
  {
    accessorKey: 'quantity',
    header: $t('Quantity'),
    id: 'quantity',
  },
  {
    accessorKey: 'asset',
    header: $t('Asset'),
    id: 'asset',
  },
  {
    accessorKey: 'scope',
    header: $t('Scope'),
    id: 'scope',
  },
  {
    accessorKey: 'available',
    header: $t('Available'),
    id: 'available',
  },
  {
    accessorKey: 'used_installed',
    header: $t('Used/Installed'),
    id: 'used_installed',
  },
];

const material_list = computed(() => {
  return [
    {
      name: 'Torque tube xmm',
      quantity: 2,
      asset: ['A8egmIiVyP', 'GWJOxCUEpl', 'OmSMyAYgoO'],
    },
    {
      name: 'Isolator Box 630A',
      quantity: 2,
      asset: ['A8egmIiVyP', 'GWJOxCUEpl', 'OmSMyAYgoO'],
    },
  ];
});
</script>

<template>
  <div>
    <div class="flex justify-between mb-4">
      <div>
        <p class="text-sm font-semibold test-gray-900">
          {{ $t('Material') }}
        </p>
        <span class="text-xs text-gray-600">
          {{ $t('Summary of all the materials used of this component in different assets. You can see the scope, availability and installed count for each material.') }}
        </span>
      </div>
      <HawkButton type="outlined">
        <IconHawkPlus />
        {{ $t('New material') }}
      </HawkButton>
    </div>
    <HawkTable
      :data="material_list"
      :columns="table_columns"
      :show_menu_header="false"
      :non_sortable_columns="['context_menu']"
      is_gapless
    >
      <template #name="{ data }">
        <p class="font-medium font-sm text-gray-900">
          {{ data?.row?.original?.name }}
        </p>
      </template>
      <template #asset="{ data }">
        <div class="flex flex-col divide-y w-full">
          <template v-for="asset in data?.row?.original?.asset" :key="`asset_${asset}`">
            <p class="font-xs text-gray-600 py-2">
              {{ assets_map?.[asset]?.name }}
            </p>
          </template>
        </div>
      </template>
      <template #scope="{ data }">
        <div class="flex flex-col divide-y w-full">
          <template v-for="asset in data?.row?.original?.asset" :key="`asset_${asset}`">
            <p class="font-xs text-gray-600 py-2">
              200psc
            </p>
          </template>
        </div>
      </template>
      <template #available="{ data }">
        <div class="flex flex-col divide-y w-full">
          <template v-for="asset in data?.row?.original?.asset" :key="`asset_${asset}`">
            <p class="font-xs text-gray-600 py-2 flex items-center gap-2">
              200psc
              <HawkBadge color="red" :is_rounded="false">
                <IconHawkArrowUp class="w-4 h-4" />
                <IconHawkArrowDown class="w-4 h-4" />
                200
              </HawkBadge>
            </p>
          </template>
        </div>
      </template>
      <template #used_installed="{ data }">
        <div class="flex flex-col divide-y w-full">
          <template v-for="asset in data?.row?.original?.asset" :key="`asset_${asset}`">
            <p class="font-xs text-gray-600 py-2">
              200psc
            </p>
          </template>
        </div>
      </template>
    </HawkTable>
  </div>
</template>
