<script setup>
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useCommonStore } from '~/common/stores/common.store.js';
import CmComponentDetailMaintenanceScheduleForm from '~/system-model/component-master/pages/cm-component-detail/cm-component-detail-maintenance-schedule-form.vue';

// import CmComponentDetailInstantiateMaintenanceForm from '~/system-model/component-master/pages/cm-component-detail/cm-component-detail-instantiate-maintenance-form.vue';

const $t = inject('$t');

const common_store = useCommonStore();
const { assets_map } = storeToRefs(common_store);

const state = reactive({
  active_layout: 'list',
});
const view_items = [
  {
    uid: 'list',
    leftSlot: IconHawkTableTwo,
    action: 'list',
  },
  {
    uid: 'calender',
    leftSlot: IconHawkCalendar,
    action: 'calender',
  },
];

const table_columns = [
  {
    accessorKey: 'activity',
    header: $t('Activity'),
    id: 'activity',
  },
  {
    accessorKey: 'description',
    header: $t('Description'),
    id: 'description',
  },
  {
    accessorKey: 'asset',
    header: $t('Asset'),
    id: 'asset',
  },
  {
    accessorKey: 'frequency',
    header: $t('Frequency'),
    id: 'frequency',
  },
  {
    accessorKey: 'progress',
    header: $t('Progress'),
    id: 'progress',
  },
  {
    accessorKey: 'status',
    header: $t('Status'),
    id: 'status',
  },
  {
    accessorKey: 'due_date',
    header: $t('Due date'),
    id: 'due_date',
  },
  {
    accessorKey: 'next_schedule',
    header: $t('Next schedule'),
    id: 'next_schedule',
  },
];

const material_list = computed(() => {
  return [
    {
      activity: 'Module cleaning',
      description: 'Cleaning of modules monthly by maintenance team',
      asset: ['A8egmIiVyP', 'GWJOxCUEpl', 'OmSMyAYgoO'],
    },
    {
      activity: 'Check wiring',
      description: 'All wirings to be checked by the electricians',
      asset: ['A8egmIiVyP', 'GWJOxCUEpl', 'OmSMyAYgoO'],
    },
  ];
});

const maintenance_schedule_modal = useModal({
  component: CmComponentDetailMaintenanceScheduleForm,
});

function openMaintenanceScheduleForm() {
  maintenance_schedule_modal.patchOptions({
    attrs: {
      onClose() {
        maintenance_schedule_modal.close();
      },
    },
  });
  maintenance_schedule_modal.open();
}

function changeActiveLayout(layout) {
  state.active_layout = layout;
}
</script>

<template>
  <div>
    <div class="flex justify-between items-center mb-4">
      <p class="text-sm font-semibold test-gray-900">
        {{ $t('Maintenance schedules') }}
      </p>
      <div class="flex gap-3">
        <HawkButtonGroup
          :items="view_items"
          :active_item="state.active_layout"
          icon
          size="md"
          @calender="changeActiveLayout('calender')"
          @list="changeActiveLayout('list')"
        />
        <HawkButton type="outlined" @click="openMaintenanceScheduleForm">
          <IconHawkPlus />
          {{ $t('New maintenance schedule') }}
        </HawkButton>
      </div>
    </div>
    <HawkTable
      :data="material_list"
      :columns="table_columns"
      :show_menu_header="false"
      :non_sortable_columns="['context_menu']"
      is_gapless
    >
      <template #activity="{ data }">
        <p class="font-medium font-sm text-gray-900 flex items-center gap-3">
          {{ data?.row?.original?.activity }}
          <HawkButton type="plain" :rounded="true" class="text-primary-700 hover:bg-primary-50 !h-5 w-5 flex justify-center !p-4">
            <IconHawkPlus />
          </HawkButton>
        </p>
      </template>
      <template #asset="{ data }">
        <div class="flex flex-col divide-y w-full">
          <template v-for="asset in data?.row?.original?.asset" :key="`asset_${asset}`">
            <p class="font-xs text-gray-600 py-2">
              {{ assets_map?.[asset]?.name }}
            </p>
          </template>
        </div>
      </template>
      <template #frequency="{ data }">
        <div class="flex flex-col divide-y w-full">
          <template v-for="asset in data?.row?.original?.asset" :key="`asset_${asset}`">
            <p class="font-xs text-gray-600 py-2">
              every month
            </p>
          </template>
        </div>
      </template>
      <template #progress="{ data }">
        <div class="flex flex-col divide-y flex w-full">
          <template v-for="asset in data?.row?.original?.asset" :key="`asset_${asset}`">
            <div class="font-xs text-gray-600 py-2 flex items-center gap-2">
              <HawkProgress :progress="50" class="flex-auto" />
              <span class="text-sm font-medium text-gray-900">5/6</span>
            </div>
          </template>
        </div>
      </template>
      <template #status="{ data }">
        <div class="flex flex-col divide-y w-full">
          <template v-for="asset in data?.row?.original?.asset" :key="`asset_${asset}`">
            <p class="font-xs text-gray-600 py-2">
              Ongoing
            </p>
          </template>
        </div>
      </template>
      <template #due_date="{ data }">
        <div class="flex flex-col divide-y w-full">
          <template v-for="asset in data?.row?.original?.asset" :key="`asset_${asset}`">
            <p class="font-xs text-gray-600 py-2">
              30th April 2024
            </p>
          </template>
        </div>
      </template>
      <template #next_schedule="{ data }">
        <div class="flex flex-col divide-y w-full">
          <template v-for="asset in data?.row?.original?.asset" :key="`asset_${asset}`">
            <p class="font-xs text-gray-600 py-2">
              23rd May 2024
            </p>
          </template>
        </div>
      </template>
    </HawkTable>
  </div>
</template>
