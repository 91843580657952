<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { $t, $date, $date_relative, $services, $toast, auth_store, common_store, route, router, $track_event } = useCommonImports();

const form$ = ref(null);
const permissions = ref({});
const state = reactive({
  is_loading: false,
  role_detail: {},
});

const breadcrumb_items = computed(() => {
  return [
    { uid: '1', label: $t('Account Settings'), disabled: true },
    { uid: '2', label: $t('Roles'), to: { name: 'account-settings-roles' } },
    { uid: '3', label: $t('New Role') },
  ];
});

async function save() {
  try {
    const form_data = form$.value.data;
    const payload = {
      ...form_data,
      users: common_store.filter_users(form_data.users.map(user => user?.uid ? user.uid : user)),
      teams: common_store.filter_teams(form_data.users.map(team => team?.uid ? team.uid : team)),
      permissions: permissions.value,
    };

    if (!route.params.id) {
      payload.organization = auth_store.current_organization?.uid;
      payload.asset = route?.params?.asset_id ? route.params.asset_id : null;
    }

    const { data } = await common_store.update_data({
      type: route.params.id ? 'patch_update' : 'add',
      ...(route.params.id ? { id: route.params.id } : {}),
      data: { role: payload },
      service: 'roles_v2',
      asset_id: route.params.id ? route?.params?.asset_id : null,
      response_key: 'role',
      append_data: false,
      state_prop: 'roles_map',
      update_state: false,
    });

    if (data?.role) {
      await common_store.update_global_data({ roles: [data?.role?.uid], ...(route.params.asset_id ? { assets: [route.params.asset_id] } : {}) });
      if (route.params.id) {
        $toast({
          title: $t('Role updated'),
          text: $t('Your changes to the role have been successfully saved'),
          type: 'success',
        });
      }

      else {
        $toast({
          title: $t('Role created'),
          text: $t('Your changes to the role have been successfully saved'),
          type: 'success',
        });
        $track_event('create_role', { members: data.role.users.length });
      }
      router.push({ name: 'account-settings-roles' });
    }
  }
  catch (error) {
    logger.error('error', error);
    if (route.params.id)
      $toast({
        title: $t('Failed to update role'),
        text: $t('Unable to update the role. Please double-check your input and try again'),
        type: 'error',
      });

    else
      $toast({
        title: $t('Failed to create role'),
        text: $t('Unable to create the role. Please double-check your input and try again'),
        type: 'error',
      });
  }
}

async function getDetails() {
  try {
    const { data } = await $services.roles_v2.get({
      id: route.params.id,
      asset_id: route?.params?.asset_id,
    });
    state.role_detail = data.role;
  }
  catch (error) {
    logger.log('error', error);
  }
}

function loadForm() {
  form$.value.update({
    name: state.role_detail.name,
    description: state.role_detail.description,
    users: [
      ...(common_store?.filter_users(state?.role_detail?.users || []) || []),
      ...(common_store?.filter_teams(state?.role_detail?.teams || []) || []),
    ],
  });
  permissions.value = state?.role_detail?.permissions || {};
}

onMounted(async () => {
  state.is_loading = true;
  if (route.params.id)
    await getDetails();
  setTimeout(() => {
    loadForm();
    state.is_loading = false;
  }, 0);
});
</script>

<template>
  <div>
    <div class="my-5">
      <HawkPageSecondaryHeader class="w-full">
        <template #left>
          <div class="flex items-center gap-3">
            <HawkButton type="text" icon @click="router.push({ name: 'account-settings-roles' });">
              <IconHawkChevronLeft />
            </HawkButton>
            <div>
              <p class="text-lg font-semibold text-gray-900 leading-none">
                {{ route.params.id ? $t('Update role') : $t('Add role') }}
              </p>
              <span class="text-xs text-gray-600">
                {{ $t('Configure granular permissions and assign to members/teams') }}
              </span>
            </div>
          </div>
        </template>
      </HawkPageSecondaryHeader>
    </div>
    <HawkLoader v-if="state.is_loading" />
    <div v-show="!state.is_loading" class="mt-6">
      <Vueform
        ref="form$"
        size="sm"
        :display-errors="false"
        :endpoint="save"
      >
        <div class="col-span-12">
          <div class="max-w-[700px]">
            <TextElement
              :label="$t('Name')"
              name="name"
              :placeholder="$t('Enter name')"
              :rules="['required', 'max:100']"
              class="mb-4"
            />
            <TextareaElement
              :label="$t('Description')"
              name="description"
              :placeholder="$t('Enter description')"
              :rules="['max:200']"
              class="mb-4"
            />
            <HawkAssigneeInput
              :callbacks="route?.params?.asset_id ? { user_filter: (item) => !(common_store.active_asset?.team_members?.map(user => user.uid)?.includes(item.uid)) } : { user_filter: (item) => item }"
              class="mr-5 w-full"
              :multi="true"
              :options="{
                'name': 'users',
                'has_teams': true,
                'placeholder': $t('Select users or teams'),
                'class': 'mb-4',
                'group-select': false,
                'object': false,
                'label': $t('Members/Teams'),
                'description': $t('Choose members and teams to assign/inherit role permissions'),
              }"
              :format_load="true"
            />
          </div>
          <AcctPermissions v-if="!state.is_loading" v-model="permissions" />

          <div class="sticky bottom-0 bg-white">
            <hr class="my-5">
            <div class="flex justify-end gap-3 pb-5">
              <HawkButton type="outlined" @click="$router.push({ name: 'account-settings-roles' })">
                {{ $t('Cancel') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true" :disabled="!auth_store.check_permission('modify_teams', $route.params.asset_id)">
                {{ route.params.id ? $t('Update') : $t('Save') }}
              </ButtonElement>
            </div>
          </div>
        </div>
      </Vueform>
    </div>
  </div>
</template>
