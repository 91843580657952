<script setup>
// other

// components

// assets
import HawkInfoIcon from '~/common/components/atoms/hawk-info-icon.vue';

const props = defineProps({
  header: {
    type: String,
    default: 'Cancel Request',
  },
  sub_header: {
    type: String,
    default: 'This action cannot be undone.',
  },
  confirm: {
    type: Function,
    default: null,
  },
});
const emit = defineEmits(['close']);

const form$ = ref(null);

async function submitHandler(data, form$) {
  const payload = { ...form$?.data };
  await props.confirm(payload);
}
</script>

<template>
  <hawk-modal-container content_class="rounded-lg">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 4 },
      }"
      :endpoint="submitHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #header>
            <div
              class="flex items-start p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800"
            >
              <div class="flex items-start">
                <div class="flex items-start mr-4 border-gray-200 text-xl">
                  <component :is="HawkInfoIcon" class="w-12 h-12" />
                </div>
                <div class="flex flex-col justify-start">
                  {{ header }}
                  <span class="font-normal text-sm text-gray-600">
                    {{ sub_header }}
                  </span>
                </div>
              </div>
              <div class="flex font-normal items-center justify-center -m-2">
                <div
                  class="text-gray-600 rounded-md cursor-pointer flex justify-center items-center p-2 ml-3 hover:bg-gray-50"
                  @click="$emit('close')"
                >
                  <IconHawkXClose class="w-6 h-6 text-gray-500 hover:text-gray-900" />
                </div>
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div class="mt-6">
            <WysiwygEditorElement
              name="description"
              :label="$t('Comment')"
              :options="{
                editor_enabled: true,
                menu_enabled: true,
                plugins: ['images', 'tables', 'tags'],
                placeholder_text: $t('Enter info here'),
              }"
            />
            <DmsUploadElement
              class="mt-6"
              name="supporting_documents"
              :label="$t('Supporting documents')"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement
                  button-class="!border !border-transparent"
                  submits
                  size="sm"
                  name="submit"
                  :button-label="$t('Transmit')"
                />
              </div>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
