<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { uniqBy } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useThermStore } from '../store/therm.store';
import ThermProjects from '../components/therm-projects.vue';
import ThermLayers from '../components/therm-layers.vue';
import ThermRightSidebar from '../components/therm-right-sidebar.vue';
import ThermTools from '../components/therm-tools.vue';
import { useMap } from '~/common/composables/mapbox/maps';
import { useMapboxFeatureSelection } from '~/common/composables/mapbox/selection';
import { useMapItemCreation } from '~/common/composables/mapbox/creation-icons';
import { useThermHelperComposable } from '~/therm/composables/helper-composable.js';
import { useTasksStore } from '~/tasks/store/tasks.store';
import TaskForm from '~/tasks/components/molecule/task-form/therm-task-form.vue';
import { parseStringifiedObjectsInArray } from '~/common/utils/common.utils';

const therm_store = useThermStore();
const route = useRoute();
const router = useRouter();
const tasks_store = useTasksStore('therm_tasks_store');
const { addSymbols, loadTaskIcons, createThermTasks, loadDefectStatusIcons } = useThermHelperComposable();
const task_marker_active = ref(false);
const open_task_popup = ref(false);
const task_form_centroid = ref(null);

watch(() => [tasks_store.tasks(), therm_store.use_tasks, therm_store.show_defect_status_icons, therm_store.features], () => {
  addSymbols();
});
watch(() => therm_store.last_selected_project, async () => {
  await initial_project_initialization();
});
const { styleSelection, loadSelectionEvents } = useMapboxFeatureSelection({

  layer_ids: ['polygon_feature_layer', 'linestring_feature_layer', 'point_feature_layer'],
}, (e) => {
  therm_store.selected_features = parseStringifiedObjectsInArray(uniqBy(e, f => f.properties.uid).map(item => ({
    geometry: item.geometry || item._geometry,
    id: item.id,
    properties: item.properties,
    type: item.type,
  })));
});
const { loadSelectionEvents: loadTaskFormSelectionEvents } = useMapboxFeatureSelection({
  layer_ids: ['symbol-layer-icon'],
  style_selection: false,
  use_normal_selection: true,
}, (e, type) => {
  const tasksForms = uniqBy(e, f => f.properties.uid).map(item => ({
    geometry: item.geometry || item._geometry,
    id: item.id,
    properties: item.properties,
    type: item.type,
  }));

  if (tasksForms.length === 1 && type === 'click') {
    const task_form = tasksForms[0].properties;
    logger.log(tasksForms);
    if (task_form.feature_type === 'task')
      router.push({
        ...route,
        query: {
          task: btoa(JSON.stringify({
            id: task_form.feature_type_id,
            store_key: 'therm_tasks_store',
          })),
        },
      });
  }
});
watch(() => therm_store.selected_features, (val) => {
  if (val)
    styleSelection(val, {
      mapbox_instance: therm_store.map,
      colors_map_accessor: 'featureTypeId',
      colors_map: therm_store.ftg,
    });
});

const { initMapbox, loadMapBoxPackage, addMapboxToken, setSources, setLayers, removeMapboxInstance } = useMap({}, async (event_data, event_name) => {
  if (event_name === 'loaded') {
    loadTaskIcons();
    loadSelectionEvents({ mapbox_instance: therm_store.map, use_gl_draw: false });
    loadTaskFormSelectionEvents({ mapbox_instance: therm_store.map, use_gl_draw: false });
    const sources = ['all_features_source', 'symbol-source'];

    const layers = [
      {
        id: 'point_feature_layer',
        type: 'circle',
        source: 'all_features_source',
        filter: ['==', '$type', 'Point'],
      },
      {
        id: 'polygon_feature_layer',
        type: 'fill',
        source: 'all_features_source',
        paint: {
          'fill-opacity': 0,
        },
      },
      {
        id: 'linestring_feature_layer',
        type: 'line',
        source: 'all_features_source',
        paint: {
          'line-width': 2,
        },
      },
      {
        id: 'symbol-layer-icon',
        type: 'symbol',

        source: 'symbol-source',
        layout: {
          'icon-image': ['coalesce', ['get', 'icon'], 'marker'],
          'icon-size': ['coalesce', ['get', 'icon_size'], 1],
          'icon-allow-overlap': true,
        },
      },
    ];
    setSources(sources, therm_store.map);
    setLayers(layers, therm_store.map);

    if (therm_store.last_selected_project)
      await initial_project_initialization();
  }
});

const { addIconMarkerForItemCreation, removeItemCreationIcon } = useMapItemCreation({}, (e) => {
  if (task_marker_active.value)
    open_task_popup.value = true;
  task_form_centroid.value = e.lngLat;
});
async function initial_project_initialization() {
  const initial = async (project) => {
    therm_store.set_project_ortho({ project });
    await therm_store.fly_to_first_feature({ project });
    therm_store.update_map_features();
    therm_store.set_ftg_and_update_features_styles();
    addSymbols();
  };

  therm_store.active_projects?.forEach(async (project) => {
    if (
      project.uid !== therm_store.last_selected_project?.uid
            && project.is_active
    )
      await initial(project);
  });
  await initial(therm_store.last_selected_project);
}

onMounted(async () => {
  await loadMapBoxPackage();

  await addMapboxToken();
  therm_store.map = await initMapbox({
    container_id: 'therm-map',
    style: 'mapbox://styles/mapbox/streets-v11',
  });
  loadDefectStatusIcons();
});

onUnmounted(() => {
  removeMapboxInstance(therm_store.map);
});

function toggleCreation(trigger_popup = true) {
  if (trigger_popup && therm_store.selected_features.length) {
    if (therm_store.use_tasks)
      createThermTasks(therm_store.selected_features);
    else
      open_task_popup.value = true;
  }
  else {
    task_marker_active.value = !task_marker_active.value;
    if (!task_marker_active.value) {
      removeItemCreationIcon(therm_store.map);
      open_task_popup.value = false;
      return;
    }

    addIconMarkerForItemCreation({
      map_instance: therm_store.map,
      icon: 'status-1',
    });
  }
}

async function onToolActivated(tool_event_name) {
  switch (tool_event_name) {
    case 'zoom-in':
      therm_store.map.zoomIn();
      break;
    case 'zoom-out':
      therm_store.map.zoomOut();
      break;
    case 'reset-north':
      await therm_store.fly_to_project({
        project:
                therm_store.active_project
                ?? therm_store.last_selected_project,
      });
      break;
    default:
      break;
  }
}
</script>

<template>
  <div>
    <div class="flex m-0 overflow-hidden h-full">
      <div class="flex-grow relative">
        <ThermProjects class="therm-projects top-3" />
        <ThermLayers />
        <div id="therm-map" />
        <ThermTools
          :active_tools="{
            create: task_marker_active,
          }"
          @toolActivated="onToolActivated"
          @compare="$router.push({ name: 'therm-compare', params: { ...route.params } })"
          @create="toggleCreation"
        />
        <TaskForm v-if="open_task_popup" :marker_location="task_form_centroid" @refreshSymbols="addSymbols(), toggleCreation(false)" @toggleCreation="toggleCreation" />
      </div>
      <ThermRightSidebar class="flex-grow p-0" />
    </div>
  </div>
</template>
