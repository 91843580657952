<script setup>
import { useModal } from 'vue-final-modal';
import { capitalize, isNull } from 'lodash-es';
import { useRoute } from 'vue-router';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import { searchData, sortRowsByColumn } from '~/common/utils/common.utils';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import AcctSettingsAssetFieldsPopup from '~/acct-settings/components/acct-settings-asset-fields/acct-settings-asset-fields-popup.vue';
import DuplicateField from '~/account-settings/components/asset-settings/duplicate-field.vue';

const $t = inject('$t');
const $track_event = inject('$track_event');
const $toast = inject('$toast');
const auth_store = useAuthStore();
const common_store = useCommonStore();
const route = useRoute();

const state = reactive({
  search: '',
  selected: {},
  close_on_submit: false,
});

const columns = computed(() => {
  return [
    {
      header: $t('Name'),
      accessorKey: 'name',
      id: 'name',
      cell: info => info.getValue(),
      columns: [],
      sortingFn: sortRowsByColumn,
    },
    {
      header: $t('Type'),
      accessorKey: 'type',
      id: 'type',
    },
    {
      header: $t('Category'),
      accessorKey: 'category',
      id: 'category',
    },
    {
      header: $t('Aggregation'),
      accessorKey: 'properties.aggregation',
      id: 'aggregation',
    },
    {
      header: $t('Configuration'),
      accessorKey: 'config',
      id: 'config',
    },
    {
      header: $t('Description'),
      accessorKey: 'description',
      id: 'description',
    },
    {
      accessorKey: 'context_menu',
      header: '',
      id: 'context_menu',
      size: '5',
      show_on_hover: 'true',
    },
  ];
});

const filtered_data = computed(() => searchData(common_store?.assets_custom_fields, state.search, ['name', 'type']));
const hawk_menu_items = [
  {
    label: $t('Duplicate'),
    uid: 'duplicate',
  },
  {
    label: $t('Edit'),
    uid: 'edit',
  },
  {
    label: $t('Delete'),
    uid: 'delete',
  },
];

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

const {
  open: openCustomFieldPopup,
  close: closeCustomFieldPopup,
  patchOptions: patchEditOptions,
} = useModal({
  component: AcctSettingsAssetFieldsPopup,
});

const { open: openDuplicateModal, close: closeDuplicateModal, patchOptions: patchFieldsOptions } = useModal({
  component: DuplicateField,
});

async function customFieldEditHandler(field) {
  patchEditOptions(
    {
      attrs: {
        custom_field: field,
        onClose() {
          closeCustomFieldPopup();
        },
        onCancel() {
          closeCustomFieldPopup();
        },
      },
    },
  );
  openCustomFieldPopup();
}

function getConfig(data) {
  let config = {};
  if (data.field_type === 'text')
    config = { aggregation: data?.text_aggregation };

  else if (data.field_type === 'number' || data.field_type === 'planned_actual' || data.field_type === 'percentage')
    config = { aggregation: data?.aggregation };

  else if (data.field_type === 'formula')
    config = { formula: data?.formula, fields: [] };

  else if (data.field_type === 'files')
    config = { count: data?.file_count || 0 };

  else if (!isNull(data?.options_list[0]))
    config = data.options_list.map(option => ({
      name: option,
      color: '#000',
    }));
  else
    config = {};
  return config;
}

function parsePayload(payload) {
  return {
    name: payload.field_name,
    type: getType(payload?.field_type),
    properties: {
      type: (payload.field_type === 'select' ? 'dropdown' : payload.field_type),
      category_uid: payload?.category?.length ? payload?.category : null,
      ...payload.field_type === 'money'
        ? {
            currency: payload.currency_symbol,
          }
        : {},
      required: payload.required,
    },
    organization: auth_store.current_organization?.uid,
    config: getConfig(payload) || {},
    // ...(!isNull(payload.config[0])
    //   ? {
    //       config: payload.config.map(option => ({
    //         name: option.name,
    //         color: '#000',
    //       })),
    //     }
    //   : {}),
  };
}

function customFieldDeleteHandler({ name, uid }) {
  patchOptions(
    {
      attrs: {
        header: $t('Delete field'),
        content: `Are you sure you want to delete ${name || ''}? This action cannot be undone.`,
        onClose() {
          closeDeletePopup();
        },
        confirm: async () => {
          try {
            const response = await common_store.update_data({
              type: 'delete',
              id: uid,
              service: 'fields',
              append_data: false,
              state_prop: 'assets_custom_fields_map',
              update_state: true,
            });
            if (response.status === 200 || response.status === 201 || response.status === 204) {
              logger.log('response', response);
              $toast({
                title: 'Deleted Successfully',
                type: 'success',
              });
              closeDeletePopup();
            }
          }
          catch (err) {
            $toast({
              title: 'Something went wrong',
              text: 'Please try again',
              type: 'error',
            });
          }
        },
      },
    },
  );
  openDeletePopup();
}

function fieldDuplicateHandler(field) {
  patchFieldsOptions(
    {
      attrs: {
        item: field,
        onClose() {
          closeDuplicateModal();
        },
      },
    },
  );
  openDuplicateModal();
}

function onClicked(action, field) {
  switch (action) {
    case 'duplicate':
      setTimeout(() => {
        fieldDuplicateHandler(field);
      }, 100);
      break;
    case 'edit':
      state.selected = field;
      customFieldEditHandler(field);
      break;
    case 'delete':
      customFieldDeleteHandler(field);
      break;
  }
}

function getType(type) {
  if (type === 'select' || type === 'radio')
    return 'dropdown';

  else if (type === 'money')
    return 'number';

  else if (type === 'checkbox')
    return 'labels';

  else
    return type;
}

async function createNewMeta(data) {
  try {
    state.close_on_submit = true;
    const payload = {
      ...data,
      type: getType(data?.type),
      properties: {
        ...data.properties,
        type: data?.type === 'select' ? 'dropdown' : data?.type,
      },
    };
    const response = await common_store.update_data({
      type: 'add',
      data: {
        field: {
          ...payload,
          organization: auth_store.current_organization?.uid,
        },
      },
      service: 'fields',
      append_data: false,
      state_prop: 'assets_custom_fields_map',
      update_state: false,
    });
    if (response?.data?.field) {
      await common_store.update_global_data({ asset_custom_fields: true });
      $toast({
        text: 'Field created successfully',
        type: 'success',
      });
      $track_event('create_project_metadata', {
        type: data.type,
      });
    }
    else if (response?.data?.error === 'True') {
      state.close_on_submit = false;
      $toast({
        title: response?.data?.message,
        text: 'Please try again',
        type: 'error',
      });
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    }
  }
  catch (error) {
    logger.log('error', error);
    $toast({
      title: 'Something went wrong',
      type: 'error',
    });
  }
}
</script>

<template>
  <div>
    <HawkPageHeader class="!px-0">
      <template #title>
        <div>
          <div>{{ $t('Asset properties') }}</div>
          <p class="text-sm text-gray-600 font-normal">
            {{ $t('Define properties to record additional information for all assets which can be used for filtering, grouping and reporting') }}
          </p>
        </div>
      </template>
    </HawkPageHeader>
    <HawkPageSecondaryHeader>
      <template #left>
        <HawkSearchInput
          v-model="state.search"
          :placeholder="$t('Search property')"
        />
      </template>
      <template #right>
        <HawkButton @click="customFieldEditHandler()">
          <IconHawkPlus class="text-white" />
          {{ $t('New Property') }}
        </HawkButton>
      </template>
    </HawkPageSecondaryHeader>

    <div v-if="!filtered_data?.length">
      <HawkIllustrations v-if="state.search" type="no-results" for="asset-settings" />
      <HawkIllustrations v-else type="no-data" for="asset-settings" />
    </div>

    <TableWrapperVue v-else-if="filtered_data?.length" container_class="my-4">
      <HawkTable
        :key="columns.length"
        :pagination_config="{ totalRows: filtered_data?.length, pageSize: 25 }"
        :data="filtered_data"
        :columns="columns"
        :is_loading="false"
        is_gapless :non_sortable_columns="['context_menu']"
        @rowClicked="onClicked('edit', $event)"
      >
        <template #aggregationHeader>
          <div class="flex items-center gap-2">
            <div>{{ $t('Aggregation') }}</div>
            <span v-tippy="`${$t('The aggregated values are displayed in the footer when the assets are grouped in the list view')}`">
              <IconHawkInfoCircle class="h-4 w-4" />
            </span>
          </div>
        </template>
        <template #name="name">
          <span class="font-medium text-gray-900">{{ name.data.getValue() }}</span>
        </template>
        <template #description="description">
          {{ description?.data.row.original?.properties?.description || '-' }}
        </template>
        <template #type="type">
          <!-- for money field -->
          <!-- for planned_actual -->
          <span>{{ capitalize(type.data.row.original?.properties?.type || type.data.row.original?.type).split('_').join(' ') }}
            <span v-if="type?.data.row.original.properties?.currency">({{ type.data.row.original.properties?.currency }})</span>
          </span>
        </template>
        <template #category="category">
          <HawkCategoryName :uid="category.data.row.original?.properties?.category_uid" />
        </template>
        <template #aggregation="{ data }">
          <div class="uppercase">
            {{ data.getValue() || '-' }}
          </div>
        </template>
        <template #config="config">
          {{
            config.data.getValue().length
              ? config.data.getValue().map(item => item.name).join(", ")
              : "-"
          }}
        </template>

        <template #context_menu="cf">
          <HawkMenu
            v-if="auth_store.check_permission('modify_organization', $route.params.asset_id)"
            :items="hawk_menu_items"
            position="fixed"
            class="z-[999]" @click.stop
          >
            <template #trigger>
              <IconHawkDotsVertical />
            </template>
            <template #content>
              <div class="p-1 w-40 flex flex-col">
                <HawkButton
                  v-for="item in hawk_menu_items"
                  :key="item.label"
                  type="text"
                  @click="onClicked(item.uid, cf.data.row.original)"
                >
                  {{ item.label }}
                </HawkButton>
              </div>
            </template>
          </HawkMenu>
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
</template>
