<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { cloneDeep, isEqual, orderBy } from 'lodash-es';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import AssetResources from '~/account-settings/components/shared/asset-resources.vue';

const route = useRoute();
const router = useRouter();
const $t = inject('$t');
const $track_event = inject('$track_event');
const $toast = inject('$toast');
const $services = inject('$services');
const common_store = useCommonStore();
const auth_store = useAuthStore();

const form = ref({});
const form$ = ref(null);
const loading = ref(false);
const user_details = ref({});
const permissions = ref({});

const payload = ref({});

const breadcrumb_items = computed(() => {
  return [
    { uid: '1', label: $t('Account Settings'), disabled: true },
    ...(
      route.name === 'account-settings-invites-details' || route.name === 'asset-settings-invites-details'
        ? [{
            uid: '2',
            label: $t('Invites'),
            to: { name: route.params.asset_id ? 'asset-settings-users-invitation' : 'account-settings-users-invitation' },
          }]
        : [{
            uid: '2',
            label: $t('Users'),
            to: { name: route.params.asset_id ? 'asset-settings-users' : 'account-settings-users' },
          }]),
    {
      uid: '3',
      label: user_details.value?.first_name
        ? `${user_details.value?.first_name} ${user_details.value?.last_name || ''}`
        : user_details.value?.email,
    },
  ];
});

const user_status = computed(() => {
  if (user_details.value?.status === 'invited')
    return $t('Invited');
  else
    return user_details.value?.active ? $t('Active') : $t('Deactivated');
});

function onTreeUpdate(e) {
  user_details.value.assets = [...e.assets];
  user_details.value.resources = [...e.resources];
  user_details.value = { ...user_details.value };
}

const has_permission = computed(() => {
  if (['account-settings-invites-details', 'account-settings-users-details'].includes(route.name))
    return auth_store.check_permission('invite_users', route.params.asset_id);
  return true;
});

async function getData() {
  if (has_permission.value)
    try {
      loading.value = true;
      const { data } = await $services.users.get({ id: route?.params?.user_id });
      user_details.value = data.user;

      loading.value = false;
    }
    catch (error) {
      loading.value = false;
      logger.log(error);
    }
}

function formatTeams(uids) {
  const teams = [
    ...common_store.scope_teams(route.params?.asset_id).map((team) => {
      return {
        name: team.name,
        uid: team.uid,
        default: team.default,
      };
    }),
  ];
  const data = teams.filter(team => !team.default && uids.includes(team.uid));
  data.push(...common_store.scope_teams(route.params?.asset_id).filter(team => team.default).map((team) => {
    return {
      name: team.name,
      uid: team.uid,
      default: team.default,
    };
  }));
  return orderBy(data, 'default', 'desc');
}

function formatRoles(uids) {
  const roles = [
    ...common_store.scope_roles(route.params?.asset_id).map((role) => {
      return {
        name: role.name,
        uid: role.uid,
      };
    }),
  ];
  return roles.filter(role => uids.includes(role.uid));
}

async function save() {
  try {
    payload.value = {
      ...user_details.value,
      ...form.value,
    };

    if (!route?.params?.asset_id)
      payload.value.assets = user_details.value?.resources.filter((resource) => {
        return user_details.value?.assets?.includes(resource?.asset);
      }).map(item => item.asset);

    payload.value.teams = form.value?.teams.map(
      team => team.uid,
    );

    payload.value.roles = form.value?.roles.map(
      role => role.uid,
    );
    payload.value.permissions = permissions.value;

    const response = await $services.users.patch({
      id: payload.value.uid,
      body: {
        user: payload.value,
      },
      ...(route?.params?.asset_id
        ? {
            query: {
              asset: route.params.asset_id,
            },
          }
        : {}),
    });

    if (response.data?.user) {
      $toast({
        text: 'User updated successfully',
        type: 'success',
      });
      if (!isEqual(user_details.value.permissions, payload.value.permissions))
        $track_event('permissions_changed', {
          type: 'Permissions were changed',
          where: 'User details',
        });
      if (payload.value.roles?.length && !isEqual(user_details.value.roles, payload.value.roles))
        $track_event('permissions_changed', {
          type: 'Role was assigned',
          where: 'User details',
        });

      await common_store.update_global_data({ users: true });
      goBack();
    }
    else {
      $toast({
        text: 'Something went wrong',
        type: 'error',
      });
    }
  }
  catch (error) {
    logger.log('error', error);
  }
}

function goBack() {
  if (route.name === 'account-settings-invites-details' || route.name === 'asset-settings-invites-details')
    router.push({ name: route?.params?.asset_id ? 'asset-settings-users-invitation' : 'account-settings-users-invitation' });

  else
    router.push({ name: route?.params?.asset_id ? 'asset-settings-users' : 'account-settings-users' });
}

onMounted(async () => {
  if (has_permission.value) {
    common_store.set_organization_list();
    await getData();
    form$.value.update({
      access_all_assets: user_details.value?.access_all_assets,
      assets: user_details.value?.assets,
      teams: formatTeams(user_details.value?.teams),
      roles: formatRoles(user_details.value?.roles),
    });
    permissions.value = user_details.value?.permissions
      ? cloneDeep(user_details.value.permissions)
      : {};
  }
});
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="pt-6 pb-5 border-b">
      <template #left>
        <div class="flex items-center">
          <HawkBreadcrumbs :items="breadcrumb_items" />
        </div>
      </template>
    </HawkPageSecondaryHeader>
    <div v-if="loading">
      <HawkLoader />
    </div>
    <HawkIllustrations v-else-if="!has_permission" type="no-permission" />
    <div v-else>
      <div class="my-5">
        <div class="text-lg font-semibold text-gray-900">
          {{ $t('User Info') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ $t('User Basic Details') }}
        </div>
      </div>

      <Vueform
        ref="form$"
        v-model="form"
        size="sm"
        :display-errors="false"
        :columns="{ container: 12, label: 6, wrapper: 12 }"
        :endpoint="save"
      >
        <div class="col-span-12">
          <div class="max-w-[700px]">
            <div class="text-sm gap-4 grid mb-4">
              <div class="flex gap-10 mb-1">
                <div class="w-1/2 font-medium text-gray-700">
                  {{ $t("Profile Picture") }}
                </div>
                <div class="w-1/2">
                  <HawkMembers :members="user_details.uid" size="xl" />
                </div>
              </div>
              <div class="flex gap-2">
                <div class="w-1/2 font-medium text-gray-700">
                  {{ $t("Email") }}
                </div>
                <div class="w-1/2">
                  {{ user_details.email || "Not found" }}
                </div>
              </div>
              <div class="flex gap-2">
                <div class="w-1/2 font-medium text-gray-700">
                  {{ $t("Phone number") }}
                </div>
                <div class="w-1/2">
                  {{ user_details.phone_number || "NA" }}
                </div>
              </div>
              <div class="flex gap-2">
                <div class="w-1/2 font-medium text-gray-700">
                  {{ $t("Type") }}
                </div>
                <div class="w-1/2 capitalize">
                  {{ user_details?.user_type || "" }}
                </div>
              </div>
              <div class="flex gap-2">
                <div class="w-1/2 font-medium text-gray-700">
                  {{ $t("Subscription") }}
                </div>
                <div class="w-1/2">
                  {{ user_details.paid ? "Paid" : "Free" }}
                </div>
              </div>
              <div class="flex gap-2">
                <div class="w-1/2 font-medium text-gray-700">
                  {{ $t("Last Active on") }}
                </div>
                <div class="w-1/2">
                  {{ user_details.updated_at || "Not found" }}
                </div>
              </div>
              <div class="flex gap-2">
                <div class="w-1/2 font-medium text-gray-700">
                  {{ $t("Organization") }}
                </div>
                <div class="w-1/2">
                  {{
                    common_store.get_organization(user_details.organization)?.name || "NA"
                  }}
                </div>
              </div>
              <div class="flex gap-2">
                <div class="w-1/2 font-medium text-gray-700">
                  {{ $t("Status") }}
                </div>
                <div class="w-1/2 capitalize">
                  <HawkBadge :color="user_details.active ? 'green' : 'gray'">
                    {{ user_status }}
                  </HawkBadge>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <hawk-teams-input
                class="w-full mr-5"
                :multi="true"
                :options="{
                  name: 'teams',
                  placeholder: $t('Select teams'),
                }"
              >
                <template #label>
                  <div class="flex items-center">
                    <span class="mr-2">{{ $t("Teams") }}</span>
                    <span v-tippy="$t('Select teams to add this member')">
                      <IconHawkInfoCircle class="h-4 w-4" />
                    </span>
                  </div>
                </template>
              </hawk-teams-input>
            </div>
            <div class="mb-4">
              <hawk-roles-input
                class="w-full mr-5"
                :multi="true"
                :options="{
                  name: 'roles',
                  placeholder: $t('Select roles'),
                }"
              >
                <template #label>
                  <div class="flex items-center">
                    <span class="mr-2">{{ $t("Roles") }}</span>
                    <span
                      v-tippy="
                        `${$t(
                          'Select roles to add this member',
                        )}`
                      "
                    >
                      <IconHawkInfoCircle class="h-4 w-4" />
                    </span>
                  </div>
                </template>
              </hawk-roles-input>
            </div>
            <AssetResources :item_details="user_details" class="mb-4" @update="onTreeUpdate($event)" />
          </div>
          <hr class="my-5">
          <div>
            <div class="my-5">
              <div class="text-lg font-semibold text-gray-900">
                {{ $t('Permissions') }}
              </div>
            </div>
            <div class="max-w-[435px]">
              <Permissions
                :model_value="permissions"
                :is_admin="user_details.is_manager"
                @update:model_value="e => permissions = e"
              />
            </div>
          </div>
          <div class="sticky bg-white bottom-0">
            <hr class="my-5">
            <div class="flex justify-end gap-3 pb-5">
              <HawkButton
                type="outlined"
                @click="goBack()"
              >
                {{ $t("Cancel") }}
              </HawkButton>
              <ButtonElement
                button-class="w-full bg-blue-600"
                name="submit"
                :submits="true"
              >
                {{ route.params.id ? $t("Update") : $t("Save") }}
              </ButtonElement>
            </div>
          </div>
        </div>
      </Vueform>
    </div>
  </div>
</template>
