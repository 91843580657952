<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { isEqual } from 'lodash-es';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import DashboardFormsFilter from '~/dashboard/components/filters/dashboard-forms-filters.vue';
import DashboardSingleFormFields from '~/dashboard/components/form-detail-fields/dashboard-single-form-fields.vue';
import { useFamCustomView } from '~/forms-as-module/composables/fam-custom-view.composable.js';
import FamTabsList from '~/forms-as-module/components/fam-tabs-list/fam-tabs-list.vue';

const form_template_detail_store = useFormTemplateDetailStore();

const dashboard_forms_store = useDashboardFormsStore();
const dashboard_store = useDashboardStore();
const route = useRoute();

const {
  forms_schema,
  active_form_id,
  active_template_id,
  active_template_form_id,
  forms_widget_type,
  forms_configuration,
  forms_options,
  template_options,
  template_forms_options,
  field_options,
  value_options,
  breakdown_options,
  timerange_interval_options,
  axis_widget_types,
  datetime_widget_types,
  draft_form_uid,
  disable_fields_exception,
  activity_history_filter_options,
} = storeToRefs(dashboard_forms_store);

const {
  update_forms_configuration,
  set_forms,
  set_form_fields,
  set_data,
  set_form_dashboard_uid,
  set_draft_form_uid,
  set_templates,
  set_template_forms,
  set_form_dashboard_template,
} = dashboard_forms_store;

const {
  widget_asset,
} = storeToRefs(dashboard_store);

const {
  set_widget_configuration,
  set_form_valid,
} = dashboard_store;
const forms_configuration_tabs = computed(() => forms_configuration.value?.tabs || []);
const { handleConfigureTabs, getConfigureTabsProperties, setCustomViews } = useFamCustomView({
  feature: 'widget_view',
  fam_view_store_id: `dashboard_widget_view_${dashboard_store.widget_uid || 'preview'}`,
});

const timeseries_type = computed(() => forms_configuration.value?.timerange_type || null);
const summary_type = computed(() => forms_configuration.value?.summary || null);
const include_filters = computed(() => forms_configuration.value?.include_filters || false);
const form$ = ref(null);

async function validateForm() {
  // sometimes changes take longer
  await new Promise(resolve => setTimeout(resolve, 100));
  form$.value.validate();

  const is_valid = !form$.value.hasErrors;
  set_form_valid(is_valid);
  if (is_valid)
    set_widget_configuration({
      ...forms_configuration.value,
    });
}

function updateFormsConfiguration(data, key = null) {
  if (!key) {
    update_forms_configuration({
      ...forms_configuration.value,
      ...data,
    });
  }
  else {
    const copy = {
      ...forms_configuration.value,
    };

    copy[key] = data;
    update_forms_configuration(copy);
  }

  validateForm();
}

function updateFields() {
  // reset field values to match the data from the active form
  if (axis_widget_types.value.includes(forms_widget_type.value)) {
    if (form$.value.el$('field'))
      form$.value.el$('field').update(field_options.value[0]);
    if (form$.value.el$('value')) {
      form$.value.el$('value').update(value_options.value[0]);
      form$.value.el$('value_select')?.update(value_options.value[0]);
    }
    if (form$.value.el$('summary'))
      form$.value.el$('summary').update('count');
    if (form$.value.el$('breakdown'))
      form$.value.el$('breakdown').update(breakdown_options.value[0]);
  }
  else if (forms_widget_type.value === 'form_responses' && form$.value.el$('columns')) {
    form$.value.el$('columns').update([]);
  }
  else if (forms_widget_type.value === 'single_form_view' && form$.value.el$('template_form')) {
    form$.value.el$('template_form').update(template_forms_options.value?.[0]?.value || null);
  }

  updateFormsConfiguration(null, 'filters');
}

function toggleFields(action = 'enable') {
  const keys = Object.keys(form$.value?.schema || {});

  keys.forEach((key) => {
    // disable all the fields, except the ones in the exception list
    if (action === 'disable') {
      if (
        !disable_fields_exception.value.includes(key)
        && typeof form$.value.el$(key)?.disable === 'function'
      )
        form$.value.el$(key).disable();
      else if (typeof form$.value.el$(key)?.enable === 'function')
        form$.value.el$(key).enable();
    }

    // if action is enable, enable all fields
    else if (action === 'enable') {
      if (
        typeof form$.value.el$(key)?.enable === 'function'
      )
        // in template form dashboard, template should stay disabled
        if (
          route.name !== 'form-template-reports'
          || (route.name === 'form-template-reports' && key !== 'template')
        )
          form$.value.el$(key).enable();
    }
  });
}

function getFilters(id) {
  if (id && !['forms_list', 'forms_list_with_tabs', 'single_form_view', 'activity_history', null].includes(forms_widget_type.value))
    form_template_detail_store.get_field_filter_list({ id });
}

watch(widget_asset, (new_val, old_val) => {
  if (!isEqual(new_val, old_val)) {
    resetConfigOnAssetChange();
    updateFormsConfiguration(widget_asset.value, 'asset_id');
  };
});

async function resetConfigOnAssetChange() {
  if (form$.value.el$('template')) {
    form$.value.el$('template')?.update(null);
    await set_templates();
    form$.value.el$('templates')?.update(template_options.value?.[0]?.value || null);
  }
  if (form$.value.el$('form')) {
    form$.value.el$('form')?.update(null);
    await set_forms();
    form$.value.el$('form')?.update(forms_options.value?.[0]?.value || null);
  }
  if (form$.value.el$('template_form')) {
    form$.value.el$('template_form')?.update(null);
    await set_template_forms();
    form$.value.el$('template_form')?.update(template_forms_options.value?.[0]?.value || null);
  }
};

watch(active_form_id, async (new_val, old_val) => {
  if ((new_val !== old_val) && (new_val !== draft_form_uid.value)) {
    set_draft_form_uid(null);
    getFilters(active_form_id.value);
  }
  // this function either sets the fields and filters for the active form, or gets the templates for single form view
  await set_form_fields();
  // only watch to update fields if the form changes, not on initial value
  if (typeof old_val === 'string')
    updateFields();
});

watch(active_template_id, async (new_val, old_val) => {
  if ((new_val !== old_val) && (new_val !== draft_form_uid.value)) {
    set_draft_form_uid(null);
    getFilters(active_template_id.value);
    if (form$.value.el$('template_form'))
      form$.value.el$('template_form').update(null);
  }

  // prevent fetching template forms children, if parent template is unpublished
  if (!draft_form_uid.value)
    await set_template_forms();
  if (old_val && old_val !== new_val)
    updateFields();
});

watch(active_template_form_id, (new_val, old_val) => {
  if (new_val !== draft_form_uid.value)
    set_draft_form_uid(null);
});

watch(forms_widget_type, async (new_val, old_val) => {
  await set_form_fields();
  const id = new_val === 'features_map' ? active_template_id.value : active_form_id.value;
  getFilters(id);
  if (new_val === 'forms_list' && draft_form_uid.value) {
    set_draft_form_uid(null);
    toggleFields('enable');
  }

  // date time fields have fewer options for configuration, we must reset them
  if (
    datetime_widget_types.value.includes(new_val)
    && !datetime_widget_types.value.includes(old_val)
    && old_val
  )
    updateFields();
});

watch(summary_type, (new_val, old_val) => {
  // if id doesn't exist, it's when setting initial data
  if (!old_val)
    return;

  // match value with summary
  if (new_val !== 'none' && form$.value.el$('value')) {
    form$.value.el$('value').update(value_options.value[0]);
    form$.value.el$('value_select')?.update(value_options.value[0]);
  }
});

watch(include_filters, (new_val, old_val) => {
  // if id doesn't exist, it's when setting initial data
  if (!old_val)
    return;

  // match value with summary
  if (new_val === false)
    form$.value.el$('activity_history_filters').update('all');
});

// reset interval to match type
watch(timeseries_type, () => {
  if (form$.value?.el$('timerange_interval'))
    form$.value.el$('timerange_interval').update(timerange_interval_options.value[0].value);
});

// set initial data
watch(forms_options, () => {
  if (forms_options.value?.length && !active_form_id.value)
    form$.value.el$('form').update(forms_options.value[0]?.value);

  // if it is a draft form, disable the fields, except the form one
  else if (forms_options.value?.length && draft_form_uid.value)
    toggleFields('disable');
});

watch(template_options, () => {
  if (template_options.value?.length && !forms_configuration.value?.template)
    form$.value.el$('template')?.update(template_options.value[0]?.value);

  else if (template_options.value?.length && draft_form_uid.value)
    toggleFields('disable');
});

watch(template_forms_options, () => {
  if (template_forms_options.value?.length && !forms_configuration.value?.template_form)
    form$.value.el$('template_form').update(template_forms_options.value[0]?.value);

  // if it is a draft form, disable the fields, except the form one
  else if (template_forms_options.value?.length && draft_form_uid.value)
    toggleFields('disable');
});

watch(field_options, () => {
  if (field_options.value?.length && !forms_configuration.value?.field)
    form$.value.el$('field').update(field_options.value[0]);
});

watch(value_options, () => {
  if (value_options.value?.length && !forms_configuration.value?.value) {
    form$.value.el$('value').update(value_options.value[0]);
    form$.value.el$('value_select')?.update(value_options.value[0]);
  }
});

watch(breakdown_options, () => {
  if (breakdown_options.value?.length && !forms_configuration.value?.breakdown)
    form$.value.el$('breakdown').update(breakdown_options.value[0]);
});

watch(draft_form_uid, (new_val, old_val) => {
  // iff the draft form uid is cleared, the inputs are enabled
  if (old_val && !new_val)
    toggleFields('enable');
});

onMounted(() => {
  if (route.name === 'form-template-reports' || route.name === 'fam-overview') {
    set_form_dashboard_uid(route.params.template_uid);
    set_form_dashboard_template();
  }
  else { set_forms(); }
  set_data();
  validateForm();
  setCustomViews(forms_configuration_tabs.value);
});

onBeforeUnmount(() => {
  dashboard_forms_store.$reset();
  set_widget_configuration(null);
  set_form_valid(false);
});
</script>

<template>
  <Vueform
    ref="form$"
    size="sm"
    :schema="forms_schema"
    :presets="['supress_errors']"
    :columns="{
      default: {
        container: 12,
        label: 4,
        wrapper: 12,
      },
      sm: {
        label: 4,
      },
      md: {
        label: 4,
      },
      lg: {
        label: 4,
      },
    }"
    :should_validate_on_mount="false"
    :display-errors="false"
    class="mb-6"
    @change="updateFormsConfiguration($event)"
  />
  <div v-if="forms_widget_type === 'forms_list_with_tabs' ">
    <!-- <HawkButton type="outlined" @click="handleConfigureTabs">
      {{ $t('Configure tabs') }}
    </HawkButton> -->
    <div class="text-sm mb-3">
      {{ $t('Configure tabs') }}
    </div>
    <FamTabsList
      :is_popup="false"
      v-bind="getConfigureTabsProperties(true)"
    />
  </div>
  <DashboardSingleFormFields
    v-if="forms_widget_type === 'single_form_view'"
    @update="updateFormsConfiguration($event, 'single_form_visibility')"
  />
  <DashboardFormsFilter
    @update="updateFormsConfiguration($event, 'filters')"
  />
</template>
