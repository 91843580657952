<script setup>
// --------------------------------- Imports -------------------------------- //
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  data: {
    type: Array,
    default: () => ([]),
  },
  group_by_fn: {
    type: Function,
    default: () => {},
  },
  weekly_progress: {
    type: Object,
    default: () => ({}),
  },
  formatted_week_dates: {
    type: Array,
    default: () => ([]),
  },
  weekly_progress_tooltip: {
    type: String,
    default: '',
  },
});

// ---------------------------------- Emits --------------------------------- //
const emits = defineEmits(['previous_week', 'today', 'next_week', 'date_select']);
dayjs.extend(isSameOrBefore);

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const current_date = dayjs().startOf('date');

// ------------------------ Variables - Local - refs ------------------------ //
const selected_date = ref(current_date);

// ---------------------- Variables - Local - reactives --------------------- //

// --------------------------- Computed properties -------------------------- //
const disable_previous_week_toggle = computed(() => props.formatted_week_dates[0].date.isSameOrBefore(current_date));

// -------------------------------- Functions ------------------------------- //

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
</script>

<template>
  <!-- Calendar header -->
  <HawkStickyHeader>
    <div class="flex justify-evenly gap-6 pt-2 pb-3 border-b border-gray-300">
      <!-- Weekly Progress -->
      <div class="flex flex-col justify-between w-1/5 text-center">
        <div class="flex gap-2 text-sm font-medium text-gray-600 mb-2">
          {{ $t('Weekly Progress') }}
          <div v-if="weekly_progress_tooltip" v-tippy="weekly_progress_tooltip">
            <icon-hawk-info-circle class="ml-1 relative top-0.5 w-4 h-4 text-gray-600" />
          </div>
        </div>
        <div v-tippy="weekly_progress.completed === 0 ? `${weekly_progress.completed}/${weekly_progress.total} completed` : ''">
          <HawkProgressBar :progress="(weekly_progress.completed / weekly_progress.total) * 100" :tooltip_content="() => `${weekly_progress.completed}/${weekly_progress.total} completed`" />
        </div>
      </div>
      <!-- Week day navigation with progress -->
      <div class="flex gap-2 justify-between w-full">
        <div v-for="date_obj in formatted_week_dates" :key="date_obj.date" class="flex flex-col justify-between w-1/12 text-center">
          <div
            class="text-sm font-medium mb-2 flex gap-2 justify-center items-center cursor-pointer"
            :class="{ 'text-primary-600': selected_date.isSame(date_obj.date), 'text-gray-400 !cursor-default': date_obj.is_before }"
            @click="() => { if (!date_obj.is_before) { selected_date = date_obj.date; emits('date_select', selected_date) } }"
          >
            <span>{{ date_obj.date.format('ddd') }}</span>
            <span :class="{ 'rounded-full bg-primary-600 h-6 w-6 flex items-center justify-center text-white': selected_date.isSame(date_obj.date) }">{{ date_obj.date.format('D') }}</span>
          </div>
          <div v-tippy="!date_obj.is_before && date_obj.progress.completed === 0 ? `${date_obj.progress.completed}/${date_obj.progress.total} completed` : ''">
            <HawkProgressBar :is_disabled="date_obj.is_before" :progress="(date_obj.progress.completed / date_obj.progress.total) * 100" :tooltip_content="() => `${date_obj.progress.completed}/${date_obj.progress.total} completed`" />
          </div>
        </div>
      </div>
      <div class="flex w-1/5 justify-between items-center">
        <HawkButton v-tippy="`${$t('Previous week')}`" icon type="text" :disabled="disable_previous_week_toggle">
          <icon-hawk-chevron-left class="w-5 h-5" @click="!disable_previous_week_toggle && emits('previous_week')" />
        </HawkButton>
        <HawkButton v-tippy="`${current_date.format('DD MMMM YYYY')}`" class="flex items-center" type="outlined" @click="() => { selected_date = current_date;emits('today') }">
          {{ $t('Today') }}
        </HawkButton>
        <HawkButton v-tippy="`${$t('Next week')}`" icon type="text">
          <icon-hawk-chevron-right class="w-5 h-5" @click="emits('next_week')" />
        </HawkButton>
      </div>
    </div>
  </HawkStickyHeader>
  <!-- Calendar body -->
  <div class="flex justify-evenly gap-6">
    <div class="w-1/5">
      <slot name="left" />
    </div>
    <div class="w-full">
      <slot name="default" />
    </div>
    <div class="w-1/5">
      <slot name="right" />
    </div>
  </div>
</template>
