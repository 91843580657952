<script setup>
import { isEmpty } from 'lodash-es';

const props = defineProps({
  transmittal: {
    type: Object,
    default: null,
  },
  is_detail_page: {
    default: false,
    type: Boolean,
  },
  read_only: {
    type: Boolean,
    default: false,
  },
});

const transmittal_messages = computed(() => {
  if (isEmpty(props.transmittal))
    return [];
  const { messages, members, owner, message } = props.transmittal;
  if (messages?.length) {
    const message_list = messages.map((item, index) => {
      const cc = (item?.cc || []).map(uid => ({ uid, role: 'information' }));
      const assignees = (item?.to || []).map(uid => ({ uid, role: 'submitter' }));
      return {
        ...item,
        message: item.message,
        members: [...assignees, ...cc],
      };
    });
    return message_list;
  }
  else {
    return [{
      message: message?.body,
      to: members?.filter(member => member.role !== 'information')?.map(member => member.uid),
      cc: members?.filter(member => member.role === 'information').map(member => member.uid),
      members,
      from: owner,
      attachments: message?.attachments,
    }];
  }
});
</script>

<template>
  <div v-if="transmittal" class="col-span-12 grid gap-4">
    <div class="col-span-12 grid gap-4" :class="{ 'pl-8': is_detail_page }">
      <transmittal-detail-header
        class="col-span-12"
        :transmittal="transmittal"
        :is_detail_page="is_detail_page"
        :read_only="read_only"
      />
      <transmittal-detail-subheader class="col-span-12" :transmittal="transmittal" />
    </div>

    <transmittal-detail-messages
      v-if="transmittal_messages?.length"
      :transmittal_messages="transmittal_messages"
    />
  </div>
</template>
