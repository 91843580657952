import { defineStore } from 'pinia';
import { groupBy, isEmpty, isObject, keyBy } from 'lodash-es';
import { useFormTemplatesStore } from './form-templates.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

export function getFields(data) {
  const fields = [];

  data.forEach((item1) => {
    if (item1.is_static)
      fields.push({ ...item1 });
    if (item1.children?.length)
      item1.children.forEach((item2) => {
        if (item2.is_field)
          fields.push({
            ...item2,
            section_name: item1.name,
            section_id: item1.uid,
          });
        if (item2.children)
          item2.children.forEach((item3) => {
            if (item3.is_field)
              fields.push({
                ...item3,
                form_block_name: item1.name,
                form_block_id: item1.name,
                section_name: item2.name,
                section_id: item2.uid,
              });
          });
      });
  });
  return fields;
}

const store = {
  state: () => ({
    form_template_detail: {},
    step_number: -1,
    previous_step_number: -1,
    responses_count: 0,
    field_filters_tree: [],
    is_list_collapsed: false,
  }),
  getters: {
    field_filters_map(state) {
      return keyBy(getFields(state.field_filters_tree), 'uid');
    },
    sections: (state) => {
      return state.form_template_detail.sections;
    },
    is_template_flow: (state) => {
      return !!state.form_template_detail?.can_view_forms;
    },
    steps_with_sections: (state) => {
      if (state?.form_template_detail?.isChild && !state?.form_template_detail?.workflow)
        return {};
      const steps_with_sections = { ...(state.form_template_detail?.steps || {}) };
      const form_template_detail = state.form_template_detail;

      const sections_hash = groupBy(
        form_template_detail?.sections,
        item => item.step_index ?? '1',
      );

      Object.keys(state.form_template_detail?.steps || {}).forEach((item) => {
        steps_with_sections[item].sections = sections_hash[item] ?? [];
        steps_with_sections[item].collapse = false;
      });
      return steps_with_sections;
    },
    template_fields(state) {
      if (!state.form_template_detail)
        return [];
      const fields = [];
      state.form_template_detail.sections.forEach((section) => {
        section.fields.forEach((field) => {
          fields.push({
            ...field,
            // Used for rule fields hierarchy
            section_name: section.name,
            section_id: section.uid,
            ...(state?.form_template_detail?.workflow
              ? {
                  form_block_name: this.steps_with_sections[section.step_index].name,
                  form_block_id: `block_${section.step_index}`,
                }
              : {}),
          });
        });
      });
      return fields;
    },
    template_fields_tree(state) {
      return (field_filter_callback = item => true, section_filter_callback = item => true, step_filter_callback = item => true) => {
        if (!isEmpty(state.form_template_detail?.steps))
          return Object.values(state.form_template_detail?.steps).filter(step_filter_callback)
            .reduce((steps, step) => {
              const sections = (state.form_template_detail?.sections?.filter(section => String(section.step_index) === String(step.index) && section_filter_callback(section)) || [])
                .reduce((sections, section) => {
                  const fields = section.fields.filter(field_filter_callback);

                  if (fields.length)
                    sections.push({
                      ...section,
                      nodeKey: section.uid,
                      hasChildren: true,
                      children: fields.map(field => ({
                        ...field,
                        nodeKey: field.uid,
                        hasChildren: false,
                      })),
                    });

                  return sections;
                }, []);
              if (sections.length)
                steps.push({
                  ...step,
                  uid: step.index,
                  nodeKey: step.index,
                  hasChildren: true,
                  children: sections,
                });

              return steps;
            }, []);
        else
          return state.form_template_detail.sections
            .filter(section_filter_callback)
            .reduce((sections, section) => {
              const fields = section.fields.filter(field_filter_callback);

              if (fields.length)
                sections.push({
                  ...section,
                  children: fields.map(field => ({
                    ...field,
                    nodeKey: field.uid,
                    hasChildren: false,
                  })),
                });

              return sections;
            }, []).map(section => ({
              ...section,
              nodeKey: section.uid,
              hasChildren: true,
            }));
      };
    },
  },
  actions: {
    async set_form_template(req) {
      try {
        const { data } = await this.$services.forms.get({ ...req, id: `templates/${req.id}` });
        this.form_template_detail = data?.template || {};
        this.form_template_detail.filters.data_filters = isObject(this.form_template_detail.filters.data_filters) ? [] : this.form_template_detail.filters.data_filters;
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    // TODO update the name to update_form_template
    async update_form_details(req) {
      const form_template = Object.assign(this.form_template_detail, {});
      this.form_template_detail = { ...this.form_template_detail, ...req.body };
      try {
        const { data } = await this.$services.forms.patch({
          attribute: `templates/${this.form_template_detail.uid}`,
          query: {
            ...req?.query || {},
          },
          body: {
            form: {
              ...req.body,
            },
          },
        });
        if (!req.suppressToast)
          this.$toast({ text: 'Template Updated Successfully!', type: 'success' });
        this.form_template_detail = {
          ...this.form_template_detail, ...(data?.template || {}),
        };
      }
      catch (err) {
        this.form_template_detail = { ...form_template };
        this.$toast({ text: err?.data?.message || 'Template Updating failed!', type: 'error' });
        throw err;
      }
    },
    async update_section(uid, data, index) {
      if (data.description)
        data.description = data.description.trim();
      const response = await this.$services.forms.patch({
        attribute: `sections/${uid}`,
        body: { section: data },
      });
      this.form_template_detail.sections[index] = { ...this.form_template_detail.sections[index], ...response.data.section };
    },
    async update_field(uid, { data, section_index, field_index }) {
      const response = await this.$services.forms.patch({
        attribute: `fields/${uid}`,
        body: { field: data },
      });
      this.form_template_detail.sections[section_index].fields[field_index] = response.data.field;
      return response.data.field;
    },
    async delete_field(uid, { section_index, field_index }) {
      try {
        await this.$services.forms.delete({
          attribute: `fields/${uid}`,
        });
        this.form_template_detail.sections[section_index].fields.splice(
          field_index,
          1,
        );
      }
      catch (error) {
        this.$toast({ text: 'Field deletion failed!', type: 'error' });
      }
    },
    async set_up_summary(section_id, payload) {
      try {
        await this.$services.forms.patch({
          attribute: `sections/${section_id}`,
          body: payload,
        });
      }
      catch (error) {
        this.$toast({ text: 'Setting up Summary Failed!', type: 'error' });
      }
    },
    async delete_section(uid, { section_index }) {
      try {
        await this.$services.forms.delete({
          attribute: `sections/${uid}`,
        });
        this.form_template_detail.sections.splice(
          section_index,
          1,
        );
      }
      catch (error) {
        this.$toast({ text: 'Field deletion failed!', type: 'error' });
      }
    },
    async reset_form_template(section_uid) {
      this.form_template_detail.sections = this.form_template_detail.sections.filter(section => section.uid).sort((form_1, form_2) => {
        return form_1.order_index - form_2.order_index;
      });
      this.form_template_detail.sections.forEach((section) => {
        if (section_uid === section.uid)
          section.fields = section.fields?.filter(field => field.uid).sort((form_1, form_2) => {
            return form_1.order_index - form_2.order_index;
          }) || [];
      });
      this.form_template_detail = { ...this.form_template_detail };
    },
    async delete_form_template(uid) {
      let is_template_deleted = false;
      try {
        await this.$services.forms.delete({
          attribute: `templates/${uid}`,
        });
        delete useFormTemplatesStore().form_templates_map[uid];
        this.$router.push({ name: 'form-templates' });
        this.$toast({ text: 'Form template deleted successfully', type: 'success' });
        is_template_deleted = true;
      }
      catch (error) {
        logger.error(error);
        this.$toast({ text: 'Template Deletion failed!', type: 'error' });
        return error;
      }
      // Refetching the quick access
      if (is_template_deleted) {
        const common_store = useCommonStore();
        common_store.set_form_quick_access();
      }
    },
    async duplicate_form_template(req = {}) {
      const { auth_store } = useCommonImports();

      try {
        const { data } = await this.$services.forms.post({
          ...(req || {}),
          attribute: `templates/${req.uid}/duplicate`,
        });
        this.$router.push({ name: auth_store.check_split('forms_v2') ? 'fam' : 'form-template-overview', params: { template_uid: data.template.uid } });
        if (data.template)
          this.form_template_detail = data.template;
        this.$toast({ title: 'Template duplicated', text: 'Your template has been duplicated successfully.', type: 'success' });
      }
      catch (error) {
        logger.log(error);
        this.$toast({ title: 'Failed to duplicate template', text: 'Unable to duplicate template. Please try again.', type: 'error' });
        return error;
      }
    },
    form_template_track_events(event_name, extra_properties = {}) {
      const default_properties = { type: !this.form_template_detail.workflow ? 'General' : 'Workflow' };
      default_properties.associated_with = `${this.form_template_detail?.target_element?.type?.toUpperCase()}`;
      this.$track_event(event_name, { ...default_properties, ...extra_properties, module: 'Forms' });
    },
    async get_field_filter_list(req = {}) {
      this.field_filters_tree = [];
      try {
        const { data } = await this.$services.forms.get_field_filters_list({ ...req, id: req.id });
        this.field_filters_tree = data.fields;
      }
      catch (error) {
        logger.log('🚀 ~ get_field_filter_list ~ error:', error);
      }
    },
    set_list_collapsed() {
      this.is_list_collapsed = !this.is_list_collapsed;
    },
  },
};

export const useFormTemplateDetailStore = defineStore('form-template-detail', store);
