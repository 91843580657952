<script setup>
import { useModal } from 'vue-final-modal';
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';
import { useTerraStore } from '~/terra/store/terra.store.js';
import NewForm from '~/forms/components/new-form/new-form-modal.vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useTerraHelperComposable } from '~/terra/utils/helper-composable.js';

const props = defineProps({
  marker_location: {
    type: Object,
    default: () => null,
  },
  project_details_for_task_form_creation: {
    type: Object,
    default: () => null,
  },
  opened_from: {
    type: String,
    default: 'Quick action',
  },
  template_uid: {
    type: String,
    default: '',
  },
  workflow_form: {
    type: Boolean,
    default: false,
  },
  workflow_form_payload: {
    type: Object,
    default: () => {},
  },
  form_data: {
    type: Object,
    default: null,
  },
  create_form_on_load: {
    type: Boolean,
    default: false,
  },
  workflow_form_properties: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['close', 'formCreated', 'setLoader']);
const $toast = inject('$toast');
const $form_create_permission = inject('$form_create_permission');
const route = useRoute();
const terra_store = useTerraStore();
const auth_store = useAuthStore();
const disable_close_cancel = ref(false);
const { createTerraForm } = useTerraHelperComposable();

const { open: openTaskForm, close: closeNewForm, patchOptions } = useModal({
  component: NewForm,
  attrs: {
    clickToClose: !props.workflow_form,
    fields: ['Form Name', 'Template', 'Assignees', 'Checkbox', 'Start Date', 'Due Date', 'Tags', 'Category', 'Prefix feature name'],
    onClose() {
      if (!disable_close_cancel.value) {
        closeNewForm();
        emit('close');
      }
    },
    on_cancel() {
      if (!disable_close_cancel.value) {
        closeNewForm();
        emit('close');
      }
    },
    form_data: props.form_data,
    event_properties: {
      bulk: terra_store.selected_features.length > 1,
      how: props.opened_from,
    },
    async on_submit(data) {
      disable_close_cancel.value = props.workflow_form;
      await createForm(data);
      disable_close_cancel.value = false;
      closeNewForm();
    },
  },

});

async function createForm(data) {
  try {
    const turf = await import('@turf/turf');
    const { response, elements } = await createTerraForm(data, props, turf);

    terra_store.selected_features = terra_store.selected_features.map((f) => {
      let element_object = f.properties.element;
      if (Object.keys(elements || {}).length)
        element_object = elements?.[f.properties.uid] ?? (f.properties.element);

      f.properties.element = element_object;
      return f;
    });

    const { is_active } = terra_store.gallery_view_state;
    terra_store.create_or_update_selected_features({
      clearSelectedFeatures: !is_active && !props.workflow_form,
      updateFeatureRequest: false,
    });
    if (response.forms.added.length) {
      emit('formCreated', { uid: response.forms.added[0].uid, status: 'open' });
      emit('close');
    }
  }
  catch (err) {
    logger.log(err);
  }
}
if (!props.create_form_on_load)
  if ($form_create_permission?.value?.state === 'enabled')
    openTaskForm();
  else
    $toast({ text: 'Permission Denied!', type: 'error' });
onMounted(async () => {
  if (props.create_form_on_load) {
    const data = { forms: { add: [props.form_data] }, prefix_feature_name: true, form_uid: props.form_data.template_uid };
    await createForm(data);
  }
});
</script>

<template>
  <div />
</template>

<style lang="scss" scoped>

</style>
