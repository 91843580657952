export function permissionsMatrix($t) {
  const GENERAL = {
    label: $t('General'),
    value: 'general',
    description: '<some desc>',
    permissions: [
      {
        label: $t('Basic'),
        value: 'basic',
        subtitle: $t('No Permissions'),
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        subtitle: $t('Can view users & teams'),
      },
      {
        label: $t('Manager'),
        value: 'manager',
        subtitle: $t('Can invite users and modify teams & categories'),
      },
      {
        label: $t('Admin'),
        value: 'admin',
        subtitle: $t('Can modify users, teams, roles.'),
      },
    ],
    columns: [
      {
        label: $t('Basic'),
        value: 'basic',
        info: null,
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        info: null,
      },
      {
        label: $t('Manager'),
        value: 'manager',
        info: null,
      },
      {
        label: $t('Admin'),
        value: 'admin',
        info: null,
      },
    ],
    matrix: [
      {
        label: $t('General'),
        rows: [
          {
            label: $t('Access permitted assets'),
            basic: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Access users, teams and roles directory'),
            basic: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Invite new users, manager users, teams and categories'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Manage roles'),
            basic: false,
            advanced: false,
            manager: false,
            admin: true,
          },
        ],
      },
    ],
  };
  const FORMS = {
    label: $t('Forms'),
    value: 'forms',
    description: '<some desc>',
    permissions: [
      {
        label: $t('Basic'),
        value: 'basic',
        subtitle: $t('Can view and submit assigned forms'),
      },
      {
        label: $t('Template Manager'),
        value: 'templates_manager',
        subtitle: $t('Create, view, modify all form templates. Can copy template from an asset into one/more assets'),
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        subtitle: $t('Can view all form templates and create a form for any template'),
      },
      {
        label: $t('Manager'),
        value: 'manager',
        subtitle: $t('Create, view, modify all form templates. Can copy template from an asset into one/more assets. Can create a form for any template. Can view all forms/responses and historical submissions. Can submit all forms'),
      },
      {
        label: $t('Admin'),
        value: 'admin',
        subtitle: $t('Create, view, modify all form templates. Can copy template from an asset into one/more assets. Can create a form for any template. Can view all forms/responses and historical submissions. Can submit and modify all forms'),
      },
    ],
    columns: [
      {
        label: $t('Basic'),
        value: 'basic',
        info: null,
      },
      {
        label: $t('Template Manager'),
        value: 'templates_manager',
        info: null,
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        info: null,
      },
      {
        label: $t('Manager'),
        value: 'manager',
        info: null,
      },
      {
        label: $t('Admin'),
        value: 'admin',
        info: null,
      },
    ],
    matrix: [
      {
        label: $t('Forms'),
        rows: [
          {
            label: $t('View, fill and submit assigned forms only'),
            basic: true,
            templates_manager: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Create a new form'),
            basic: false,
            templates_manager: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('View responses and historical submissions for all forms'),
            basic: false,
            templates_manager: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Fill and submit any form irrespective of the assignment'),
            basic: false,
            templates_manager: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Modify any form i.e change assignees, priority, dates, category, tags, etc'),
            basic: false,
            templates_manager: false,
            advanced: false,
            manager: false,
            admin: true,
          },
        ],
      },
      {
        label: $t('Templates'),
        rows: [
          {
            label: $t('View all form templates i.e workflow, form layout and settings'),
            basic: false,
            templates_manager: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Create new form templates'),
            basic: false,
            templates_manager: true,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Modify any form template i.e workflow, form and their settings'),
            basic: false,
            templates_manager: true,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Import a template from an asset into other assets.'),
            basic: false,
            templates_manager: true,
            advanced: false,
            manager: true,
            admin: true,
          },
        ],
      },
    ],
  };
  const TASKS = {
    label: $t('Tasks'),
    value: 'tasks',
    description: '<some desc>',
    permissions: [
      {
        label: $t('Basic'),
        value: 'basic',
        subtitle: $t('Can work on assigned and owned tasks. Can create observations'),
      },
      {
        label: $t('Template Manager'),
        value: 'templates_manager',
        subtitle: $t('Can view, create and modify task templates'),
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        subtitle: $t('Can create tasks, modify assigned tasks and close assigned and owned tasks'),
      },
      {
        label: $t('Manager'),
        value: 'manager',
        subtitle: $t('Can view, modify, close all tasks. Can use task templates. Can import task templates shared by other assets.'),
      },
      {
        label: $t('Admin'),
        value: 'admin',
        subtitle: $t('Can view, modify, close all tasks. Can create, modify, use task templates. Can import task templates shared by other assets.'),
      },
    ],
    columns: [
      {
        label: $t('Basic'),
        value: 'basic',
        info: null,
      },
      {
        label: $t('Template Manager'),
        value: 'templates_manager',
        info: null,
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        info: null,
      },
      {
        label: $t('Manager'),
        value: 'manager',
        info: null,
      },
      {
        label: $t('Admin'),
        value: 'admin',
        info: null,
      },
    ],
    matrix: [
      {
        label: $t('Template'),
        rows: [
          {
            label: $t('View, change status, attach and comment on assigned tasks only'),
            basic: true,
            templates_manager: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Create and modify task templates'),
            basic: false,
            templates_manager: true,
            advanced: false,
            manager: false,
            admin: true,
          },
          {
            label: $t('Create tasks, modify assigned tasks, close assigned and owned tasks'),
            basic: false,
            templates_manager: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Modify and close all tasks, import task templates from other assets'),
            basic: false,
            templates_manager: false,
            advanced: false,
            manager: true,
            admin: true,
          },
        ],
      },
    ],
  };
  const DMS = {
    label: $t('DMS'),
    value: 'documents',
    description: '<some desc>',
    permissions: [
      {
        label: $t('Basic'),
        value: 'basic',
        subtitle: $t('No Permissions'),
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        subtitle: $t('Can create and share document, create transmittal and view DMS workflow template'),
      },
      {
        label: $t('Manager'),
        value: 'manager',
        subtitle: $t('Can create/view/share documents & transmittals. Custom statuses, review statuses, Issue purposes, transmittal schema. Can view, create & modify DMS workflow templates, DMS custom fields, DMS statuses'),
      },
      {
        label: $t('Admin'),
        value: 'admin',
        subtitle: $t('Can create/view/share,modify documents & transmittals. Custom statuses, review statuses, Issue purposes, transmittal schema. Can view, create & modify DMS workflow templates, DMS custom fields, DMS statuses'),
      },
    ],
    columns: [
      {
        label: $t('Basic'),
        value: 'basic',
        info: null,
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        info: null,
      },
      {
        label: $t('Manager'),
        value: 'manager',
        info: null,
      },
      {
        label: $t('Admin'),
        value: 'admin',
        info: null,
      },
    ],
    matrix: [
      {
        label: $t('Documents'),
        rows: [
          {
            label: $t('Access, view and download only shared documents'),
            basic: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Add new versions, share, update document properties only for items shared with edit access'),
            basic: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Add document placeholders, upload files/folders'),
            basic: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Share all accessible files and folders'),
            basic: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Access view and download all documents'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Add new versions, share, update document properties for all files and folders'),
            basic: false,
            advanced: false,
            manager: false,
            admin: true,
          },
        ],
      },
      {
        label: $t('Transmittals'),
        rows: [
          {
            label: $t('View all transmittals'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Create new transmittals'),
            basic: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('View and use transmittal workflows'),
            basic: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Create & modify DMS workflow templates, custom fields, statuses, issue purposes, schema'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Modify transmittal properties, manage members and perform actions on all transmittals/submittals except reviewal.'),
            basic: false,
            advanced: false,
            manager: false,
            admin: true,
          },
        ],
      },
    ],
  };
  const PLANS = {
    label: $t('Plans'),
    value: 'plan',
    description: '<some desc>',
    permissions: [
      {
        label: $t('Basic'),
        value: 'basic',
        subtitle: $t('Can only view public plans. Can view annotations'),
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        subtitle: $t('Can view public plans. Can create, modify, share annotations'),
      },
      {
        label: $t('Manager'),
        value: 'manager',
        subtitle: $t('Can view all plans including private plans. Can create, modify, share annotations'),
      },
      {
        label: $t('Admin'),
        value: 'admin',
        subtitle: $t('Can view all plans including private plans. Can mark plans as private. Can create, modify, share annotations'),
      },
    ],
    columns: [
      {
        label: $t('Basic'),
        value: 'basic',
        info: null,
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        info: null,
      },
      {
        label: $t('Manager'),
        value: 'manager',
        info: null,
      },
      {
        label: $t('Admin'),
        value: 'admin',
        info: null,
      },
    ],
    matrix: [
      {
        label: $t('General'),
        rows: [
          {
            label: $t('Access all non-private drawings and their annotations/markups'),
            basic: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Add, update and delete annotations/markups'),
            basic: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('View all private/non-private drawings'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },

          {
            label: $t('Toggle privacy setting for any drawing'),
            basic: false,
            advanced: false,
            manager: false,
            admin: true,
          },
        ],
      },
    ],
  };
  const SCHEDULE = {
    label: $t('Schedule'),
    value: 'schedules',
    description: '<some desc>',
    permissions: [
      {
        label: $t('Basic'),
        value: 'basic',
        subtitle: $t('Can only access schedules that are directly shared'),
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        subtitle: $t('Can view schedules in all accessible assets'),
      },
      {
        label: $t('Manager'),
        value: 'manager',
        subtitle: $t('Can create and view schedules in all accessible assets'),
      },
      {
        label: $t('Admin'),
        value: 'admin',
        subtitle: $t('Can create, view and modify schedules in all accessible assets.'),
      },
    ],
    columns: [
      {
        label: $t('Basic'),
        value: 'basic',
        info: null,
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        info: null,
      },
      {
        label: $t('Manager'),
        value: 'manager',
        info: null,
      },
      {
        label: $t('Admin'),
        value: 'admin',
        info: null,
      },
    ],
    matrix: [
      {
        label: $t('General'),
        rows: [
          {
            label: $t('Access only shared schedules'),
            basic: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('View all schedules'),
            basic: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Create new schedules'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },

          {
            label: $t('Update all schedules, activities, configure settings and manage versions'),
            basic: false,
            advanced: false,
            manager: false,
            admin: true,
          },
        ],
      },
    ],
  };
  const REPORTS = {
    label: $t('Dashboards'),
    value: 'reports',
    description: '<some desc>',
    permissions: [
      {
        label: $t('Basic'),
        value: 'basic',
        subtitle: $t('Can only view shared dashboards'),
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        subtitle: $t('Can view all dashboards'),
      },
      {
        label: $t('Manager'),
        value: 'manager',
        subtitle: $t('Can view and share all dashboards'),
      },
      {
        label: $t('Admin'),
        value: 'admin',
        subtitle: $t('Can create, view, share, and modify all dashboards.'),
      },
    ],
    columns: [
      {
        label: $t('Basic'),
        value: 'basic',
        info: null,
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        info: null,
      },
      {
        label: $t('Manager'),
        value: 'manager',
        info: null,
      },
      {
        label: $t('Admin'),
        value: 'admin',
        info: null,
      },
    ],
    matrix: [
      {
        label: $t('General'),
        rows: [
          {
            label: $t('Access only shared dashboards'),
            basic: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('View all dashboards'),
            basic: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Share any dashboard'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },

          {
            label: $t('Add, update, remove dashboards and widgets'),
            basic: false,
            advanced: false,
            manager: false,
            admin: true,
          },
        ],
      },
    ],
  };
  const TERRA = {
    label: $t('Terra'),
    value: 'terra',
    description: '<some desc>',
    permissions: [
      {
        label: $t('Basic'),
        value: 'basic',
        subtitle: $t('View pivot data, View Scan, download reports, Create and modify views, Update progress for features, View created workflows'),
      },
      {
        label: $t('Limited'),
        value: 'limited',
        subtitle: $t('View published charts, Download project reports, Create/modify pivot, Add/Update feature extraProperties and change feature type, Modify feature type symbology'),
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        subtitle: $t('View all maps/layers, Share any map/layer, Update schedule tracker, Clear duplicate barcodes, Modify feature geometry, import/export feature json, properties, type and delete features.'),
      },
      {
        label: $t('Manager'),
        value: 'manager',
        subtitle: $t('Modify map data - name, description, add/update/delete projects, groups for self-serve terra, Add/modify feature type groups and types'),
      },
      {
        label: $t('Admin'),
        value: 'admin',
        subtitle: $t('Add/modify terra progress workflows'),
      },
    ],
    columns: [
      {
        label: $t('Basic'),
        value: 'basic',
        info: null,
      },
      {
        label: $t('Limited'),
        value: 'limited',
        info: null,
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        info: null,
      },
      {
        label: $t('Manager'),
        value: 'manager',
        info: null,
      },
      {
        label: $t('Admin'),
        value: 'admin',
        info: null,
      },
    ],
    matrix: [
      {
        label: $t('Maps'),
        rows: [
          {
            label: $t('View all maps/layers'),
            basic: false,
            limited: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Download layer based reports - DSM, DTM'),
            basic: false,
            limited: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('View/analyze vector data i.e pivot table'),
            basic: true,
            limited: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('View barcode scan dashboards and download scan reports'),
            basic: true,
            limited: true,
            advanced: true,
            manager: true,
            admin: true,
          },

          {
            label: $t('Create, update and delete views'),
            basic: true,
            limited: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('View charts - construction progress monitoring reports'),
            basic: false,
            limited: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Update pivot table settings'),
            basic: false,
            limited: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Update schedule tracker for construction progress monitoring'),
            basic: false,
            limited: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Clear duplicates in Scan'),
            basic: false,
            limited: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Modify map data - name, description, add/update/delete projects, groups for maps that are manually created'),
            basic: false,
            limited: false,
            advanced: false,
            manager: true,
            admin: true,
          },
        ],
      },
      {
        label: $t('Vectors'),
        rows: [
          {
            label: $t('Import/Export vectors, modify and delete vectors'),
            basic: false,
            limited: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Add/update properties, change classes. Scan barcodes using mobile application'),
            basic: false,
            limited: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Add, update daily construction progress'),
            basic: true,
            limited: true,
            advanced: true,
            manager: true,
            admin: true,
          },
        ],
      },
      {
        label: $t('Classes'),
        rows: [
          {
            label: $t('Change class color & pattern symbology'),
            basic: false,
            limited: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Add, modify and remove classes and groups'),
            basic: false,
            limited: false,
            advanced: false,
            manager: true,
            admin: true,
          },
        ],
      },
      {
        label: $t('Workflows'),
        rows: [
          {
            label: $t('View existing construction progress workflows'),
            basic: true,
            limited: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Add, update and delete construction progress workflows'),
            basic: false,
            limited: false,
            advanced: false,
            manager: false,
            admin: true,
          },
          {
            label: $t('Add or change construction progress workflows association for respective vectors (only for Sensehawk users)'),
            basic: false,
            limited: false,
            advanced: false,
            manager: false,
            admin: false,
          },
        ],
      },
    ],
  };
  const INVENTORY = {
    label: $t('Inventory'),
    value: 'inventory',
    description: '<some desc>',
    permissions: [
      {
        label: $t('Basic'),
        value: 'basic',
        subtitle: $t('Can access and view items-stock and their quantities from warehouses to which user has access. can view records of own  transactions and create transactions to which user has access in accessible assets.'),
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        subtitle: $t('Can view records of all transactions and create transactions to which user has access in accessible assets'),
      },
      {
        label: $t('Manager'),
        value: 'manager',
        subtitle: $t('Can create and modify all transactions in accessible assets'),
      },
      {
        label: $t('Admin'),
        value: 'admin',
        subtitle: $t('Can access, view, create and modify all items, transactions, warehouses and vendors in accessible assets'),
      },
    ],
    columns: [
      {
        label: $t('Basic'),
        value: 'basic',
        info: null,
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        info: null,
      },
      {
        label: $t('Manager'),
        value: 'manager',
        info: null,
      },
      {
        label: $t('Admin'),
        value: 'admin',
        info: null,
      },
    ],
    matrix: [
      {
        label: $t('General'),
        rows: [
          {
            label: $t('Create drafts or publish transactions for the types that are explicitly granted'),
            basic: true,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('View all transactions'),
            basic: false,
            advanced: true,
            manager: true,
            admin: true,
          },
          {
            label: $t('Create transactions of all types'),
            basic: false,
            advanced: true,
            manager: true,
            admin: true,
          },

          {
            label: $t('View all items, their details and stock information'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Create and modify item details'),
            basic: false,
            advanced: false,
            manager: false,
            admin: true,
          },
          {
            label: $t('View warehouses, stock and transactions within'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Create and modify warehouse details'),
            basic: false,
            advanced: false,
            manager: false,
            admin: true,
          },

          {
            label: $t('View and modify bill of material'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Modify any unpublished transactions'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('View and manage reports'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },
          {
            label: $t('Configure transaction types, statuses, vendors, item types and other settings'),
            basic: false,
            advanced: false,
            manager: true,
            admin: true,
          },
        ],
      },
    ],
  };
  const THERM = {
    label: $t('Therm'),
    value: 'therm',
    description: '<some desc>',
    permissions: [
      {
        label: $t('Basic'),
        value: 'basic',
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
      },
      {
        label: $t('Admin'),
        value: 'admin',
      },
    ],
    columns: [
      {
        label: $t('Basic'),
        value: 'basic',
        info: null,
      },
      {
        label: $t('Advanced'),
        value: 'advanced',
        info: null,
      },
      {
        label: $t('Admin'),
        value: 'admin',
        info: null,
      },
    ],
    matrix: [
      {
        label: $t('General'),
        rows: [
          {
            label: $t('View assigned defects in accessible maps'),
            basic: true,
            advanced: true,
            admin: true,
          },
          {
            label: $t('Update defect status, priority, assignees, serial number for assigned defects'),
            basic: true,
            advanced: true,
            admin: true,
          },
          {
            label: $t('Add notes & attachments for assigned defects'),
            basic: true,
            advanced: true,
            admin: true,
          },
          {
            label: $t('View all defects in accessible maps'),
            basic: false,
            advanced: true,
            admin: true,
          },
          {
            label: $t('Update defect status, priority, assignees, serial number for all defects'),
            basic: false,
            advanced: true,
            admin: true,
          },
          {
            label: $t('Add notes & attachments for all defects'),
            basic: false,
            advanced: true,
            admin: true,
          },
          {
            label: $t('Update/Re-classify defect type'),
            basic: false,
            advanced: false,
            admin: true,
          },
        ],
      },
    ],
  };

  return [
    GENERAL,
    TASKS,
    FORMS,
    DMS,
    PLANS,
    SCHEDULE,
    REPORTS,
    TERRA,
    INVENTORY,
    THERM,
  ];
}
