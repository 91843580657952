<script setup>
import { useRoute } from 'vue-router';
import { onMounted, ref, watchEffect } from 'vue';
import { cloneDeep, compact, groupBy } from 'lodash-es';
import AccessDropdown from '~/account-settings/components/shared/access-dropdown.vue';

const props = defineProps({
  model_value: {
    type: Object,
    default: () => {},
  },
  is_admin: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:model_value']);
const route = useRoute();
const $services = inject('$services');

const all_permissions = ref({
  organization: [],
  asset: [],
  accessible_assets: [],
});

const permissions_value = ref({});
const data_loading = ref(false);

watchEffect(() => {
  emit('update:model_value', permissions_value.value);
});

function setPermissionNames(permissions, values) {
  Object.keys(values).forEach((key) => {
    const _key = (key === 'organization' || key === 'accessible_assets') ? key : 'asset';

    permissions[_key].forEach((permission) => {
      if (props.is_admin) {
        const admin = permission.permissions?.find(
          f => f.name.includes('ADMIN') || f.label.includes('admin'),
        );
        values[key][permission?.name] = admin.name;
      }
      else if (!values[key][permission.name]) {
        const basic = permission.permissions?.find(
          f => f.name.includes('BASIC') || f.label.includes('Basic'),
        );
        values[key][permission?.name] = basic?.name;
      }
    });
  });
}

function setPermissionsKey() {
  const values = { ...props.model_value };

  if (route?.params?.asset_id) {
    if (route.name === 'asset-settings-users-details')
      values[route.params.asset_id] = values[route.params.asset_id] || {};
    else
      values.asset = values.asset || {};
  }
  else {
    values.organization = values.organization || {};
    values.accessible_assets = values.accessible_assets || {};
  }

  const permissions = cloneDeep(all_permissions.value);

  setPermissionNames(permissions, values);

  all_permissions.value = cloneDeep(permissions);
  permissions_value.value = values;
  emit('update:model_value', values);
}

async function getData() {
  try {
    data_loading.value = true;
    const { data } = await $services.permissions.getAll();

    const v = data.map((el) => {
      el.permissions = groupBy(el.permissions, 'scope');
      return el;
    });

    Object.keys(all_permissions.value).forEach((key) => {
      all_permissions.value[key] = compact(
        v.map((el) => {
          return el.permissions[key]
            ? { ...el, permissions: el.permissions[key] }
            : null;
        }),
      );
    });

    setPermissionsKey();
    data_loading.value = false;
  }
  catch (error) {
    logger.log('error', error);
    data_loading.value = false;
  }
}

onMounted(() => {
  getData();
});
</script>

<template>
  <HawkLoader v-if="data_loading" />
  <div v-else>
    <div v-if="$route.params.asset_id">
      <div class="grid gap-4 text-sm">
        <div v-for="permission in all_permissions.asset" :key="permission.name">
          <div v-if="permission.permissions" class="flex items-center justify-between">
            <span>{{ $t(permission.label) }}</span>
            <AccessDropdown
              :value="model_value?.[$route.params.asset_id] ? model_value[$route.params.asset_id][permission.name] : model_value.asset[permission.name]"
              :accesses="
                (permission.permissions || []).map(p => ({
                  ...p,
                  name: p.label,
                  type: p.name,
                }))
              "
              @input="permissions_value?.[$route.params.asset_id]
                ? (permissions_value[$route.params.asset_id][permission.name] = $event)
                : (permissions_value.asset[permission.name] = $event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <div class="my-5">
          <div class="text-lg font-semibold text-gray-900">
            {{ $t('Organization') }}
          </div>
          <div class="text-xs text-gray-600">
            {{ $t('Organization and account administration related permissions') }}
          </div>
        </div>
        <div class="grid gap-4 text-sm">
          <div v-for="permission in all_permissions.organization" :key="permission.name">
            <div v-if="permission.permissions" class="flex items-center justify-between">
              <span>{{ $t(permission.label) }}</span>
              <AccessDropdown
                :value="model_value.organization[permission.name]"
                :accesses="
                  (permission.permissions || []).map(p => ({
                    ...p,
                    name: p.label,
                    type: p.name,
                  }))
                "
                @input="permissions_value.organization
                  ? (permissions_value.organization[permission.name] = $event)
                  : null"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="pt-5">
        <div class="my-5">
          <div class="text-lg font-semibold text-gray-900">
            {{ $t('Asset Level') }}
          </div>
          <div class="text-xs text-gray-600">
            {{ $t('Permissions related to assets that the user is added to') }}
          </div>
        </div>
        <div class="grid gap-4 text-sm">
          <div v-for="permission in all_permissions.accessible_assets" :key="permission.name" class="flex items-center justify-between">
            <span>{{ $t(permission.label) }}</span>
            <AccessDropdown
              :value="model_value.accessible_assets[permission.name]"
              :accesses="
                (permission.permissions || []).map(p => ({
                  ...p,
                  name: p.label,
                  type: p.name,
                }))
              "
              @input="permissions_value.accessible_assets
                ? (permissions_value.accessible_assets[permission.name] = $event)
                : null"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
