<script setup>
const props = defineProps({
  form_detail_store: {
    type: Object,
  },
  on_submit: {
    type: Function,
    default: null,
  },
});
const emit = defineEmits(['close']);

const form_detail_store = props.form_detail_store;

const forms$ = ref(null);
const blocks_data = ref(null);

async function onSave() {
  const { reason, rollback_to } = forms$.value.data;
  const req = {
    body: {
      status: 'rollback',
      message: reason,
      ...(rollback_to ? { step_index: Number(rollback_to) } : {}),
    },
    attribute: `${form_detail_store.form_detail.uid}/revert`,
  };
  await props.on_submit(req);
}

onMounted(() => {
  blocks_data.value = form_detail_store.form_detail.completed_steps;
  forms$.value.elements$.rollback_to.load(form_detail_store.form_detail.completed_steps.at(-1).index);
});
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="forms$" size="sm" :display-errors="false"
      :presets="['']" :float-placeholders="false" class="grid grid-rows-1 grid-flow-col gap-4"
      :endpoint="onSave"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            {{ $t("rollback") }}
          </template>
        </hawk-modal-header>
        <hawk-modal-content>
          <StaticElement
            name="text"
            :content="`${$t('The form will be rolled back to the selected form block and the assignees will be notified')}.`"
            size="sm"
            class="mb-4 text-[#475467]"
          />
          <SelectElement
            class="mb-5"
            name="rollback_to"
            :columns="{
              default: { container: 8, wrapper: 12, label: 4 },
              sm: { container: 12, label: 12, wrapper: 12 },
              md: { container: 8, label: 4, wrapper: 12 },
            }"
            :native="false"
            :placeholder="$t('Choose step')"
            label-prop="name"
            value-prop="index"
            :can-clear="false"
            :can-deselect="false"
            :items="blocks_data"
          >
            <template #label>
              <div class="flex gap-2 items-center">
                {{ $t('Rollback to') }}
              </div>
            </template>
          </SelectElement>
          <TextareaElement
            name="reason"
            :columns="{
              default: { container: 8, wrapper: 12, label: 4 },
              sm: { container: 12, label: 12, wrapper: 12 },
              md: { container: 8, label: 4, wrapper: 12 },
            }"
            :label="`${$t('reason')}`"
            :rules="[
              'required',
            ]"
            :placeholder="`${$t('Reason for rollback (required)')}`"
            size="sm"
          />
          <div class="flex mt-3">
            <HawkText
              class="text-sm font-semibold mr-1"
              :content="`${$t('Note')}:`"
            />
            <HawkText
              length="1000"
              class="text-sm"
              :content="`${$t('The form will be reassigned to the same members who were assignees at the time of submission of the')} ${blocks_data?.find(item => item.index === forms$?.data?.rollback_to)?.name || 'previous step'}`"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end text-sm font-medium">
              <hawk-button class="mr-3" type="outlined" @click="$emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true">
                {{ $t('rollback') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>

<style scoped>

</style>
