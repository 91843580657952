import { cloneDeep, filter, flatten, isString, omit, pick, sortBy } from 'lodash-es';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { inject, onBeforeMount } from 'vue';
import Gradient from 'javascript-color-gradient';
import { Validator } from '@vueform/vueform';
import { useMap } from '~/common/composables/mapbox/maps';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { useFormsStore } from '~/forms/store/forms.store';
import { useTasksStore } from '~/tasks/store/tasks.store';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { hexToRgb, htmlToText, nanoid } from '~/common/utils/common.utils';
import { $date } from '~/common/utils/date.util';

dayjs.extend(isBetween);
export function useTerraHelperComposable() {
  const { loadImages } = useMap();
  const $services = inject('$services');
  let turf;
  const static_keys = ['uid', 'name', 'description', 'group', 'color', 'fill_color', 'opacity', 'dataProperties', 'hierarchyProperties', 'extraProperties', 'workflowProgress', 'workflow', 'workflowProgressTimestamp', 'project', 'featureType', 'featureTypeId', 'type_id', 'oldfeatureTypeId', 'element', 'FeatId1'];
  onBeforeMount(async () => {
    turf = (await import('@turf/turf'));
  });
  const loadTaskFormIcons = (options) => {
    if (!options.map)
      return;

    loadImages(options.map, '/img/icons/marker.png', 'location-marker');

    // Forms images
    loadImages(options.map, '/img/icons/forms/drafts-form.png', 'drafts-form');
    loadImages(options.map, '/img/icons/forms/drafts-form-selected.png', 'drafts-form-selected');
    loadImages(options.map, '/img/icons/forms/open-form.png', 'open-form');
    loadImages(options.map, '/img/icons/forms/open-form-selected.png', 'open-form-selected');
    loadImages(options.map, '/img/icons/forms/submitted-form.png', 'submitted-form');
    loadImages(options.map, '/img/icons/forms/submitted-form-selected.png', 'submitted-form-selected');

    // Tickets
    for (let i = 1; i <= 4; i++) {
      loadImages(options.map, `/img/icons/priority/${i}.png`, `priority-${i}`);
      loadImages(options.map, `/img/icons/priority/${i}-selected.png`, `priority-${i}-selected`);
    }
    for (let i = 1; i <= 5; i++) {
      loadImages(options.map, `/img/icons/status/${i}.png`, `status-${i}`);
      loadImages(options.map, `/img/icons/status/${i}-selected.png`, `status-${i}-selected`);
    }
  };
  function getFilteredTasks(data) {
    const { tasks_cluster, settings, feature_elements } = useTerraStore();
    function isTaskAssigned(task) {
      if (!tasks_cluster.filters?.assignees?.length)
        return true;

      return task.assignees.some(assignee => tasks_cluster.filters.assignees.includes(assignee));
    }

    function isTaskOverdue(task) {
      if (!tasks_cluster.filters.tasks_overdue?.active)
        return true;
      return dayjs().isBefore(dayjs(task.due_date).format('YYYY-MM-DD 00:00'));
    }

    function hasTaskStatus(task) {
      if (!tasks_cluster.filters.status?.length)
        return true;

      return tasks_cluster.filters.status.includes(task.status);
    }

    function hasTaskBookmark(task) {
      if (!tasks_cluster.filters.starred)
        return true;

      return tasks_cluster.filters.starred === task.bookmark;
    }

    function hasTaskArchive(task) {
      if (!tasks_cluster.filters.archived)
        return true;

      return tasks_cluster.filters.archived === task.archive;
    }

    function hasTaskOwner(task) {
      if (!tasks_cluster.filters.owner?.length)
        return true;
      return tasks_cluster.filters.owner.includes(task.owner.uid);
    }
    function hasTaskCategory(task) {
      if (!tasks_cluster.filters?.category?.length)
        return true;
      return tasks_cluster.filters?.category?.includes(task.category);
    }
    function hasTaskPriority(task) {
      if (!tasks_cluster.filters?.priority?.length)
        return true;
      return tasks_cluster.filters?.priority?.includes(task.priority);
    }
    function hasTaskElement(task) {
      if (!settings.filter_task_form_with_target_element)
        return true;
      return feature_elements.includes(task.target_element?.uid);
    }
    function dueDate(date) {
      if (!tasks_cluster.filters?.due_date_start || !tasks_cluster.filters?.due_date_end)
        return true;
      if (!date)
        return false;

      return dayjs(date).isBetween(tasks_cluster.filters?.due_date_start, tasks_cluster.filters?.due_date_end, null, '[]');
    }
    const filteredTasks = data.filter((task) => {
      const isAssigned = isTaskAssigned(task);
      const isDue = dueDate(task.due_date);
      const isOverdue = isTaskOverdue(task);
      const hasStatus = hasTaskStatus(task);
      const hasBookmark = hasTaskBookmark(task);
      const hasArchive = hasTaskArchive(task);
      const hasOwner = hasTaskOwner(task);
      const hasCategory = hasTaskCategory(task);
      const hasPriority = hasTaskPriority(task);
      const hasElement = hasTaskElement(task);

      return isAssigned && isDue && isOverdue && hasStatus && hasBookmark && hasArchive && hasOwner && hasCategory && hasPriority && hasElement;
    });

    return filteredTasks;
  }
  function getFilteredForms(data) {
    const form_status = {
      draft: 'draft',
      submitted: 'submitted',
      open: 'open',
    };
    const { forms_cluster, settings, feature_elements } = useTerraStore();
    function dueDate(form) {
      if (!forms_cluster.filters?.due_date_start || !forms_cluster.filters?.due_date_end)
        return true;
      if (!form.due_date)
        return false;
      return dayjs(form.due_date).isBetween(forms_cluster.filters?.due_date_start, forms_cluster.filters?.due_date_end, null, '[]');
    }
    function assignees(form) {
      const assignee_list = [
        ...(forms_cluster.filters.assignees || []),
        ...(forms_cluster.filters.assignees_teams || []),
      ];
      if (assignee_list.length === 0)
        return true;
      const form_member_uids = form.members.map(item => item.uid);
      return assignee_list.some(assignee => form_member_uids.includes(assignee));
    };
    const templateFilter = (parent_form) => {
      if (!forms_cluster.filters?.parent_form_uid?.length)
        return true;
      return forms_cluster.filters?.parent_form_uid.includes(parent_form);
    };
    const statusFilter = (status) => {
      if (!forms_cluster.filters.status?.length)
        return true;
      return forms_cluster.filters.status.includes(status);
    };
    const rollBack = (form) => {
      if (!forms_cluster.filters.rolled_back)
        return true;
      return form?.current_submission[0]?.rolled_back || form?.current_submission[0]?.re_opened;
    };
    const targetElementFilter = (target_element) => {
      if (!settings.filter_task_form_with_target_element)
        return true;
      return feature_elements.includes(target_element);
    };
    const categoryFilter = (category) => {
      if (!forms_cluster.filters.category?.length)
        return true;
      return forms_cluster.filters.category.includes(category);
    };
    const ownerFilter = (owner) => {
      if (!forms_cluster.filters.owner?.length)
        return true;
      return forms_cluster.filters.owner.includes(owner);
    };

    const filterForm = (form) => {
      return templateFilter(form.template) && statusFilter(form_status[form.status.submission_status] || 'open') && assignees(form) && targetElementFilter(form.target_element?.uid) && categoryFilter(form.category) && ownerFilter(form.owner) && dueDate(form) && rollBack(form);
    };

    const filteredData = filter(data || [], filterForm);

    return filteredData;
  }
  function _removeZ(objects) {
    for (const obj of objects)

      if (Array.isArray(obj[0]))
        _removeZ(obj);

      else
        if (Array.isArray(obj))
          obj.splice(2);
  }

  function preProcessGeojson(geojson) {
    // Remove additional coordinates
    for (const feature of geojson.features) {
      _removeZ(feature.geometry?.type === 'Point' ? [feature?.geometry?.coordinates] : feature?.geometry?.coordinates);
      feature.properties.name = feature.properties.Name || feature.properties.name;
      feature.properties.description = htmlToText(feature.properties.description || '');
      feature.properties.featureTypeId = feature.properties.type_id || feature.properties.featureTypeId;
      feature.properties = omit(feature.properties, [
        'extrude',
        'tessellate',
        'altitudeMode',
        'gx:altitudeMode',
        'refreshVisibility',
        'flyToView',
        'visibility',
        'open',
        'Name',
      ]);
    }

    // Strip HTML from description
    // Rename Name -> name in the properties
    // Remove reserved properties from the GeoJSON

    return geojson;
  }

  function getParsedMapTasks() {
    try {
      const { selected_tasks_forms, tasks_cluster } = useTerraStore();
      const { tasks } = useTasksStore('terra_task_store');
      return (getFilteredTasks((tasks ? tasks() : [])) || []).reduce(
        (features, task) => {
          const location = task.location;
          if (location) {
            const feature = turf.feature(location);
            feature.properties.uid = task.uid;

            feature.properties.icon = tasks_cluster?.filters?.group_by === 'Priority'
              ? `priority-${task.priority}`
              : `status-${task.status}`;

            if (selected_tasks_forms?.[task.uid])
              feature.properties.icon += '-selected';
            feature.properties.feature_type = 'task';
            feature.properties.task_form_uid = task.uid;
            feature.properties.data = task;
            features.push(feature);
          }
          return features;
        },
        [],
      );
    }
    catch (err) {
      logger.error(err);
      return [];
    }
  }
  function getParsedMapForms() {
    try {
      const { forms } = useFormsStore('terra_form_store');
      const { selected_tasks_forms } = useTerraStore();
      return (getFilteredForms(forms) || []).reduce(
        (features, form) => {
          const location = form.location;
          if (location) {
            const feature = turf.feature(location);
            feature.properties.uid = form.uid;
            if (form.status.submission_status === 'submitted')
              feature.properties.icon = 'submitted-form';
            else if (form.status.submission_status === 'draft')
              feature.properties.icon = 'drafts-form';
            else
              feature.properties.icon = 'open-form';

            if (selected_tasks_forms?.[form.uid])
              feature.properties.icon += '-selected';

            feature.properties.feature_type = 'form';
            feature.properties.task_form_uid = form.uid;

            feature.properties.data = form;

            features.push(feature);
          }
          return features;
        },
        [],
      );
    }
    catch (err) {
      logger.error(err);
      return [];
    }
  }
  async function addSymbols() {
    const { forms_cluster, tasks_cluster, map } = useTerraStore();
    if (!map?.getSource('symbol-source'))
      return;

    try {
      let features = [];

      features = [
        tasks_cluster?.active ? getParsedMapTasks() : [],
        forms_cluster?.active ? getParsedMapForms() : [],
      ];
      map.getSource('symbol-source').setData({
        type: 'FeatureCollection',
        features: flatten(features),
      });
    }
    catch (err) {
      logger.error(err);
    }
  }
  function dragLayers({ layer, source, save_location, layer_type, map_instance }) {
    let selected_feature_for_dragging = null;
    const canvas = map_instance.getCanvasContainer();
    // drag supports
    function onMove(e) {
      const coords = e.lngLat;

      // Set a UI indicator for dragging.
      canvas.style.cursor = 'grabbing';

      // Update the Point feature in `geojson` coordinates
      // and call setData to the source layer `point` on it.
      const featuresCollection = map_instance.getSource(source)._data;
      featuresCollection.features = featuresCollection.features.map((f) => {
        const new_feature = cloneDeep(f);
        if (
          new_feature.properties.uid
          === selected_feature_for_dragging.properties.uid
        )
          new_feature.geometry.coordinates = [coords.lng, coords.lat];

        return new_feature;
      });
      map_instance.getSource(source).setData(featuresCollection);
    }

    function onUp(e) {
      // Print the coordinates of where the point had
      // finished being dragged to on the map.
      canvas.style.cursor = '';

      // Unbind mouse/touch events
      map_instance.off('mousemove', onMove);
      map_instance.off('touchmove', onMove);

      selected_feature_for_dragging = null;
    }

    map_instance.on('mousedown', layer, (e) => {
      // Prevent the default map drag behavior.
      e.preventDefault();

      if (!selected_feature_for_dragging)
        selected_feature_for_dragging = e.features[0];

      canvas.style.cursor = 'grab';

      map_instance.on('mousemove', onMove);
      map_instance.once('mouseup', onUp);
    });

    map_instance.on('touchstart', layer, (e) => {
      if (e.points.length !== 1)
        return;

      // Prevent the default map_instance drag behavior.
      e.preventDefault();
      if (!selected_feature_for_dragging)
        selected_feature_for_dragging = e.features[0];
      map_instance.on('touchmove', onMove);
      map_instance.once('touchend', onUp);
    });
  }

  function getStyles(feature_type, type = null) {
    const properties = (feature_type?.properties || {});
    const rgb = hexToRgb(properties?.fill_color);

    // rgb to hex
    function rgbToHex(rgb) {
      const rgbValues = rgb.match(/\d+/g);

      const componentToHex = (c) => {
        const hex = Number.parseInt(c).toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
      };

      const r = componentToHex(rgbValues[0]);
      const g = componentToHex(rgbValues[1]);
      const b = componentToHex(rgbValues[2]);

      return `#${r}${g}${b}`;
    }

    if (type === 'tag') {
      if (properties?.color?.includes('rgb'))
        properties.color = rgbToHex(properties?.color);
      const color = hexToRgb(properties?.color || '#000000');
      return {
        color: properties?.color || '#000000',
        backgroundColor: `rgba(${color.r},${color.g},${color.b},${0.125})`,
      };
    }

    let backgroundColor = 'white';
    if (properties.opacity && rgb)
      backgroundColor = `rgba(${rgb.r},${rgb.g},${rgb.b},${properties.opacity})`;
    else if (rgb)
      backgroundColor = `rgba(${rgb.r},${rgb.g},${rgb.b},${100})`;

    const style = {
      border: properties?.color ? `2px solid ${properties.color}` : '2px solid black',
      backgroundColor,
    };
    return style;
  }

  function getProjectOptions() {
    const { container } = useTerraStore();

    let groups = { ...(cloneDeep(container?.groups || {})) };
    groups = groups ? Object.values(groups) : [];
    return groups.reduce((acc, group) => {
      if (group?.properties?.date)
        group.name = $date(group?.properties?.date, 'DATE_MED');
      group.projects = group.projects
        ? Object.values(group.projects)
        : null;
      if (group.projects)
        group.projects.map((project) => {
          if (project?.properties?.date)
            project.name = $date(project?.properties?.date, 'DATE_MED');
          return project;
        });
      if (group.projects.length)
        acc.push(group);
      return acc;
    }, []);
  }

  function parseFeature(feature) {
    const extraProperties = omit(feature.properties, static_keys);
    feature.properties = pick(feature.properties, static_keys);
    feature.properties.extraProperties = { ...omit(feature.properties?.extraProperties || {}, static_keys), ...extraProperties };
    return feature;
  }

  async function createTerraForm(data, options = {}, turf_instance = {}) {
    const auth_store = useAuthStore();
    const forms_store = useFormsStore('terra_form_store');
    const terra_store = useTerraStore();
    const form_data = data.forms.add[0];
    let elements;
    let payload = [];
    const turf_module = turf || turf_instance;

    const location = options.marker_location
      ? turf_module.centroid({
        type: 'Point',
        coordinates: Object.values(options.marker_location),
      })
      : null;
    payload.target_element = options.project_details_for_task_form_creation;
    if (!terra_store.selected_features.length) {
      payload = [{
        ...form_data,
        properties: {
          projectUid: options.project_details_for_task_form_creation?.project_details?.uid,
        },

        target_element: options.project_details_for_task_form_creation?.target_element,
        location: location
          ? {
              type: 'Point',
              coordinates: location.geometry?.coordinates,
            }
          : null,
      }];
    }
    else {
      let features = options.features || terra_store.selected_features;
      if (options.project_details_for_task_form_creation)
        features = terra_store.selected_features.filter(item => item.properties.uid === options.project_details_for_task_form_creation.feature_details?.uid);
      const non_created_elements = features.reduce((acc, item) => {
        if (isString(item.properties.element))
          acc.push(item.properties.uid);
        return acc;
      }, []);
      if (non_created_elements.length) {
        const response = await $services.terra_view_service.get_elements({
          body: {
            uids: non_created_elements,
          },
        });

        elements = response.data;
      }
      payload = features.map((feature) => {
        const element_object = elements?.[feature.properties.uid] ?? feature.properties.element;
        const element = pick(element_object, ['uid', 'type', 'asset', 'stage']);

        let centroid;
        if (location)
          centroid = {
            geometry: {
              coordinates: location.geometry?.coordinates,
            },
          };

        else
          centroid = turf_module.centroid(feature);
        let name = form_data?.name || feature.properties?.name || '';
        if (form_data?.prefix_feature_name || data?.prefix_feature_name)
          name = [feature.properties?.name || '', form_data?.name].join(' ');
        const body = {
          ...form_data,
          target_element: element,
          name,
          uid: nanoid(),
          properties: {
            projectUid: options.project_details_for_task_form_creation?.project_details?.uid || feature.properties.project,
            ...((feature.properties.name) && {
              reference_name: String(feature.properties.name),
            }),

            ...((options.workflow_form) && {
              integration: {
                ...options.workflow_form_properties,
                feature: { uid: feature.properties.uid, name: feature.properties.name },
                source: { uid: feature.properties?.featureType, name: terra_store.feature_types_by_uid[feature.properties?.featureType]?.name },
              },
            }),
          },

          organization: auth_store.current_organization?.uid,
          location: centroid
            ? {
                type: 'Point',
                coordinates: centroid?.geometry?.coordinates,
              }
            : null,
        };

        return body;
      });
    }
    data.forms = payload;
    const response = await forms_store?.create_form(
      {
        body: { forms: { add: data.forms } },
        do_not_open_form_details: options.do_not_open_form_details || (terra_store.selected_features.length > 1),
      },
      true,
      {
        disable_toast: options.do_not_open_form_details,
        properties: options.workflow_form ? { view: 'TerraProgressUpdate' } : {},
      },
    );
    return { response, elements };
  }

  function getFormPayload(field_details) {
    const common_store = useCommonStore();
    const start_date = dayjs();
    const due_date = start_date.add(Number(field_details.config.duration), 'days');

    const members = {};
    const teams = {};
    field_details.config.assignees?.forEach((uid) => {
      if (common_store.teams_map[uid])
        teams[uid] = 'assignee';
      else
        members[uid] = 'assignee';
    });

    return {
      name: field_details.name,
      template: field_details.config.template,
      start_date: start_date.toISOString(),
      created_at: new Date().toISOString(),
      due_date: due_date.toISOString(),
      members,
      teams,
    };
  }
  function setMapboxLayersStylesByColorProperty() {
    const terra_store = useTerraStore();
    if (terra_store.map.getLayer('linestring_feature_layer')) {
      terra_store.map.setPaintProperty(
        'linestring_feature_layer',
        'line-color',
        ['coalesce', ['get', 'color'], '#222'],
      );
      terra_store.map.setPaintProperty(
        'linestring_feature_layer',
        'line-opacity',
        ['coalesce', ['get', 'line_opacity'], 1],
      );
    }

    if (terra_store.map.getLayer('polygon_feature_layer')) {
      terra_store.map.setPaintProperty('polygon_feature_layer', 'fill-color', ['coalesce', ['get', 'color'], 'transparent']);
      terra_store.map.setPaintProperty(
        'polygon_feature_layer',
        'fill-opacity',
        ['coalesce', ['get', 'opacity'], 0.5],
      );
    }
    if (terra_store.map.getLayer('point_feature_layer'))
      terra_store.map.setPaintProperty(
        'point_feature_layer',
        'circle-color',
        ['coalesce', ['get', 'color'], '#222'],
      );
  }
  function exportGeoJson(features = null, context = '') {
    const terra_store = useTerraStore();
    const FeatureCollection = {
      type: 'FeatureCollection',
      features: features ? sortBy(features, [item => item.properties.name]) : terra_store.features_on_map,
    };
    if (context === 'from_layers')
      FeatureCollection.features = FeatureCollection.features.map(item => ({ ...item, properties: { name: item.properties.name, type_id: item.properties.featureTypeId, type: terra_store.feature_types?.[item?.properties?.featureTypeId]?.name, ...item.properties.extraProperties } }));

    const blob = new Blob([JSON.stringify(FeatureCollection)], {
      type: 'text/json',
    });
    const filename = 'data.json';
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }

  function getGradientColors(form) {
    if (+form.classes < 1)
      return [];
    const classes = +form.classes > 10 ? 10 : +form.classes;
    const gradient_colors = new Gradient().setColorGradient(...form.color.value).setMidpoint(classes).getColors();
    const color_ranges = [];
    for (let i = 0; i < gradient_colors.length; i++) {
      let start = (i / classes) * 100;
      let end = ((i + 1) / classes) * 100;
      if (start % 1 !== 0)
        start = start.toFixed(0);
      if (end % 1 !== 0)
        end = end.toFixed(0);
      color_ranges.push({ start, end, color: gradient_colors[i] });
    }
    return color_ranges;
  }

  function applyGradientColors(form, object_values) {
    const data = Object.keys(object_values).map(val => +val);
    const gradient_colors = getGradientColors(form);
    const classes = +form.classes;

    const min = Math.min(...data);
    const max = Math.max(...data);
    const range = max - min;

    const formatted_data = gradient_colors.map(({ color }) => ({ value_keys: [], color }));

    data.forEach((value) => {
      let index = 0;
      if (range > 0) {
        const percentage = ((value - min) / range) * 100;
        index = Math.floor((percentage / 100) * classes);

        if (index >= classes)
          index = classes - 1;
        if (index < 0)
          index = 0;
      }
      formatted_data[index].value_keys.push(String(value));
    });
    return formatted_data;
  }

  function getValidationRulesForPropertyKey() {
    const checkPropertyKey = class extends Validator {
      get message() {
        return '\'Layer\' and \'Sublayer\' keys are not allowed';
      }

      check(value) {
        return !['Layer', 'Sublayer'].includes((value || '').trim());
      }
    };
    return { checkPropertyKey };
  }

  return { loadTaskFormIcons, getParsedMapForms, getParsedMapTasks, addSymbols, dragLayers, getStyles, getProjectOptions, parseFeature, preProcessGeojson, createTerraForm, getFormPayload, setMapboxLayersStylesByColorProperty, exportGeoJson, static_keys, getGradientColors, applyGradientColors, getValidationRulesForPropertyKey };
}
