<script setup>
import { storeToRefs } from 'pinia';
import { computed, inject } from 'vue';
import { groupBy, keys, random } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useSystemModelStore } from '~/system-model/store/system-model.store';

const props = defineProps({
  component_master: {
    type: Array,
    default: () => ([]),
  },
});
const router = useRouter();
const common_store = useCommonStore();
const system_model_store = useSystemModelStore();

const { assets, assets_map } = storeToRefs(common_store);
const { categories_map } = storeToRefs(system_model_store);

const $t = inject('$t');

const asset_uids = computed(() => keys(assets_map.value));
const grouped_component = computed(() => groupBy(props.component_master, 'category'));

const asset_instance_counts = computed(() => {
  const instance_counts = {};
  props.component_master.forEach((component) => {
    instance_counts[component.uid] = {};
    asset_uids.value.forEach((asset) => {
      instance_counts[component.uid][asset] = random(0, 9); // TODO:
    });
  });
  return instance_counts;
});

const category_instance_counts = computed(() => {
  const counts_by_category = {};
  for (const category_uid in grouped_component.value) {
    const components = grouped_component.value[category_uid];
    counts_by_category[category_uid] = {};
    components.forEach((component) => {
      const assets_count = asset_instance_counts.value[component.uid];
      asset_uids.value.forEach((asset) => {
        counts_by_category[category_uid][asset] = (counts_by_category[category_uid][asset] || 0) + (assets_count[asset] || 0);
      });
    });
  }
  return counts_by_category;
});
function expandRows(category, index) {
  const rows = document.querySelectorAll(`[data-row='${category}-${index}']`);
  document.querySelector(`svg.tr-${index}`)?.classList?.toggle('rotate-90');
  rows.forEach((row) => {
    const is_open = row.getAttribute('data-open') === 'true';
    row.setAttribute('data-open', String(!is_open));
  });
}

function getTotalInstance(uid, type = 'component') {
  const asset_instances = type === 'component' ? asset_instance_counts.value?.[uid] : category_instance_counts.value?.[uid];
  const asset_counts = Object.values(asset_instances || {});
  return asset_counts.reduce((total, count) => {
    if (count)
      total += count;
    return total;
  }, 0);
}

function goToDetailPage(component_uid) {
  router.push({
    name: 'component-master-detail',
    params: {
      component_uid,
    },
  });
}
</script>

<template>
  <div class="mt-4 flex flex-col">
    <div class="scrollbar pb-2">
      <table class="w-full table-auto border border-gray-200" aria-labelledby="component master">
        <!-- table header -->
        <thead class="z-10 bg-gray-50 border-b border-b-gray-200">
          <tr>
            <th class="text-left">
              <div class="px-6 py-3 font-medium text-gray-600 text-xs whitespace-nowrap border-r">
                {{ $t('Component') }}
              </div>
            </th>
            <template v-for="(asset_uid, index) of asset_uids" :key="index">
              <th class="px-6 py-3 font-medium text-gray-600 text-xs whitespace-nowrap">
                {{ assets_map[asset_uid]?.name }}
              </th>
            </template>
            <th class="text-left">
              <div class="px-6 py-3 font-medium text-gray-600 text-xs whitespace-nowrap border-l border-gray-200">
                {{ $t('Total') }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <template v-for="([category, components], index) in Object.entries(grouped_component)" :key="category">
            <!-- category row -->
            <tr class="cursor-pointer hover:bg-gray-100 group" @click="expandRows(category, index)">
              <td class="flex gap-3 px-6 py-1.25 items-center whitespace-nowrap border-r group-hover:bg-gray-100">
                <IconHawkChevronRight class="text-gray-600 w-4 h-4" :class="`tr-${index}`" />
                <div class="text-xs font-semibold text-gray-900">
                  {{ categories_map[category]?.name }}
                </div>
                <HawkBadge v-if="components?.length">
                  {{ components.length }}
                </HawkBadge>
              </td>
              <template v-for="(asset_uid, indx) of asset_uids" :key="indx">
                <td class="px-6 py-1.25 font-medium text-xs whitespace-nowrap text-gray-900 text-right">
                  {{ category_instance_counts?.[category]?.[asset_uid] }}
                </td>
              </template>
              <td class="text-right group-hover:bg-gray-100">
                <div class="px-6 py-1.25 font-medium text-xs whitespace-nowrap text-gray-900 border-l border-gray-200">
                  {{ getTotalInstance(category, 'category') }}
                </div>
              </td>
            </tr>

            <!-- Component row -->
            <tr
              v-for="component in components"
              :key="`component-${component.uid}`"
              data-open="false"
              :data-row="`${category}-${index}`"
              class="hidden data-[open=true]:table-row cursor-pointer hover:bg-gray-100 group"
              @click="goToDetailPage(component.uid)"
            >
              <td class="block pr-6 pl-12 py-1.25 whitespace-nowrap text-xs text-gray-600 border-r group-hover:bg-gray-100">
                {{ component.name }}
              </td>

              <template v-for="(asset_uid, indx) of asset_uids" :key="indx">
                <td class="px-6 py-1.25 font-medium text-gray-600 text-xs whitespace-nowrap text-right">
                  {{ asset_instance_counts?.[component.uid]?.[asset_uid] }}
                </td>
              </template>

              <td class="text-right group-hover:bg-gray-100">
                <div class="px-6 py-1.25 font-medium text-gray-600 text-xs whitespace-nowrap border-l border-gray-200">
                  {{ getTotalInstance(component.uid) }}
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style lang="scss" scoped>
table {
  tbody, thead {
    th:first-child, th:last-child {
      @apply bg-gray-50;
    }
    th:last-child, td:last-child {
      @apply sticky bg-white right-0 w-16;
    }
    td:first-child, th:first-child {
      @apply sticky bg-white left-0 w-96;
    }
  }
}
</style>
