<script setup>
const documents = [
  {
    uid: '764228b1-0bd7-4a02-8a7f-d16ff9ba24bc',
    notes: 'All areas inspected and two problems noticed',
    url: 'https://api-dev.sensehawk.com/storage/download/?b=sensehawk-test-data-management&k=terra/6Ab0VErSJdlf/pydxr2xU9.jpg&r=ap-south-1&f=motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_name: 'motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_size: 2032031,
    file_type: 'image/jpeg',
    created_at: '2024-04-16T12:43:58.799Z',
    created_by: 'mT75ptGECH',
    updated_at: '2024-04-16T12:43:58.799Z',
  },
  {
    uid: 'b1111521-7f0a-4079-a47a-198239e9427e',
    notes: 'All areas inspected and two problems noticed',
    url: 'https://api-dev.sensehawk.com/storage/download/?b=sensehawk-test-data-management&k=terra/6Ab0VErSJdlf/Mgky82DXg.jpg&r=ap-south-1&f=motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_name: 'motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_size: 2032031,
    file_type: 'image/jpeg',
    created_at: '2024-04-16T12:44:11.596Z',
    created_by: 'mT75ptGECH',
    updated_at: '2024-04-16T12:44:11.596Z',
  },
  {
    uid: 'b1111521-7f0a-4079-a47a-198239e9427e',
    notes: 'All areas inspected and two problems noticed',
    url: 'https://api-dev.sensehawk.com/storage/download/?b=sensehawk-test-data-management&k=terra/6Ab0VErSJdlf/Mgky82DXg.jpg&r=ap-south-1&f=motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_name: 'motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_size: 2032031,
    file_type: 'image/jpeg',
    created_at: '2024-04-16T12:44:11.596Z',
    created_by: 'mT75ptGECH',
    updated_at: '2024-04-16T12:44:11.596Z',
  },
  {
    uid: '764228b1-0bd7-4a02-8a7f-d16ff9ba24bc',
    notes: null,
    url: 'https://api-dev.sensehawk.com/storage/download/?b=sensehawk-test-data-management&k=terra/6Ab0VErSJdlf/pydxr2xU9.jpg&r=ap-south-1&f=motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_name: 'motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_size: 2032031,
    file_type: 'image/jpeg',
    created_at: '2024-04-16T12:43:58.799Z',
    created_by: 'mT75ptGECH',
    updated_at: '2024-04-16T12:43:58.799Z',
  },
  {
    uid: 'b1111521-7f0a-4079-a47a-198239e9427e',
    notes: 'All areas inspected and two problems noticed',
    url: 'https://api-dev.sensehawk.com/storage/download/?b=sensehawk-test-data-management&k=terra/6Ab0VErSJdlf/Mgky82DXg.jpg&r=ap-south-1&f=motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_name: 'motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_size: 2032031,
    file_type: 'image/jpeg',
    created_at: '2024-04-16T12:44:11.596Z',
    created_by: 'mT75ptGECH',
    updated_at: '2024-04-16T12:44:11.596Z',
  },
  {
    uid: '764228b1-0bd7-4a02-8a7f-d16ff9ba24bc',
    notes: null,
    url: 'https://api-dev.sensehawk.com/storage/download/?b=sensehawk-test-data-management&k=terra/6Ab0VErSJdlf/pydxr2xU9.jpg&r=ap-south-1&f=motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_name: 'motorcycle-race-colorful-digital-art-4k-wallpaper-uhdpaper.com-615@1@k.jpg',
    file_size: 2032031,
    file_type: 'image/jpeg',
    created_at: '2024-04-16T12:43:58.799Z',
    created_by: 'mT75ptGECH',
    updated_at: '2024-04-16T12:43:58.799Z',
  },
];
</script>

<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="w-4/5">
        <p class="text-sm font-semibold test-gray-900">
          {{ $t('Documents') }}
        </p>
        <span class="text-xs text-gray-600 w-3/6">
          Summary of all the documents used of this component in different assets. You can see the preview, description and other details of the documents
        </span>
      </div>
      <HawkButton type="outlined">
        <IconHawkPlus />
        {{ $t('New document') }}
      </HawkButton>
    </div>

    <hawk-attachments-grid
      v-if="documents?.length"
      :show_delete="false"
      :can_download="false"
      :items="documents"
      :enable_description="true"
      :can_view="false"
      :image_dimensions="[240, 160]"
    />
    <HawkIllustrations v-else type="no-data" for="attachments" variant="mini" />
  </div>
</template>
