<script setup>
import { useModal } from 'vue-final-modal';
import CmComponentDetailParameterForm from '~/system-model/component-master/pages/cm-component-detail/cm-component-detail-parameter-form.vue';

const $t = inject('$t');
const table_columns = [
  {
    accessorKey: 'name',
    header: $t('Parameter'),
    id: 'name',
  },
  {
    accessorKey: 'value',
    header: $t('Value'),
    id: 'value',
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    size: '2',
    show_on_hover: 'true',
  },
];

const parameter_list = computed(() => {
  return [
    {
      name: 'Phase',
      value: '3 phase',
    },
    {
      name: 'Cooling means',
      value: 'ONAN/ONAF1/ONAF',
    },
    {
      name: 'Power ONAN',
      value: 'NA',
    },
    {
      name: 'Power ONAF1',
      value: 'NA',
    },
  ];
});

const parameter_modal = useModal({
  component: CmComponentDetailParameterForm,
});

function openParameterForm() {
  parameter_modal.patchOptions({
    attrs: {
      onClose() {
        parameter_modal.close();
      },
    },
  });
  parameter_modal.open();
}

function getMenuItems(item) {
  return [
    { label: 'Item 1', on_click: () => ({}) },
    { label: 'Item 2', on_click: () => ({}) },
  ];
}
</script>

<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="w-4/5">
        <p class="text-sm font-semibold test-gray-900">
          {{ $t('Parameters') }}
        </p>
        <span class="text-xs text-gray-600">Parameters are characteristic properties of a component. The list shows parameter name and associated types.</span>
      </div>
      <HawkButton type="outlined" @click="openParameterForm">
        <IconHawkPlus />
        {{ $t('New parameter') }}
      </HawkButton>
    </div>
    <HawkTable
      :data="parameter_list"
      :columns="table_columns"
      :show_menu_header="false"
      :non_sortable_columns="['context_menu']"
      is_gapless
    >
      <template #name="{ data }">
        <p class="font-medium font-sm text-gray-900">
          {{ data?.row?.original?.name }}
        </p>
      </template>

      <template #context_menu="{ data: { row: { original } } }">
        <HawkMenu
          class="flex justify-end w-full"
          additional_trigger_classes="!ring-0 focus:!ring-0"
          :items="getMenuItems(original)"
        >
          <template #trigger>
            <IconHawkDotsVertical class="text-gray-600 h-5" />
          </template>
        </HawkMenu>
      </template>
    </HawkTable>
  </div>
</template>
