<script setup>
import { onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useModal } from 'vue-final-modal';
import { useComponentMasterStore } from '~/system-model/store/component-master.store';
import { useSystemModelStore } from '~/system-model/store/system-model.store';
import CmList from '~/system-model/component-master/pages/cm/cm-list.vue';
import { searchData } from '~/common/utils/common.utils';
import CmNewComponentForm from '~/system-model/component-master/pages/cm/cm-new-component-form.vue';

const $t = inject('$t');
const component_master_store = useComponentMasterStore();
const system_model_store = useSystemModelStore();
const { component_master } = storeToRefs(component_master_store);
const { set_component_master } = component_master_store;
const { set_categories } = system_model_store;
const header_tabs = [
  {
    uid: 'registry',
    label: $t('Registry'),
    to: { name: '' },
  },
  {
    uid: 'repository',
    label: $t('Repository'),
    to: { name: '' },
  },
];

const state = reactive({
  is_loading: false,
  active_header_tab: 'registry',
  search: '',
});

const new_component_modal = useModal({
  component: CmNewComponentForm,
});

const component_master_list = computed(() => searchData(component_master.value, state.search, 'name'));

function openNewComponentModal() {
  new_component_modal.patchOptions({
    attrs: {
      onClose() {
        new_component_modal.close();
      },
    },
  });
  new_component_modal.open();
}
async function getData() {
  state.is_loading = true;
  await set_categories();
  await set_component_master();
  state.is_loading = false;
}
onMounted(async () => {
  await getData();
});
</script>

<template>
  <HawkPageHeader class="h-[70px]" :title="$t('Component Master')">
    <template #left>
      <div class="flex">
        <HawkPageHeaderTabs :tabs="header_tabs" :active_item="state.active_header_tab" />
      </div>
    </template>
  </HawkPageHeader>
  <HawkPageSecondaryHeader class="px-4">
    <template #left>
      <HawkSearchInput v-model="state.search" placeholder="Search" />
    </template>
    <template #right>
      <hawkButton color="primary" @click="openNewComponentModal">
        <IconHawkPlusWhite class="w-3 h-3" /> {{ $t('New Component') }}
      </hawkButton>
    </template>
  </HawkPageSecondaryHeader>
  <div class="pb-4">
    <div v-if="state.is_loading" class="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]">
      <HawkLoader />
    </div>
    <div v-else-if="!component_master_list.length && state.search">
      <HawkIllustrations type="no-results" />
    </div>
    <div v-else-if="!component_master_list.length && !state.is_loading">
      <HawkIllustrations type="on-boarding" for="component-master" />
    </div>
    <CmList
      v-else
      :key="component_master_list?.length"
      :component_master="component_master_list"
    />
  </div>
</template>
