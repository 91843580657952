<script setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { groupBy, orderBy } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useAuthStore } from '~/auth/stores/auth.store';
import AssetMetadataVisibilityPopup from '~/dashboard/components/asset-metadata-visibility-popup.vue';

defineProps({
  show_sidebar: {
    type: Boolean,
    default: true,
  },
});

const $toast = inject('$toast');

const dashboard_store = useDashboardStore();
const common_store = useCommonStore();
const auth_store = useAuthStore();

const { active_asset, assets_custom_fields, assets_custom_fields_map } = storeToRefs(common_store);

const { get_field_values } = dashboard_store;
const route = useRoute();

const is_loading = ref(false);
const selected_fields = ref([]);
const fields_data = ref([]);
const active_category = ref([]);

function toggleCategory(uid) {
  const index = active_category.value.indexOf(uid);
  if (index !== -1)
    active_category.value.splice(index, 1);
  else active_category.value.push(uid);
}

const visible_fields = computed(() => {
  let fields;
  // if there are selected fields, display them in order
  if (active_asset.value?.properties?.metadata_visibility)
    fields = active_asset.value?.properties?.metadata_visibility.reduce((res, field_uid) => {
      const field = active_asset.value?.metadata?.find(f => f?.field === field_uid);

      if (field)
        res.push({
          ...field,
          name: assets_custom_fields_map.value?.[field.field]?.name,
          category_uid: assets_custom_fields_map.value?.[field.field]?.properties?.category_uid,
        });
      return res;
    }, []);
  // else display them alphabetically
  else
    fields = active_asset.value?.metadata.map((item) => {
      return {
        ...item,
        name: assets_custom_fields_map.value?.[item.field]?.name,
        category_uid: assets_custom_fields_map.value?.[item.field]?.properties?.category_uid,
      };
    });
  return fields;
});
const non_category_fields = computed(() => {
  return visible_fields.value.filter(item => !item.category_uid);
});
const grouped_by_category_fields = computed(() => {
  return orderBy(
    Object.values(groupBy(visible_fields.value.filter(item => item.category_uid), 'category_uid')).map(group => orderBy(group, 'name')),
    items => common_store.categories_map[items[0].category_uid]?.name,
  );
});
</script>

<template>
  <div v-if="active_asset" class="sticky top-16">
    <div
      class=" h-[calc(100vh-65px)] flex flex-col px-4 py-3 border-r border-solid border-gray-200 transition-all ease-in-out duration-300 relative"
      :class="show_sidebar ? 'left-[-308px] w-0 !p-0' : 'left-[0px] w-[308px]'"
    >
      <div class="flex pb-3 border-b border-gray-200 border-solid">
        <div
          v-if="active_asset?.cover_image?.url"
          class="w-12 h-12 rounded-xl overflow-hidden mr-3 flex-shrink-0"
        >
          <img :src="active_asset?.cover_image?.url" class="object-contain w-full h-full" alt="cover image">
        </div>
        <div>
          <div class="text-sm font-medium text-gray-900">
            {{ $filters.truncate(active_asset.name, 35) }}
          </div>
          <div class="text-xs font-normal text-gray-600">
            {{ (active_asset
              && active_asset.address
              && active_asset.address.name) || '' }}
          </div>
        </div>
        <AssetMetadataVisibilityPopup />
      </div>
      <HawkLoader v-if="is_loading" />
      <div v-else class="scrollbar h-full py-3">
        <!-- non_category_fields -->
        <div v-if="non_category_fields.length">
          <div v-for="field in non_category_fields" :key="field.id" class="mb-4">
            <div class="text-sm font-medium text-gray-900 mb-1">
              {{ field.name }}
            </div>
            <div class="text-xs font-normal text-gray-700">
              <HawkCustomFieldValueRender :field_value="field" field_selector="field" value_separator=", " />
            </div>
          </div>
          <hr class="my-4">
        </div>
        <div v-if="grouped_by_category_fields?.length">
          <div v-for="group in grouped_by_category_fields" :key="group[0].category_uid">
            <div class="cursor-pointer flex items-center pb-2 gap-1" @click="toggleCategory(group[0].category_uid)">
              <IconHawkChevronDown v-if="active_category.includes(group[0].category_uid)" />
              <IconHawkChevronRight v-else />
              <HawkCategoryName :uid="group[0].category_uid" class="text-sm font-semibold text-gray-700" />
            </div>
            <div v-if="active_category.includes(group[0].category_uid)">
              <div v-for="field in group" :key="field.uid" class="mb-4 ml-6">
                <div class="text-sm font-medium text-gray-900 mb-1">
                  {{ field.name }}
                </div>
                <div class="text-xs font-normal text-gray-700">
                  <HawkCustomFieldValueRender :field_value="field" field_selector="field" value_separator=", " />
                </div>
              </div>
            </div>
            <hr class="my-4">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
