<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

const { auth_store, common_store, $t, $services, route } = useCommonImports();

const state = reactive({
  invited_users: null,
  is_loading: false,
  active_tab_item: 'account-settings-users',
});

function checkUser(user) {
  return (((user?.first_name && user?.last_name)
    ? (user?.first_name + user?.last_name)
    : user?.email) || user?.username);
}

const filtered_users = computed(() => {
  return common_store
    ?.members_scope_users(route?.params?.asset_id)
    .filter(user => user?.status !== 'invited' && checkUser(user)?.toLowerCase());
});

const tabs_items = computed(() => {
  let extra = {
    label: $t('Invitations'),
    count: state.invited_users?.length ?? 0,
  };

  if (route?.params?.asset_id)
    extra = {
      ...extra,
      uid: 'asset-settings-users-invitation',
      to: { name: 'asset-settings-users-invitation' },
    };

  else
    extra = {
      ...extra,
      uid: 'account-settings-users-invitation',
      to: { name: 'account-settings-users-invitation' },
    };

  return [
    (route?.params?.asset_id
      ? {
          uid: 'asset-settings-users',
          label: $t('Members'),
          count: auth_store.check_permission('view_users', route.params.asset_id) ? filtered_users.value?.length : 0,
          to: { name: 'asset-settings-users' },
        }
      : {
          uid: 'account-settings-users',
          label: $t('Members'),
          count: auth_store.check_permission('view_users', route.params.asset_id) ? filtered_users.value?.length : 0,
          to: { name: 'account-settings-users' },
        }
    ),
    auth_store.check_permission('invite_users', route.params.asset_id) ? extra : {},
  ];
});

async function getInvitationData() {
  if (auth_store.check_permission('invite_users', route.params.asset_id))
    try {
      state.is_loading = true;
      const query = { network_first: true };
      query.asset = route?.params?.asset_id;
      const { data } = await $services.users.get_invites({
        organization_id: auth_store.current_organization?.uid,
        query,
      });
      state.invited_users = data.invitations;
      state.is_loading = false;
    }
    catch (error) {
      state.is_loading = false;
      logger.log(error);
    }
}

onMounted(() => {
  if (route.name === 'asset-settings-users-invitation' || route.name === 'account-settings-users-invitation')
    getInvitationData();
});

watch(() => route.name, () => {
  if (route.name === 'asset-settings-users-invitation' || route.name === 'account-settings-users-invitation')
    getInvitationData();
});
</script>

<template>
  <div>
    <HawkTabs
      class="pt-4"
      :tabs="tabs_items"
      :active_item="route.name"
      @tab-click="state.active_tab_item = $event.uid"
    />
    <RouterView
      :invited_users="state.invited_users"
      :loading="state.is_loading"
      @update="getInvitationData()"
    />
  </div>
</template>
