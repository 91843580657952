<!-- eslint-disable import/order -->
<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useModal } from 'vue-final-modal';
import { Pane, Splitpanes } from 'splitpanes';
import IconSubmitted from '~icons/hawk/tick-mark';
import IconWarn from '~icons/hawk/warn-icon';
import 'splitpanes/dist/splitpanes.css';
import FormDetailReopenModal from './form-detail-reopen-modal.vue';
import FormDetailRollbackModal from './form-detail-rollback-modal.vue';
import IconRollback from '~icons/hawk/rollback';
import { $date, $date_relative } from '~/common/utils/date.util';
import { useAuthStore } from '~/auth/stores/auth.store';
import ApprovalBlock from '~/forms/components/form-detail/form-detail-approval-block.vue';
import FormDetailHeader from '~/forms/components/form-detail/form-detail-header.vue';
import { useFormDetailStore } from '~/forms/store/form-detail.store.js';
import FormDetailStep from '~/forms/components/form-detail/form-detail-step.vue';
import CustomVueformTextElement from '~/common/components/vueform/vueform-text-element.vue';
import FromDetailSections from '~/forms/components/form-detail/form-detail-sections.vue';
import { useFormWorkflowStore } from '~/forms/store/form-workflow.store';
import useEmitter from '~/common/composables/useEmitter';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { STATIC_FORM_DATA } from '~/forms/constants.js';
import { $toast } from '~/common/utils/common.utils';
import { useFormatSavePayload } from '~/forms/composables/form-detail-composable';
import { filter } from 'lodash-es';
import HawkFilePreviewGrid from '~/common/components/atoms/hawk-file-preview-grid.vue';

const route = useRoute();
const emitter = useEmitter();
const { store_key, form_uid, is_preview, teleport_to = 'body', track_event_view = '', archived = false } = route.query.form_id ? { form_uid: route.query.form_id } : JSON.parse(atob(route.query.form));
const form_workflow_store = useFormWorkflowStore();
const $track_event = inject('$track_event');
const auth_store = useAuthStore();
const form_detail_store = useFormDetailStore(store_key);
const form_template_detail_store = useFormTemplateDetailStore();
const is_read_only = is_preview || route.query.submission_history;
provide('form_detail_store', form_detail_store);
const task = route?.query?.task ? JSON.parse(atob(route.query.task)) : null;
const form$ = ref(null);
const uploading = ref(null);

function getSteps() {
  if (form_workflow_store.form_blocks.length)
    return markRaw(Object.values(form_detail_store.steps_with_sections || {}).filter(step => (form_workflow_store.form_blocks.includes(step.index))).reduce((acc, curr) => {
      acc[curr.index] = curr;
      return acc;
    }, {}));

  return markRaw(form_detail_store.steps_with_sections);
}
const router = useRouter();
const $t = inject('$t');
// form status
const is_saved = ref(form_detail_store?.form_detail?.status?.name !== 'Open');
const has_form_changed = ref(false);
const is_submitted = computed(() => form_detail_store?.form_detail?.status.name === 'Submitted');
const allow_rollback = computed(() => !is_submitted.value && form_detail_store?.form_template?.steps?.[form_detail_store?.form_detail?.status?.index]?.allow_rollback);

// reopen modal
const { open: openReopenPopup, close: closeReopenPopup } = useModal({
  component: FormDetailReopenModal,
  attrs: {
    form_detail_store,
    onClose() {
      closeReopenPopup();
    },
    async on_submit(req) {
      await form_detail_store?.reopen_form(form_uid, req);
      emitter.emit('form_reopen', { data: form_detail_store?.form_detail });
      closeReopenPopup();
      emptyRoute();
    },
  },
});

// rollback step modal
const { open: openRollbackStepPopup, close: closeRollbackStepPopup } = useModal({
  component: FormDetailRollbackModal,
  attrs: {
    form_detail_store,
    onClose() {
      closeRollbackStepPopup();
    },
    async on_submit(req) {
      await form_detail_store?.rollback_form(form_uid, req);
      closeRollbackStepPopup();
      emptyRoute();
    },
  },
});

const getFormFieldsData = useFormatSavePayload(form_detail_store);

const is_saving = ref(false);
// save button
async function Save() {
  try {
    is_saving.value = true;
    const field_value_map = form$.value.requestData;
    const fields_data = getFormFieldsData(field_value_map);
    const attached_to = task ? { object: task.id, service: 'TICKETS' } : undefined;
    const payload = { strict: true, form_submissions: [{ fields: fields_data, attached_to }] };
    await form_detail_store?.save_form(form_uid, { body: payload }).then(() => {
      is_saved.value = true;
    });
    emitter.emit('form_save', { uid: form_uid });
    is_saving.value = false;
  }
  catch (e) {
    is_saving.value = false;
    logger.log(e);
  }
}

const is_form_invalid = computed(() => form$?.value?.invalid);

watch(is_form_invalid, () => {
  if (is_saved.value) {
    const invalid_field = filter(Object.values(form$.value?.elements$ || {}) ?? [], 'invalid')[0];
    if (invalid_field) {
      $toast({
        text: `Validation failed for ${invalid_field.genericName}`,
        type: 'error',
      });

      const scroll_element = invalid_field.$el?.scrollIntoView ? invalid_field.$el : invalid_field.$el.parentElement;
      scroll_element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }
});

const table_error_section = ref(null);
provide('table_error_section', table_error_section);
// submit button

const is_submitting = ref(false);

async function Submit() {
  try {
    is_submitting.value = true;
    await form_detail_store?.submit_form(form_uid, { body: { submission: { status: 'filled' } }, attribute: `${form_uid}/submit` });
    emitter.emit('form_submit', { uid: form_uid, data: form_detail_store.forms_list_store.forms_map[form_uid] });
    emptyRoute();
  }
  catch (error) {
    if (error?.data?.code === 'FORMS_503')
      table_error_section.value = { uid: error?.data?.errors?.[0]?.section_uid, field_name: error?.data?.errors?.[0]?.field_name };
  }
  is_submitting.value = false;
}

const is_modal_open = ref(true);
const is_loading = ref(true);

// approval status
function form_approval() {
  if (form_detail_store?.form_detail?.is_approval_pending) {
    const approver = form_detail_store?.form_detail?.approvers?.filter(
      user => auth_store?.logged_in_user_details?.user_id === user.uid,
    );
    if (!form_detail_store?.form_detail?.approval)
      return {
        pending: form_detail_store?.form_detail?.is_approval_pending,
        show_waiting: true,
      };

    const difference = Number.parseInt($date_relative(form_detail_store?.form_detail.approval?.due_date, true).split(' ')[0]);
    return {
      pending: form_detail_store?.form_detail?.is_approval_pending,
      show_waiting: approver.length === 0,
      outcome: approver[0]?.outcome ? approver[0]?.outcome : '',
      action_complete:
              approver.length
              && (approver[0].approver
                ? approver[0].outcome !== null
                : approver[0].outcome),
      is_reviewer: approver.length && !approver[0].approver,
      due_date: $date(form_detail_store?.form_detail?.approval?.due_date, 'Do MMMM, YYYY'),
      is_overdue: difference < 0,
      ...(approver.length ? { approved: approver[0].outcome } : {}),
    };
  }
  return {};
}

const computed_form_approval = computed(form_approval);

function emptyRoute() {
  const query = { ...route.query };
  delete query.form;
  delete query.form_id;
  delete query.organization;
  router.replace({ query });
}

function templateRoute() {
  router.push({ name: 'form-template-detail-builder', params: { template_uid: form_detail_store?.form_detail?.template?.uid } });
}
async function updateFormData(data) {
  const { category, start_date, due_date, description, tags, priority } = data;
  const payload = {
    uid: form_uid,
    assignees: data?.assignees,
    start_date,
    due_date,
    tags,
    description,
    category,
    priority,
  };

  // INFO: Handle 'not_set' with null map in BE for old forms
  if (payload?.priority === 'not_set')
    payload.priority = null;

  Object.keys(payload).forEach(key => payload[key] === undefined && delete payload[key]);
  await form_detail_store.update_form_details({ body: payload });
}

function update_checklist({ field, checklist }) {
  const checklist_element = form$.value.el$(field.uid);
  checklist_element.update(checklist);
}

function onFormUpdated(event) {
  if (event.type === 'upload_started')
    uploading.value = true;
  else if (event.type === 'upload_completed')
    uploading.value = false;
}

function formMounted(form$) {
  form$.disableValidation();
}

async function getData() {
  try {
    if (is_preview) {
      is_modal_open.value = true;
      is_loading.value = true;
      if (!form_template_detail_store.form_template_detail.uid)
        emptyRoute();
      const template = form_template_detail_store.form_template_detail;
      form_detail_store.form_detail = {
        ...STATIC_FORM_DATA,
        name: template.name,
        template,
        visibility: template.visibility,
        ...template,
      };
      form_detail_store.form_template = template;
      is_loading.value = false;
      return;
    }

    if (form_detail_store.forms_list_store?.forms_map[form_uid]) {
      const form_detail = form_detail_store.forms_list_store?.forms_map[form_uid];
      delete form_detail.sections;
      delete form_detail_store.form_template.sections;
      form_detail_store.form_detail = form_detail;
    }
    is_loading.value = true;
    is_modal_open.value = true;
    if (route.query.submission_history) {
      await form_detail_store.set_submission_history({ attribute: `history/${route.query.submission_history}` });
    }
    else {
      await form_detail_store.set_form({ id: form_uid, query: { archived } });
      form_detail_store.forms_list_store.forms_map[form_uid] = form_detail_store.form_detail;
    }
    if (form_detail_store.forms_list_store?.forms_map[form_uid])
      form_detail_store.forms_list_store.forms_track_events(route.query.submission_history ? 'Viewed past submission' : 'Viewed', form_uid, track_event_view ? { view: track_event_view } : {});
    form_detail_store.track_event_view = track_event_view;
    is_saved.value = form_detail_store?.form_detail?.status?.name !== 'Open';
    is_loading.value = false;
    setTimeout(() => {
      form$.value?.enableValidation();
    }, 500);
  }
  catch (e) {
    emptyRoute();
    is_loading.value = false;
    logger.log(e);
  }
}

getData();

onBeforeUnmount(() => {
  is_modal_open.value = false;
  form_detail_store.reset();
});
</script>

<template>
  <hawk-modal-container v-model="is_modal_open" content_class="w-full h-screen !rounded-none" :options="{ teleportTo: teleport_to }" @close="emptyRoute()">
    <hawk-loader v-if="is_loading" class="h-full" />
    <div v-else-if="form_detail_store.form_detail.uid" id="form-details" class="flex-col flex  h-full">
      <div class="flex-col flex h-full">
        <FormDetailHeader :is_preview_mode="is_read_only" @close="emptyRoute()" />
        <Splitpanes class="default-theme">
          <Pane min-size="30" size="33" class="p-8 scrollbar !bg-white">
            <FormDetailLeftSplitpanes :is_preview_mode="is_read_only" @formData="$event => updateFormData($event)" />
          </Pane>
          <Pane min-size="30" class="!bg-white">
            <div>
              <Vueform
                ref="form$"
                :endpoint="Submit"
                :display-errors="false"
                :columns="{
                  default: { container: 6, wrapper: 8, label: 6 },
                  sm: { container: 12, label: 12, wrapper: 12 },
                  md: { container: 8, label: 4, wrapper: 8 },
                }"
                :attachment_config="{
                  meta: {
                    service: 'forms',
                    id: form_detail_store.form_detail.uid,
                  },
                }"
                :messages="{
                  required: 'This field is required',
                }"
                :remove-classes="{ ElementLabel: { container_horizontal_sm: 'text-type:form-pt-input-border-sm', container_horizontal_sm_SM: 'sm:text-type:form-pt-input-border-sm', container_horizontal_sm_MD: 'md:text-type:form-pt-input-border-sm' } }"
                size="sm"
                :templates="{ TextElement: CustomVueformTextElement, FilePreview: HawkFilePreviewGrid }"
                @change="is_saved = false;has_form_changed = true"
                @mounted="formMounted"
                @updated="onFormUpdated"
              >
                <div class="col-span-12 flex flex-col h-[calc(100vh-80px)]">
                  <hawk-modal-content v-if="form_detail_store.form_template.sections" class="overflow-auto flex-1 scrollbar !max-h-none">
                    <div v-for="(step, idx) in getSteps()" :key="idx">
                      <FormDetailStep :step_name="step.name" :step_index="step.index" :is_preview_mode="is_read_only">
                        <template #step_sections="slot_props">
                          <FromDetailSections :form$="form$" :is_preview="is_preview" :step="step" :is_step_submitted="slot_props.is_step_submitted || form_detail_store.form_detail.is_approval_pending" class="py-2" @update_checklist="update_checklist" />
                        </template>
                      </FormDetailStep>
                    </div>
                    <FromDetailSections v-if="Object.keys(form_detail_store.steps_with_sections).length <= 0" :form$="form$" :is_preview="is_preview" @update_checklist="update_checklist" />
                  </hawk-modal-content>
                  <hawk-loader v-else-if="is_loading" class="h-full" />
                  <hawk-modal-footer v-if="!is_read_only && form_detail_store.form_template.sections" class="px-8">
                    <template #right>
                      <div v-if="form_detail_store.form_template.status !== 'published'" class="flex p-4 border border-[#FEC84B] rounded-lg bg-[#FFFCF5] justify-between items-center">
                        <div class="flex items-center">
                          <span class="mr-2">  <icon-hawk-alert-triangle class="text-[#DC6803]" /></span>
                          <span class="text-[#B54708] text-sm font-semibold mr-1">{{ $t('Please publish the template to make any operations') }}</span>
                        </div>

                        <span class="text-[#B54708] cursor-pointer text-sm font-semibold" @click="templateRoute()">{{ $t('Go to template') }}</span>
                      </div>
                      <div v-else>
                        <!-- approval form -->
                        <div v-if="form_approval().pending">
                          <ApprovalBlock :key="computed_form_approval" :form_approval="computed_form_approval" />
                        </div>
                        <!-- reopen form -->
                        <div v-else>
                          <div class="col-span-12">
                            <div v-if="is_submitted" class="flex items-center justify-between w-full">
                              <div class="flex items-center">
                                <IconSubmitted class="mr-2 text-success-500" />
                                <div class="content-center text-sm mr-1 text-[#667085]">
                                  {{ $t('Submitted by') }}:
                                </div> <HawkMembers :members="form_detail_store?.form_submissions?.submitted_by" type="badge" /> <span class=" text-[#101828] text-sm font-normal ml-1">
                                  on
                                  {{ form_detail_store?.form_submissions?.submitted_at ? new Date(form_detail_store?.form_submissions?.submitted_at).toLocaleDateString("en-US", {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit",
                                    hour12: true,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  }) : null }}
                                </span>
                              </div>

                              <HawkButton v-if="form_detail_store?.form_detail?.can_modify" type="outlined" text="Cancel" @click="openReopenPopup">
                                {{ $t('reopen') }}
                              </HawkButton>
                            </div>
                            <div v-if="form_detail_store?.form_detail?.reverted" class="flex mb-4 items-center">
                              <span class="mr-2"> <IconWarn class="text-sm text-[#DC6803]" /></span><span><HawkMembers class="mr-1" :members="form_detail_store?.form_detail?.reverted?.owner" type="badge" /></span><span v-if="form_detail_store?.form_detail?.reverted.type === 'rolledBack'" class="mr-1 text-sm text-[#DC6803]">{{ $t('rolledback the form on') }} </span><span v-else class="mr-1 text-sm text-[#DC6803]">{{ $t('reopened the form on') }}</span><span class="text-sm font-semibold">{{ $date(form_detail_store?.form_detail?.reverted?.time_stamp, "L_DATETIME_MED") }},</span> <span class="mx-1 text-sm text-[#DC6803]">{{ $t('with a note') }} </span> "<span class=" text-sm font-semibold">{{ form_detail_store?.form_detail?.reverted?.message }}"</span>
                            </div>
                            <div class="flex justify-between w-full items-center">
                              <!-- rollback form  -->
                              <div>
                                <hawk-button v-if="allow_rollback" class="flex cursor-pointer items-center " type="text" @click="openRollbackStepPopup">
                                  <IconRollback class="mr-2 w-4.5 h-4.5" />
                                  <div class="text-gray-600 font-semibold text-sm">
                                    {{ $t('Rollback to previous step') }}
                                  </div>
                                </hawk-button>
                              </div>
                              <div class="flex">
                                <HawkButton v-if="!is_submitted" type="outlined" text="Cancel" @click="emptyRoute()">
                                  {{ $t('Cancel') }}
                                </HawkButton>
                                <template v-if="!is_submitted && form_detail_store.form_detail.can_submit">
                                  <HawkButton v-if="!is_submitting && !is_saved" :loading="is_saving || uploading" :disabled="(!form$?.dirty && !has_form_changed) || is_saving || uploading" class="ml-4" data-dd-action-name="form-save" @click="Save">
                                    {{ $t('Save') }}
                                  </HawkButton>
                                  <ButtonElement
                                    v-else
                                    data-dd-action-name="form-submit"
                                    name="submit" :submits="true" class="ml-4" button-class="bg-blue-600" :add-classes="{
                                      ElementLayout: { outerWrapper: 'block', innerContainer: 'block' },
                                    }"
                                    :remove-classes="{
                                      ElementLayout: { outerWrapper: 'grid', innerContainer: 'grid' },
                                    }"
                                  >
                                    {{ $t('submit') }}
                                  </ButtonElement>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </hawk-modal-footer>
                </div>
              </Vueform>
            </div>
          </Pane>
        </Splitpanes>
      </div>
    </div>
  </hawk-modal-container>
</template>
