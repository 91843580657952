<script setup>
const props = defineProps({
  title: {
    type: String,
  },
  value: {
    type: [String, Number],
    required: true,
  },
});

const number_value = computed(() => {
  if (typeof props.value === 'number')
    return props.value;

  return 0;
});
</script>

<template>
  <div class="px-3 pb-3 pt-0">
    <div
      class="text-lg font-semibold text-error-700"
      :class="{ 'text-success-700': number_value > 1 }"
    >
      {{ props.value ?? 0 }}
    </div>
  </div>
</template>

<style>

</style>
