import { defineStore } from 'pinia';
import { keyBy } from 'lodash-es';

const transmittal_status = [
  {
    name: 'Pending review',
    slug: 'pending_review',
    class: 'bg-gray-400',
    color: '#98A2B3',
    disabled: true,
    active: null,
  },
  {
    name: 'Reviewed',
    slug: 'reviewed',
    class: 'bg-primary-600',
    color: '#1570EF',
    active: null,
  },
  {
    name: 'Approved',
    slug: 'approved',
    class: 'bg-success-600',
    color: '#039855',
    active: null,
  },
  {
    name: 'Approve with comments',
    slug: 'approved_with_comments',
    class: 'bg-green-300',
    color: '#73E2A3',
    active: null,
  },
  {
    name: 'Revise & Resubmit',
    slug: 'revise_resubmit',
    class: 'bg-orange-400',
    color: '#F38744',
    active: null,
  },
  {
    name: 'Rejected',
    slug: 'rejected',
    class: 'bg-error-500',
    color: '#F04438',
    active: null,
  },
];

export function useDMSSettingsStore(key) {
  return defineStore(key ? `${key}-settings` : 'DMS-settings', {
    state: () => ({
      custom_fields: [],
      document_status: [],
      configuration: {},
      issue_purposes: [],
      transmittal_status,
    }),
    getters: {
      active_document_status: (state) => {
        return state.document_status.filter(status => status.active);
      },
      review_status_list: (state) => {
        const default_statuses = [...state.transmittal_status];
        return default_statuses.map((status) => {
          const config = state.configuration?.config || {};
          return {
            ...status,
            name: config[status.slug] || status.name,
          };
        });
      },
      document_status_map: (state) => {
        return keyBy(state.document_status, 'uid');
      },
    },
    actions: {
      async set_custom_fields(options = { query: {} }) {
        const { data } = await this.$services.dms.getAll({
          attribute: 'custom-fields',
          query: options.query,
        });
        this.custom_fields = data.custom_fields;
      },
      async add_custom_fields(payload) {
        const { data } = await this.$services.dms.post({
          attribute: 'custom-fields',
          body: { custom_fields: payload },
        });
        this.custom_fields = [...this.custom_fields, ...(data.custom_fields || [])];
      },
      async update_custom_fields(uid, index, payload = {}) {
        const { data } = await this.$services.dms.patch({
          attribute: `custom-fields/${uid}`,
          body: { custom_fields: payload },
        });
        this.custom_fields[index] = data.custom_fields[0];
      },
      async delete_custom_fields(uid, index) {
        await this.$services.dms.delete({
          attribute: `custom-fields/${uid}`,
        });
        this.custom_fields.splice(index, 1);
      },
      async activate_custom_fields(uid) {
        await this.$services.dms.post({
          attribute: `custom-fields/${uid}/activate`,
        });
      },
      async deactivate_custom_fields(uid) {
        await this.$services.dms.post({
          attribute: `custom-fields/${uid}/deactivate`,
        });
      },
      async fetch_all_issue_purposes() {
        const { data } = await this.$services.dms.getAll({
          attribute: 'issue-purposes',
        });
        this.issue_purposes = data.issuePurposes;
        return data.issuePurposes;
      },
      async create_issue_purposes(issue_purpose) {
        try {
          const { data } = await this.$services.dms.post({
            attribute: 'issue-purposes',
            body: { issue_purpose },
          });

          if (data?.issuePurposes?.[0])
            this.issue_purposes.push(data.issuePurposes[0]);

          this.$toast({
            title: 'New document status added successfully',
            type: 'success',
          });

          return data.issuePurposes;
        }
        catch (error) {
          this.$toast({
            title: 'Something went wrong',
            text: error.data?.description ?? '',
            type: 'error',
          });
          return error;
        }
      },
      async update_issue_purposes(status_id, issue_purpose) {
        try {
          const { data } = await this.$services.dms.patch({
            attribute: `issue-purposes/${status_id}`,
            body: { issue_purpose },
          });

          return data.issuePurposes;
        }
        catch (error) {
          this.$toast({
            title: 'Something went wrong',
            text: error.data?.description ?? '',
            type: 'error',
          });
          return error;
        }
      },
      async delete_issue_purposes(id, payload) {
        try {
          await this.$services.dms.delete({
            attribute: `issue-purposes/${id}`,
            body: { ...payload },
          });
        }
        catch (error) {
          // Document status used in some documents error
          if (error.data?.errorCode === 'DMS_990.1')
            return error.data;
          else
            this.$toast({
              title: 'Something went wrong',
              text: error.data?.description ?? '',
              type: 'error',
            });
        }
      },
      async fetch_all_statuses() {
        const { data } = await this.$services.dms.getAll({
          attribute: 'document-statuses',
        });

        this.document_status = data.document_statuses;

        return data.document_statuses;
      },
      async create_document_statuses(document_status) {
        try {
          const { data } = await this.$services.dms.post({
            attribute: 'document-statuses',
            body: { document_status },
          });

          this.$toast({
            title: 'New document status added successfully',
            type: 'success',
          });

          return data.document_statuses;
        }
        catch (error) {
          logger.error(error);
          this.$toast({
            title: 'Something went wrong',
            text: error.data?.description ?? '',
            type: 'error',
          });
          return error;
        }
      },
      async update_document_status(status_id, document_status) {
        try {
          const { data } = await this.$services.dms.patch({
            attribute: `document-statuses/${status_id}`,
            body: { document_status },
          });

          return data.document_statuses;
        }
        catch (error) {
          logger.error(error);
          this.$toast({
            title: 'Something went wrong',
            text: error.data?.description ?? '',
            type: 'error',
          });
          return error;
        }
      },
      async delete_document_status(status_id, payload) {
        try {
          await this.$services.dms.delete({
            attribute: `document-statuses/${status_id}`,
            body: { ...payload },
          });
        }
        catch (error) {
          // Document status used in some documents error
          if (error.data?.errorCode === 'DMS_990.1')
            return error.data;
          else
            this.$toast({
              title: 'Something went wrong',
              text: error.data?.description ?? '',
              type: 'error',
            });
        }
      },
      async activate_document_status(uid) {
        await this.$services.dms.post({
          attribute: `document-statuses/${uid}/activate`,
        });
      },
      async deactivate_document_status(uid) {
        await this.$services.dms.post({
          attribute: `document-statuses/${uid}/deactivate`,
        });
      },
      async set_configuration() {
        const { data } = await this.$services.dms.getAll({
          attribute: 'configuration',
          query: {
            allowed_in: 'organization',
          },
        });
        if (data?.configuration?.length)
          this.configuration = data?.configuration[0];
      },
      async add_configuration(payload) {
        try {
          const { data } = await this.$services.dms.post({
            attribute: 'configuration',
            body: { configuration: payload },
          });
          if (data?.configuration?.length) {
            this.configuration = data?.configuration[0];
            this.$toast({
              title: 'Configuration has been created successfully',
              type: 'success',
            });
          }
        }
        catch (error) {
          logger.error(error);
          this.$toast({
            title: 'Something went wrong',
            text: error?.data?.description ?? '',
            type: 'error',
          });
        }
      },
      async update_configuration(uid, payload) {
        try {
          const { data } = await this.$services.dms.patch({
            attribute: `configuration/${uid}`,
            body: { configuration: payload },
          });
          if (data?.configuration?.length) {
            this.configuration = data.configuration[0];
            this.$toast({
              title: 'Configuration has been updated successfully',
              type: 'success',
            });
          }
        }
        catch (error) {
          logger.error(error);
          this.$toast({
            title: 'Something went wrong',
            text: error?.data?.description ?? '',
            type: 'error',
          });
        }
      },
    },
  })();
}
