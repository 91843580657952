<script setup>
// --------------------------------- Imports -------------------------------- //
import { isEmpty } from 'lodash-es';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  header: {
    type: String,
    required: true,
  },
  sub_header: {
    type: String,
    default: '',
  },
  custom_fields: {
    type: Array,
    default: () => [],
  },
  document: {
    type: Object,
    default: null,
  },
  document_status: {
    type: Object,
    default: null,
  },
  document_status_options: {
    type: Array,
    default: () => [],
  },
  field_values_map: {
    type: Object,
    default: () => {},
  },
  save: {
    type: Function,
    required: true,
  },
  has_active_transmittal: {
    type: Boolean,
    default: false,
  },
});

// ---------------------------------- Emits --------------------------------- //
const emit = defineEmits(['close']);

// ------------------------ Variables - Local - refs ------------------------ //
const form$ = ref(null);

// -------------------------------- Functions ------------------------------- //
async function onSave() {
  const data = { ...form$.value.data, name: form$.value.data.name + getFileExtension() };
  await props.save(data);
  emit('close');
}
function onFormMounted(el$) {
  if (!isEmpty(props?.field_values_map)) {
    const field_values = {};
    for (const field_key in props.field_values_map)
      field_values[field_key] = props.field_values_map[field_key].value;

    el$.load({ field_values, document_status: props.document_status, category: props.document.category, tags: props.document.tags, number: props.document?.number, name: calculateDefault() });
  }
}

function getFileExtension() {
  if (props.document?.type === 'file' && props.document?.name.split('.')[1])
    return `.${props.document.name?.split('.').pop()}`;
  else return '';
}

function calculateDefault() {
  if (props.document?.type === 'file' && props.document?.name.split('.')[1])
    return props.document.name?.split('.').slice(0, -1).join('.');
  else
    return props.document.name;
}

function setCreatedCategory(e) {
  form$.value.elements$.category.load(e.uid);
}

function setCreatedTags(e) {
  form$.value.elements$.tags.load([...form$.value.data.tags, e.uid]);
}
</script>

<template>
  <HawkModalContainer content_class="rounded-lg">
    <Vueform
      ref="form$"
      size="sm"
      :display-errors="false"
      :columns="{
        default: { container: 12, label: 3, wrapper: 12 },
        sm: { container: 12, label: 3, wrapper: 12 },
        md: { container: 12, wrapper: 12, label: 3 },
      }"
      :endpoint="onSave"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #header>
            <div
              class="flex items-start p-6 border-b border-b-gray-200 justify-between text-lg font-semibold text-gray-800"
            >
              <div class="flex items-start">
                <div class="flex flex-col justify-start">
                  {{ header }}
                  <span v-if="sub_header" class="font-normal text-sm text-gray-600">
                    {{ sub_header }}
                  </span>
                </div>
              </div>
              <div class="flex font-normal items-center justify-center -m-2">
                <div
                  class="text-gray-600 rounded-md cursor-pointer flex justify-center items-center p-2 ml-3 hover:bg-gray-50"
                  @click="$emit('close')"
                >
                  <IconHawkXClose class="w-6 h-6 text-gray-500 hover:text-gray-900" />
                </div>
              </div>
            </div>
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <TextElement
            v-if="document" class="mb-4" name="name" :default="calculateDefault()" :label="$t('Name')"
            rules="required"
            :columns="{
              default: { container: 12, wrapper: 6, label: 12 },
              sm: { container: 12, label: 6, wrapper: 12 },
              md: { container: 12, label: 6, wrapper: 12 },
            }"
          />
          <TextElement
            v-if="document" class="mb-4" name="number" :label="$t('Number')" :default="document.number"
            :columns="{
              default: { container: 12, wrapper: 6, label: 12 },
              sm: { container: 12, label: 6, wrapper: 12 },
              md: { container: 12, label: 6, wrapper: 12 },
            }"
          />
          <SelectElement
            class="mb-4"
            :disabled="has_active_transmittal"
            name="document_status"
            label-prop="name"
            value-prop="uid"
            :label="$t('Status')"
            :native="false"
            :object="true"
            :items="document_status_options"
            :columns="{
              default: { container: 12, wrapper: 6, label: 12 },
              sm: { container: 12, label: 6, wrapper: 12 },
              md: { container: 12, label: 6, wrapper: 12 },
            }"
          />
          <hawk-category-input
            :options="{
              name: 'category',
              label: $t('Category'),
              placeholder: $t('Select Category'),
              create: true,
              from: 'DMS',
            }"
            :columns="{
              default: { container: 12, wrapper: 6, label: 12 },
              sm: { container: 12, label: 6, wrapper: 12 },
              md: { container: 12, label: 6, wrapper: 12 },
            }"
            :setter_function="setCreatedCategory"
          />
          <hawk-tags-input
            :options="{
              name: 'tags',
              label: $t('Tags'),
              placeholder: $t('Select Tags'),
              create: true,
              from: 'DMS',
            }"
            :columns="{
              default: { container: 12, wrapper: 6, label: 12 },
              sm: { container: 12, label: 6, wrapper: 12 },
              md: { container: 12, label: 6, wrapper: 12 },
            }"
            :setter_function="setCreatedTags"
            class="my-4"
          />
          <hawk-custom-fields-input
            class="mb-8"
            name="field_values"
            :configuration="custom_fields"
            :columns="{
              default: { container: 12, wrapper: 6, label: 12 },
              sm: { container: 12, label: 6, wrapper: 12 },
              md: { container: 12, label: 6, wrapper: 12 },
            }"
            :disable_edit="true"
          />
          <span v-if="has_active_transmittal" class="text-sm">
            <span class="font-semibold">{{ $t('Note') }}</span>:
            {{ $t(`Status changes can be made only for the document${document ? '' : 's'} without an active transmittal.`) }}
          </span>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="col-span-12">
              <div class="flex justify-end w-full">
                <HawkButton type="outlined" class="mr-4" @click="emit('close')">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement submits size="sm" name="submit" :button-label="$t('Save')" button-class="vf-btn-primary" />
              </div>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
