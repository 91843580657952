<script setup>
import { ref } from 'vue';
import { useModal } from 'vue-final-modal';
import { filter } from 'lodash-es';
import { useRoute } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { searchData, sortRowsByColumn } from '~/common/utils/common.utils.js';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import createCategory from '~/account-settings/components/categories/category-form.vue';
import HawkDeletePopup from '~/common/components/organisms/hawk-delete-popup.vue';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

const $t = inject('$t');
const auth_store = useAuthStore();
const common_store = useCommonStore();
const route = useRoute();

const search = ref('');
const active_category = ref({});
const action_type = ref('');

const columns = [
  {
    header: $t('Name'),
    accessorKey: 'uid',
    id: 'uid',
    cell: info => info.getValue(),
    sortingFn: sortRowsByColumn,
  },
  {
    header: $t('Description'),
    accessorKey: 'description',
    id: 'description',
    cell: info => info.getValue(),
    columns: [],
  },
  {
    accessorKey: 'context_menu',
    header: '',
    id: 'context_menu',
    maxSize: 10,
    show_on_hover: 'true',
  },
];

function getChildren(row) {
  return common_store?.categories.filter((item) => {
    return item.parent === row.uid;
  });
}

const filtered_data = computed(() =>
  searchData(
    common_store
      ?.scope_categories(route?.params?.asset_id, 'list')
      .filter(item => !item.parent),
    search.value,
  ),
);

const { open: openCategoryModal, close: closeNormalModal } = useModal({
  component: createCategory,
  attrs: {
    category: active_category,
    action_type,
    onClose() {
      active_category.value = {};
      action_type.value = '';
      closeNormalModal();
    },
  },
});

const { open: openDeletePopup, close: closeDeletePopup, patchOptions } = useModal({
  component: HawkDeletePopup,
});

function categoryDeleteHandler({ name, uid }) {
  patchOptions({
    attrs: {
      header: $t('Delete Category'),
      content: `Are you sure you want to delete ${
        name || ''
      }? This action cannot be undone.`,
      onClose() {
        closeDeletePopup();
      },
      confirm: async () => {
        try {
          await common_store.update_data({
            type: 'delete',
            id: uid,
            service: 'categories',
            append_data: true,
            state_prop: 'categories_map',
            update_state: true,
          });
          closeDeletePopup();
        }
        catch (err) {
          $toast({
            title: 'Something went wrong',
            text: 'Please try again',
            type: 'error',
          });
        }
      },
    },
  });
  openDeletePopup();
}

async function onActionClicked(type, category) {
  try {
    active_category.value = category;
    action_type.value = type;
    switch (type) {
      case 'add_subcategory':
      case 'edit':
        setTimeout(() => {
          openCategoryModal();
        }, 100);
        break;

      case 'delete':
        categoryDeleteHandler(category);
        break;
    }
  }
  catch (error) {
    logger.log('error', error);
  }
}

function getMenu(item) {
  const menu = [
    ...[
      !item?.parent?.length
        ? {
            label: $t('Add Subcategory'),
            uid: 'add_subcategory',
            on_click: () => {},
          }
        : null,
    ],
    {
      label: $t('Edit'),
      uid: 'edit',
      on_click: () => {},
    },
    {
      label: $t('Delete'),
      uid: 'delete',
      on_click: () => {},
    },
  ];
  return filter(menu, o => o !== null);
}
</script>

<template>
  <div class="w-full">
    <HawkPageHeader class="!px-0" :class="{ '!pt-0': !route?.params?.asset_id }">
      <template #title>
        <div>
          <div>{{ $t('Categories') }}</div>
          <p class="text-sm text-gray-600 font-normal">
            {{ $t('Setup categories and sub-categories and attach to resources like tasks, forms, documents, etc to efficiently segregate') }}
          </p>
        </div>
      </template>
      <template #right>
        <HawkButton
          v-if="auth_store.check_permission('modify_categories', $route.params.asset_id)"
          color="primary"
          @click="
            () => {
              action_type = 'add';
              openCategoryModal();
            }
          "
        >
          <IconHawkPlus class="text-white" />
          <span>{{ $t('New Category') }}</span>
        </HawkButton>
      </template>
    </HawkPageHeader>

    <HawkPageSecondaryHeader>
      <template #left>
        <HawkSearchInput
          v-model="search"
          :placeholder="$t('Search category')"
        />
      </template>
    </HawkPageSecondaryHeader>

    <div v-if="!filtered_data?.length">
      <HawkIllustrations v-if="search" type="no-results" for="categories" />
      <HawkIllustrations v-else type="no-data" for="categories" />
    </div>
    <div v-else-if="filtered_data?.length">
      <TableWrapperVue :key="filtered_data.length" container_class="my-4">
        <HawkTable
          :pagination_config="{ totalRows: filtered_data.length, pageSize: 25 }"
          :data="filtered_data"
          :columns="columns"
          :is_loading="false"
          :default_height="500"
          :get-children="getChildren"
          is_gapless
          :show_menu_header="false"
          :non_sortable_columns="['context_menu']"
          @rowClicked="onActionClicked('edit', $event)"
        >
          <template #uid="uid">
            <HawkCategoryName v-if="uid.data.getValue()" :uid="uid.data.getValue()" class="font-medium text-gray-900" />
          </template>
          <template #context_menu="category">
            <HawkMenu v-if="auth_store.check_permission('modify_categories', $route.params.asset_id)" :items="getMenu(category.data.row.original)" position="fixed" class="z-[999]" @click.stop>
              <template #trigger>
                <IconHawkDotsVertical />
              </template>
              <template #content>
                <div class="p-1 w-44 flex flex-col">
                  <HawkButton
                    v-for="item in getMenu(category.data.row.original)"
                    :key="item.label"
                    type="text"
                    @click="onActionClicked(item.uid, category.data.row.original)"
                  >
                    {{ item.label }}
                  </HawkButton>
                </div>
              </template>
            </HawkMenu>
          </template>
        </HawkTable>
      </TableWrapperVue>
    </div>
  </div>
</template>
