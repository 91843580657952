<script setup>
import { computed, inject, reactive } from 'vue';

import dayjs from 'dayjs';

import { useRoute, useRouter } from 'vue-router';
import { sum } from 'lodash-es';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useThermHelperComposable } from '~/therm/composables/helper-composable.js';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
});

const $services = inject('$services');
const route = useRoute();
const $t = inject('$t');
const router = useRouter();
const common_store = useCommonStore();
const { editGroupDetails, getAffectedAndTotalMetrics, getThermTableColumns } = useThermHelperComposable();

const state = reactive({
  is_loading: false,
});

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

const asset_id = computed(() => props.data?.data?.asset_id || null);

const asset_meta_data = computed(() => common_store.asset_meta[asset_id.value]);

async function getTerraData() {
  try {
    const terraResponse = await $services.terra_view_service.getAll({
      query: {
        asset: asset_id.value,
      },
    });

    common_store.asset_meta[asset_id.value] = {
      ...common_store.asset_meta[asset_id.value],
      terra_list: terraResponse.data || [],
    };
  }
  catch (err) {
    logger.log(err);
  }
}

async function getThermData() {
  try {
    const thermResponse = await $services.therm.getAll({
      query: {
        assetUid: asset_id.value,
        metrics: true,
        complete: true,
        groups: true,
      },
      attribute: 'views',
    });

    common_store.asset_meta[asset_id.value] = {
      ...common_store.asset_meta[asset_id.value],
      therm_list: thermResponse.data || [],
    };
  }
  catch (err) {
    logger.log(err);
  }
}

async function getData() {
  state.is_loading = true;
  await getTerraData();
  await getThermData();
  state.is_loading = false;
}

onMounted(() => {
  setTimeout(() => {
    getData();
  }, 100);
});

const terra_columns = computed(() => {
  return [
    {
      id: 'name',
      header: $t('Name'),
      accessorKey: 'name',
      enableSorting: false,
      enableResizing: false,
    },
    {
      id: 'description',
      header: $t('Description'),
      accessorKey: 'description',
      enableSorting: false,
      enableResizing: false,
    },
    // {
    //   id: 'active',
    //   header: 'Active',
    //   accessorKey: 'active',
    //   enableSorting: false,
    //   enableResizing: false,
    // },
  ];
});

function handleRowClick(e, name, row_obj) {
  const parent_row = row_obj?.getParentRow();
  if (parent_row)
    router.push({
      name,
      params: { asset_id: asset_id.value, id: parent_row.original?.uid },
      query: {
        group: e.uid,
      },
    });
  else router.push({
    name,
    params: { asset_id: asset_id.value, id: e.uid },
  });
}

function total_issues(issues) {
  return sum(Object.values({ ...issues, table: 0 }));
}

function getThermSubRows(row) {
  if (Array.isArray(row.groups) && row.groups?.length) {
    const sorted_groups = row.groups.sort((a, b) => new Date(b?.properties?.date) - new Date(a?.properties?.date));
    return sorted_groups.map(item => ({
      ...item,
      name: item?.properties?.date ? dayjs(item.properties.date).format('DD-MM-YYYY') : item.name,
    }));
  };
  return [];
}

watch(asset_id, () => getData());
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <div>
      <div v-if="!asset_meta_data?.therm_list?.length && !asset_meta_data?.terra_list?.length && !state.is_loading" class="text-sm font-semiBold flex justify-center w-full mt-8">
        {{ $t('No data present') }}
      </div>

      <div v-else :style="{ height: `${content_height - 48 || height}px` }" class="scrollbar">
        <div v-if="asset_meta_data?.therm_list?.length" class="p-4 w-full">
          <p class="font-semibold text-lg mb-6 text-gray-900">
            {{ $t('Therm') }}
          </p>

          <div class="mt-4">
            <div v-if="state.is_loading">
              <hawk-loader />
            </div>
            <TableWrapperVue v-else-if="asset_meta_data?.therm_list?.length" class="min-h-[15rem]">
              <HawkTable
                is_gapless
                :disable_resize="true"
                :data="asset_meta_data.therm_list"
                :columns="getThermTableColumns()"
                :get-children="getThermSubRows"
                :show_menu_header="false"
                sub_rows_key="groups"
                class="max-w-full"
                @row-clicked="(original, event, row_obj) => handleRowClick(original, 'therm', row_obj)"
              >
                <template #name="name">
                  <p class="font-medium text-sm">
                    {{ name.data.row.original.name }}
                  </p>
                </template>
                <template #description="description">
                  <p v-if="description.data.row.original.description" class="text-gray-600 text-sm">
                    {{ description.data.row.original.description }}
                  </p>
                  <p v-else class="text-gray-600 text-sm">
                    {{ $t('No description added') }}
                  </p>
                </template>
                <template #affected="affected">
                  <p class="text-gray-600 text-sm">
                    {{ getAffectedAndTotalMetrics(affected.data.row.original).affected_capacity }}KW
                  </p>
                </template>
                <template #total_dc_capacity="total_dc_capacity">
                  <p class="text-gray-600 text-sm">
                    {{ getAffectedAndTotalMetrics(total_dc_capacity.data.row.original).total_dc_capacity }}MW
                  </p>
                </template>
                <template #issues="issues">
                  <p class="text-gray-600 text-sm">
                    {{ total_issues(issues.data.row.original.metrics.issues) }}
                  </p>
                </template>
                <template #action_button="{ data: group_details }">
                  <div
                    v-if="group_details.row.original?.projects"
                    class="cursor-pointer"
                    @click.stop="editGroupDetails(group_details.row.original, group_details.row.getParentRow().original)"
                  >
                    <IconHawkPencilOne class="text-gray-500 h-4.5 w-4.5" />
                  </div>
                </template>
              </HawkTable>
            </TableWrapperVue>
          </div>
        </div>
        <div v-if="asset_meta_data?.terra_list?.length" class="p-4 w-full">
          <p class="font-semibold text-lg mb-6 text-gray-900">
            {{ $t('Terra') }}
          </p>
          <div>
            <div v-if="state.is_loading">
              <hawk-loader />
            </div>

            <div v-else-if="asset_meta_data?.terra_list?.length" class="w-full">
              <HawkTable
                is_gapless
                :disable_resize="true"
                :data="asset_meta_data.terra_list"
                :columns="terra_columns"
                :show_menu_header="false"
                class="max-w-full"
                @row-clicked="(original, event, row_obj) => handleRowClick(original, 'terra-viewer', row_obj)"
              >
                <template #name="name">
                  <p class="font-medium text-sm">
                    {{ name.data.row.original.name }}
                  </p>
                </template>
                <template #description="description">
                  <p v-if="description.data.row.original.description" class="text-gray-600 text-sm">
                    {{ description.data.row.original.description }}
                  </p>
                  <p v-else class="text-gray-600 text-sm">
                    {{ $t('No description added') }}
                  </p>
                </template>
              </HawkTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
