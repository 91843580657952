<script setup>
import { debounce } from 'lodash-es';

const props = defineProps(['field']);

const $toast = inject('$toast');

const $services = inject('$services');
const addItem = ref(false);
const checklist = props.field?.config;
const debounced_save = debounce(save, 1000);

function addChecklistItems(items) {
  checklist.items = [...checklist.items, ...items];
  debounced_save();
}

async function save() {
  try {
    await $services.forms.patch({
      attribute: `fields/${props.field.uid}`,
      body: { field: { config: props.field.config } },
    });
  }
  catch (e) {
    $toast({ text: e?.data?.message || 'Checklist Updating failed!', type: 'error' });
  }
}

function update() {
  debounced_save();
}
</script>

<template>
  <div @click.stop>
    <div id="container" class="border-b border-gray-300 pb-3 last:border-b-0">
      <ol>
        <ChecklistItem
          class="my-2"
          :subitem_level="0"
          :items="checklist.items"
          :button_items="[]"
          :dropdown_items="[{
                              label: 'Rename',
                              emit: 'renameItem',
                            },
                            {
                              label: 'Add sub item',
                              emit: 'addSubItem',
                            },
                            {
                              label: 'Delete',
                              emit: 'deleteItem',
                            }]"
          @updateChecklist="update"
        />
        <ChecklistInput
          v-if="addItem" class="mx-5 mt-4" placeholder="Add item" :allow_multiple="true"
          @close="addItem = false"
          @add="addChecklistItems"
        />
        <hawk-button v-else type="outline" @click="addItem = true">
          + {{ $t('Add items') }}
        </hawk-button>
      </ol>
    </div>
  </div>
</template>

<style></style>
