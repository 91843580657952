<script setup>
import { useRoute } from 'vue-router';
import { groupBy } from 'lodash-es';
import sanitize from 'sanitize-s3-objectkey';
import { usePlansStore } from '~/plans/store/plans.store';

const props = defineProps({
  documents_to_upload: {
    type: Array,
    default: () => [],
  },
  versioning_form_value: {
    type: Object,
  },
  /* upload_new_version_flow is done from sheet version dropdown in sheets page */
  upload_new_version_flow: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['close']);

const route = useRoute();
const plans_store = usePlansStore();

const upload_btn_elem = ref(null);
const form = ref({ upload_pdf: props.documents_to_upload });
const s3_upload_progress = ref(0);

const drawing_name_options = computed(() => plans_store.get_all_drawing_names.map(drawing => drawing.name));

function onCloseHandler() {
  upload_btn_elem.value?.clear();
  emit('close');
}

function addFilesToUppy(el$) {
  nextTick(() => {
    props.documents_to_upload.forEach((value) => {
      try {
        const id = el$.form$.uppy.addFile({ data: value.file_blob });
        const file = el$.form$.uppy.getFile(id);
        file.meta.name = sanitize(file.meta.name);
        value.uppy_id = id;
      }
      catch (error) {
        logger.info(`[DEBUG] assigned-drawing-upload-modal.vue::47\n${error}`);
      }
    });

    el$.form$.uppy.on('progress', (progress) => {
      s3_upload_progress.value = progress;
    });
    el$.form$.uppy.on('complete', (result) => {
      if (props.upload_new_version_flow)
        uploadAndPatchNewSheet();
      else
        onSaveHandler(el$.form$);
    });
  });
}

async function onSaveHandler(form$) {
  const assigned_docs = [];
  const duplicate_docs = [];

  form$.data.upload_pdf.forEach((doc) => {
    if (doc.is_duplicate)
      duplicate_docs.push(doc);
    else
      assigned_docs.push(doc);
  });

  const assigned_documents = groupBy(assigned_docs, doc => doc.assigned_drawing);
  const assigned_drawing_names = Object.keys(assigned_documents);
  const duplicate_documents = groupBy(duplicate_docs, doc => doc.assigned_drawing);
  const duplicate_drawing_names = Object.keys(duplicate_documents);

  // Upload sheet to drawing for assigned flow
  for (let i = 0; i < assigned_drawing_names.length; i++) {
    const drawing_name = assigned_drawing_names[i];

    let drawing_uid = null;

    if (!drawing_name_options.value.includes(drawing_name))
      drawing_uid = await plans_store.create_drawing({
        drawing: {
          name: drawing_name,
          asset: route.params.asset_id,
        },
      });
    else
      drawing_uid = plans_store.get_all_drawing_names.find(drawing => drawing.name === drawing_name).uid;

    const sheets_to_upload = assigned_documents[drawing_name].map(doc => ({
      asset: route.params.asset_id,
      description: doc.page_data.desc_text,
      file_name: doc.page_data.title_text.replace(/[^\w ]+/g, '').replace(/ +/g, '-'),
      name: doc.page_data.title_text,
      new_version: false,
      height: Math.round(doc.page_data.page_height),
      width: Math.round(doc.page_data.page_width),
      rotation: doc.page_data.page_roataion,
      thumbnails: {
        small: doc.thumbnail,
      },
      service: doc.file_blob.service_object,
    }));

    await plans_store.upload_sheet_to_drawing({ sheets: sheets_to_upload }, drawing_uid);
  }

  // Patch sheet with new version flow
  for (let i = 0; i < duplicate_drawing_names.length; i++) {
    const drawing_name = duplicate_drawing_names[i];
    const drawing_uid = plans_store.get_all_drawing_names.find(drawing => drawing.name === drawing_name).uid;

    const sheets_to_patch = duplicate_documents[drawing_name].map(doc => ({
      uid: doc.existing_sheet_uid,
      asset: route.params.asset_id,
      thumbnails: {
        small: doc.thumbnail,
      },
      service: doc.file_blob.service_object,
      version_name: props.versioning_form_value.version_name || props.versioning_form_value[`version_name_${doc.existing_sheet_uid}`],
      version_date: props.versioning_form_value.version_date || props.versioning_form_value[`version_date_${doc.existing_sheet_uid}`],
      default_version: props.versioning_form_value.is_current_version || props.versioning_form_value[`is_current_version_${doc.existing_sheet_uid}`],
    }));

    await plans_store.patch_new_sheet_version({ sheets: sheets_to_patch }, drawing_uid);
  }

  plans_store.is_upload_flow = false;
  plans_store.uploaded_document_arr = [];
  plans_store.all_drawings = {};

  onCloseHandler();
}

async function uploadAndPatchNewSheet() {
  const drawing_uid = route.params.drawing_id;
  const sheet_uid = route.params.sheet_id;
  const sheets_to_patch = props.documents_to_upload.map(doc => ({
    uid: sheet_uid,
    service: doc.file_blob.service_object,
    version_name: props.versioning_form_value.version_name,
    version_date: props.versioning_form_value.version_date,
    default_version: props.versioning_form_value.is_current_version,
  }));

  await plans_store.patch_new_sheet_version(
    { sheets: sheets_to_patch },
    drawing_uid,
    {
      sheet_uid,
      default_version: props.versioning_form_value.is_current_version,
      thumbnail: props.documents_to_upload[0].thumbnail,
    },
  );
  onCloseHandler();
}
</script>

<template>
  <hawk-modal-container>
    <Vueform
      v-model="form"
      size="sm"
      class="w-full"
      :display-errors="false"
      sync
    >
      <div class="col-span-12">
        <hawk-modal-content class="flex align-center h-[500px]">
          <div class="flex flex-col align-center items-center w-1/2 m-auto h-[1.5rem]">
            {{ `${$t('Uploading files')}...` }}
            <div class="w-full flex">
              <div class="bg-gray-200 rounded-full dark:bg-gray-200 my-2 flex-1">
                <div
                  class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 rounded-full h-[0.5rem]"
                  :style="`width:${s3_upload_progress}%;`"
                />
              </div>
              <div style="width:3rem;text-align:right;">
                {{ s3_upload_progress }}%
              </div>
            </div>
          </div>
          <ListElement
            ref="upload_btn_elem"
            name="upload_pdf"
            accept=".pdf"
            :auto="false"
            :add-class="{ list: ['hidden'], add: ['hidden'] }"
            @mounted="addFilesToUppy"
          />
        </hawk-modal-content>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
