import { acceptHMRUpdate, defineStore } from 'pinia';
import { keyBy } from 'lodash-es';
import { extractAttrFromXML } from '../utils/helper';
import { COLORS } from '../constants';
import { usePusherStore } from '~/common/stores/pusher.store.js';

let pusher_channel;
export const useAnnotationsStore = defineStore('annotations', {
  state: () => ({
    annotations_map: {},
    element_uid: null,
    annotations_edit_config: {
      fill_color: COLORS[0],
      fill_opacity: 0,
      outline_color: COLORS[0],
      outline_opacity: 100,
      stroke_thickness: 1,
      is_initial_stroke: true,
      stroke_style: 'solid',
      text_x_align: 'left',
      text_y_align: 'top',
      font_size: 12,
    },
    location_marker_config: {
      is_visible: false,
      pos_x: 0,
      pos_y: 0,
    },
  }),
  getters: {
    get_annotations(state) {
      return Object.values(state.annotations_map);
    },
  },
  actions: {
    async create_annotations(annotations) {
      try {
        annotations.forEach((annotation) => {
          const annot_id = extractAttrFromXML(annotation.annotationData, 'name');
          this.annotations_map[annot_id] = {};
          this.annotations_map[annot_id].promise = new Promise((resolve) => {
            this.annotations_map[annot_id].resolve = resolve;
          });
        });
        const { data } = await this.$services.annotations_service.post({
          body: {
            annotations,
          },
        });

        (data.annotations || [data.annotation]).forEach((annot) => {
          const annot_id = extractAttrFromXML(annot.annotation_data, 'name');
          annot.id = annot_id;
          const resolve = this.annotations_map[annot_id].resolve;
          this.annotations_map[annot_id] = annot;
          resolve();
        });
      }
      catch (error) {
        logger.log(error);
        this.get_annotations.forEach((annotation) => {
          const annot_id = extractAttrFromXML(annotation.annotation_data, 'name');
          this.annotations_map[annot_id]?.resolve?.();
        });
        logger.error(error);
        return error;
      }
    },
    async fetch_annotations(element_id, owner_uid, version) {
      try {
        const { data } = await this.$services.annotations_service.getAll({
          query: {
            element: element_id,
            version,
            ...(owner_uid ? { owner: owner_uid } : {}),
          },
        });

        this.element_uid = element_id;
        (data.annotations || [data.annotation]).forEach((annot) => {
          const annot_id = extractAttrFromXML(annot.annotation_data, 'name');
          annot.id = annot_id;
        });

        this.annotations_map = keyBy((data.annotations || [data.annotation]), 'id');
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async update_annotations(annotations) {
      try {
        for (let i = 0; i < annotations.length; i++) {
          const annot_id = extractAttrFromXML(annotations[i].annotationData, 'name');
          if (this.annotations_map[annot_id]?.promise)
            await this.annotations_map[annot_id].promise;
        }
        await this.$services.annotations_service.patch({
          body: {
            annotations,
          },
        });
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    async delete_annotations(annotations) {
      try {
        for (let i = 0; i < annotations.length; i++) {
          if (this.annotations_map[annotations[i].Id]?.promise)
            await this.annotations_map[annotations[i].Id].promise;
          annotations[i].hidden = true;
        }
        const annotation_uids = annotations.map(annotation => this.annotations_map[annotation.Id].uid);
        const annotations_ids = annotations.map(annotation => annotation.Id);
        await this.$services.annotations_service.delete({
          body: {
            annotations: annotation_uids,
          },
        });

        // Delete annotations from store
        annotations_ids.forEach((annotation_id) => {
          delete this.annotations_map[annotation_id];
        });
      }
      catch (error) {
        logger.error(error);
        return error;
      }
    },
    reset_annotations_edit_config() {
      this.annotations_edit_config = {
        fill_color: COLORS[0],
        fill_opacity: 100,
        outline_color: COLORS[0],
        outline_opacity: 100,
        stroke_thickness: 1,
        stroke_style: 'solid',
      };
    },
    reset_location_marker_config() {
      this.location_marker_config = {
        is_visible: false,
        pos_x: 0,
        pos_y: 0,
      };
    },
    async subscribe_pusher(payload, additional_queries) {
      const pusher_store = usePusherStore();
      const action = async (uids) => {
        const { data } = await this.$services.annotations_service.getAll({
          query: {
            element: this.element_uid,
            annotation_uid: uids,
          },
        });
        let annotations = data.annotations;
        if (data.annotation)
          annotations = [data.annotation];
        annotations?.forEach((annot) => {
          const annot_id = extractAttrFromXML(annot.annotation_data, 'name');
          this.annotations_map[annot_id] = annot;
        });
      };
      if (!pusher_channel) {
        pusher_channel = pusher_store.PUSHER.subscribe(
          `private-annotations-org_${payload.organization}`,
        );
        pusher_channel.bind('ANNOTATIONS_UPDATED', ({ elementUid, uids }) => {
          if (this.element_uid === elementUid)
            action(uids);
        });
        pusher_channel.bind('ANNOTATIONS_CREATED', ({ elementUid, uids }) => {
          if (this.element_uid === elementUid)
            action(uids);
        });
        pusher_channel.bind('ANNOTATIONS_REMOVED', ({ elementUid, uids }) => {
          if (this.element_uid === elementUid)
            this.get_annotations.forEach((annotation) => {
              const annot_id = extractAttrFromXML(annotation.annotation_data, 'name');
              if (uids.includes(annotation.uid))
                delete this.annotations_map[annot_id];
            });
        });
      }
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAnnotationsStore, import.meta.hot));
