<script setup>
const $t = inject('$t');
const state = reactive({
  search: '',
  is_exporting: false,
  is_instance_dropdown_open: false,
});

const table_columns = [
  {
    accessorKey: 'name',
    header: 'Instance',
    id: 'name',
  },
];
const instance_list = [
  {
    name: 'TR01',
  },
  {
    name: 'TR02',
  },
  {
    name: 'TR03',
  },
  {
    name: 'TR04',
  },
  {
    name: 'TR05',
  },
];
</script>

<template>
  <!-- TODO: will be replaced with sm-instance.vue if in case -->
  <div>
    <HawkExportToast
      v-if="state.is_exporting"
      progress_text="Exporting to CSV" completed_text="Exported CSV" @close="state.is_exporting = false"
    />
    <HawkPageSecondaryHeader class="my-4">
      <template #left>
        <HawkSearchInput v-model="state.search" placeholder="Search" :debounce_time="300" />
      </template>
      <template #right>
        <div class="flex gap-4">
          <HawkButton
            type="outlined"
            class="font-semibold"
            @click="state.is_exporting = true"
          >
            <IconHawkDownloadOne class="!text-gray-600 h-4 w-4" />
            <span class="text-gray-700">{{ $t('Export') }}</span>
          </HawkButton>
          <HawkMenu
            :items="[]"
            additional_trigger_classes="!ring-0"
            @open="state.is_instance_dropdown_open = true"
            @close="state.is_instance_dropdown_open = false"
          >
            <template #trigger>
              <HawkButton type="outlined" class="font-semibold">
                {{ $t('Add') }}
                <IconHawkChevronUp v-if="state.is_instance_dropdown_open" class="text-gray-700 font-semibold" />
                <IconHawkChevronDown v-else class="text-gray-700 font-semibold" />
              </HawkButton>
            </template>
          </HawkMenu>
        </div>
      </template>
    </HawkPageSecondaryHeader>
    <HawkTable
      :data="instance_list"
      :columns="table_columns"
      :show_menu_header="false"
      is_gapless
    />
  </div>
</template>
