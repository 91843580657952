import dayjs from 'dayjs';
import { flattenDeep, fromPairs, isArray, isObject, keyBy, mapValues, reduce, sortBy } from 'lodash-es';
import { computed, inject, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useCommonStore } from '~/common/stores/common.store.js';
import useAbortController from '~/common/composables/abort-controller.js';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { csvInjectionProtector, getUserFullName } from '~/common/utils/common.utils.js';

const styles_map = {
  row_header_style: {
    font: {
      size: 9,
      color: { argb: '000000' },
      bold: true,
    },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'efefef' },
    },
  },
  file_style: {
    font: {
      size: 10,
      color: { argb: '434343' },
    },
  },
  folder: {
    font: {
      size: 11,
      bold: true,
    },
    border: {
      bottom: { style: 'thin', color: { argb: 'b7b7b7' } },
    },
    alignment: {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    },
    parent_style: {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'd9d9d9' },
      },
    },
    sub_style: {
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'cfe2f3' },
      },
    },
  },
};

export function useExportDocumentsMetadata(table_instance = {}) {
  const is_metadata_exporting = ref(false);
  const selected_folders = ref([]);
  const export_documents = ref(null);
  const route = useRoute();
  const $services = inject('$services');
  const common_store = useCommonStore();
  const controllers = useAbortController();
  const auth_store = useAuthStore();
  const dms_settings_store = useDMSSettingsStore();

  const export_documents_map = computed(() => keyBy(export_documents.value, 'uid'));

  const version_documents_map = computed(() => {
    const versions = export_documents.value.reduce((acc, doc) => {
      const modify_versions = doc.version?.map((v, i) => ({ ...v, name: `V${i + 1}` }));
      if (modify_versions)
        acc.push(...modify_versions);
      return acc;
    }, []);
    return keyBy(flattenDeep(versions), 'uid');
  });

  const review_status_map = computed(() => keyBy(dms_settings_store.review_status_list, 'slug'));
  const custom_fields = computed(() => {
    return dms_settings_store.custom_fields?.filter(field => field.active);
  });
  const custom_field_columns = computed(() => {
    return custom_fields.value?.filter(field => field.active)?.map(field => ({ key: field.uid, title: csvInjectionProtector(field.label), width: 20 }));
  });

  const field_values_map = computed(() => {
    return fromPairs(custom_fields.value.map(field => [
      field.uid,
      mapValues(keyBy(field.items, 'value'), 'label'),
    ]));
  });

  function createCellFill(options = {}) {
    return {
      type: options?.type || 'pattern',
      pattern: options?.pattern || 'solid',
      fgColor: { argb: options?.color || 'FFFFFF' },
    };
  }

  function setHeaderInfo(worksheet, sheet, folders = '') {
    worksheet.mergeCells('B2:J2');
    worksheet.getCell('B2').value = sheet.sheet_title;
    const second_rows = worksheet.getRow(2);
    second_rows.height = 42;
    worksheet.getCell('B2').style = {
      font: {
        size: 30,
        bold: true,
        color: { argb: '0b5394' },
      },
      alignment: {
        vertical: 'middle',
        wrapText: true,
      },
    };
    second_rows.eachCell((cell) => {
      cell.border = createCellBorder({ color: '0b5394' });
    });
    const header_cells = {
      B4: {
        value: 'Organization',
        style: true,
      },
      C4: {
        merge_with: 'E4',
        value: csvInjectionProtector(auth_store.current_organization?.name) || '-',
        style: false,
      },
      F4: {
        value: 'Asset',
        style: true,
      },
      G4: {
        merge_with: 'J4',
        value: csvInjectionProtector(common_store?.active_asset?.name) || '-',
        style: false,
      },
      B5: {
        value: 'Folder',
        style: true,
      },
      C5: {
        merge_with: 'E5',
        value: folders || '-',
        style: false,
      },
      F5: {
        value: 'Date',
        style: true,
      },
      G5: {
        merge_with: 'J5',
        value: dayjs().format('MMM D, YYYY'),
        style: false,
      },
    };

    for (const [cell, property] of Object.entries(header_cells)) {
      const first_cell = worksheet.getCell(cell);
      first_cell.value = property.value;
      const cell_border = createCellBorder({ color: 'd9d9d9' });
      if (property?.merge_with) {
        worksheet.mergeCells(`${cell}:${property.merge_with}`);
        const second_cell = worksheet.getCell(property.merge_with);
        second_cell.border = cell_border;
      }
      if (property.style)
        first_cell.style = {
          font: {
            bold: true,
            color: { argb: '666666' },
          },
          border: cell_border,
        };
    }
  }

  async function fetchDocumentsMeta(folders) {
    try {
      const response = await $services.dms.post({
        attribute: 'documents/export-csv',
        body: {
          documents: folders.map(({ uid }) => ({ uid })),
        },
      });
      return response?.data?.data;
    }
    catch (err) {
      logger.error(err);
    }
  }
  function createCellBorder(options = {}) {
    const { borders = ['bottom'], style = 'thin', color = 'b7b7b7' } = options;
    const cell_borders = {};
    borders.forEach((side) => {
      cell_borders[side] = { style, color: { argb: color } };
    });
    return cell_borders;
  }

  function setRowHeader(worksheet, columns = []) {
    const starting_row = worksheet.getRow(8);
    starting_row.height = 28;
    starting_row.values = columns.map(({ title }) => title);
    starting_row.eachCell((cell, colNumber) => {
      cell.style = {
        ...styles_map.row_header_style,
        alignment: { horizontal: [1, 2, 3].includes(colNumber) ? 'left' : 'center', vertical: 'middle' },
      };
    });
  }

  function getFieldValues(field_values) {
    const values = {};
    field_values.forEach(({ field: field_uid, value, type }) => {
      if (isArray(value)) {
        values[field_uid] = value
          .map(item => csvInjectionProtector((item?.member ? item.name : field_values_map.value[field_uid][item])))
          .filter(val => val !== undefined)
          .join(',');
      }
      else {
        let field_value = ['text', 'number', 'yes_no_na'].includes(type) ? value : field_values_map.value[field_uid][value];
        if (type === 'number')
          field_value = field_value?.toString();
        if (type === 'yes_no_na') {
          const yes_no_na_map = {
            '1': 'Yes',
            '0': 'NA',
            '-1': 'No',
          };
          field_value = yes_no_na_map[field_value?.toString()];
        }
        values[field_uid] = csvInjectionProtector(field_value || '');
      }
    });
    return values;
  }

  function getUserName(uid) {
    const user = common_store.get_user(uid) || common_store.get_org_or_internal_user(uid);
    return getUserFullName(user);
  }

  function createRowData(document, custom_fields) {
    const {
      uid,
      created_on,
      s_no,
      created_by,
      defaultversion = null,
      document_status,
      parent,
      type,
      name = '',
      document_number = '',
      transmittal = '',
      issue_purpose = '',
      version = [],
    } = document;

    const custom_field_values = document?.field_values ? getFieldValues(document?.field_values) : {};
    const current_version = version.find(v => v.uid === defaultversion)?.name || '';
    return {
      parent,
      type,
      s_no,
      document_number: csvInjectionProtector(type === 'folder' ? name : document_number) || '-',
      document_name: type === 'file' ? csvInjectionProtector(name) : '',
      current_status: document_status || '',
      current_version: csvInjectionProtector(current_version),
      issue_purpose: csvInjectionProtector(issue_purpose) || '',
      transmittal: csvInjectionProtector(transmittal),
      created_on: type === 'file' ? dayjs(created_on).format('MMM D, YYYY') : '',
      created_by: getUserName(created_by),
      ...custom_fields,
      ...custom_field_values,
      link: type === 'file' ? uid : '',
    };
  }

  function customSort(a, b) {
    const a_parts = a.s_no.split('.');
    const b_parts = b.s_no.split('.');
    for (let i = 0; i < Math.max(a_parts.length, b_parts.length); i++) {
      const a_part = Number.parseInt(a_parts[i]) || 0;
      const b_part = Number.parseInt(b_parts[i]) || 0;
      if (a_part !== b_part)
        return a_part - b_part;
    }
    return 0;
  }

  function modifyDocumentsData(list) {
    const modified_data = [];
    const custom_field_uid_map = reduce(custom_fields.value, (obj, field) => {
      obj[field.uid] = '';
      return obj;
    }, {});
    list.forEach((doc) => {
      const obj = createRowData(doc, custom_field_uid_map);
      modified_data.push(obj);
    });
    return modified_data.sort(customSort);
  }

  function modifyVersionsData(documents) {
    const modified_versions = [];
    const sorted_files = sortBy(documents.filter(doc => doc.type === 'file'), 's_no');
    let s_no = 0;
    sorted_files.forEach((file, index) => {
      const { version, document_number, name, transmittal = '' } = file;
      if (version.length)
        s_no += 1;

      version.length && version.forEach((v) => {
        const review_status_info = review_status_map.value[v?.review_status] || {};
        const review_status_obj = v?.review_status ? { name: csvInjectionProtector(review_status_info.name) || '', color: review_status_info.color || '' } : '';
        const obj = {
          s_no,
          document: csvInjectionProtector(document_number || name) || '',
          version: version_documents_map.value[v?.uid]?.name || '',
          transmittal: csvInjectionProtector(v.transmittal),
          review_status: review_status_obj,
          document_status: v.document_status,
          issue_purpose: csvInjectionProtector(v?.issue_purpose) || '',
          created_on: dayjs(v.created_on).format('MMM D, YYYY'),
          created_by: getUserName(v.created_by),
          link: v.uid,
        };
        modified_versions.push(obj);
      });
    });

    return modified_versions;
  }

  function createCellLink(worksheet, column, type) {
    const column_obj = worksheet.getColumn(column);
    const column_values = column_obj.values;
    const files_map = type === 'documents' ? export_documents_map.value : version_documents_map.value;
    for (let row_no = 9; row_no <= column_values.length; row_no++) {
      const cell_value = column_values[row_no];
      const cell = worksheet.getCell(`${column_obj.letter}${row_no}`);

      if (cell_value && files_map[cell_value]) {
        const { url } = files_map[cell_value];
        cell.value = url ? { text: 'Download', hyperlink: url } : '';
      }
      else {
        cell.value = '';
      }
    }
  }

  function setDocumentToSheet(worksheet, list) {
    const documents = modifyDocumentsData(list);
    documents.forEach((document) => {
      const row = worksheet.addRow(document);
      if (document.type === 'file')
        row.outlineLevel = 1;

      row.eachCell((cell, colNumber) => {
        cell.style = {
          ...(document.type === 'file' ? styles_map.file_style : { font: styles_map.folder.font }),
          ...(document.parent === '' && document.type !== 'file' ? styles_map.folder.parent_style : {}),
          ...(document.parent && document.type === 'folder' ? styles_map.folder.sub_style : {}),
          alignment: { horizontal: [1, 2, 3].includes(colNumber) ? 'left' : 'center' },
        };
        if (isObject(cell.value) && cell.value) {
          if (cell.value?.color) {
            cell.style.font = {
              ...cell.style.font,
              bold: true,
            };
            cell.style.fill = createCellFill({ color: cell.value.color?.substring(1) });
          }
          cell.value = csvInjectionProtector(cell.value.name) || '-';
        }
        cell.border = createCellBorder();
      });
      row.commit();
    });
    createCellLink(worksheet, worksheet.columnCount, 'documents');
  }

  function findSequenceIndices(arr) {
    const sequences = [];
    let startIndex = null;
    let prevValue = null;

    for (let i = 9; i <= arr.length; i++) {
      const value = arr[i];
      if (value === prevValue) {
        if (startIndex === null)
          startIndex = i - 1;
      }
      else {
        if (startIndex !== null) {
          sequences.push([startIndex, i - 1]);
          startIndex = null;
        }
      }
      prevValue = value;
    }
    if (startIndex !== null)
      sequences.push([startIndex, arr.length]);

    return sequences;
  }

  function mergeColumnCell(worksheet, cols) {
    cols.forEach((col) => {
      const column = worksheet.getColumn(col);
      const sequence = findSequenceIndices(column.values);
      if (sequence.length)
        sequence.forEach((cells) => {
          worksheet.mergeCells(`${col}${cells[0]}:${col}${cells[1]}`);
        });
    });
  }

  function setVersionsToSheet(worksheet, list) {
    const versions = modifyVersionsData(list);
    versions.forEach((version) => {
      const row = worksheet.addRow(version);
      row.eachCell((cell, colNumber) => {
        cell.style = {
          alignment: {
            vertical: 'middle',
            horizontal: [1, 2].includes(colNumber) ? 'left' : 'center',
          },
        };
        if (isObject(cell.value) && cell.value) {
          if (cell.value?.color) {
            cell.style.font = {
              ...cell.style.font,
              bold: true,
            };
            cell.style.fill = createCellFill({ color: cell.value.color?.substring(1) });
          }
          cell.value = cell.value.name;
        }
        cell.border = createCellBorder({ borders: ['top', 'left', 'bottom', 'right'] });
      });
    });
    createCellLink(worksheet, worksheet.columnCount, 'versions');
    mergeColumnCell(worksheet, ['A', 'B']);
  }

  const sheets_data = computed(() => {
    return [
      {
        name: 'Documents',
        sheet_title: 'Document Register',
        sheet_columns: [
          { key: 's_no', title: 'S. No.' },
          { key: 'document_number', title: 'Document No.', width: 20 },
          { key: 'document_name', title: 'Document Name', width: 30 },
          { key: 'current_status', title: 'Current Status', width: 20 },
          { key: 'current_version', title: 'Current Version', width: 20 },
          { key: 'issue_purpose', title: 'Issue Purpose', width: 20 },
          { key: 'transmittal', title: 'Transmittal', width: 20 },
          { key: 'created_on', title: 'Created on', width: 20 },
          { key: 'created_by', title: 'Created by', width: 20 },
          ...custom_field_columns.value,
          { key: 'link', title: 'Link', width: 20 },
        ],
        action: setDocumentToSheet,
      },
      {
        name: 'Version History',
        sheet_title: 'DMS Report',
        sheet_columns: [
          { key: 's_no', title: 'S. No.' },
          { key: 'document', title: 'Document', width: 30 },
          { key: 'version', title: 'Version', width: 20 },
          { key: 'transmittal', title: 'Transmittal', width: 20 },
          { key: 'review_status', title: 'Review Status', width: 20 },
          { key: 'document_status', title: 'Document Status', width: 20 },
          { key: 'issue_purpose', title: 'Issue purpose', width: 20 },
          { key: 'created_on', title: 'Created on', width: 20 },
          { key: 'created_by', title: 'Created by', width: 20 },
          { key: 'link', title: 'Link', width: 20 },
        ],
        action: setVersionsToSheet,
      },
    ];
  });

  async function exportDocumentsMetaData() {
    try {
      controllers.add('export_dms_metadata');
      const ExcelJS = await import('exceljs');
      const { saveAs } = await import('file-saver');
      const workbook = new ExcelJS.Workbook();

      const selected_folders_name = selected_folders.value
        .map(({ name }) => csvInjectionProtector(name))
        ?.filter(Boolean)
        ?.join(', ');
      const documents = await fetchDocumentsMeta(selected_folders.value);
      export_documents.value = documents;

      sheets_data.value.forEach((sheet) => {
        const worksheet = workbook.addWorksheet(sheet.name, {
          views: [{ showGridLines: false }],
        });
        setHeaderInfo(worksheet, sheet, selected_folders_name);
        worksheet.columns = sheet.sheet_columns;
        worksheet.properties.outlineProperties = { summaryBelow: false }; // collapse button on top
        setRowHeader(worksheet, sheet.sheet_columns);
        sheet.action(worksheet, documents);
      });

      if (is_metadata_exporting.value) {
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), 'Document register.xlsx');
        setTimeout(() => {
          is_metadata_exporting.value = false;
          table_instance?.clearSelect?.();
        }, 3000);
      }
    }
    catch (err) {
      logger.error(err);
    }
  }
  function startExporting(data) {
    selected_folders.value = data;
    is_metadata_exporting.value = true;
  }
  function cancelExportingMetadata() {
    controllers.abort('export_dms_metadata');
    is_metadata_exporting.value = false;
    table_instance?.clearSelect?.();
  }
  return {
    startExporting,
    exportDocumentsMetaData,
    cancelExportingMetadata,
    is_metadata_exporting,
  };
}
