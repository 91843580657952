<script setup>
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { useComponentMasterStore } from '~/system-model/store/component-master.store';
import CmComponentDetailParameters from '~/system-model/component-master/pages/cm-component-detail/cm-component-detail-parameters.vue';
import CmComponentDetailDocuments from '~/system-model/component-master/pages/cm-component-detail/cm-component-detail-documents.vue';
import CmComponentDetailMaterials from '~/system-model/component-master/pages/cm-component-detail/cm-component-detail-materials.vue';
import CmComponentDetailMaintenanceSchedules from '~/system-model/component-master/pages/cm-component-detail/cm-component-detail-maintenance-schedules.vue';
import CmComponentDetailDefects from '~/system-model/component-master/pages/cm-component-detail/cm-component-detail-defects.vue';
import CmComponentDetailInstances from '~/system-model/component-master/pages/cm-component-detail/cm-component-detail-instances.vue';
import TasksView from '~/tasks/pages/tasks/tasks-view.vue';

// import FormCompactView from '~/forms/components/form-compact-view.vue';

const router = useRouter();
const route = useRoute();

const component_master_store = useComponentMasterStore();
const { active_component_master } = storeToRefs(component_master_store);
const { set_component_master_detail } = component_master_store;

const state = reactive({
  is_loading: false,
  active_tab: 'details',
});
const breadcrumb_items = computed(() => {
  const items = [
    {
      uid: 'component-master',
      label: 'Component Master',
      disabled: true,
    },
    {
      uid: 'registry',
      label: 'Registry',
      disabled: true,
    },
  ];
  if (active_component_master.value) {
    const { category, name, uid } = active_component_master.value;
    items.push(
      {
        uid: category.uid,
        label: category.name,
        disabled: true,
      },
      {
        uid,
        label: name,
      },
    );
  }
  return items;
});

const tabs = [
  {
    uid: 'details',
    label: 'Details',
  },
  {
    uid: 'instances',
    label: 'Instances',
  },
];
const context_menu_items = [
  {
    uid: 'sample',
    label: 'Sample',
    on_click: () => ({}),
  },
];
async function getData() {
  state.is_loading = true;
  await set_component_master_detail({ component_uid: route.params.component_uid });
  state.is_loading = false;
}

onMounted(async () => {
  await getData();
});

function backToList() {
  router.push({
    name: 'component-master',
  });
}
</script>

<template>
  <div v-if="state.is_loading">
    <HawkLoader class="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%]" />
  </div>
  <div v-else-if="active_component_master" class="px-4 pb-4">
    <HawkPageHeader class="px-0">
      <template #left>
        <div class="flex gap-3">
          <div class="cursor-pointer" @click="backToList">
            <IconHawkChevronLeft class="text-gray-600" />
          </div>
          <HawkBreadcrumbs
            :items="breadcrumb_items"
            class="font-medium"
            @crumb-clicked="clickHandler"
          />
        </div>
      </template>
    </HawkPageHeader>

    <div class="flex items-center justify-between">
      <div class="text-lg text-gray-900 font-semibold">
        {{ active_component_master.name }}
        <span class="text-xs text-gray-600 block font-normal">{{ active_component_master?.category?.name }}</span>
      </div>
      <HawkMenu
        :items="context_menu_items"
        additional_trigger_classes="!ring-0 !focus:ring-0"
      />
    </div>
    <p class="text-sm text-gray-700 mt-1">
      {{ active_component_master.description || '' }}
    </p>

    <hawk-tabs
      :tabs="tabs"
      :active_item="state.active_tab"
      class="my-6"
      @tabClick="tab => state.active_tab = tab.uid"
    />
    <div v-if="state.active_tab === 'details'">
      <div class="flex border rounded-lg w-full divide-x">
        <CmComponentDetailParameters class="p-4 w-1/2" />
        <CmComponentDetailDocuments class="p-4 w-1/2" />
      </div>
      <CmComponentDetailMaterials class="my-4" />
      <hr class="my-5 border-gray-200">
      <CmComponentDetailMaintenanceSchedules />
      <hr class="my-5 border-gray-200">
      <CmComponentDetailDefects />
      <hr class="my-5 border-gray-200">

      <!-- task/form -->
      <div class="flex w-full gap-5">
        <div class="w-1/2 border rounded-lg">
          <TasksView
            is_widget
            :is_compact_view="true"
            store_key="cm_task"
            :table_options="{
              height: 'max-h-96',
            }"
          >
            <template #top-panel>
              <div class="text-sm font-bold text-gray-900 p-2 bg-gray-50 border-b">
                {{ $t('Tasks') }}
              </div>
            </template>
          </TasksView>
        </div>
        <div class="w-1/2 border rounded-lg">
          <div class="text-sm font-bold text-gray-900 p-2 bg-gray-50 border-b">
            {{ $t('Forms') }}
          </div>
          <!-- <FormCompactView
            id="cm_forms"
            wrapper_class="max-h-96 scrollbar px-2"
            :options="{
              show_no_data: true,
              query: {},
            }"
          /> -->
        </div>
      </div>
    </div>
    <div v-else>
      <CmComponentDetailInstances />
    </div>
  </div>
</template>
