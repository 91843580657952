<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import Permissions from '~/account-settings/components/shared/permissions.vue';

const $toast = inject('$toast');
const $t = inject('$t');
const $track_event = inject('$track_event');
const $services = inject('$services');
const route = useRoute();
const router = useRouter();
const auth_store = useAuthStore();
const common_store = useCommonStore();

const form$ = ref(null);

const state = reactive({
  is_loading: false,
  role_detail: {},
  role_permissions: {},
  form: {
    name: null,
    description: null,
    users: [],
  },
});

const breadcrumb_items = computed(() => {
  return [
    { uid: '1', label: $t('Account Settings'), disabled: true },
    { uid: '2', label: $t('Roles'), to: { name: route?.params?.asset_id ? 'asset-settings-roles' : 'account-settings-roles' } },
    { uid: '3', label: route.params.id ? state.role_detail.name : $t('New Role') },
  ];
});

onMounted(async () => {
  if (route.params.id) {
    await getDetails();
    form$.value.update({
      name: state.role_detail.name,
      description: state.role_detail.description,
      users: [
        ...common_store.filter_users(state.role_detail.users),
        ...common_store.filter_teams(state.role_detail.teams),
      ],
    });
    state.role_permissions = state.role_detail.permissions || {};
  }
});

async function getDetails() {
  try {
    state.is_loading = true;
    const { data } = await $services.roles.get({ id: route.params.id });
    state.role_detail = data.role;
    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
    logger.log('error', error);
  }
}

async function save() {
  try {
    const payload = {
      ...state.form,
      users: common_store.filter_users(state.form.users.map(user => user?.uid ? user.uid : user)),
      teams: common_store.filter_teams(state.form.users.map(user => user?.uid ? user.uid : user)),
      permissions: state.role_permissions,
    };

    if (!route.params.id) {
      payload.organization = auth_store.current_organization?.uid;
      payload.asset = route?.params?.asset_id ? route.params.asset_id : null;
    }

    const { data } = await common_store.update_data({
      type: route.params.id ? 'patch_update' : 'add',
      ...(route.params.id ? { id: route.params.id } : {}),
      data: { role: payload },
      service: 'roles',
      response_key: 'role',
      append_data: false,
      state_prop: 'roles_map',
      update_state: false,
    });

    if (data?.role) {
      await common_store.update_global_data({ roles: [data?.role?.uid] });
      if (route.params.id) {
        $toast({
          title: $t('Updated'),
          text: $t('Role updated successfully'),
          type: 'success',
        });
      }

      else {
        $toast({
          title: $t('Created'),
          text: $t('Role created successfully'),
          type: 'success',
        });
        $track_event('create_role', { members: data.role.users.length });
      }
      router.push({ name: route?.params?.asset_id ? 'asset-settings-roles' : 'account-settings-roles' });
    }
  }
  catch (error) {
    logger.error('error', error);
    $toast({
      title: $t('Something went wrong'),
      text: $t('Please try again'),
      type: 'error',
    });
  }
}
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="pt-6 pb-5 border-b">
      <template #left>
        <div class="flex items-center">
          <HawkBreadcrumbs :items="breadcrumb_items" />
        </div>
      </template>
    </HawkPageSecondaryHeader>
    <div>
      <div class="my-5">
        <div class="text-lg font-semibold text-gray-900">
          {{ $t('Role') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ $t('Role details') }}
        </div>
      </div>
      <HawkLoader v-if="state.is_loading" />
      <div v-else>
        <Vueform
          ref="form$"
          v-model="state.form"
          size="sm"
          :format-load="(data) => data"
          :display-errors="false"
          :endpoint="save"
        >
          <div class="col-span-12">
            <div class="max-w-[700px]">
              <TextElement
                :label="$t('Role name')"
                name="name"
                :placeholder="$t('Enter name')"
                :rules="['required', 'max:100']"
                class="mb-4"
              />
              <TextareaElement
                :label="$t('Role description')"
                name="description"
                :placeholder="$t('Enter description')"
                :rules="['max:200']"
                class="mb-4"
              />
              <HawkAssigneeInput
                class="w-full mr-5"
                :multi="true"
                :options="{
                  'name': 'users',
                  'has_teams': true,
                  'placeholder': $t('Select users or teams'),
                  'class': 'mb-4',
                  'group-select': false,
                  'object': false,
                }"
                :format_load="true"
              >
                <template #label>
                  <div class="flex items-center">
                    <span class="mr-2">{{ $t('Members') }}</span>
                    <span v-tippy="`${$t('Select users to be assigned this role')}`">
                      <IconHawkInfoCircle class="h-4 w-4" />
                    </span>
                  </div>
                </template>
              </HawkAssigneeInput>
            </div>
            <hr class="my-5">
            <div class="my-5">
              <div class="text-lg font-semibold text-gray-900">
                {{ $t('Permissions') }}
              </div>
            </div>
            <div class="max-w-[435px]">
              <Permissions
                :model_value="state.role_permissions"
                @update:model_value="permissions => state.role_permissions = permissions"
              />
            </div>

            <div class="sticky bg-white bottom-0">
              <hr class="my-5">
              <div class="flex justify-end gap-3 pb-5">
                <HawkButton type="outlined" @click="router.push({ name: route?.params?.asset_id ? 'asset-settings-roles' : 'account-settings-roles' })">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true" :disabled="!auth_store.check_permission('modify_roles', route.params.asset_id)">
                  {{ route.params.id ? $t('Update') : $t('Save') }}
                </ButtonElement>
              </div>
            </div>
          </div>
        </Vueform>
      </div>
    </div>
  </div>
</template>
