<script setup>
const $t = inject('$t');
const menu_containers = [{
  title: 'Assets',
  menu_type: 'assets',
  items: [
    { label: 'asset 1', on_click: () => ({}) },
    { label: 'asset 2', on_click: () => ({}) },
  ],
},
{
  title: 'Type',
  menu_type: 'type',
  items: [
    { label: 'type 1', on_click: () => ({}) },
    { label: 'type 2', on_click: () => ({}) },
  ],
},
{
  title: 'Status',
  menu_type: 'status',
  items: [
    { label: 'status 1', on_click: () => ({}) },
    { label: 'status 2', on_click: () => ({}) },
  ],
},
{
  title: 'Assignee',
  menu_type: 'assignee',
  items: [
    { label: 'assignee 1', on_click: () => ({}) },
    { label: 'assignee 2', on_click: () => ({}) },
  ],
},
{
  title: 'Priority',
  menu_type: 'priority',
  items: [
    { label: 'priority 1', on_click: () => ({}) },
    { label: 'priority 2', on_click: () => ({}) },
  ],
},
];
const table_columns = [
  {
    accessorKey: 'name',
    header: $t('Name'),
    id: 'name',
  },
  {
    accessorKey: 'inverter',
    header: $t('Inverter'),
    id: 'inverter',
  },
  {
    accessorKey: 'string',
    header: $t('String'),
    id: 'string',
  },
  {
    accessorKey: 'defect_type',
    header: $t('Defect type'),
    id: 'defect_type',
  },
  {
    accessorKey: 'due_date',
    header: $t('Due date'),
    id: 'due_date',
  },
  {
    accessorKey: 'assignee',
    header: $t('Assignee'),
    id: 'assignee',
  },
  {
    accessorKey: 'status',
    header: $t('Status'),
    id: 'status',
  },
  {
    accessorKey: 'priority',
    header: $t('Priority'),
    id: 'priority',
  },
  {
    accessorKey: 'timestamp',
    header: $t('Timestamp'),
    id: 'timestamp',
  },
  {
    accessorKey: 'temp',
    header: 'Temp',
    id: 'temp',
  },
  {
    accessorKey: 'tags',
    header: $t('Tags'),
    id: 'tags',
  },
];
const defects_list = [
  {
    name: 'B1-INV46-S1-R2-C8 : Bypass diode',
    inverter: 'G3P1',
    string: 'B1-INV46-S1-R2-C8',
    defect_type: 'Bypass diode active',
    due_date: new Date(),
    assignee: '-',
    status: 'in-progress',
    priority: 1,
    timestamp: '2023:05:18 13:19:16',
    temp: '2.33 °C',
    tags: '-',
  },
  {
    name: 'B1-INV46-S1-R2-C8 : Bypass diode',
    inverter: 'G3P1',
    string: 'B1-INV46-S1-R2-C8',
    defect_type: 'Bypass diode active',
    due_date: new Date(),
    assignee: '-',
    status: 'in-progress',
    priority: 2,
    timestamp: '2023:05:18 13:19:16',
    temp: '2.33 °C',
    tags: '-',
  },
  {
    name: 'B1-INV46-S1-R2-C8 : Bypass diode',
    inverter: 'G3P1',
    string: 'B1-INV46-S1-R2-C8',
    defect_type: 'Bypass diode active',
    due_date: new Date(),
    assignee: '-',
    status: 'in-progress',
    priority: 3,
    timestamp: '2023:05:18 13:19:16',
    temp: '2.33 °C',
    tags: '-',
  },
  {
    name: 'B1-INV46-S1-R2-C8 : Bypass diode',
    inverter: 'G3P1',
    string: 'B1-INV46-S1-R2-C8',
    defect_type: 'Bypass diode active',
    due_date: new Date(),
    assignee: '-',
    status: 'Identified',
    priority: 4,
    timestamp: '2023:05:18 13:19:16',
    temp: '2.33 °C',
    tags: '-',
  },
];
</script>

<template>
  <div>
    <p class="text-sm font-semibold test-gray-900 mb-4">
      {{ $t('Defects') }}
    </p>

    <div class="flex gap-2 mb-4">
      <template v-for="menu in menu_containers" :key="menu.menu_type">
        <HawkMenu
          :items="menu.items"
          additional_trigger_classes="!ring-0 focus:!ring-0"
        >
          <template #trigger>
            <HawkButton type="outlined">
              {{ menu.title }}<IconHawkChevronDown class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </HawkButton>
          </template>
        </HawkMenu>
      </template>
    </div>
    <HawkTable
      :data="defects_list"
      :columns="table_columns"
      :show_menu_header="false"
      :non_sortable_columns="['context_menu']"
      is_gapless
    >
      <template #name="{ data }">
        <p class="font-medium font-sm text-gray-900">
          {{ data?.row?.original?.name }}
        </p>
      </template>
      <template #priority="{ data }">
        <TaskPriority :priority="data.row.original.priority" />
      </template>
      <template #status="{ data }">
        <HawkBadge>{{ data.row.original.status }}</HawkBadge>
      </template>
      <template #due_date="{ data }">
        {{ $date(data.row.original.due_date, 'D MMMM YYYY') }}
      </template>

      <template #context_menu="{ data: { row: { original } } }">
        <HawkMenu
          class="flex justify-end w-full"
          additional_trigger_classes="!ring-0 focus:!ring-0"
          :items="getMenuItems(original)"
        >
          <template #trigger>
            <IconHawkDotsVertical class="text-gray-600 h-5" />
          </template>
        </HawkMenu>
      </template>
    </HawkTable>
  </div>
</template>
