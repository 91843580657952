<script setup>
const emit = defineEmits(['close']);
const state = reactive({
  is_loading: false,
});
function saveHandler() {}
</script>

<template>
  <hawk-modal-container content_class="w-64 rounded-lg">
    <Vueform
      :display-errors="false"
      size="sm"
      :columns="{
        default: { container: 12, label: 4, wrapper: 12 },
        sm: { container: 12, label: 4, wrapper: 12 },
        md: { container: 12, label: 4, wrapper: 12 },
      }"
      @submit="saveHandler"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #left>
            {{ $t('New maintenance schedule') }}
          </template>
        </hawk-modal-header>

        <hawk-modal-content :is_scroll="false">
          <TextElement
            v-bind="{
              label: `${$t('Name')}`,
              name: 'name',
              placeholder: 'Enter name',
              rules: ['required'],
              class: 'mb-6',
            }"
          />
          <TextareaElement
            v-bind="{
              name: 'description',
              label: $t('Description'),
              placeholder: $t('Add description'),
              autocomplete: 'off',
              class: 'mb-6',
            }"
          />
        </hawk-modal-content>

        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end">
              <hawk-button type="outlined" class="mr-3 font-semibold" @click="emit('close')">
                {{ $t('Cancel') }}
              </hawk-button>
              <ButtonElement
                submits
                size="sm"
                name="submit"
                :button-label="$t('Save')"
                :loading="state.is_loading"
              />
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
