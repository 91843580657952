import TerraViewer from '~/terra/pages/terra-viewer.vue';
import TerraCompare from '~/terra/pages/terra-compare.vue';

const routes = [{
  path: '/:asset_id?/terra-viewer/:id?',
  name: 'terra-viewer',
  component: TerraViewer,
  meta: {
    title: 'Terra viewer',
    meta: {
      view_name: 'Terra viewer',
    },

  },
},
{
  path: '/:asset_id?/maps/:id?',
  name: 'maps',
  component: TerraViewer,
  meta: {
    title: 'Terra viewer',

  },
},
{
  path: '/:asset_id?/terra/:id?/compare',
  name: 'terra-compare',
  component: TerraCompare,
  meta: {
    title: 'Terra viewer',
    view_name: 'Terra viewer - Compare',
  },
},
{
  path: '/:asset_id?/maps/:id?/compare',
  name: 'maps-compare',
  component: TerraCompare,
  meta: {
    title: 'Maps',

  },
},

];
export default routes;
