<script setup>
import { computed, watch } from 'vue';
import { useTerraStore } from '~/terra/store/terra.store';

const props = defineProps({
  selected_features: {
    type: Array,
  },
});

const terra_store = useTerraStore();
const state = reactive({
  selected_unit: 'Square meters',
  calculated_area: null,
});

const scale_units = computed(() => {
  let items = [];
  if (props.selected_features.length)
    if (
      props.selected_features[0].geometry.type === 'Polygon'
            || props.selected_features[0].geometry.type === 'MultiPolygon'
    )
      items = [
        {
          label: 'Square meters',
          action: 'square-meter',
        },
        {
          label: 'Square kilometers',
          action: 'square-kilometer',
        },
      ];
    else if (props.selected_features[0].geometry.type === 'LineString')
      items = [
        {
          label: 'Meters',
          action: 'meters',
        },
        {
          label: 'Kilometers',
          action: 'kilometer',
        },
        {
          label: 'Miles',
          action: 'miles',
        },
      ];
  return items.map((item) => {
    return {
      ...item,
      on_click: async () => {
        state.selected_unit = item.label;
        await recalculateScale();
      },
    };
  });
});
async function recalculateScale() {
  let value;
  const turf = (await import('@turf/turf'));
  if (props.selected_features.length)
    if (
      props.selected_features[0].geometry.type === 'LineString'
            || props.selected_features[0].geometry.type === 'MultiLineString'
    ) {
      if (
        !scale_units.value.map(val => val.label).includes(state.selected_unit)
      )
        state.selected_unit = 'Meters';
      value = turf.length(
        {
          type: 'FeatureCollection',
          features: props.selected_features,
        },
        { units: state.selected_unit.toLowerCase() },
      );
      state.calculated_area = value.toFixed(2);
    }
    else {
      if (!scale_units.value.map(val => val.label).includes(state.selected_unit))
        state.selected_unit = 'Square meters';
      value = turf.area({
        type: 'FeatureCollection',
        features: props.selected_features,
      });
      state.calculated_area
              = state.selected_unit === 'Square kilometers'
          ? (value / 1000000).toFixed(2)
          : value.toFixed(2);
    }
  const id = terra_store.selected_features[0]?.properties?.uid;
  terra_store.terra_track_events('Scale', { units: state.selected_unit, id });
}
watch(() => props.selected_features, async (val) => {
  if (val?.length)
    await recalculateScale();
}, { immediate: true });
</script>

<template>
  <div class="w-80 p-3 bg-white rounded-md ">
    <div class="flex items-center justify-between">
      <div class="text-[16px] font-semibold">
        {{ $t('Scale') }}
      </div>
      <HawkMenu
        :items="scale_units"
        :has_bordered_trigger="true"
        :active_item="state.selected_unit"
        additional_trigger_classes="!border-0 !ring-0 !p-0 !-my-2"
      >
        <template #trigger="{ open }">
          <HawkButton type="link">
            {{ $t(state.selected_unit) }}
            <span class="text-gray-600">
              <IconHawkChevronUp v-if="open" />
              <IconHawkChevronDown v-else />
            </span>
          </HawkButton>
        </template>
      </HawkMenu>
    </div>
    <div v-if="selected_features.length" class="text-[14px] font-medium mt-2">
      {{ $t('Calculated value') }}: {{ state.calculated_area }}
    </div>
    <div v-else class="text-[14px] mt-2 font-medium">
      {{ $t('Please select a vector to calculate it’s area/distance') }}
    </div>
  </div>
</template>
