<script setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { isEqual, map } from 'lodash-es';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import AssetResources from '~/account-settings/components/shared/asset-resources.vue';

const $toast = inject('$toast');
const $track_event = inject('$track_event');
const $t = inject('$t');
const $services = inject('$services');
const route = useRoute();
const router = useRouter();
const auth_store = useAuthStore();
const common_store = useCommonStore();

const form = ref({
  name: '',
  description: null,
});
const form$ = ref(null);
const is_loading = ref(false);
const saving = ref(false);
const team_detail = ref({});

const selected_users = ref([]);
const selected_roles = ref([]);

function onTreeUpdate(e) {
  team_detail.value.assets = [...e.assets];
  team_detail.value.resources = [...e.resources];
  team_detail.value = { ...team_detail.value };
}

async function setTeams(response, old_roles) {
  if (response.value.data?.team) {
    await common_store.update_global_data({ assets: [response.value.data?.team?.asset], teams: [response.value.data?.team?.uid] });
    if (route.params.id) {
      if (form.value.roles?.length && !isEqual(old_roles, form.value.roles))
        $track_event('permissions_changed', {
          type: 'Role was assigned',
          where: 'Team details',
        });

      $toast({
        text: 'Team updated successfully',
        type: 'success',
      });
    }

    else {
      $toast({
        text: 'Team created successfully',
        type: 'success',
      });
      $track_event('create_team', {
        members: response.value.data.team.users?.length,
        roles: response.value.data.team.roles?.length,
      });
    }

    router.push({ name: route?.params?.asset_id ? 'asset-settings-teams' : 'account-settings-teams' });
  }
}

async function save() {
  try {
    saving.value = true;

    const old_roles = team_detail.value.roles;
    form.value.organization = auth_store.current_organization?.uid;
    form.value.users = (form.value.users?.length) ? map(form.value.users, 'uid') : [];
    form.value.roles = (form.value.roles?.length) ? map(form.value.roles, 'uid') : [];
    form.value.asset = route.params.asset_id || null;

    const response = {};
    if (route.params.id)
      response.value = await common_store.update_data({
        type: 'patch_update',
        id: route.params.id,
        data: {
          team: {
            ...form.value,
            asset: route?.params?.asset_id || null,
            assets: team_detail.value.assets,
            resources: team_detail.value.resources,
          },
        },
        service: 'teams',
        append_data: false,
        state_prop: 'teams_map',
        update_state: true,
      });

    else
      response.value = await common_store.update_data({
        type: 'add',
        data: {
          team: {
            ...form.value,
            asset: route?.params?.asset_id || null,
            assets: team_detail.value.assets,
            resources: team_detail.value.resources,
          },
        },
        service: 'teams',
        append_data: false,
        state_prop: 'teams_map',
        update_state: true,
      });

    await setTeams(response, old_roles);

    saving.value = false;
  }
  catch (error) {
    saving.value = false;
    logger.log(error);
  }
}

async function getDetails() {
  try {
    is_loading.value = true;
    const { data } = await $services.teams.get({
      id: route.params.id,
    });
    team_detail.value = data.team;
    is_loading.value = false;
  }
  catch (error) {
    is_loading.value = false;
    logger.log('error', error);
  }
}

function formatUsers(uids) {
  const users = [
    ...common_store.scope_users(route.params?.asset_id).map((member) => {
      return {
        name: member?.first_name ? `${member.first_name} ${member?.last_name || ''}` : member.email,
        uid: member.uid,
        member: true,
      };
    }),
    ...common_store.scope_teams(route.params?.asset_id).map((team) => {
      return { ...team, member: false };
    }),
  ];
  return users.filter(user => uids.includes(user.uid));
}
function formatRoles(uids) {
  const roles = [
    ...common_store.scope_roles(route.params?.asset_id).map((role) => {
      return {
        name: role.name,
        uid: role.uid,
      };
    }),
  ];
  return roles.filter(role => uids.includes(role.uid));
}

onMounted(async () => {
  if (route.params.id) {
    await getDetails();
    selected_users.value = team_detail.value.users?.length ? formatUsers(team_detail.value.users) : [];
    selected_roles.value = team_detail.value.roles?.length ? formatRoles(team_detail.value.roles) : [];
    form$.value.update({
      name: team_detail.value.name,
      access_all_assets: team_detail.value.access_all_assets,
      description: team_detail.value.description,
      users: selected_users.value,
      roles: selected_roles.value,
      default: team_detail.value.default,
    });
  }
});

const breadcrumb_items = computed(() => {
  return [
    { uid: '1', label: common_store?.active_asset?.name || $t('Account Settings'), disabled: true },
    { uid: '2', label: $t('Teams'), to: { name: route?.params?.asset_id ? 'asset-settings-teams' : 'account-settings-teams' } },
    { uid: '3', label: route?.params?.id ? team_detail.value.name : $t('New Team') },
  ];
});
</script>

<template>
  <div>
    <HawkPageSecondaryHeader class="pt-6 pb-5 border-b">
      <template #left>
        <div class="flex items-center">
          <HawkBreadcrumbs :items="breadcrumb_items" />
        </div>
      </template>
    </HawkPageSecondaryHeader>
    <div>
      <div class="my-5">
        <div class="text-lg font-semibold text-gray-900">
          {{ $t('Team') }}
        </div>
        <div class="text-xs text-gray-600">
          {{ $t('Add/update team details') }}
        </div>
      </div>
      <HawkLoader v-if="is_loading" />
      <div v-else>
        <Vueform
          ref="form$"
          v-model="form"
          size="sm"
          :display-errors="false"
          :endpoint="save"
        >
          <div class="col-span-12">
            <div class="max-w-[700px]">
              <TextElement
                :label="$t('Name')"
                name="name"
                placeholder="Enter name"
                rules="required"
                class="mb-4"
              />
              <TextareaElement
                :label="$t('Description')"
                name="description"
                placeholder="Enter description"
                class="mb-4"
              />
              <template v-if="auth_store.check_split('default_teams')">
                <ToggleElement
                  class="mb-4"
                  name="default"
                  :label="$t('Mark as default')"
                  :info="$t('Adds all active users to the team')"
                />
                <hawk-assignee-input
                  v-if="!form.default"
                  class="w-full mr-5"
                  :multi="true"
                  :options="{
                    existing_users: selected_users,
                    name: 'users',
                    has_teams: false,
                    placeholder: $t('Select users or teams'),
                    class: 'mb-4',
                    label: $t('Members'),
                    info: $t('Choose members to add them to the team.'),
                  }"
                />
              </template>
              <hawk-assignee-input
                v-else
                class="w-full mr-5"
                :multi="true"
                :options="{
                  existing_users: selected_users,
                  name: 'users',
                  has_teams: false,
                  placeholder: $t('Select users or teams'),
                  class: 'mb-4',
                  label: $t('Members'),
                  info: $t('Choose members to add them to the team.'),
                }"
              />
              <hawk-roles-input
                class="w-full mr-5 mb-4"
                :multi="true"
                :options="{
                  existing_roles: selected_roles,
                  name: 'roles',
                  placeholder: $t('Select roles'),
                  label: $t('Roles'),
                  info: $t('The team members will inherit permissions from the below selected roles.'),
                }"
              />
              <ToggleElement v-if="!route.params.asset_id" :label="$t('Grant access to all assets')" name="access_all_assets" />
              <AssetResources v-if="!form.access_all_assets" :item_details="team_detail" @update="onTreeUpdate($event)" />
            </div>

            <div class="sticky bg-white bottom-0">
              <hr class="my-5">
              <div class="flex justify-end gap-3 pb-5">
                <HawkButton type="outlined" @click="$router.push({ name: $route?.params?.asset_id ? 'asset-settings-teams' : 'account-settings-teams' })">
                  {{ $t('Cancel') }}
                </HawkButton>
                <ButtonElement button-class="w-full bg-blue-600" name="submit" :submits="true" :disabled="!auth_store.check_permission('modify_teams', $route.params.asset_id)">
                  {{ route.params.id ? $t('Update') : $t('Save') }}
                </ButtonElement>
              </div>
            </div>
          </div>
        </Vueform>
      </div>
    </div>
  </div>
</template>
