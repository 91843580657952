import { getHostName } from './common.utils';
import { accessTokenCookie } from '~/common/utils/common.utils';

export function load_js_css_file(url, id, file_type) {
  return new Promise((resolve, reject) => {
    if (id && document.getElementById(id)) {
      resolve();
      return;
    }
    let element;

    if (file_type === 'js') {
      element = document.createElement('script');
      element.setAttribute('type', 'text/javascript');
      element.setAttribute('src', url);
    }
    else if (file_type === 'css') {
      element = document.createElement('link');
      element.setAttribute('rel', 'stylesheet');
      element.setAttribute('type', 'text/css');
      element.setAttribute('href', url);
    }

    if (typeof element != 'undefined') {
      element.id = id;
      document.getElementsByTagName('head')[0].appendChild(element);
      element.onload = resolve;
      element.onerror = reject;
    }
  });
}

export function loadScript(url, onload = () => {}, id = null) {
  if (id && document.getElementById(id))
    return onload();

  const scriptTag = document.createElement('script');
  scriptTag.setAttribute('src', url);
  scriptTag.id = id;
  document.head.appendChild(scriptTag);
  scriptTag.onload = onload;
}

export function loadScriptWithContents(contents, id = null, onload = () => {}) {
  if (id && document.getElementById(id))
    return onload();

  const scriptTag = document.createElement('script');
  scriptTag.innerHTML = contents;
  scriptTag.id = id;
  scriptTag.onload = onload;
  document.head.appendChild(scriptTag);
}

export function loadMarker() {
  if (getHostName() !== 'localhost')
    loadScriptWithContents('!(function (e, r, a) {  if (!e.__Marker) { e.__Marker = {}; const t = []; const n = { __cs: t }; [\'show\', \'hide\', \'isVisible\', \'capture\', \'cancelCapture\', \'unload\', \'reload\', \'isExtensionInstalled\', \'setReporter\', \'setCustomData\', \'on\', \'off\'].forEach((e) => { n[e] = function () { const r = Array.prototype.slice.call(arguments); r.unshift(e), t.push(r) } }); const s = r.createElement(\'script\'); s.async = 1, s.src = \'https://edge.marker.io/latest/shim.js\'; const i = r.getElementsByTagName(\'script\')[0]; i.parentNode.insertBefore(s, i) };  }(window, document))', 'markerio-js');
}

export function loadChargeBee() {
  loadScript('https://js.chargebee.com/v2/chargebee.js', () => {
    // eslint-disable-next-line no-undef
    Chargebee.init({
      site: 'taskmapper-test',
      publishableKey: 'test_Db3AJUoPTkSRX2fRFnsVk3r0zouSgSKZ',
    });
  }, 'chargebee-js');
}

export function loadAnalytics() {
  const analytics = window.analytics = window.analytics || [];
  if (!analytics.initialize)
    if (analytics.invoked) {
      logger?.error?.('Segment snippet included twice.');
    }
    else {
      analytics.invoked = !0;
      analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware'];
      analytics.factory = function (e) {
        return function () {
          // eslint-disable-next-line prefer-rest-params
          const t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (const method of analytics.methods)
        analytics[method] = analytics.factory(method);
      analytics.load = function (key, e) {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
        const n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = '<%= VITE_APP_SEGMENT_WRITE_KEY %>';
      analytics.SNIPPET_VERSION = '4.15.3';
      analytics.load(import.meta.env.VITE_APP_SEGMENT_WRITE_KEY);
    }
}

export function initFCWidget() {
  const fcScript = document.createElement('script');
  fcScript.setAttribute('src', 'https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js');
  const fcWidgetScript = document.createElement('script');
  fcWidgetScript.setAttribute('src', 'https://wchat.in.freshchat.com/js/widget.js');
  document.body.appendChild(fcScript);
  document.body.appendChild(fcWidgetScript);
  const preChatTemplate = {
    // Form header color and Submit button color.
    mainbgColor: '#0aa4db',
    // Form Header Text and Submit button text color.
    maintxColor: '#fff',
    // Chat Form Title
    heading: 'TaskMapper',
    // Chat form Welcome Message
    textBanner: 'Hello, thank you for reaching TaskMapper Support. Please share the below information. We\'ll use this to reach you in case we get disconnected.',
    // Submit Button Label.
    SubmitLabel: 'Start Chat',
    // Fields List - Maximum is 5
    // All the values are mandatory and the script will not work if not available.
    fields: {

      field1: {
        // Type for Name - Do not Change
        type: 'name',
        // Label for Field Name, can be in any language
        label: 'Name', // Default - Field ID for Name - Do Not Change
        fieldId: 'name',
        // Required "yes" or "no"
        required: 'yes',
        // Error text to be displayed
        error: 'Please Enter a valid name',
      },
      field2: {
        // Type for Email - Do Not Change
        type: 'email',
        // Label for Field Email, can be in any language
        label: 'Email',
        // Default - Field ID for Email - Do Not Change
        fieldId: 'email',
        // Required "yes" or "no"
        required: 'yes',
        // Error text to be displayed
        error: 'Please Enter a valid Email',
      },
      field3: {
        // Type for Phone - Do Not Change
        type: 'phone',
        // Label for Field Phone, can be in any language
        label: 'Phone',
        // Default - Field ID for Phone - Do Not Change
        fieldId: 'phone',
        // Required "yes" or "no"
        required: 'no',
        // Error text to be displayed
        error: 'Please Enter a valid Phone Number',
      },

    },
  };
  window.fcSettings = {
    token: '35e32135-57e6-4847-978e-12d8c806e054',
    host: 'https://wchat.in.freshchat.com',

    faqTags: {
      // Array of Tags
      tags: ['Taskmapper'],
      // For articles, the below value should be article.
      // For article category, the below value should be category.
      filterType: 'category', // Or filterType: 'article'
    },
    config: {
      ...(accessTokenCookie() && {
        headerProperty: {
          hideChatButton: true,
        },
      }),
      cssNames: {
        // The below element is mandatory. Please add any custom class or leave the default.
        widget: 'custom_fc_frame',
        // The below element is mandatory. Please add any custom class or leave the default.
        expanded: 'custom_fc_expanded',
      },
    },
    onInit() {
      const interval = setInterval(() => {
        if (!window.fcPreChatform?.fcWidgetInit)
          return;
        clearInterval(interval);
        window.fcPreChatform.fcWidgetInit(preChatTemplate);
      }, 5000);
    },
  };
}
