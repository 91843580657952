import { useRoute } from 'vue-router';
import { flatten } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import { inject, onBeforeMount } from 'vue';
import { useThermStore } from '../store/therm.store';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store.js';
import { useTerraStore } from '~/terra/store/terra.store.js';
import { useMap } from '~/common/composables/mapbox/maps';
import { useTasksStore } from '~/tasks/store/tasks.store';
import HawkConfirmationPopup from '~/common/components/organisms/hawk-confirmation-popup.vue';
import { parseStringifiedObjectsInArray } from '~/common/utils/common.utils';
import EditGroupPopup from '~/therm/components/therm-edit-group-popup.vue';

export function useThermHelperComposable() {
  const { loadImages } = useMap();
  const $t = inject('$t');
  const $services = inject('$services');
  const $toast = inject('$toast');
  const route = useRoute();
  const auth_store = useAuthStore();
  const common_store = useCommonStore();
  const terra_store = useTerraStore();
  const therm_store = useThermStore();
  const tasks_store = useTasksStore('therm_tasks_store');
  const loadTaskIcons = () => {
    for (let i = 1; i <= 4; i++) {
      loadImages(therm_store.map, `/img/icons/priority/${i}.png`, `priority-${i}`);
      loadImages(therm_store.map, `/img/icons/priority/${i}-selected.png`, `priority-${i}-selected`);
    }
    for (let i = 1; i <= 5; i++) {
      loadImages(therm_store.map, `/img/icons/status/${i}.png`, `status-${i}`);
      loadImages(therm_store.map, `/img/icons/status/${i}-selected.png`, `status-${i}-selected`);
    }
  };
  const loadDefectStatusIcons = () => {
    const available_colors = ['#2ED3B7', '#1570EF', '#6938EF', '#039855', '#475467', '#D92D20', '#D444F1', '#DC6803', '#EE46BC', '#FF692E'];
    available_colors.forEach((color) => {
      loadImages(therm_store.map, `/img/icons/defects/${color.slice(1)}.png`, `defect-status-${color.slice(1)}`);
    });
  };
  let turf;
  onBeforeMount(async () => {
    turf = (await import('@turf/turf'));
  });
  function getParsedTasks() {
    try {
      const reference_locations = getReferenceLocations();
      const filtered_tasks = therm_store.filtered_defects_therm_lite(tasks_store.tasks(), 'tasks');

      return (filtered_tasks || []).reduce((features, task) => {
        const location
          = task?.properties?.reference_name
            ? reference_locations[task.properties.reference_name]?.geometry
            : task.location;

        if (location && !task?.properties?.issue_uid) {
          const feature = turf.feature(location);
          feature.properties.uid = task.uid;
          feature.properties.icon = `status-${task.status}`;
          feature.properties.feature_type = 'task';
          feature.properties.feature_type_id = task.uid;
          feature.properties.data = task;
          features.push(feature);
        }
        return features;
      }, []);
    }
    catch (err) {
      logger.log(err);
      return [];
    }
  }
  function getParsedDefects() {
    try {
      const filtered_features = therm_store.filtered_defects_therm_lite(therm_store.features);

      return (filtered_features || []).reduce((features, feature) => {
        const centroid = turf.centroid(feature.geometry);
        const status = therm_store.status_map[feature.properties.status];

        if (location && status) {
          const feature = turf.feature({
            type: 'Point',
            coordinates: centroid.geometry.coordinates,
          });
          feature.properties.uid = status.uid;
          const color = status.color.slice(1);
          if (!therm_store.map.hasImage(`defect-status-${color}`))
            feature.properties.icon = 'defect-status-475467';
          else
            feature.properties.icon = `defect-status-${color}`;
          feature.properties.feature_type = 'defect-status';
          feature.properties.feature_type_id = status.uid;
          feature.properties.data = status;
          features.push(feature);
        }
        return features;
      }, []);
    }
    catch (err) {
      logger.log(err);
      return [];
    }
  }
  const { open: openConfirmationPopup, close: closeConfirmationPopup, patchOptions: patchConfirmationPopup } = useModal({
    component: HawkConfirmationPopup,
  });
  function createThermTasks(issues = []) {
    if (issues.length)
      if (issues.every(item => therm_store.tasks_hash[item.properties.uid])) {
        patchConfirmationPopup(
          {
            attrs: {
              header: $t('Tasks already exists'),
              content: $t('The selected issues already have a task created/associated with them.'),
              has_confirm_button: false,
              onClose() {
                closeConfirmationPopup();
              },

            },

          },
        );
        openConfirmationPopup();
      }
      else {
        patchConfirmationPopup(
          {
            attrs: {
              header: $t('Create tasks'),
              content: $t('Do you want to create tasks for the selected issues?'),
              onClose() {
                closeConfirmationPopup();
              },
              confirm: async () => {
                await therm_store.create_therm_tasks({
                  issues: parseStringifiedObjectsInArray(issues).filter(
                    item => !therm_store.tasks_hash[item.properties.uid],
                  ),
                });
                closeConfirmationPopup();
              },

            },

          },
        );
        openConfirmationPopup();
      }
  }
  function getReferenceLocations() {
    const data = {};
    therm_store.features.forEach((feature) => {
      if (feature.properties.name)
        data[feature.properties.name] = turf.centroid(feature);
    });
    return data;
  }
  function addSymbols() {
    try {
      if (!therm_store.map?.getSource('symbol-source'))
        return;
      let features = [];
      const parsed_defects = (therm_store.show_defect_status_icons ? getParsedDefects() : []);
      features = [...getParsedTasks(), ...(!therm_store.use_tasks ? parsed_defects : [])];

      therm_store.map.getSource('symbol-source').setData({
        type: 'FeatureCollection',
        features: flatten(features),
      });
    }
    catch (err) {
      logger.log(err);
    }
  }
  function getThermTableColumns() {
    return [
      {
        id: 'name',
        header: $t('Name'),
        accessorKey: 'name',
        enableSorting: false,
        enableResizing: false,
      },
      {
        id: 'affected',
        header: $t('Affected'),
        accessorKey: 'affected',
        enableSorting: false,
        enableResizing: false,
      },
      {
        id: 'total_dc_capacity',
        header: $t('Total DC capacity'),
        accessorKey: 'total_dc_capacity',
        enableSorting: false,
        enableResizing: false,
      },
      {
        id: 'issues',
        header: $t('Issues'),
        accessorKey: 'issues',
        enableSorting: false,
        enableResizing: false,
      },
      ...(auth_store.is_internal_user
        ? [{
            id: 'action_button',
            header: '',
            maxSize: 30,
            accessorKey: '',
            enableSorting: false,
            enableResizing: false,
            show_on_hover: true,
          }]
        : []),
    ];
  }
  const { open: openEditGroupPopup, close: closeEditGroupPopup, patchOptions: patchEditGroupPopup } = useModal({
    component: EditGroupPopup,
  });
  function getAffectedAndTotalMetrics(data) {
    let affected_capacity = data?.custom_metrics?.affected_capacity;
    if (affected_capacity === null)
      affected_capacity = data?.metrics?.modules?.affected
        ? ((data.metrics.modules.affected * (data.moduleWattPeak || 300)) / 1000)
        : 0;
    let total_dc_capacity = data?.custom_metrics?.total_dc_capacity;
    if (total_dc_capacity === null)
      total_dc_capacity = data?.metrics?.modules?.total
        ? ((data.metrics.modules.total * (data.moduleWattPeak || 300)) / 1000000)
        : 0;
    return { affected_capacity: affected_capacity.toFixed(1), total_dc_capacity: total_dc_capacity.toFixed(1) };
  }

  function editGroupDetails(group, view) {
    patchEditGroupPopup({
      attrs: {
        onClose() {
          closeEditGroupPopup();
        },
        group: {
          name: group.name,
          ...getAffectedAndTotalMetrics(group),
        },
        async on_save(data, form) {
          try {
            const response = await $services.therm.put({
              body: form.data,
              attribute: `views/${view.uid}/groups/${group.uid}/custom-metrics`,
            });
            common_store.asset_meta[view.assetUid].therm_list = common_store.asset_meta[view.assetUid].therm_list.map((view_data) => {
              if (view_data.uid === view.uid) {
                view_data.custom_metrics = response.data.view_custom_metrics;
                view_data.groups = view_data.groups.map((group_data) => {
                  if (group_data.uid === group.uid)
                    group_data.custom_metrics = response.data.group_custom_metrics;
                  return group_data;
                });
              }
              return view_data;
            });
            $toast({
              title: 'Success',
              text: 'Group details updated successfully!',
              type: 'success',
            });
          }
          catch (error) {
            $toast({
              title: 'Error',
              text: error?.message || 'Group details updating failed!',
              type: 'error',
            });
          }
          closeEditGroupPopup();
        },
      },
    });
    openEditGroupPopup();
  }

  async function flyToAssociatedFeature(type) {
    try {
      const metadata = JSON.parse(atob(route.query.metadata));
      if (!(metadata.group && metadata.project))
        throw new Error('Group or Project not found');
      const store = type === 'terra' ? terra_store : therm_store;
      let feature = null;
      let project = null;
      if (type === 'terra') {
        if (!store.active_projects_map[metadata.project].features_enabled) {
          project = store.active_projects_data_map({ all_projects: true })[metadata.project];
          await store.toggle_project({ project, do_not_fly: true });
        }
        feature = store.features_hash[metadata.feature];
      }
      else {
        const group = store.container.groups[metadata.group];
        store.set_active_group({ group });
        await store.set_therm_active_project({ project: group.projects[metadata.project], is_active: true });
        feature = store.features_hash[metadata.issue];
      }
      if (feature) {
        const location = turf.centroid(feature).geometry.coordinates;
        store.map.flyTo({
          center: location,
          zoom: 20,
          speed: 4,
        });
        store.selected_features = [feature];
      }
      else if (metadata.issue || metadata.feature) {
        throw new Error('Feature not found');
      }
      else {
        await terra_store.fly_to_project({ project });
      }
    }
    catch (error) {
      $toast({
        title: $t('Location not found'),
        text: $t('Can not navigate to the location. You don\'t have access, or the location is no longer available'),
        type: 'warning',
      });
    }
  }
  return { addSymbols, loadTaskIcons, loadDefectStatusIcons, createThermTasks, getAffectedAndTotalMetrics, getThermTableColumns, editGroupDetails, flyToAssociatedFeature };
}
