<script setup>
const emit = defineEmits(['close', 'confirm']);
</script>

<template>
  <hawk-modal-container>
    <hawk-modal-header @close="emit('close')">
      <template #title>
        {{ $t('Dashboard') }}
      </template>
    </hawk-modal-header>
    <hawk-modal-content @close="emit('close')">
      <div class="text-sm">
        {{ $t('You have unsaved changes. Do you want to navigate away?') }}
      </div>
    </hawk-modal-content>
    <hawk-modal-footer>
      <template #right>
        <div class="flex justify-end gap-4">
          <HawkButton type="outlined" @click="emit('close')">
            {{ $t('Close') }}
          </HawkButton>
          <HawkButton @click="emit('confirm')">
            {{ $t('Confirm') }}
          </HawkButton>
        </div>
      </template>
    </hawk-modal-footer>
  </hawk-modal-container>
</template>

<style lang="scss" scoped>

</style>
