<script setup>
import { useModal } from 'vue-final-modal';
import TableWrapperVue from '~/common/components/organisms/hawk-table/table.wrapper.vue';
import InventoryCustomField from '~/inventory/components/inventory-custom-fields/inventory-custom-field.vue';
import { useAddCustomFields } from '~/inventory/composables/inventory-add-custom-field.composable.js';
import InventoryStockUnitDetails from '~/inventory/components/inventory-items/inventory-item-stocks/inventory-item-stock-details.vue';
import InventoryItemStockStatus from '~/inventory/components/inventory-items/inventory-item-stock-status.vue';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import useAbortController from '~/common/composables/abort-controller.js';
import InventoryItemStockAggregationModal from '~/inventory/components/inventory-items/inventory-item-stocks/inventory-item-stock-aggregation-modal.vue';
import StockAggregationEdit from '~/inventory/components/inventory-items/inventory-item-stocks/stock-aggregation-edit.vue';
import { downloadBlob } from '~/plans/utils/helper.js';
import { searchData } from '~/common/utils/common.utils';

const props = defineProps({
  warehouse_id: { type: String, default: '' },
});

const { $t, $services, route, $toast } = useCommonImports();
const inventory_store = useInventoryStore();
const table_instance = ref(null);
const state = reactive({
  is_loading: false,
  is_export_loading: false,
  aggregate_item_search_key: '',
  search: '',
  items: [],
  pagination_config: { totalRows: 0, pageSize: 25, pageIndex: 1 },
  aggregation_fields: [],
  aggregation_custom_fields: [],
  force_update: 0,
});

const item_details = computed(() => inventory_store.get_active_item_details);
const custom_fields = computed(() => inventory_store.get_custom_fields({ uid: item_details.value.uid }, true));
const columns = computed(() => ([
  {
    accessorKey: 'serial_number',
    header: $t('Serial Number'),
    id: 'serial_number',
    can_aggregate: false,
    can_edit: false,
    type: 'text',
  },
  ...(item_details.value?.is_batch_number
    ? [{
        accessorKey: 'batch_number',
        header: $t('Pallet Number'),
        id: 'batch_number',
        can_aggregate: true,
        can_edit: false,
        type: 'text',
      }]
    : []),
  {
    accessorKey: 'location_id',
    header: $t('Location'),
    id: 'location_id',
    can_aggregate: true,
    can_edit: false,
    type: 'text',
  },
  {
    accessorKey: 'stock_status',
    header: $t('Status'),
    id: 'stock_status',
    can_aggregate: true,
    can_edit: false,
    type: 'text',
  },
  ...(state.aggregation_fields.length
    ? [{
        accessorKey: 'stock_quantity',
        header: $t('Quantity'),
        id: 'stock_quantity',
        can_aggregate: true,
        can_edit: false,
        type: 'text',
      }]
    : []),
  ...custom_fields.value.map((custom_field) => {
    return {
      ...custom_field,
      accessoryKey: custom_field.uid,
      header: custom_field.name,
      id: custom_field.uid,
      column_field_edit: true,
      is_custom_field: true,
      can_aggregate: true,
      can_edit: true,
    };
  }),
]));

const menu_items = computed(() => ([
  {
    uid: 'aggregate',
    label: $t('Aggregate'),
    on_click: () => {
      openAggregationModal();
    },
  },
  {
    uid: 'export',
    label: $t('Export'),
    on_click: () => {
      state.is_export_loading = true;
    },
  },
]));

const filtered_data = computed(() => {
  const excluded_fields = ['location_id', 'stock_status', 'stock_quantity'];
  const available_fields_to_search = state.aggregation_fields
    .filter(field => !field.is_custom_field && !excluded_fields.includes(field.accessorKey))
    .map(field => field.accessorKey);
  return searchData(state.items, state.aggregate_item_search_key, available_fields_to_search);
});

const customFields = useAddCustomFields({ added: (field) => {} }, 'stock_unit', item_details.value.uid);

function editCustomField(uid) {
  customFields.open(uid);
}

function onSearch(value) {
  state.items = [];
  table_instance.value.resetPagination();
  state.search = value;
  state.pagination_config = { pageSize: 25, pageIndex: 1 };
  getItems({ pagination_state: state.pagination_config });
}

async function getItems({ sort_by, pagination_state }) {
  try {
    state.is_loading = true;
    const query = {
      item: item_details.value.uid,
      page: pagination_state.pageIndex,
      limit: pagination_state.pageSize,
      search: state.search,
      ...(props?.warehouse_id?.length ? { warehouse: props?.warehouse_id } : {}),
      asset: route.params.asset_id,
    };
    if (sort_by)
      query.sort = sort_by.desc ? `-${sort_by.id}` : sort_by.id;

    const response = await inventory_store.$services.inventory_items.get_stock_units({ query });
    state.items = response?.data?.stock_units;
    state.pagination_config.totalRows = response.headers['x-total-count'];
    state.is_loading = false;
  }
  catch (error) {
    state.is_loading = false;
    logger.log('🚀 ~ getItems ~ error:', error);
  }
}

const { open: openStockDetails, close: closeStockDetails, patchOptions } = useModal({
  component: InventoryStockUnitDetails,
});

function openDetails(event) {
  patchOptions({
    attrs: {
      item: item_details.value,
      value: event,
      onClose() {
        closeStockDetails();
      },
    },
  });
  openStockDetails();
}

const { open: openStockAggregationModal, close: closeStockAggregationModal, patchOptions: patchOptionsStockAggregation } = useModal({
  component: StockAggregationEdit,
});

function stockAggregationDetails(event) {
  patchOptionsStockAggregation({
    attrs: {
      item: event,
      aggregation_fields: state.aggregation_fields,
      onClose() {
        closeStockAggregationModal();
      },
      async onUpdate() {
        try {
          state.is_loading = true;
          const payload = { fields: state.aggregation_fields?.map(field => field.id) };
          const response = await inventory_store.$services?.inventory_items?.stock_item_aggregate({
            id: item_details.value?.uid,
            body: payload,
          });
          state.items = response.data.result;
          state.is_loading = false;
        }
        catch (error) {
          state.is_loading = false;
          logger.info('[DEBUG] inventory-item-details-stocks.vue::182\n', error);
        }
      },
    },
  });
  openStockAggregationModal();
}

const { open: openAggregation, close: closeAggregation, patchOptions: aggregationPatchOptions } = useModal({
  component: InventoryItemStockAggregationModal,
});

function openAggregationModal() {
  aggregationPatchOptions({
    attrs: {
      aggregation_fields: state.aggregation_fields?.filter(field => field.id !== 'stock_quantity'),
      fields: columns.value,
      onClose() {
        closeAggregation();
      },
      async save(selected_fields) {
        try {
          state.is_loading = true;
          state.items = [];
          if (selected_fields?.fields?.length) {
            state.pagination_config = { totalRows: 0, pageSize: 25, pageIndex: 1 };
            state.aggregation_fields = selected_fields.fields.length
              ? [...selected_fields.fields,
                  {
                    accessorKey: 'stock_quantity',
                    header: $t('Quantity'),
                    id: 'stock_quantity',
                    can_aggregate: true,
                    can_edit: false,
                    type: 'text',
                  },
                ]
              : [];
            state.aggregation_custom_fields = selected_fields.fields.filter(field => field?.is_custom_field);
            const payload = { fields: selected_fields?.fields.length ? [...selected_fields.fields.map(field => field.id), 'stock_quantity'] : [] };
            const response = await inventory_store.$services?.inventory_items?.stock_item_aggregate({
              id: item_details.value?.uid,
              body: payload,
            });
            state.aggregation_fields.force_update++;
            state.items = response.data.result;
            state.pagination_config.totalRows = response.data.result?.length || 0;
          }
          else {
            state.aggregation_fields.force_update = 0;
            state.aggregation_fields = [];
            state.aggregation_custom_fields = [];
            table_instance.value.resetPagination();
            state.pagination_config = { pageSize: 25, pageIndex: 1 };
            await getItems({ pagination_state: state.pagination_config });
          }
          state.is_loading = false;
        }
        catch (error) {
          state.is_loading = false;
        }
      },
    },
  });
  openAggregation();
}

const controllers = useAbortController();

async function export_report() {
  try {
    const res = await $services.inventory_reports.export_stock_units({
      query: {
        items: item_details.value.uid,
        ...(props?.warehouse_id?.length ? { locations: props?.warehouse_id } : {}),
        asset: route.params.asset_id,
      },
    });

    const csvData = new Blob([res.data], { type: 'text/csv;charset=utf-8;' });
    downloadBlob(csvData, 'csv', 'stock_units');
  }
  catch (error) {
    state.is_export_loading = false;
    if (error.status === 401)
      $toast({ type: 'error', title: $t('No permission'), text: 'You don\'t have enough permissions.' });
    else
      $toast({ type: 'error', title: 'Error', text: 'Try again' });
  }
}

function abortExport() {
  controllers.abort('export_report');
}

onMounted(() => {
  getItems({ pagination_state: state.pagination_config });
});
</script>

<template>
  <div class="w-full overflow-x-hidden">
    <div class="flex items-center justify-between mb-5 mt-1 ml-1">
      <HawkSearchInput
        v-if="state.aggregation_fields.length"
        v-model="state.aggregate_item_search_key"
        :placeholder="$t('Search pallet number/serial number')"
      />
      <HawkSearchInput
        v-else
        v-model="state.search"
        :debounce_time="700"
        :placeholder="$t('Search pallet number/serial number')"
        @update:modelValue="onSearch"
      />
      <HawkMenu
        additional_trigger_classes="!ring-0 !focus:ring-0"
        :items="menu_items" position="fixed"
      >
        <template #trigger>
          <HawkButton icon type="outlined">
            <IconHawkSettingsIcon />
          </HawkButton>
        </template>
      </HawkMenu>
    </div>
    <TableWrapperVue v-if="state.aggregation_fields.length">
      <HawkTable
        :key="state.aggregation_fields.force_update"
        is_gapless
        :manual_pagination="false"
        :pagination_config="{ ...state.pagination_config }"
        :data="filtered_data"
        :show_menu_header="false"
        :columns="state.aggregation_fields"
        :is_loading="state.is_loading"
        is_skeleton_loading_enabled
        @sort="getItems($event)"
        @tableInstance="table_instance = $event"
        @columnFieldEditClick="editCustomField"
        @rowClicked="stockAggregationDetails($event)"
      >
        <template #serial_number="{ data }">
          <span class="font-medium">
            {{ data.row.original.serial_number }}
          </span>
        </template>
        <template #context-menu-header>
          <div class="flex items-center justify-between p-2 border-b sticky top-0 bg-white">
            <span class="ml-4 text-sm font-semibold text-gray-700">
              {{ $t('Fields') }}
            </span>
            <HawkButton size="sm" type="link" @click="customFields.open()">
              <IconHawkPlus class="h-5 w-5" />
              {{ $t('New Field') }}
            </HawkButton>
          </div>
        </template>
        <template #stock_status="{ data }">
          <InventoryItemStockStatus :status="data.getValue() " />
        </template>
        <template #location_id="{ data }">
          <div>
            {{ inventory_store.get_location_details({
              uid: data?.row?.original.location_id,
              type: data?.row?.original.location_type,
            })?.name || '-' }}
          </div>
        </template>
        <template
          v-for="custom_field in state.aggregation_custom_fields"
          #[custom_field.id]="cf"
          :key="custom_field.id"
        >
          <InventoryCustomField :field="custom_field" :value="cf.data.row.original[custom_field.uid]" />
        </template>
      </HawkTable>
    </TableWrapperVue>
    <TableWrapperVue v-else container_class="border" :height="500">
      <HawkTable
        :key="columns.length"
        is_gapless
        :manual_pagination="true"
        :pagination_config="{ ...state.pagination_config }"
        :data="state.items"
        :columns="columns"
        :is_loading="state.is_loading"
        is_skeleton_loading_enabled
        @pagination="getItems($event)"
        @sort="getItems($event)"
        @rowClicked="openDetails($event)"
        @tableInstance="table_instance = $event"
        @columnFieldEditClick="editCustomField"
      >
        <template #serial_number="{ data }">
          <span class="font-medium">
            {{ data.row.original.serial_number }}
          </span>
        </template>
        <template #context-menu-header>
          <div class="flex items-center justify-between p-2 border-b sticky top-0 bg-white">
            <span class="ml-4 text-sm font-semibold text-gray-700">
              {{ $t('Fields') }}
            </span>
            <HawkButton size="sm" type="link" @click="customFields.open()">
              <IconHawkPlus class="h-5 w-5" />
              {{ $t('New Field') }}
            </HawkButton>
          </div>
        </template>
        <template #stock_status="{ data }">
          <InventoryItemStockStatus :status="data.getValue()" />
        </template>
        <template #location_id="{ data }">
          <div>
            {{ inventory_store.get_location_details({
              uid: data?.row?.original.location_id,
              type: data?.row?.original.location_type,
            })?.name || '-' }}
          </div>
        </template>
        <template
          v-for="custom_field in custom_fields"
          #[custom_field.uid]="cf"
          :key="custom_field.uid"
        >
          <InventoryCustomField
            :field="custom_field"
            :value="cf.data.row.original.custom_fields.find(({ uid }) => uid === custom_field.uid)?.value"
          />
        </template>
      </HawkTable>
    </TableWrapperVue>
  </div>
  <HawkExportToast
    v-if="state.is_export_loading"
    class="z-[1100]"
    :submit="export_report"
    :progress_text="$t('Exporting Report')"
    :completed_text="$t('Report Exported')"
    @complete="state.is_export_loading = false"
    @cancel="state.is_export_loading = false; abortExport();"
  />
</template>
