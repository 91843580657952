<script setup>
import { ref } from 'vue';
import { useCommonStore } from '~/common/stores/common.store.js';
import HawkTreeSelect from '~/common/components/organisms/hawk-tree/hawk-treeselect.vue';

const props = defineProps({
  asset_uid: {
    type: String,
    default: '',
  },
  resources: {
    type: Array,
    default: () => [],
  },
  selected_assets: {
    type: Array,
    default: () => [],
  },
  on_submit: {
    type: Function,
    default: () => true,
  },
});

const emit = defineEmits(['close']);

const $t = inject('$t');
const route = useRoute();
const $services = inject('$services');
const common_store = useCommonStore();

const form$ = ref(null);
const is_loading = ref(false);
const form = ref({});
const options = ref([]);

const assets_options = computed(() => {
  const assets_list = route?.params?.asset_id
    ? [common_store.assets.find(asset => asset.uid === route.params.asset_id)]
    : common_store.assets;

  const not_available_assets = props.asset_uid?.length
    ? props.selected_assets.filter(uid => uid !== props.asset_uid)
    : props.selected_assets;

  return Object.values(assets_list.filter(asset => !not_available_assets.includes(asset.uid))).map((item) => {
    return {
      label: item.name,
      value: item.uid,
    };
  });
});

const map_data_options = [
  {
    label: $t('No access'),
    value: 'no_access',
  },
  {
    label: $t('Allow access to'),
    value: 'allow_access',
  },
];

async function getData() {
  if (form.value.access_type === 'no_access')
    return;
  is_loading.value = true;
  options.value = [];
  const response = await $services.assets.post({
    attribute: 'tree-views',
    body: {
      assets: [form.value.asset_uid],
    },
  });

  options.value = response.data?.assets[0]?.containers || [];

  options.value = options.value.map((container) => {
    return {
      ...container,
      children: (container?.groups || []).map((group) => {
        return { ...group, children: group.projects || [] };
      }),
    };
  });
  is_loading.value = false;
}
async function remove_asset() {
  await props.on_submit({ action: 'remove', asset_uid: props.asset_uid });
}

async function save() {
  let data = {};
  if (form.value.access_type === 'no_access')
    data = { action: form.value.access_type, asset_uid: form.value.asset_uid };
  else if (form.value.access_type === 'allow_access')
    data = {
      action: form.value.access_type,
      asset_uid: form.value.asset_uid,
      resources: form.value?.resources?.map((item) => {
        return {
          uid: item.uid,
          asset: item.asset,
          type: item.type,
          name: item.name,
          ...(item.container ? { container: item.container } : {}),
          ...(item.group ? { group: item.group } : {}),
        };
      }) || [],
    };
  props.on_submit(data);
}
onMounted(async () => {
  init();
});
async function init() {
  await form$.value.load({
    asset_uid: props.asset_uid,
    access_type: !props.resources.length ? 'no_access' : 'allow_access',
    resources: { resources: props.resources || [] },
  });
}
</script>

<template>
  <hawk-modal-container :width="400">
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :endpoint="save"
      sync
    >
      <div class="col-span-12">
        <hawk-modal-header @close="emit('close')">
          <template #title>
            <div class="mb-1">
              {{ $t('Assets') }}
            </div>
            <div class="text-sm font-normal text-gray-600">
              {{ $t('Select the assets maps to assign to this user') }}
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content class="w-[600px]" :is_scroll="false">
          <div>
            <SelectElement
              name="asset_uid"
              :search="true"
              :items="assets_options"
              :native="false"
              :label="$t('Asset')"
              :placeholder="$t('Select Asset')"
              :can-deselect="false"
              :can-clear="false"
              class="mb-4"
              :loading="is_loading"
              :disabled="asset_uid"
              @change="getData"
            />
            <RadiogroupElement
              name="access_type"
              class="mb-4"
              :label="$t('Map data')"
              :items="map_data_options"
              @change="getData"
            />
            <HawkTreeSelect
              v-if="form.access_type === 'allow_access' && (options || []).length"
              :options="{
                name: 'resources',
                object: true,
              }"
              label=" "
              children_key="children"
              data_type="object"
              label_key="name"
              value_key="uid"
              :data="options || []"
              :initial_state="resources || []"
              select_type="BRANCH_PRIORITY"
              :option_text_length="20"
              @updateForm="form.resources = $event"
            />
            <div v-if="form.access_type === 'allow_access' && !((options || [])?.length) && !is_loading">
              <TextElement disabled label=" " :placeholder="$t('No Data')" />
            </div>
          </div>
        </hawk-modal-content>
        <hawk-modal-footer class="flex items-center justify-between">
          <template #left>
            <HawkButton v-if="asset_uid" color="error" type="link" @click="() => remove_asset()">
              Remove
            </HawkButton>
          </template>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <HawkButton @click="save">
                {{ $t('Save') }}
              </HawkButton>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
