<script setup>
const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  accesses: {
    type: Array,
    default: () => [],
  },
});
const emit = defineEmits(['input']);

const open = ref(false);

const select_items = computed(() => {
  return props.accesses.map((item) => {
    return {
      ...item,
      value: item.type,
    };
  });
});
</script>

<template>
  <div class="w-56">
    <Vueform size="sm">
      <div class="col-span-12">
        <SelectElement
          name="access_options"
          :search="false"
          :default="value || select_items[0].value"
          :items="select_items"
          :native="false"
          :can-deselect="false"
          :can-clear="false"
          class="text-sm"
          @open="open = true"
          @close="open = false"
          @select="emit('input', $event)"
        >
          <template #caret>
            <div class="mr-2">
              <IconHawkChevronUp v-if="open" />
              <IconHawkChevronDown v-else />
            </div>
          </template>
          <template #option="{ option }">
            <div>
              <div class="font-medium text-sm">
                {{ $t(option.name) }}
              </div>
              <div class="text-xs">
                {{ $t(option.description) }}
              </div>
            </div>
          </template>
        </SelectElement>
      </div>
    </Vueform>
  </div>
</template>
