<script setup>
import { computed } from 'vue';
import { useAuthStore } from '~/auth/stores/auth.store';
import { useCommonStore } from '~/common/stores/common.store';
import { getUserName } from '~/dms/utils/dms.utils';

const props = defineProps({
  message: {
    type: Object,
    default: null,
  },
});

const auth_store = useAuthStore();
const common_store = useCommonStore();
const logged_in_user_details = computed(() => auth_store.logged_in_user_details);

const members = computed(() => {
  const is_user_logged_in = logged_in_user_details.value.user_id === props.message?.from;
  const user = is_user_logged_in ? logged_in_user_details.value : getUser(props.message?.from);
  return {
    from: {
      name: is_user_logged_in ? `${user?.firstname} ${user?.lastname}` : user?.name,
      email: user?.email || null,
      user: props.message?.from,
    },
    to: props.message?.to?.map(uid => getUser(uid)),
    cc: props.message?.cc?.map(uid => getUser(uid)),
  };
});

function getUser(uid) {
  const user = common_store.get_org_or_internal_user(uid);
  return {
    uid: user?.uid || uid,
    name: getUserName(user),
    email: user?.email || null,
  };
}
</script>

<template>
  <div class="border border-gray-200 rounded-lg bg-white shadow-md py-3 px-1.5 w-[450px] z-[100]">
    <div class="grid grid-cols-12 my-2 items-center">
      <div class="col-span-2 text-sm font-medium text-gray-400 text-right mr-2">
        {{ $t('from') }}:
      </div>
      <div class="col-span-10 text-sm text-gray-900 font-medium">
        <span v-if="members.from.name">{{ members.from.name }} </span>
        <HawkMembers v-else :members="members.from.user" size="md" type="label" :has_avatar="false" />
        <span v-if="members.from?.email" class="text-gray-600 font-normal">
          {{ `<${members.from?.email}>,` }}
        </span>
      </div>
    </div>
    <div class="grid grid-cols-12 my-2">
      <div class="col-span-2 text-sm font-medium text-gray-400 text-right mr-2">
        {{ $t('to') }}:
      </div>
      <template v-if="members?.to?.length">
        <div class="col-span-10 items-center text-sm">
          <div v-for="to in members.to" :key="to" class="text-gray-900 font-medium">
            <span v-if="to.name">{{ to.name }} </span>
            <HawkMembers v-else :members="to.uid" size="md" type="label" :has_avatar="false" />
            <span v-if="to?.email" class="text-gray-600 break-all font-normal">
              {{ `<${to.email}>,` }}
            </span>
          </div>
        </div>
      </template>
      <div v-else>
        -
      </div>
    </div>
    <div class="grid grid-cols-12 my-2">
      <div class="col-span-2 text-sm font-medium text-gray-400 text-right mr-2">
        {{ $t('cc') }}:
      </div>
      <template v-if="members?.cc?.length">
        <div class="col-span-10 items-center text-sm">
          <div v-for="cc in members.cc" :key="cc" class="text-gray-900 font-medium">
            {{ cc.name }}
            <span v-if="cc?.email" class="text-gray-600 break-all font-normal">
              {{ `<${cc.email}>,` }}
            </span>
          </div>
        </div>
      </template>
      <div v-else>
        -
      </div>
    </div>
    <div class="grid grid-cols-12 my-2 items-center">
      <div class="col-span-2 text-sm font-medium text-gray-400 text-right mr-2">
        {{ $t('date') }}:
      </div>
      <div class="col-span-10 flex flex-wrap items-center text-sm font-medium">
        {{ $date(message.timestamp, 'L_DATETIME_MED') }}
      </div>
    </div>
  </div>
</template>
