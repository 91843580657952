import { markRaw } from 'vue';
import sensehawk_logo from '../../../assets/logos/sensehawk@3x.png';
import { useAuthStore } from '~/auth/stores/auth.store';
import { services } from '~/common/installers/services';

export function usePdfExporter() {
  const auth_store = useAuthStore();
  const current_organization = auth_store.current_organization;
  const $services = markRaw(services);

  async function createPDFGeneratorWebWorker() {
    const comlink = await import('comlink');
    const worker = new Worker(new URL('./pdf-generator.worker.js', import.meta.url), { type: 'module' });
    return {
      wrapped_worker: comlink.wrap(worker),
      worker,
    };
  }

  async function getOrgLogo(fallback_to_app_logo = true) {
    try {
      const { data } = await $services.common.get({
        url: `get-organization-logo/?organization=/${current_organization.uid}`,
      });
      if (!data?.image)
        throw new Error('noimage');
      return `data:image/jpg;base64,${data.image}`;
    }
    catch (error) {
      logger.error(error);
      return fallback_to_app_logo ? await getAppLogo() : '';
    }
  }

  async function getAppLogo() {
    return await imageToDataURL(sensehawk_logo);
  }

  async function imageToDataURL(url, fallback) {
    try {
      const res = await fetch(url);
      const blob = await res.blob();
      const data_url = await blobToDataURL(blob);
      if (data_url?.startsWith('data:binary/octet-stream'))
        return data_url.replace('data:binary/octet-stream;base64,', 'data:image/jpg;base64,');

      else if (!data_url?.startsWith('data:image/'))
        throw new Error(`Bad image at ${url}`);
      return data_url;
    }
    catch (_) {
      const image_strings = await import('~/common/utils/image-strings.js');
      return fallback ?? image_strings.nothumbnailfound;
    }
  }

  async function blobToDataURL(blob) {
    const data_url = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
    return data_url;
  }

  return {
    createPDFGeneratorWebWorker,
    getOrgLogo,
    getAppLogo,
    blobToDataURL,
    imageToDataURL,
  };
}
