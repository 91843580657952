import { apiWrapper } from './api-wrapper.util';

export default class BaseService {
  constructor(url, baseURL = null) {
    this.url = url;
    this.baseURL = baseURL;
  }

  async getAll(req = {}) {
    return apiWrapper(
      {
        url: `${req.url || this.url}/${
          req.attribute ? `${req.attribute}` : ''
        }`,
        ...(req.query && { params: req.query }),
        ...(this.baseURL && { baseURL: this.baseURL }),
        ...(req.headers && { headers: req.headers }),
        ...(req.extraOptions || {}),
        toast: req.toast,
        signal: req.signal,
      },
    );
  }

  async post(req = {}) {
    return apiWrapper(
      {
        url: `${req.url || this.url}/${req.id ? `${req.id}/` : ''}${
          req.attribute ? `${req.attribute}/` : ''
        }`,
        method: 'post',
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(this.baseURL && { baseURL: this.baseURL }),
        ...(req.headers && { headers: req.headers }),
        ...(req.extraOptions || {}),
        toast: req.toast,
        signal: req.signal,
      },
    );
  }

  async get(req = {}) {
    return apiWrapper(
      {
        url: `${req.url || this.url}/${req.id ? `${req.id}/` : ''}${
          req.attribute ? `${req.attribute}` : ''
        }`,
        ...(req.query && { params: req.query }),
        ...(this.baseURL && { baseURL: this.baseURL }),
        ...(req.headers && { headers: req.headers }),
        toast: req.toast,
        signal: req.signal,
      },
    );
  }

  async put(req = {}) {
    return apiWrapper(
      {
        url: `${req.url || this.url}/${req.id ? `${req.id}/` : ''}${
          req.attribute ? `${req.attribute}/` : ''
        }`,
        method: 'put',
        data: req.body,
        ...(this.baseURL && { baseURL: this.baseURL }),
        ...(req.headers && { headers: req.headers }),
        toast: req.toast,
        signal: req.signal,
      },
    );
  }

  async patch(req = {}) {
    return apiWrapper(
      {
        url: `${req.url || this.url}${req.id ? `/${req.id}` : ''}${
          req.attribute ? `/${req.attribute}` : ''
        }`,
        ...(req.query && { params: req.query }),
        method: 'patch',
        data: req.body,
        ...(req.headers && { headers: req.headers }),
        ...(this.baseURL && { baseURL: this.baseURL }),
        toast: req.toast,
        signal: req.signal,
      },
    );
  }

  async delete(req = {}) {
    return apiWrapper(
      {
        url: `${req.url || this.url}${req.id ? `/${req.id}` : ''}${
          req.attribute ? `/${req.attribute}` : ''
        }`,
        method: 'delete',
        data: req.body,
        ...(req.query && { params: req.query }),
        ...(req.headers && { headers: req.headers }),
        ...(this.baseURL && { baseURL: this.baseURL }),
        toast: req.toast,
        signal: req.signal,
      },
    );
  }
}
