<script setup>
import { useClipboard } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { Validator } from '@vueform/vueform';
import { useAuthStore } from '~/auth/stores/auth.store';
import { $toast } from '~/common/utils/common.utils';
import { useCommonStore } from '~/common/stores/common.store.js';

const emit = defineEmits(['close', 'save']);

const $track_event = inject('$track_event');
const $services = inject('$services');
const source = ref('Hello');
const { isSupported } = useClipboard({ source });

const auth_store = useAuthStore();
const common_store = useCommonStore();
const route = useRoute();
const router = useRouter();
const isValidEmail = ref({});
const loading = ref(false);
const fetched_users = ref([]);
const form$ = ref(null);
const form = ref({
  emails: '',
  user_type: null,
  paid: true,
});
const sharable_link = ref('');

const users_in_assets = computed(() => {
  return common_store?.members_scope_users(route?.params?.asset_id) || [];
});

const checkEmails = class extends Validator {
  get message() {
    return 'Please enter valid email address';
  }

  check(value) {
    // Custom validation, check each value(type-object) whether it is a valid email address
    return Object.values(value).map((val) => {
      const emailFormat = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      isValidEmail.value[val] = val.match(emailFormat);
      return !val.match(emailFormat);
    }).every(email => !email);
  }
};

const checkMemberExists = class extends Validator {
  get message() {
    return 'Member Already Exists';
  }

  check(value) {
    // Custom validation, check each value(type-object) whether it is a valid email address
    return value.map((val) => {
      const userExists = users_in_assets.value?.map(user => user.email)?.includes(val);
      if (userExists)
        isValidEmail.value[val] = null;
      return userExists;
    }).every(email => !email);
  }
};

async function getLink(method = 'get') {
  try {
    loading.value = true;
    const response = await $services.organizations[method]({
      id: auth_store.current_organization?.uid,
      attribute: 'generate-invite-link',
      query: { asset_id: route?.params?.asset_id },
    });
    sharable_link.value = response?.data?.url;
    loading.value = false;
  }
  catch (error) {
    loading.value = false;
    logger.log('error', error);
  }
}

async function getSharableLink() {
  if (sharable_link.value?.length) {
    const { copy } = useClipboard({ source: sharable_link.value });
    copy();
    $toast({
      text: 'Url copied!',
      type: 'success',
    });
  }
  else {
    $toast({
      title: 'Something went wrong',
      text: 'Please try again',
      type: 'error',
    });
  }
}
const items = ref([
  {
    value: 0,
    name: 'Label',
  }]);

function added(email) {
  logger.log('added', email);
}

async function save() {
  try {
    loading.value = true;
    const payload = [];
    await form.value.emails.forEach((email) => {
      payload.push({
        uid: '',
        email,
        user_type: 'member',
        paid: true,
      });
    });
    const body = {
      invitations: payload,
    };
    if (route?.params?.asset_id)
      body.asset = route?.params?.asset_id;
    const response = await $services.organizations.invite_users({
      organization_id: auth_store.current_organization?.uid,
      body,
    });
    if (response?.data?.length || response.status === 200) {
      $toast({
        text: 'User invited successfully',
        type: 'success',
      });
      $track_event('invite_user', {
        count: response.data.length,
        members: response.data.filter(val => val.user_type === 'member').length,
        paid: response.data.filter(val => val.paid === true).length,
        new_to_org: false,
        where: 'Default',
      });
      emit('close');
      emit('save');
    }
    else {
      $toast({
        title: 'Something went wrong',
        text: 'Please try again',
        type: 'error',
      });
    }

    loading.value = false;
  }
  catch (error) {
    $toast({
      title: 'Something went wrong',
      text: error.data?.message,
      type: 'error',
    });
    loading.value = false;
  }
}

async function setUsers(search) {
  const response = await $services.users.getAll({
    query: {
      search,
      asset: false,
    },
  });
  // filtering the users which is already in the assets
  fetched_users.value = response?.data?.users.filter((user) => {
    return !users_in_assets.value.map(user => user?.uid).includes(user?.uid);
  });
}

onMounted(async () => {
  if (route?.params?.asset_id)
    setUsers();

  await getLink();
});
</script>

<template>
  <hawk-modal-container>
    <Vueform
      ref="form$"
      v-model="form"
      size="sm"
      :display-errors="false"
      :format-load="(data) => data"
      :endpoint="save"
    >
      <div class="col-span-12">
        <hawk-modal-header @close="$emit('close')">
          <template #left>
            <div class="flex items-center gap-4">
              <div class="border h-12 w-12 grid place-content-center rounded-[10px]">
                <IconHawkUsersPlus />
              </div>
              <div>
                <div>{{ $t('Invite Members') }}</div>
                <div class="text-sm text-gray-600 font-normal">
                  <p v-if="route?.params?.asset_id">
                    {{ `${$t('Invite your team members to ')} ${common_store?.active_asset?.name}` }}:
                  </p>
                  <p v-else>
                    {{ `${$t('Invite your team members to ')} ${auth_store.current_organization?.name}` }}:
                  </p>
                </div>
              </div>
            </div>
          </template>
        </hawk-modal-header>
        <hawk-modal-content :is_scroll="false">
          <div v-if="sharable_link?.length" class="mb-6">
            <div class="mb-3 text-gray-700 text-sm font-semibold">
              {{ $t('Invite link') }}
            </div>
            <div class="flex item-center gap-4">
              <TextElement
                :key="sharable_link"
                name="sharable_link"
                class="w-full"
                readonly
                :default="sharable_link"
              >
                <template #addon-after>
                  <HawkLoader v-if="loading" container_class="m-0" height="5" width="5" />
                  <IconHawkRefreshCwTwo v-else v-tippy="{ content: $t('Regenerate link'), placement: 'bottom' }" class="w-4 h-4 cursor-pointer" @click="getLink('post')" />
                </template>
              </TextElement>
              <HawkButton type="outlined" class="flex-shrink-0" @click="getSharableLink">
                {{ $t('Copy Link') }}
              </HawkButton>
            </div>
          </div>
          <div>
            <div class="mb-3 text-gray-700 text-sm font-semibold">
              {{ $t('Invite by email') }}
            </div>
            <hawk-email-tags-input
              class="mb-4"
              :options="{
                'name': 'emails',
                'placeholder': $t('Enter email address'),
                'tags_removable': true,
                'create': true,
                'handleCreateInline': added,
                'class': 'mb-4',
                'show-options': !!$route?.params?.asset_id,
                'isValidEmail': isValidEmail,
                'description': $t('Please press enter to add an email'),
                'label-prop': 'email',
                'value-prop': 'email',
                'loading': false,
                'close-on-select': true,
                'track-by': 'email',
              }"
              :items="fetched_users"
              :rules="['required', checkEmails, checkMemberExists]"
            />
          </div>
        </hawk-modal-content>
        <hawk-modal-footer>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="$emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement name="submit" button-class="w-full bg-blue-600" :submits="true">
                {{ $t('Invite') }}
              </ButtonElement>
            </div>
          </template>
        </hawk-modal-footer>
      </div>
    </Vueform>
  </hawk-modal-container>
</template>
