<script setup>
import { useCommonImports } from '~/common/composables/common-imports.composable.js';

defineEmits(['onInit']);
const IconIllustrationInventoryGetStarted = defineAsyncComponent(() => import('~icons/illustration/inventory-get-started.vue'));
const { $t, auth_store, common_store } = useCommonImports();

async function handleChatWithUs() {
  common_store.toggle_chat_with_us(true);
}
</script>

<template>
  <div class="h-[calc(100vh-300px)] grid place-content-center">
    <div class="max-w-[520px] flex items-center justify-center flex-col">
      <IconIllustrationInventoryGetStarted class="w-44 mb-3" />
      <div class="text-2xl font-semibold mb-2 text-gray-900">
        {{ $t('Welcome to Inventory') }}
      </div>
      <div class="text-base text-gray-500 text-center mb-5">
        {{ $t('Stay on top of things and keep track of all your stock to be used in your projects') }}
      </div>
      <HawkButton v-if="!auth_store.is_hacker_user" @click="$emit('onInit')">
        {{ $t('Get Started ') }}
      </HawkButton>
      <HawkButton v-else @click="handleChatWithUs">
        {{ $t('Contact us') }}
      </HawkButton>
    </div>
  </div>
</template>
